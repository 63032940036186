// @ts-nocheck

import { setX, setXY, setY } from "./transformUtils";
import { D, pointAt, toDegree, toRadians } from "./utils";

const THRESHOLD = 5;
const lineSize = 100;
const MOVEMENT_THRESHOLD = 10;

function getSnapPoints(item) {
  const x = item.x || item.mpTransforms?.translate?.x || 0;
  const y = item.y || item.mpTransforms?.translate?.y || 0;

  if (item.proseMirrorData) {
    return [
      {
        x: x,
        y: y,
      },
    ];
  }

  return [
    {
      x: x + item.width / 2,
      y: y + item.height / 2,
    },
  ];
}

export const snapMouse = (e, xy, z) => {
  if (!z.shouldSnap || z.selectedIds.length == 0) return;

  if (Math.abs(e.movementX) > MOVEMENT_THRESHOLD && Math.abs(e.movementY) > MOVEMENT_THRESHOLD) return;

  z.snapLines = [];
  z.setSnapLines([]);

  let sel = [],
    unsel = [];

  for (let id in z.items) {
    const item = z.items[id];
    const points = getSnapPoints(item);
    const diffs = points.map((p) => {
      return {
        x: p.x - (item.x || item.mpTransforms?.translate?.x || 0),
        y: p.y - (item.y || item.mpTransforms?.translate?.y || 0),
      };
    });
    const obj = {
      id: id,
      points,
      diffs,
    };
    if (z.selectedIds.includes(id)) {
      sel.push(obj);
    } else {
      unsel.push(obj);
    }
  }

  for (let p of sel) {
    for (let q of unsel) {
      for (let pi = 0; pi < p.points.length; pi++) {
        const pp = p.points[pi];
        for (let qq of q.points) {
          if (Math.abs(e.movementX) <= MOVEMENT_THRESHOLD && Math.abs(pp.x - qq.x) < THRESHOLD) {
            let item = z.items[p.id];
            setX(item, qq.x - p.diffs[pi].x);

            z.update({ items: z.items, groups: z.groups, defs: z.defs }, false);

            z.snapLines.push({
              p1: {
                x: qq.x,
                y: pp.y,
              },
              p2: qq,
            });
            z.setSnapLines(z.snapLines);

            setTimeout(() => {
              z.setSnapLines([]);
            }, 3000);
            return;
          }

          if (Math.abs(e.movementY) <= MOVEMENT_THRESHOLD && Math.abs(pp.y - qq.y) < THRESHOLD) {
            let item = z.items[p.id];
            setY(item, qq.y - p.diffs[pi].y);
            z.update({ items: z.items, groups: z.groups, defs: z.defs }, false);

            z.snapLines.push({
              p1: {
                x: pp.x,
                y: qq.y,
              },
              p2: qq,
            });
            z.setSnapLines(z.snapLines);

            setTimeout(() => {
              z.setSnapLines([]);
            }, 3000);
            return;
          }
        }
      }
    }
  }
};
