import { useEffect, useState } from "react";
import { useSVG } from "./SVGContext";
import { Button, Card, IconButton, Link, Menu, MenuItem } from "@mui/material";
import "./Designs.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { fetchDesigns, updateDesignServer } from "./utils/ServerUtils";

export function Designs({}) {
  const { designs, setDesigns } = useSVG();

  function reload() {
    fetchDesigns()
      .then(setDesigns)
      .catch((error) => {
        console.error("Error fetching designs:", error);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 20,
        justifyContent: "start",
        alignItems: "start",
        padding: 20,
        overflowY: "scroll",
      }}
    >
      <Blank />
      {designs && designs.map((design) => <Design design={design} reload={reload} />)}
    </div>
  );
}

function Blank({}) {
  function createNewDesign() {
    window.location.href = "/?loggedin";
  }
  return (
    <Card style={{ width: 200, height: 250 }} className="design blank" onClick={createNewDesign}>
      <div
        style={{
          width: 200,
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: 100,
            color: "grey",
          }}
        >
          +
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
    </Card>
  );
}
function Design({ design, reload }) {
  function updateDesignTitle(title) {
    updateDesignServer(design.id, {
      title: title,
    });
  }
  return (
    <Card style={{ width: 200 }} className="design">
      <div
        style={{
          width: 200,
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "cover",
          }}
          src={design.preview}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="single-line-contenteditable"
          contentEditable
          style={{
            padding: 15,
            textAlign: "center",
          }}
          onKeyDown={(e) => {
            // if enter, then save title
            if (e.key === "Enter") {
              e.preventDefault();
              // updateDesignTitle(e.target.innerText);
              // blur
              e.target.blur();
            }
          }}
          onBlur={(e) => updateDesignTitle(e.target.innerText)}
        >
          {design.title || "Untitled"}
        </div>
        <ThreeDots design={design} onComplete={reload} />
      </div>
      <div className="edit">
        <Button
          variant="contained"
          onClick={() => {
            window.location.href = "/design/" + design.id + "?loggedin";
          }}
        >
          Edit
        </Button>
      </div>
    </Card>
  );
}
function ThreeDots({ design, onComplete }) {
  const { deleteDesign } = useSVG();

  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <div className="threedots">
      <IconButton
        onClick={(e) => {
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            deleteDesign(design.id, onComplete);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
