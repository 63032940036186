import { useState, useEffect, useRef } from "react";
import "./css/Gallery.css";
import LazyImage from "./library/LazyImage";
import { getTemplatePreviewUrl } from "./utils/utils";
import { LazyImageWithAddBtn } from "./library/LazyImageWithAddBtn";
import { useSVG } from "./SVGContext";

const Gallery = ({ data, render, cols }) => {
  const containerRef = useRef(null);

  const [itemWidth, setItemWidth] = useState(0);
  let [columns, setColumns] = useState(3);
  let [columnContents, setColumnContents] = useState([]);
  let [columnHeights, setColumnHeights] = useState([]);

  const handleResize = () => {
    const c = containerRef.current;
    if (!c) return;
    const width = c.getBoundingClientRect().width;

    if (cols) {
      columns = cols;
      setColumns(cols);
    } else if (width <= 768) {
      columns = 3;
      setColumns(3);
      setItemWidth("100%");
      return;
    } else if (width <= 1024) {
      columns = 4;
      setColumns(4);
    } else if (width <= 2048) {
      columns = 5;
      setColumns(5);
    }
    const gap = 20;
    setItemWidth((width - gap * (columns - 1)) / columns);
    putDataInColumns();
  };

  function putDataInColumns() {
    // you cannot delete items and expect this to work well
    // for each item, add it to the column with the least height

    columnHeights = Array.from({ length: columns }, () => 0);
    columnContents = Array.from({ length: columns }, () => []);

    for (let d of data) {
      const index = columnHeights.indexOf(Math.min(...columnHeights));
      columnHeights[index] += d.height;

      columnContents[index] = columnContents[index] || [];
      columnContents[index].push(d);
    }
    setColumnHeights(columnHeights);
    setColumnContents(columnContents);
  }

  useEffect(() => {
    // window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      // window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    putDataInColumns();
  }, [data]);

  return (
    <div
      key={data.length}
      ref={containerRef}
      className="gallery-container"
      style={{
        overflowY: "scroll",
      }}
    >
      <div className="gallery-grid">
        {columnContents.map((column, index) => (
          <div key={index} className="gallery-column">
            {column.map((item) => (
              <div key={item.id} className="gallery-item">
                {render(item, itemWidth)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
