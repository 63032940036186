import { Button, Drawer, Link } from "@mui/material";
import { useSVG } from "./SVGContext";
import { Banner } from "./Banner";
import { useEffect } from "react";
import { fetchDesigns } from "./utils/ServerUtils";

export function MyDrawer({}) {
  const { drawerOpen, setDrawerOpen, designs, setDesigns } = useSVG();

  useEffect(() => {
    if (drawerOpen && !designs) {
      fetchDesigns().then(setDesigns);
    }
  }, [drawerOpen]);

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <div>
        <Banner />

        <div
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "flex-start",
            alignItems: "start",

            maxWidth: "70vw",
          }}
        >
          <Button
            variant="outlined"
            style={{
              width: "100%",
              margin: "10px 0",
            }}
          >
            + Create a Design
          </Button>
          <DesignsList />
        </div>
      </div>
    </Drawer>
  );
}

function DesignsList({}) {
  const { designs, design } = useSVG();
  if (!designs) return null;
  return (
    <>
      <DesignsTitlebar />
      {designs.map((des) => (
        <div
          key={design.id}
          className="sidebar-design-list-item"
          style={{
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: 10,
            padding: 10,
            width: 300,
            backgroundColor: des.id == design.id ? "#fbecfc" : "white",
          }}
          onClick={() => {
            window.open(`/design/${des.id}`, "_blank");
          }}
        >
          {des.preview != "" && (
            <img
              src={des.preview}
              style={{
                width: 20,
                height: 20,
                marginRight: 10,
                borderRadius: 5,
                border: "1px solid lightgray",
              }}
            />
          )}
          <div className="single-line">{des.title}</div>
        </div>
      ))}
    </>
  );
}

function DesignsTitlebar() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: "1px solid lightgray",
      }}
    >
      <span
        style={{
          //   fontSize: "1.2rem",
          marginBottom: 5,
        }}
      >
        Recent Designs
      </span>
      <span
        className="see-all"
        onClick={() => {
          window.open("/designs", "_blank");
        }}
      >
        See All
      </span>
    </div>
  );
}
