import { Box, IconButton, Popper, Slider, TextField } from "@mui/material";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { useState } from "react";
import { useSVG } from "../SVGContext";

export function RoundCorners({}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { update, items, groups, defs, selectedIds } = useSVG();

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <HighlightAltIcon />
      </IconButton>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",

            backgroundColor: "white",
            border: "solid 1px #ccc",
            borderRadius: 5,
            boxShadow: "0px 5px 5px #929292",
            marginTop: 5,
            padding: 10,
          }}
        >
          <Slider
            min={0}
            max={100}
            value={selectedIds.length == 1 && items[selectedIds[0]].type == "rectangle" ? items[selectedIds[0]].rx : 0}
            onChange={(e, value) => {
              for (let id of selectedIds) {
                if (items[id].type != "rectangle") continue;
                items[id].rx = value;
                items[id].ry = value;
              }
              update({ items, groups, defs, selectedIds });
            }}
            style={{
              width: 200,
            }}
          />
        </Box>
      </Popper>
    </>
  );
}
