export const LABELS = {
  English: {
    Appearance: "Appearance",
    Objects: "Objects",
    Library: "Library",
    Export: "Export",
    History: "History",

    // export details
    "File Name": "File Name",
    Format: "Format",
  },
  Spanish: {
    Appearance: "Apariencia",
    Objects: "Objetos",
    Library: "Biblioteca",
    Export: "Exportar",
    History: "Historia",
  },
  Tamil: {
    Appearance: "தோற்றம்",
    Objects: "பொருள்கள்",
    Library: "நூலகம்",
    Export: "ஏற்றுமதி",
    History: "வரலாறு",
  },
};
