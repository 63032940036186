import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import RazorpayPayment from "./RazorpayPayment";
import { LOGIN_URL, useSVG } from "./SVGContext";
import { Button, CircularProgress, Dialog, DialogContent, IconButton, Link, Menu, MenuItem, TextareaAutosize, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import HambergerMenu from "@mui/icons-material/Menu";
import { EditableDiv } from "./EditableDiv";
import { createDesignServer, req, updateDesignServer } from "./utils/ServerUtils";
import DoneIcon from "@mui/icons-material/Done";
import { ROOT_GROUP_ID, SCALE_PREVIEW } from "./values/constants";
import ShareBtn from "./ShareBtn";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import PreviewIcon from "@mui/icons-material/Preview";
import { AiTemplateCreator } from "./AiTemplateCreator";
import EditProfileDialog from "./EditProfileDialog";
import { Credits } from "./Credits";
import { HelpDialog } from "./HelpDialog";
import { LayoutFinder } from "./LayoutFinder";
import { HamburgerLogo } from "./HamburgerLogo";

export function EditorNavBar({}) {
  const { isMobile, design, setDrawerOpen, userInfo } = useSVG();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 5,
        justifyContent: "space-between",
        alignItems: "center",
        padding: 8,

        background: "linear-gradient(33deg, rgb(46 118 61) 30%, rgb(41 158 52) 60%)",
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 15,
          justifyContent: "space-between",
          alignItems: "center",

          background: "transparent",
        }}
      >
        <HamburgerLogo />
        <Credits />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          justifyContent: "space-between",
          alignItems: "center",

          background: "transparent",
        }}
      >
        {design && (
          <>
            <Undo />
            <Redo />
            {!isMobile && <Title />}
            <LayoutFinder />
            {userInfo?.permissions.includes("can_ai_generate") && <Preview />}
            <ShareBtn />
            <Save />
            <Download />
          </>
        )}

        <ProfilePic />

        {!userInfo && (
          <Link
            underline="none"
            variant="button"
            href={LOGIN_URL}
            style={{
              textTransform: "none",
              color: "white",
            }}
          >
            Login
          </Link>
        )}
      </div>
    </div>
  );
}
function Title() {
  const { design, setDesign } = useSVG();
  return (
    <EditableDiv
      text={design.title}
      setText={(t) => {
        setDesign({ ...design, t });
      }}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 200,
      }}
      className="design-title"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.target.blur();
        }
        if (e.key === "Escape") {
          e.target.innerText = design.title;
          e.target.blur();
        }
      }}
      onBlur={(e) => {
        setDesign({ ...design, title: e.target.innerText });

        updateDesignServer(design, {
          title: e.target.innerText,
        });
      }}
    />
  );
}
function Download() {
  const [anchorEl, setAnchor] = useState(null);
  const [paymentDialog, setPaymentDialog] = useState(false);
  let [format, setFormat] = useState("png");

  async function download(f) {
    setAnchor(null);
    format = f;
    setFormat(format);
    setPaymentDialog(true);
  }
  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={(e) => setAnchor(anchorEl ? null : e.currentTarget)}
        sx={{
          borderRadius: "12px",
          textTransform: "none",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          background: "rgb(229 229 229)",
          border: "1px solid white",
          color: "#333",
          borderRadius: 5,
        }}
      >
        <DownloadIcon
          style={{
            fontSize: "1rem",
          }}
        />
        <span className="min-width-1000">Download</span>
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem onClick={async () => await download("png")}>Download PNG</MenuItem>
        <MenuItem onClick={async () => await download("pdf")}>Download PDF</MenuItem>
      </Menu>

      <Dialog
        open={paymentDialog}
        onClose={() => setPaymentDialog(false)}
        style={{
          padding: 20,
        }}
      >
        <RazorpayPayment format={format} setPaymentDialog={setPaymentDialog} />
      </Dialog>
    </>
  );
}
export function attachBoxes(items, design, getBox) {
  for (let id in items) {
    let box = getBox(id);
    if (!box) continue;
    box = {
      x: box.x,
      y: box.y,
      boxHeight: box.height,
      boxWidth: box.width,
    };

    items[id] = {
      ...items[id],
      box,
    };
  }
  design.pages[0].items = items;
}

function Save() {
  const [anchorEl, setAnchor] = useState(null);
  const [saving, setSaving] = useState(false);
  const { getBox, fileManager, svgRef, width, height, items, setFullscreenLoading, design, setDesign, userInfo, selectedIds } = useSVG();

  function save() {
    setAnchor(null);
    setSaving(true);

    // if it's a template, attach boxes first
    if (design.isTemplate) {
      attachBoxes(items, design, getBox);
    }

    fileManager
      .getPreviewImage({
        svgRef,
        width,
        height,
        items,
        scale: SCALE_PREVIEW,
      })
      .then((canvas) => {
        const base64 = canvas.toDataURL("image/png");
        design.preview = base64;
        setDesign({ ...design });

        updateDesignServer(design)
          .then((res) => {
            setDesign({ ...design, version: res.version });
            setSaving("saved");

            if (design.isTemplate || design.isBlock) return;
            uploadInfographic();
            setTimeout(() => {
              setSaving(false);
            }, 3000);
          })
          .catch((e) => {
            setSaving(false);
          });
      });
  }
  function saveAs() {
    setFullscreenLoading(true);

    createDesignServer({
      ...design,
      title: design.title + " (Copy)",
      template_id: design.templateId,
    })
      .then((res) => {
        setFullscreenLoading(false);
        window.location.href = "/design/" + res.design_id;
      })
      .catch((e) => {
        setFullscreenLoading(false);
      });
  }

  async function uploadInfographic() {
    setFullscreenLoading(true);
    return fileManager
      .getPreviewImage({
        svgRef,
        width: design.width,
        height: design.height,
        items,
        scale: 2,
      })
      .then(async (canvas) => {
        const base64 = canvas.toDataURL("image/png");
        const blob = await (await fetch(base64)).blob();

        // TODO: make it such that the name of this png is the design ID
        // maybe for each design, generate a md5 or sha3 hash and use that as the image name in CDN
        const width = design.width;
        const height = design.height;
        const formData = new FormData();
        formData.append("image", blob, design.title + ".png");
        formData.append("width", width);
        formData.append("height", height);
        formData.append("url", design.metainfo?.input_url || "");
        formData.append("version", design.version);

        if (!design.isTemplate && !design.isBlock) {
          formData.append("design_id", design.id);
        }

        req("/infographic", "POST", formData, false)
          .then((res) => {
            setFullscreenLoading(false);
            if (res.url) window.open(res.url, "_blank");
            else console.error(res);
          })
          .catch((e) => {
            setFullscreenLoading(false);
            console.error(e);
          });
      })
      .catch((e) => {
        setFullscreenLoading(false);
        console.error(e);
      });
  }
  function saveAsBlock() {
    setFullscreenLoading(true);
    // gather selectedIds

    const newItems = {};
    for (const id of selectedIds) {
      newItems[id] = {
        ...items[id],
        group: ROOT_GROUP_ID,
      };
    }

    // crop
    const minX = Math.min(...selectedIds.map((id) => items[id].x));
    const minY = Math.min(...selectedIds.map((id) => items[id].y));
    for (const id of selectedIds) {
      newItems[id].x -= minX;
      newItems[id].y -= minY;
    }
    const maxWidth = Math.max(...selectedIds.map((id) => newItems[id].x + newItems[id].width));
    const maxHeight = Math.max(...selectedIds.map((id) => newItems[id].y + newItems[id].height));

    const groups = {
      [ROOT_GROUP_ID]: {
        id: ROOT_GROUP_ID,
        children: selectedIds,
      },
    };

    req(
      "/blocks",
      "POST",
      JSON.stringify({
        pages: [
          {
            items: newItems,
            groups,
            defs: [],
            width: maxWidth,
            height: maxHeight,
          },
        ],
      }),
    )
      .then((res) => {
        setFullscreenLoading(false);
        window.open("/block/" + res.block_id, "_blank");
      })
      .catch((e) => {
        setFullscreenLoading(false);
        console.error(e);
      });
  }

  return (
    <>
      <IconButton
        onClick={(e) => setAnchor(anchorEl ? null : e.currentTarget)}
        variant="outline"
        style={{
          color: "#ccc",
        }}
      >
        {saving == true && (
          <CircularProgress
            style={{
              height: 24,
              width: 24,
            }}
          />
        )}
        {saving == false && <SaveIcon />}
        {saving == "saved" && <DoneIcon />}
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem onClick={save}>Save</MenuItem>
        <MenuItem onClick={saveAs}>Save as New</MenuItem>
        {userInfo?.permissions.includes("can_ai_generate") && <MenuItem onClick={saveAsBlock}>Save as Block</MenuItem>}
      </Menu>
    </>
  );
}
function Undo() {
  const { undo } = useSVG();
  return (
    <IconButton
      style={{
        color: "#ccc",
      }}
      onClick={undo}
    >
      <UndoIcon />
    </IconButton>
  );
}
function Redo() {
  const { redo } = useSVG();
  return (
    <IconButton
      style={{
        color: "#ccc",
      }}
      onClick={redo}
    >
      <RedoIcon />
    </IconButton>
  );
}

function Preview() {
  const { setLeftPanel } = useSVG();
  return (
    <>
      <IconButton
        size="small"
        onClick={() => {
          setLeftPanel("preview");
        }}
        style={{
          color: "#ccc",
        }}
      >
        <PreviewIcon />
      </IconButton>
    </>
  );
}

function Generate() {
  const { setFullscreenLoading, design } = useSVG();
  const [blogUrl, setBlogUrl] = useState("");
  function generate() {
    if (!design.templateId) {
      console.error("Template ID not available");
      return;
    }
    setFullscreenLoading(true);

    req(
      "/generatev2",
      "POST",
      JSON.stringify({
        url: blogUrl,
        template_id: design.templateId,
        dontReplace: true,
      }),
    )
      .then((res) => {
        setFullscreenLoading(false);

        window.open("/design/" + res.design_id, "_blank");
      })
      .catch((e) => {
        setFullscreenLoading(false);
      });
  }
  return (
    <>
      <Button
        size="small"
        variant="contained"
        className="navbar-btn"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
        onClick={() => {
          setShowDialog(true);
        }}
      >
        Generate!
      </Button>

      <Dialog open={showDialog != null} onClose={() => setShowDialog(null)}>
        <div
          style={{
            height: 500,
            width: 600,

            padding: 20,

            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "start",
            alignItems: "stretch",
          }}
        >
          <TextField
            label="Blog Url"
            variant="outlined"
            fullWidth
            value={blogUrl}
            onChange={(e) => {
              setBlogUrl(e.target.value);
            }}
          />
          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={() => {
              setShowDialog(null);
              generate();
            }}
            fullWidth
          >
            Generate Ideas
          </Button>
        </div>
      </Dialog>
    </>
  );
}

function ProfilePic() {
  const { userInfo, setUserInfo } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDialog, setShowDialog] = useState(null);
  const fileRef = useRef(null);
  const [src, setSrc] = useState(userInfo?.profile_pic_cropped || "/images/profilepic.jpg");
  const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    fetch(userInfo?.profile_pic_cropped + "?t=" + new Date().getTime())
      .then((res) => {
        if (res.status === 404) {
          setSrc("/images/profilepic.jpg");
        } else {
          setSrc(userInfo?.profile_pic_cropped + "?t=" + new Date().getTime());
        }
      })
      .catch((e) => {
        setSrc("/images/profilepic.jpg");
        console.error(e);
      });
  }, [userInfo]);

  function verifyAndUpload(key, file, api) {
    if (!file.type.startsWith("image/")) {
      error("File is not an image.");
      return Promise.reject("File is not an image.");
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const formData = new FormData();
          formData.append(key, file);

          api(formData)
            .then((res) => {
              if (res.error) {
                reject(res.error);
              } else {
                resolve(res);
              }
            })
            .catch((e) => reject(e));
        };
        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    });
  }

  function onFileUpload(e) {
    verifyAndUpload("profile_pic_uncropped", e.target.files[0], (formData) => {
      return req("/upload_profile_pic", "POST", formData, false);
    })
      .then((res) => {
        userInfo.profile_pic_cropped = res.profile_pic_url;
        setUserInfo({ ...userInfo });
      })
      .catch((e) => {
        console.error(e);
      });
    fileRef.current.value = ""; // Without this, clicking it 2nd time doesn't work
  }

  function onCropComplete(blob) {
    const formData = new FormData();
    formData.append("profile_pic_cropped", blob, "cropped-image.png");
    req("/upload_profile_pic", "POST", formData, false)
      .then((res) => {
        userInfo.profile_pic_cropped = res.profile_pic_url;
        setUserInfo({ ...userInfo });
      })
      .catch((error) => console.error(error));
  }

  return (
    <>
      <div
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <img
          src={src}
          alt="Profile"
          style={{
            height: "30px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        />
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setShowEditProfileDialog("profile");
            setAnchorEl(null);
          }}
        >
          Your Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowEditProfileDialog("tokens");
            setAnchorEl(null);
          }}
        >
          Your Tokens
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setShowHelp(true);
          }}
        >
          Help
        </MenuItem>
        <MenuItem
          onClick={() => {
            req("/logout", "POST", {}).then(() => {
              window.location.href = "/login";
            });
            setAnchorEl(null);
          }}
        >
          Logout
        </MenuItem>
      </Menu>

      <input style={{ display: "none" }} ref={fileRef} type="file" accept="image/*" onChange={(e) => onFileUpload(e)} />

      <EditProfileDialog dialog={showEditProfileDialog} onClose={() => setShowEditProfileDialog(false)} onSave={(data) => console.log(data)} user={userInfo} />
      <HelpDialog showHelp={showHelp} setShowHelp={setShowHelp} />
    </>
  );
}
