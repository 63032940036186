export function Privacy() {
  return (
    <div class="container my-5">
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> September 14, 2024
      </p>

      <h2>1. Information We Collect</h2>
      <ul>
        <li>Personal Information: Name and email address.</li>
        <li>Account Details: Username and password</li>
        <li>Financial Data: Payment information for purchases.</li>
        <li>Technical Data: IP address, browser type, device, browsing activity.</li>
        <li>Usage Data: Interactions with our platform, including design templates used or saved.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>Service Delivery: To provide access to our design tools and templates.</li>
        <li>Personalization: Customize your experience and recommend templates.</li>
        <li>Analytics and Improvements: Improve platform functionality and features.</li>
        <li>Customer Support: Respond to inquiries and support requests.</li>
        <li>Marketing: With your consent, we may send marketing materials or offers.</li>
      </ul>

      <h2>3. Data Sharing and Third Parties</h2>
      <p>We do not sell, lease, or rent your data. We may share your data with:</p>
      <ul>
        <li>Service Providers: For hosting, payment processing, and analytics.</li>
        <li>Compliance with Laws: When required by legal authorities.</li>
      </ul>

      <h2>4. Cookies and Tracking</h2>
      <p>We use cookies to enhance user experience, track preferences, and analyze site usage. You can opt-out via browser settings.</p>

      <h2>5. Data Security</h2>
      <p>We use industry-standard measures to secure your data. However, no method of transmission over the Internet is 100% secure.</p>

      <h2>6. Your Privacy Rights</h2>
      <ul>
        <li>Access: Request access to the data we hold about you.</li>
        <li>Correction: Update or correct your personal information.</li>
        <li>Erasure: Request the deletion of your data.</li>
        <li>Objection: Opt-out of certain data processing activities.</li>
        <li>Data Portability: Request your data in a machine-readable format.</li>
      </ul>

      <h2>7. Children’s Privacy</h2>
      <p>Our services are not intended for users under the age of 13, and we do not knowingly collect data from children.</p>

      <h2>8. Data Retention</h2>
      <p>We retain your personal data as long as your account is active or necessary to provide our services.</p>

      <h2>9. Changes to This Policy</h2>
      <p>We may update this policy periodically. All changes will be posted on our website, and significant updates will be communicated via email.</p>

      <h2>10. Contact Us</h2>
      <p>
        For questions, contact us at <a href="mailto:support@designkart.in">support@designkart.in</a>.
      </p>
    </div>
  );
}
