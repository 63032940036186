import { useEffect, useState } from "react";
import { req } from "../utils/ServerUtils";
import { SVGGroupView } from "../views/SVGGroupView";
import { ROOT_GROUP_ID } from "../values/constants";
import { useSVG } from "../SVGContext";
import { Button, Dialog, DialogContent, TextField } from "@mui/material";

export function BlocksPanel() {
  const { items, groups, update, setSelectedIds } = useSVG();
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [blocks, setBlocks] = useState([]);

  const [rows, setRows] = useState(1);
  const [cols, setCols] = useState(1);
  const [selectedBlock, setSelectedBlock] = useState(null);

  useEffect(() => {
    req("/blocks", "GET").then((data) => {
      setBlocks(data);
    });
  }, []);

  function getBlockSize(block) {
    let width = 0,
      height = 0;
    for (let id in block.pages[0].items) {
      const item = block.pages[0].items[id];
      width = Math.max(width, item.x + item.width);
      height = Math.max(height, item.y + item.height);
    }
    return { width, height };
  }

  function add() {
    let ids = [];

    const { width: blockWidth, height: blockHeight } = getBlockSize(selectedBlock);
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        ids = [...ids, ...addBlock(selectedBlock, j * blockWidth, i * blockHeight)];
      }
    }
    setSelectedIds(ids);
  }

  function addBlock(block, dx, dy) {
    const ids = [];
    for (let id in block.pages[0].items) {
      const newid = Math.random().toString(36).substring(7);
      const item = JSON.parse(JSON.stringify(block.pages[0].items[id]));
      item.id = newid;
      item.x += dx;
      item.y += dy;

      items[item.id] = item;
      groups[ROOT_GROUP_ID].children.push(item.id);
      ids.push(item.id);
    }
    update({ items, groups });
    return ids;
  }

  return (
    <>
      <div>
        {blocks.map((block, i) => (
          <div
            key={i}
            style={{
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedBlock(block);
              setShowAddOptions(true);
            }}
          >
            <img
              src={block.preview}
              style={{
                width: "100%",
              }}
            />
          </div>
        ))}
      </div>
      <Dialog open={showAddOptions} onClose={() => setShowAddOptions(false)}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            aligItems: "stretch",
            gap: 20,
            padding: 20,
            // backgroundColor: "white",
            // color: "black",
          }}
        >
          <TextField type="number" label="Rows" fullWidth value={rows} onChange={(e) => setRows(e.target.value)} />
          <TextField type="number" label="Columns" fullWidth value={cols} onChange={(e) => setCols(e.target.value)} />
          <Button
            fullWidth
            variant="contained"
            style={{
              textTransform: "none",
            }}
            onClick={() => {
              add();
              setShowAddOptions(false);
            }}
          >
            Add
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
