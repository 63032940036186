import { useEffect, useState } from "react";
import { useSVG } from "../SVGContext";
import { Slider } from "@mui/material";

export function ImageAdjustPanel({}) {
  const { items, groups, defs, update, selectedIds } = useSVG();
  const [intensity, setIntensity] = useState(50);
  const [item, setItem] = useState(items[selectedIds[0]]);
  if (!item) return null;

  useEffect(() => {
    if (selectedIds.length === 1) {
      setItem(items[selectedIds[0]]);
    } else {
      setItem(null);
    }
    setIntensity(items[item.id].filter?.filterIntensity || 50);
  }, [items, selectedIds]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "start",
        alignItems: "start",
        height: "fit-content",
        gap: 10,

        boxShadow: "0px 0px 8px #ccc",
        zIndex: 2,

        border: "solid 1px #ccc",
        borderRadius: 5,
        padding: 10,
      }}
    >
      {Object.keys(FILTER_CONFIG).map((name) => (
        <div
          key={name}
          style={{
            display: "flex",

            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            gap: 5,

            padding: 5,
            borderRadius: 5,
          }}
          className={((name == "None" && !item.filter?.appliedFilterName) || item.filter?.appliedFilterName == name ? "selected" : "") + " filter-item"}
        >
          <div
            style={{
              width: 70,
              height: 70,
            }}
          >
            <img
              src={"https://images.pexels.com/photos/1108099/pexels-photo-1108099.jpeg"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 5,
                boxShadow: "0px 0px 5px #ccc",
                filter: FILTER_STRINGS[name],
              }}
              onClick={() => {
                item.filter = item.filter || defaultFilter();
                item.filter.appliedFilterName = name;
                item.filter.effect = FILTER_CONFIG[name];
                item.filter.filterIntensity = intensity;

                item.filterStr = getItemFilterString(item.filter);
                items[item.id] = item;
                update({ items, groups, defs, selectedIds });
              }}
            />
          </div>
          <div
            style={{
              fontSize: "0.8rem",
              color: "#333",
            }}
          >
            {name[0].toUpperCase() + name.slice(1)}
          </div>
        </div>
      ))}
      <div
        style={{
          marginTop: 20,
          width: "100%",
          fontSize: "0.8rem",
          color: "#333",

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",

          gap: 10,
        }}
      >
        <span>Intensity</span>
        <Slider
          value={intensity}
          min={0}
          max={100}
          onChange={(e, v) => {
            item.filter = item.filter || defaultFilter();
            item.filter.filterIntensity = v;

            item.filterStr = getItemFilterString(item.filter);
            items[item.id] = item;
            update({ items, groups, defs, selectedIds });
          }}
        />
        <span>{intensity}%</span>
      </div>
      {/* not adding tint unless I figure out what it is */}
      {["brightness", "contrast", "saturation"].map((attr) => {
        let attrName = attr[0].toUpperCase() + attr.slice(1);
        let attrValue = item.filter?.effect ? item.filter.effect[attr] : 0;
        return (
          <ImageAttrSlider
            label={attrName}
            value={attrValue}
            onChange={(e, v) => {
              item.filter = item.filter || defaultFilter();

              item.filter.appliedFilterName = "Custom";
              item.filter.effect[attr] = v;
              item.filterStr = getItemFilterString(item.filter);
              items[item.id] = item;
              update({ items, groups, defs, selectedIds });
            }}
          />
        );
      })}
    </div>
  );
}

const ImageAttrSlider = ({ label, value, onChange }) => {
  return (
    <div
      style={{
        width: "100%",
        fontSize: "0.8rem",
        color: "#333",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",

        gap: 10,
      }}
    >
      <span>{label}</span>
      <Slider value={value} min={-100} max={100} onChange={onChange} />
      <span>{value}%</span>
    </div>
  );
};

export const FILTER_CONFIG = {
  None: {
    brightness: 0,
    contrast: 0,
    saturation: 0,
    tint: 0,
  },
  Cool: {
    brightness: -15,
    contrast: 15,
    saturation: 40,
    tint: 10,
  },
  Greyscale: {
    brightness: 30,
    contrast: -40,
    saturation: -200,
    tint: 0,
  },
  Retro: {
    brightness: 0,
    contrast: 30,
    saturation: 40,
    tint: -26,
  },
  Sepia: {
    brightness: -15,
    contrast: 15,
    saturation: 70,
    tint: -156,
  },
  Warm: {
    brightness: -15,
    contrast: 15,
    saturation: 40,
    tint: -130,
  },
};

export const getItemFilterString = (f) => {
  if (!f) return "none";

  const perc = Number(f.filterIntensity / 100);
  let b = 100 + +f.effect?.brightness * perc;
  let c = 100 + +f.effect?.contrast * perc;
  let s = 100 + +f.effect?.saturation * perc;

  b = Math.min(200, Math.max(0, b));
  c = Math.min(200, Math.max(0, c));
  s = Math.min(200, Math.max(0, s));
  return `brightness(${b}%) contrast(${c}%) saturate(${s}%)`;
};

const FILTER_STRINGS = {};
Object.keys(FILTER_CONFIG).map((f) => {
  FILTER_STRINGS[f] = getItemFilterString({
    effect: FILTER_CONFIG[f],
    filterIntensity: 50,
  });
});

const defaultFilter = () => {
  return {
    effect: {
      tint: 0,
      contrast: 0,
      brightness: 0,
      saturation: 0,
    },
    colorOverlay: {
      color: "",
      opacity: 1,
      isEnabled: false,
    },
    filterIntensity: 50,
    appliedFilterName: "None",
    dropShadowIntensity: 0,
    appliedDropShadowName: "None",
  };
};
