// @ts-nocheck

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./css/App.css";
import { MyRoutes } from "./Routes";
import { SVGProvider } from "./SVGContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <SVGProvider>
      <MyRoutes />
    </SVGProvider>
  </React.StrictMode>,
);

function sendToGoogleAnalytics({ id, name, value, delta }) {
  // Assumes the global `gtag()` function exists, see: https://developers.google.com/analytics/devguides/collection/gtagjs
  window.gtag("event", name, {
    event_category: "Web Vitals",
    value: Math.round(name === "CLS" ? delta * 1000 : delta), // ensure values are in milliseconds
    event_label: "G-3WJ3LQFWH8", // use the id as the event label
    non_interaction: true, // mark as non-interactive to avoid affecting bounce rate
  });
}
reportWebVitals(sendToGoogleAnalytics);
