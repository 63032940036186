import { useEffect, useState } from "react";
import { useSVG } from "../SVGContext";

export const SteppedConnector = ({ item }) => {
  const { width, height, strokeColor, strokeWidth, strokeDashType, startArrowStyle, endArrowStyle, startPort, endPort } = item;
  const { items } = useSVG();
  const [startPos, setStartPos] = useState({ xPx: 0, yPx: 0 });
  const [endPos, setEndPos] = useState({ xPx: 0, yPx: 0 });

  const strokeDashArray = strokeDashType === "dotted" ? "5,5" : "none";

  useEffect(() => {
    const startEl = document.getElementById(`${startPort.id}`);
    const startBox = startEl?.getBoundingClientRect();

    const endEl = document.getElementById(`${endPort.id}`);
    const endBox = endEl?.getBoundingClientRect();

    if (!startBox || !endBox) return;

    console.log(startBox, endBox);

    switch (startPort.side) {
      case "NORTH":
        startPos.xPx = startBox.x + startBox.width / 2;
        break;
      case "SOUTH":
        startPos.xPx = startBox.x + startBox.width / 2;
        break;
      case "EAST":
        startPos.yPx = startBox.y + startBox.height / 2;
        break;
      case "WEST":
        startPos.yPx = startBox.y + startBox.height / 2;
        break;
      default:
        break;
    }
    switch (endPort.side) {
      case "NORTH":
        endPos.xPx = endBox.x + endBox.width / 2;
        break;
      case "SOUTH":
        endPos.xPx = endBox.x + endBox.width / 2;
        break;
      case "EAST":
        endPos.yPx = endBox.y + endBox.height / 2;
        break;
      case "WEST":
        endPos.yPx = endBox.y + endBox.height / 2;
        break;
      default:
        break;
    }
    setStartPos({ ...startPos });
    setEndPos({ ...endPos });
  }, [items]);

  const getPath = () => {
    let startX = startPos.xPx;
    let startY = startPos.yPx;
    let endX = endPos.xPx;
    let endY = endPos.yPx;

    switch (startPort.side) {
      case "NORTH":
        startY -= height;
        break;
      case "SOUTH":
        startY += height;
        break;
      case "EAST":
        startX += width;
        break;
      case "WEST":
        startX -= width;
        break;
      default:
        break;
    }

    switch (endPort.side) {
      case "NORTH":
        endY -= height;
        break;
      case "SOUTH":
        endY += height;
        break;
      case "EAST":
        endX += width;
        break;
      case "WEST":
        endX -= width;
        break;
      default:
        break;
    }

    const stepX = (endX - startX) / 3;
    const stepY = (endY - startY) / 3;

    return `
      M ${startX} ${startY}
      L ${startX + stepX} ${startY}
      L ${startX + stepX} ${startY + stepY}
      L ${startX + 2 * stepX} ${startY + stepY}
      L ${startX + 2 * stepX} ${startY + 2 * stepY}
      L ${endX} ${endY}
    `;
  };

  return (
    <g transform={`translate(${item.x}, ${item.y})`}>
      <path d={getPath()} stroke={strokeColor} strokeWidth={strokeWidth} strokeDasharray={strokeDashArray} fill="none" markerStart={startArrowStyle === "none" ? "none" : "url(#startArrow)"} markerEnd={endArrowStyle === "basic" ? "url(#endArrow)" : "none"} />
      <defs>
        <marker id="startArrow" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
          <path d="M0,0 L0,10 L10,5 Z" fill={strokeColor} />
        </marker>
        <marker id="endArrow" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
          <path d="M0,0 L10,5 L0,10 Z" fill={strokeColor} />
        </marker>
      </defs>
    </g>
  );
};
