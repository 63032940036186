import { useEffect, useRef } from "react";

export function EditableDiv(props) {
  const { text, setText, ...others } = props;
  const editableDivRef = useRef(null);

  const handleInput = () => {
    setText(editableDivRef.current.innerText);
  };

  useEffect(() => {
    if (editableDivRef.current && editableDivRef.current.innerText !== text) {
      editableDivRef.current.innerText = text;
    }
  }, [text]);

  return <div spellCheck="false" contentEditable ref={editableDivRef} suppressContentEditableWarning onInput={handleInput} {...others} />;
}
