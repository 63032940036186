export const PALETTES = [
  {
    name: "christmas",
    palettes: [
      ["#EEE2B5", "#D7B26D", "#CA7373", "#3C552D"],
      ["#B8001F", "#FCFAEE", "#507687", "#384B70"],
      ["#6A9C89", "#C1D8C3", "#FFF5E4", "#CD5C08"],
      ["#96CEB4", "#FFEEAD", "#FFAD60", "#A02334"],
      ["#0C1844", "#C80036", "#FF6969", "#FFF5E1"],
      ["#FD9B63", "#E7D37F", "#81A263", "#365E32"],
      ["#97BE5A", "#FFE8C5", "#FFA27F", "#FF0000"],
      ["#EE4E4E", "#F6EEC9", "#A1DD70", "#799351"],
      ["#337357", "#FFD23F", "#EE4266", "#5E1675"],
      ["#EEEEEE", "#A3C9AA", "#C68484", "#9B4444"],
      ["#E4DEBE", "#E6BAA3", "#D24545", "#A94438"],
      ["#76453B", "#B19470", "#F8FAE5", "#43766C"],
      ["#F3EDC8", "#EAD196", "#BF3131", "#7D0A0A"],
      ["#820300", "#B80000", "#FF9800", "#5F8670"],
      ["#EE7214", "#F7B787", "#F9E8D9", "#527853"],
      ["#BED754", "#E3651D", "#750E21", "#191919"],
      ["#B99470", "#FEFAE0", "#A9B388", "#5F6F52"],
      ["#F3F3F3", "#A3B763", "#AF2655", "#860A35"],
      ["#1F1717", "#CE5A67", "#F4BF96", "#FCF5ED"],
      ["#9A4444", "#DE8F5F", "#F4DFB6", "#D6D46D"],
      ["#6A9C89", "#C1D8C3", "#F5E8B7", "#CD5C08"],
      ["#141E46", "#BB2525", "#FF6969", "#FFF5E0"],
      ["#8C3333", "#557A46", "#7A9D54", "#F2EE9D"],
      ["#2B2A4C", "#B31312", "#EA906C", "#EEE2DE"],
      ["#DEDEA7", "#F2B6A0", "#E06469", "#BE5A83"],
      ["#617143", "#DF7857", "#E7AB9A", "#EDE9D5"],
      ["#5D9C59", "#C7E8CA", "#DDF7E3", "#DF2E38"],
      ["#2B3467", "#BAD7E9", "#FCFFE7", "#EB455F"],
      ["#EEEEEE", "#F9B5D0", "#FF8E9E", "#FF597B"],
      ["#EB6440", "#497174", "#D6E4E5", "#EFF5F5"],
      ["#E26868", "#FF8787", "#EDEDED", "#D8D9CF"],
      ["#A1C298", "#C6EBC5", "#FBF2CF", "#FA7070"],
      ["#96E5D1", "#C55300", "#D07000", "#A62349"],
      ["#CFE8A9", "#FFFDE3", "#E64848", "#C21010"],
      ["#FEE0C0", "#FF7C7C", "#B9005B", "#820000"],
      ["#FFEE63", "#D4D925", "#FF5B00", "#990000"],
      ["#FFC4DD", "#E4AEC5", "#82A284", "#446A46"],
      ["#19282F", "#B33030", "#A1B57D", "#D3ECA7"],
      ["#ECB390", "#DD4A48", "#F5EEDC", "#C0D8C0"],
      ["#557C55", "#A6CF98", "#F2FFE9", "#DB6B97"],
      ["#121212", "#30475E", "#F05454", "#F5F5F5"],
      ["#F0D290", "#DE834D", "#A3423C", "#781D42"],
      ["#4F091D", "#DD4A48", "#F5EEDC", "#97BFB4"],
      ["#EC255A", "#FAEDF0", "#292C6D", "#161853"],
      ["#1B1717", "#630000", "#810000", "#EEEBDD"],
      ["#BECA5C", "#EF8D32", "#CC561E", "#AA2B1D"],
      ["#799351", "#F6EEC9", "#FFA36C", "#A20A0A"],
      ["#F8E4B7", "#F4F4F4", "#A4B787", "#AA3A3A"],
      ["#E84A5F", "#FF847C", "#FECEAB", "#99B898"],
      ["#21BF73", "#B0EACD", "#F9FCFB", "#FD5E53"],
      ["#9D2503", "#DF8543", "#F1F1B0", "#7FA998"],
      ["#FF971D", "#FFE8D6", "#F9F6F7", "#FFFFFF"],
      ["#91BD3A", "#FA4252", "#FE6845", "#FFA259"],
      ["#6E9086", "#FDC8B7", "#DE356A", "#540E33"],
      ["#FF5C00", "#2E5A1C", "#4E9525", "#EDF0C7"],
      ["#D95858", "#BDCF88", "#D9F489", "#EDFEAD"],
      ["#C2C57F", "#FCC88A", "#E75151", "#A13939"],
      ["#D96459", "#F2AE72", "#F2E394", "#588C73"],
      ["#FBFADF", "#F5F883", "#A7D82E", "#AC0C0C"],
      ["#F1F8FD", "#F6C667", "#C70039", "#900C27"],
      ["#44C662", "#E3F3AC", "#F23A3A", "#C9182B"],
      ["#A2EF44", "#31AA75", "#FCD47D", "#B23256"],
      ["#F3F5D5", "#C6DA20", "#F08C00", "#DA1212"],
      ["#E9EFBA", "#CEDE48", "#D9AF5D", "#902424"],
      ["#F7F7CF", "#C1D343", "#FF6600", "#990000"],
      ["#A7DA46", "#C2FF6B", "#E5AB39", "#913131"],
      ["#FFF279", "#A7CD78", "#C13131", "#761A1A"],
    ],
  },
  {
    name: "coffee",
    palettes: [
      ["#493628", "#AB886D", "#D6C0B3", "#E4E0E1"],
      ["#4A4947", "#B17457", "#D8D2C2", "#FAF7F0"],
      ["#3B3030", "#664343", "#795757", "#FFF0D1"],
      ["#603F26", "#6C4E31", "#FFDBB5", "#FFEAC5"],
      ["#8D493A", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#987070", "#C39898", "#DBB5B5", "#F1E5D1"],
      ["#FED8B1", "#ECB176", "#A67B5B", "#6F4E37"],
      ["#543310", "#74512D", "#AF8F6F", "#F8F4E1"],
      ["#D8AE7E", "#F8C794", "#FFE0B5", "#FFF2D7"],
      ["#322C2B", "#803D3B", "#AF8260", "#E4C59E"],
      ["#102C57", "#DAC0A3", "#EADBC8", "#FEFAF6"],
      ["#FFF2E1", "#EAD8C0", "#D1BB9E", "#A79277"],
      ["#EEE4B1", "#8C6A5D", "#5F374B", "#430A5D"],
      ["#E2BFB3", "#F7DED0", "#FEECE2", "#FFBE98"],
      ["#FFE7E7", "#CAA6A6", "#B47B84", "#944E63"],
      ["#643843", "#85586F", "#AC7D88", "#FDF0D1"],
      ["#E8D8C4", "#C7B7A3", "#6D2932", "#561C24"],
      ["#A87C7C", "#7E6363", "#503C3C", "#3E3232"],
      ["#B2A59B", "#DED0B6", "#FAEED1", "#607274"],
      ["#BBAB8C", "#DED0B6", "#FAEED1", "#FDF7E4"],
      ["#FAF6F0", "#F4EAE0", "#F4DFC8", "#000000"],
      ["#776B5D", "#B0A695", "#EBE3D5", "#F3EEEA"],
      ["#FAE7C9", "#E1C78F", "#B0926A", "#706233"],
      ["#113946", "#BCA37F", "#EAD7BB", "#FFF2D8"],
      ["#F2ECBE", "#E2C799", "#C08261", "#9A3B3B"],
      ["#65451F", "#765827", "#C8AE7D", "#EAC696"],
      ["#CEE6F3", "#DFA878", "#BA704F", "#6C3428"],
      ["#3F2305", "#DFD7BF", "#F2EAD3", "#F5F5F5"],
      ["#F9E0BB", "#FFC26F", "#C38154", "#884A39"],
      ["#675D50", "#ABC4AA", "#F3DEBA", "#A9907E"],
      ["#E3CCAE", "#B8621B", "#262A56", "#000000"],
      ["#F1DEC9", "#C8B6A6", "#A4907C", "#8D7B68"],
      ["#F9F5E7", "#F8EAD8", "#EDDBC7", "#A7727D"],
      ["#FFC3A1", "#F0997D", "#D3756B", "#A75D5D"],
      ["#E5E5CB", "#D5CEA3", "#3C2A21", "#1A120B"],
      ["#85586F", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#F1D3B3", "#C7BCA1", "#8B7E74", "#65647C"],
      ["#594545", "#815B5B", "#9E7676", "#FFF8EA"],
      ["#7D6E83", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#A77979", "#704F4F", "#553939", "#472D2D"],
      ["#F1EFDC", "#E6D2AA", "#D36B00", "#42032C"],
      ["#F5E8E4", "#F5C7A9", "#D1512D", "#411530"],
      ["#E6B325", "#BF9742", "#A47E3B", "#61481C"],
      ["#DCD7C9", "#A27B5C", "#3F4E4F", "#2C3639"],
      ["#EDDFB3", "#D8CCA3", "#B09B71", "#87805E"],
      ["#CE9461", "#DEA057", "#FCFFE7", "#E0D8B0"],
      ["#85586F", "#AC7D88", "#DEB6AB", "#F8ECD1"],
      ["#FFEBC1", "#D7A86E", "#A64B2A", "#8E3200"],
      ["#826F66", "#C69B7B", "#F7CCAC", "#3A3845"],
      ["#B7CADB", "#FDF6EC", "#F3E9DD", "#DAB88B"],
      ["#874356", "#C65D7B", "#F68989", "#F6E7D8"],
      ["#FFFBE9", "#E3CAA5", "#CEAB93", "#AD8B73"],
      ["#CC9544", "#603601", "#361500", "#1C0A00"],
      ["#54BAB9", "#E9DAC1", "#F7ECDE", "#FBF8F1"],
      ["#BF9270", "#E3B7A0", "#EDCDBB", "#FFEDDB"],
      ["#470D21", "#9C0F48", "#D67D3E", "#F9E4D4"],
      ["#DACC96", "#BF8B67", "#9D5353", "#632626"],
      ["#F4DFBA", "#EEC373", "#CA965C", "#876445"],
      ["#A68DAD", "#C7B198", "#DFD3C3", "#F0ECE3"],
      ["#F0D290", "#DE834D", "#A3423C", "#781D42"],
      ["#B3541E", "#A13333", "#461111", "#040303"],
      ["#FFE6BC", "#E4CDA7", "#C3B091", "#8E806A"],
      ["#E9C891", "#8A8635", "#AE431E", "#D06224"],
      ["#FAFAFA", "#F7D08A", "#E5890A", "#9D5C0D"],
      ["#FFF3E4", "#EED6C4", "#6B4F4F", "#483434"],
      ["#FBFBFB", "#EADCA6", "#E2C275", "#C36A2D"],
      ["#F9CF93", "#F9E4C8", "#FAEEE0", "#DBD0C0"],
      ["#FACE7F", "#FCF0C8", "#630A10", "#911F27"],
      ["#4B6587", "#C8C6C6", "#F7F6F2", "#F0E5CF"],
      ["#8FC1D4", "#FCDEC0", "#C68B59", "#865439"],
      ["#6F4C5B", "#9E7777", "#DEBA9D", "#F5E8C7"],
      ["#E0C097", "#B85C38", "#5C3D2E", "#2D2424"],
      ["#CDBBA7", "#DAD0C2", "#F4DFD0", "#FDEFEF"],
      ["#FFEBC9", "#D79771", "#B05B3B", "#753422"],
      ["#FFF1F1", "#FFDADA", "#BB8760", "#4F0E0E"],
      ["#A19882", "#C2B8A3", "#E6DDC6", "#FEF7DC"],
      ["#D7B19D", "#C68B59", "#865439", "#402218"],
      ["#AFB9C8", "#CE97B0", "#F4A9A8", "#FBC6A4"],
      ["#C8C2BC", "#F2DAC3", "#F1CA89", "#CC9B6D"],
      ["#E3CDC1", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#1B1A17", "#A35709", "#FF8303", "#F0E3CA"],
      ["#1B2021", "#EAC8AF", "#D8AC9C", "#5F939A"],
      ["#310B0B", "#9C3D54", "#E2703A", "#EEB76B"],
      ["#C06014", "#424642", "#536162", "#F3F4ED"],
      ["#B67162", "#E2BCB7", "#E4D3CF", "#9E9D89"],
      ["#1B1717", "#630000", "#810000", "#EEEBDD"],
      ["#E2D5D5", "#BBBBBB", "#8E7F7F", "#865858"],
      ["#E6D5B8", "#E45826", "#F0A500", "#4A3933"],
      ["#E3D18A", "#BD9354", "#9E7540", "#85603F"],
      ["#1E212D", "#B68973", "#EABF9F", "#FAF3E0"],
      ["#FDF1D6", "#C39E5C", "#DA723C", "#91091E"],
      ["#E3D0B9", "#E1BC91", "#C19277", "#62959C"],
      ["#FFDF91", "#EAAC7F", "#91684A", "#493323"],
      ["#58391C", "#6A492B", "#E27802", "#F88F01"],
      ["#E1D89F", "#D89216", "#374045", "#2C061F"],
      ["#0F0F0F", "#532E1C", "#C5A880", "#E6E6E6"],
      ["#F4EEED", "#EFD9D1", "#D8AC9C", "#999B84"],
      ["#682C0E", "#C24914", "#FC8621", "#F9E0AE"],
      ["#534E52", "#965D62", "#C7956D", "#F2D974"],
      ["#F2EFEA", "#CBBCB1", "#AF6B58", "#556052"],
      ["#E79E4F", "#A05344", "#734046", "#321F28"],
      ["#F7DAD9", "#FFF5EA", "#D6D2C4", "#968C83"],
      ["#30475E", "#7E8A97", "#CBAF87", "#E7DEC8"],
      ["#DBCBBD", "#C87941", "#87431D", "#290001"],
      ["#C26565", "#A35D6A", "#D9C6A5", "#F7E7BD"],
      ["#596E79", "#C7B198", "#DFD3C3", "#F0ECE3"],
      ["#D4B5B0", "#EEDAD1", "#F69E7B", "#383E56"],
      ["#000000", "#CF7500", "#F0A500", "#F4F4F4"],
      ["#562349", "#AD6989", "#FFA299", "#FEE2B3"],
      ["#581C0C", "#CA5116", "#F9B384", "#F1E3CB"],
      ["#FF926B", "#FFC38B", "#FFF3CD", "#4D3E3E"],
      ["#937D14", "#D2C6B2", "#EAE7D9", "#442727"],
      ["#5A3F11", "#9C5518", "#EF962D", "#FAF4F4"],
      ["#30475E", "#BA6B57", "#F1935C", "#E7B2A5"],
      ["#DF7861", "#ECB390", "#ECDFC8", "#FCF8E8"],
      ["#E9E2D0", "#EA9085", "#D45D79", "#6E5773"],
      ["#64CCDA", "#F9B282", "#DE6B35", "#8F4426"],
      ["#38470B", "#A0855B", "#F1D6AB", "#F9F6F2"],
      ["#C36A2D", "#E2C275", "#EADCA6", "#F4F4F4"],
      ["#DFDDC7", "#F58B54", "#A34A28", "#211717"],
    ],
  },
  {
    name: "cold",
    palettes: [
      ["#FFF4B7", "#006A67", "#003161", "#000B58"],
      ["#CBDCEB", "#608BC1", "#133E87", "#F3F3E0"],
      ["#4A628A", "#7AB2D3", "#B9E5E8", "#DFF2EB"],
      ["#EEEEEE", "#D4BEE4", "#9B7EBD", "#3B1E54"],
      ["#FFE1FF", "#E4B1F0", "#7E60BF", "#433878"],
      ["#EAD8B1", "#6A9AB0", "#3A6D8C", "#001F3F"],
      ["#A594F9", "#CDC1FF", "#F5EFFF", "#E5D9F2"],
      ["#7CF5FF", "#00CCDD", "#4F75FF", "#6439FF"],
      ["#FFF7F7", "#C68FE6", "#6C48C5", "#1230AE"],
      ["#E9EFEC", "#C4DAD2", "#6A9C89", "#16423C"],
      ["#C7FFD8", "#98DED9", "#0B2F9F", "#161D6F"],
      ["#424242", "#229799", "#48CFCB", "#F5F5F5"],
      ["#F7F7F8", "#3795BD", "#4E31AA", "#3A1078"],
      ["#F5EDED", "#E2DAD6", "#7FA1C3", "#6482AD"],
      ["#EEEEEE", "#508C9B", "#134B70", "#201E43"],
      ["#4535C1", "#478CCF", "#36C2CE", "#77E4C8"],
      ["#EBF4F6", "#37B7C3", "#088395", "#071952"],
      ["#3DC2EC", "#4B70F5", "#4C3BCF", "#402E7A"],
      ["#E2BBE9", "#9B86BD", "#7776B3", "#5A639C"],
      ["#B3E2A7", "#80B9AD", "#6295A2", "#538392"],
      ["#A7E6FF", "#3ABEF9", "#3572EF", "#050C9C"],
      ["#EEEEEE", "#D1D8C5", "#7E8EF1", "#615EFC"],
      ["#57A6A1", "#577B8D", "#344C64", "#240750"],
      ["#E6FF94", "#9DDE8B", "#40A578", "#006769"],
      ["#4D869C", "#7AB2B2", "#EEF7FF", "#CDE8E5"],
      ["#F27BBD", "#C65BCF", "#874CCC", "#10439F"],
      ["#E1F7F5", "#9AC8CD", "#0E46A3", "#1E0342"],
      ["#C5FF95", "#5DEBD7", "#1679AB", "#074173"],
      ["#A3FFD6", "#7BC9FF", "#8576FF", "#1C1678"],
      ["#E3FEF7", "#77B0AA", "#135D66", "#003C43"],
      ["#FF71CD", "#5755FE", "#8B93FF", "#FFF7FC"],
      ["#E2F4C5", "#A8CD9F", "#58A399", "#496989"],
      ["#DFF5FF", "#67C6E3", "#378CE7", "#5356FF"],
      ["#78A083", "#50727B", "#344955", "#35374B"],
      ["#F9E8C9", "#98ABEE", "#1D24CA", "#201658"],
      ["#F1FADA", "#9AD0C2", "#2D9596", "#265073"],
      ["#9290C3", "#535C91", "#1B1A55", "#070F2B"],
      ["#EFF396", "#74E291", "#59B4C3", "#211C6A"],
      ["#0D9276", "#BBE2EC", "#FFF6E9", "#40A2E3"],
      ["#FFD0EC", "#81689D", "#474F7A", "#1F2544"],
      ["#96E9C6", "#83C0C1", "#6962AD", "#6C22A6"],
      ["#F0EDCF", "#40A2D8", "#0B60B0", "#000000"],
      ["#F8E559", "#864AF9", "#3B3486", "#332941"],
      ["#0F1035", "#365486", "#7FC7D9", "#DCF2F1"],
      ["#F6B17A", "#7077A1", "#424769", "#2D3250"],
      ["#176B87", "#86B6F6", "#B4D4FF", "#EEF5FF"],
      ["#FAE7F3", "#E6B9DE", "#4942E4", "#11009E"],
      ["#F6ECA9", "#C6CF9B", "#596FB7", "#11235A"],
      ["#200E3A", "#38419D", "#3887BE", "#52D3D8"],
      ["#F0ECE5", "#B6BBC4", "#31304D", "#161A30"],
      ["#7B66FF", "#5FBDFF", "#96EFFF", "#C5FFF8"],
      ["#265073", "#2D9596", "#9AD0C2", "#ECF4D6"],
      ["#A25772", "#7C93C3", "#9EB8D9", "#EEF5FF"],
      ["#F3F3F3", "#C5E898", "#29ADB2", "#0766AD"],
      ["#F5E8C7", "#818FB4", "#435585", "#363062"],
      ["#DDF2FD", "#9BBEC8", "#427D9D", "#164863"],
      ["#CDF5FD", "#A0E9FF", "#89CFF3", "#00A9FF"],
      ["#C2D9FF", "#8E8FFA", "#7752FE", "#190482"],
      ["#687EFF", "#80B3FF", "#98E4FF", "#B6FFFA"],
      ["#008170", "#005B41", "#232D3F", "#0F0F0F"],
      ["#DAFFFB", "#64CCC5", "#176B87", "#04364A"],
      ["#EEEEEE", "#2E97A7", "#1AACAC", "#362FD9"],
      ["#BEFFF7", "#A6F6FF", "#9EDDFF", "#6499E9"],
      ["#E5C3A6", "#7C81AD", "#4B527E", "#2E4374"],
      ["#F5FCCD", "#78D6C6", "#419197", "#12486B"],
      ["#191717", "#7D7C7C", "#CCC8AA", "#F1EFEF"],
      ["#F2F7A1", "#35A29F", "#088395", "#071952"],
      ["#E5D283", "#4F709C", "#213555", "#F0F0F0"],
      ["#E4F1FF", "#AED2FF", "#9400FF", "#27005D"],
      ["#EEEEEE", "#64CCC5", "#176B87", "#053B50"],
      ["#A2C579", "#D2DE32", "#FFFFDD", "#016A70"],
      ["#FFFD8C", "#97FFF4", "#7091F5", "#793FDF"],
      ["#93B1A6", "#5C8374", "#183D3D", "#040D12"],
      ["#D5FFD0", "#40F8FF", "#279EFF", "#0C356A"],
      ["#C8FFE0", "#85E6C5", "#33BBC5", "#614BC3"],
      ["#D5FFE4", "#8BE8E5", "#A084E8", "#6F61C0"],
      ["#4682A9", "#749BC2", "#91C8E4", "#F6F4EB"],
      ["#7C73C0", "#94ADD7", "#ACFADF", "#E8FFCE"],
      ["#97FEED", "#35A29F", "#0B666A", "#071952"],
      ["#EEEEEE", "#068FFF", "#4E4FEB", "#000000"],
      ["#C5DFF8", "#A0BFE0", "#7895CB", "#4A55A2"],
      ["#A7EDE7", "#45CFDD", "#9681EB", "#6527BE"],
      ["#A2FF86", "#4FC0D0", "#1B6B93", "#164B60"],
      ["#DAFFFB", "#64CCC5", "#176B87", "#001C30"],
      ["#C4B0FF", "#8696FE", "#4942E4", "#11009E"],
      ["#DDE6ED", "#9DB2BF", "#526D82", "#27374D"],
      ["#F8F6F4", "#E3F4F4", "#D2E9E9", "#C4DFDF"],
      ["#FFEAD2", "#DBDFEA", "#ACB1D6", "#8294C4"],
      ["#146C94", "#19A7CE", "#AFD3E2", "#F6F1F1"],
      ["#9BA4B5", "#212A3E", "#394867", "#F1F6F9"],
      ["#00FFCA", "#05BFDB", "#088395", "#0A4D68"],
      ["#577D86", "#569DAA", "#87CBB9", "#B9EDDD"],
      ["#002B5B", "#1A5F7A", "#159895", "#57C5B6"],
      ["#D8D8D8", "#E49393", "#408E91", "#245953"],
      ["#000000", "#146C94", "#19A7CE", "#F6F1F1"],
      ["#62CDFF", "#97DEFF", "#C9EEFF", "#AA77FF"],
      ["#BFACE2", "#655DBB", "#3E54AC", "#ECF2FF"],
      ["#3795BD", "#2F58CD", "#4E31AA", "#3A1078"],
      ["#EEEEEE", "#BFDCE5", "#3E54AC", "#AD7BE9"],
      ["#18122B", "#393053", "#443C68", "#635985"],
      ["#E9F8F9", "#C0EEF2", "#537FE7", "#181823"],
      ["#205E61", "#3F979B", "#8BF5FA", "#F9F54B"],
      ["#DEFCF9", "#85CDFD", "#3C84AB", "#362FD9"],
      ["#EEE9DA", "#BDCDD6", "#93BFCF", "#6096B4"],
      ["#EBC7E6", "#BFACE2", "#A084DC", "#645CBB"],
      ["#FFF2F2", "#E5E0FF", "#8EA7E9", "#7286D3"],
      ["#03C988", "#1C82AD", "#00337C", "#13005A"],
      ["#2C74B3", "#205295", "#144272", "#0A2647"],
      ["#EAFDFC", "#BFEAF5", "#91D8E4", "#82AAE3"],
      ["#E6E2C3", "#88A47C", "#227C70", "#1C315E"],
      ["#F1F6F5", "#82C3EC", "#4B56D2", "#472183"],
      ["#0014FF", "#009EFF", "#00E7FF", "#00FFF6"],
      ["#0D4C92", "#59C1BD", "#A0E4CB", "#CFF5E7"],
      ["#EB6440", "#497174", "#D6E4E5", "#EFF5F5"],
      ["#E6E5A3", "#A9AF7E", "#7D8F69", "#557153"],
      ["#F7F7F7", "#F2E7D5", "#6D9886", "#393E46"],
      ["#8EC3B0", "#9ED5C5", "#BCEAD5", "#DEF5E5"],
      ["#B9E0FF", "#8D9EFF", "#8D72E1", "#6C4AB6"],
      ["#A3C7D6", "#9F73AB", "#624F82", "#3F3B6C"],
      ["#153462", "#4FA095", "#BAD1C2", "#F6F6C9"],
    ],
  },
  {
    name: "cream",
    palettes: [
      ["#FFF8DE", "#D0E8C5", "#C5D3E8", "#A6AEBF"],
      ["#3B3030", "#664343", "#795757", "#FFF0D1"],
      ["#C5705D", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FEFAE0", "#FAEDCE", "#E0E5B6", "#CCD5AE"],
      ["#987070", "#C39898", "#DBB5B5", "#F1E5D1"],
      ["#E0FBE2", "#BFF6C3", "#B0EBB4", "#ACE1AF"],
      ["#D8AE7E", "#F8C794", "#FFE0B5", "#FFF2D7"],
      ["#FFEFEF", "#F3D0D7", "#F0EBE3", "#F6F5F2"],
      ["#FFF2E1", "#EAD8C0", "#D1BB9E", "#A79277"],
      ["#E5DDC5", "#F1EEDC", "#BED7DC", "#B3C8CF"],
      ["#F1F5A8", "#E5E483", "#D2D180", "#B2B377"],
      ["#7469B6", "#AD88C6", "#E1AFD1", "#FFE6E6"],
      ["#EED3D9", "#F5E8DD", "#CCD3CA", "#B5C0D0"],
      ["#F2EFE5", "#E3E1D9", "#C7C8CC", "#B4B4B8"],
      ["#99BC85", "#BFD8AF", "#D4E7C5", "#E1F0DA"],
      ["#E6A4B4", "#F3D7CA", "#FFF8E3", "#F5EEE6"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#B2A59B", "#DED0B6", "#FAEED1", "#607274"],
      ["#F2F1EB", "#EEE7DA", "#AFC8AD", "#88AB8E"],
      ["#BBAB8C", "#DED0B6", "#FAEED1", "#FDF7E4"],
      ["#776B5D", "#B0A695", "#EBE3D5", "#F3EEEA"],
      ["#D0A2F7", "#DCBFFF", "#E5D4FF", "#F1EAFF"],
      ["#FAE7C9", "#E1C78F", "#B0926A", "#706233"],
      ["#EBF3E8", "#D2E3C8", "#B2C8BA", "#86A789"],
      ["#D6C7AE", "#BFB29E", "#B3A492", "#DADDB1"],
      ["#0F2C59", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#FFEEF4", "#E4E4D0", "#AEC3AE", "#94A684"],
      ["#BEADFA", "#D0BFFF", "#DFCCFB", "#FFF3DA"],
      ["#F1F0E8", "#EEE0C9", "#ADC4CE", "#96B6C5"],
      ["#FEBBCC", "#FFCCCC", "#FFDDCC", "#FFEECC"],
      ["#DBC4F0", "#FFCACC", "#D4E2D4", "#FAF3F0"],
      ["#FDFFAE", "#E9FFC2", "#C3EDC0", "#AAC8A7"],
      ["#F7FFE5", "#E1ECC8", "#C4D7B2", "#A0C49D"],
      ["#F2BED1", "#FDCEDF", "#F8E8EE", "#F9F5F6"],
      ["#F8F6F4", "#E3F4F4", "#D2E9E9", "#C4DFDF"],
      ["#73A9AD", "#B3C890", "#DBDFAA", "#F5F0BB"],
      ["#FEA1A1", "#ECCDB4", "#F0EDD4", "#F9FBE7"],
      ["#FFF8DE", "#D6E8DB", "#C1D0B5", "#99A98F"],
      ["#AAC8A7", "#C9DBB2", "#E3F2C1", "#F6FFDE"],
      ["#FEF2F4", "#FCC8D1", "#FFABAB", "#D14D72"],
      ["#C0DBEA", "#BA90C6", "#E8A0BF", "#FDF4F5"],
      ["#A4BC92", "#B3C99C", "#C7E9B0", "#DDFFBB"],
      ["#675D50", "#ABC4AA", "#F3DEBA", "#A9907E"],
      ["#F5EBEB", "#E4D0D0", "#D5B4B4", "#867070"],
      ["#FAEDCD", "#FEFAE0", "#E9EDC9", "#CCD5AE"],
      ["#FFF4D2", "#E5D1FA", "#E3DFFD", "#ECF2FF"],
      ["#F9F5E7", "#F8EAD8", "#EDDBC7", "#A7727D"],
      ["#F8CBA6", "#FFE7CC", "#FFFBEB", "#ECF9FF"],
      ["#EAE0DA", "#F7F5EB", "#A0C3D2", "#EAC7C7"],
      ["#F8F4EA", "#ECE8DD", "#E1D7C6", "#579BB1"],
      ["#85586F", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FAAB78", "#FFDCA9", "#FCF9BE", "#E8F3D6"],
      ["#DBA39A", "#F0DBDB", "#F5EBE0", "#FEFCF3"],
      ["#F1D3B3", "#C7BCA1", "#8B7E74", "#65647C"],
      ["#7F669D", "#BA94D1", "#DEBACE", "#FBFACD"],
      ["#8EC3B0", "#9ED5C5", "#BCEAD5", "#DEF5E5"],
      ["#BCE29E", "#E5EBB2", "#F8C4B4", "#FF8787"],
      ["#594545", "#815B5B", "#9E7676", "#FFF8EA"],
      ["#7D6E83", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#7895B2", "#AEBDCA", "#E8DFCA", "#F5EFE6"],
      ["#FF9494", "#FFD1D1", "#FFE3E1", "#FFF5E4"],
      ["#EEF1FF", "#D2DAFF", "#AAC4FF", "#B1B2FF"],
      ["#FAF4B7", "#ECC5FB", "#F9F9F9", "#CDF0EA"],
      ["#FFC090", "#F7F6DC", "#B1D7B4", "#7FB77E"],
      ["#54BAB9", "#9ED2C6", "#E9DAC1", "#F7ECDE"],
      ["#AC7088", "#DEB6AB", "#ECCCB2", "#F5E8C7"],
      ["#B2A4FF", "#FFB4B4", "#FFDEB4", "#FFF9CA"],
      ["#ECB390", "#FCF8E8", "#CEE5D0", "#94B49F"],
      ["#F7EDDB", "#DFE8CC", "#DAE2B6", "#CCD6A6"],
      ["#748DA6", "#9CB4CC", "#D3CEDF", "#F2D7D9"],
      ["#EDDFB3", "#D8CCA3", "#B09B71", "#87805E"],
      ["#FAF4B7", "#F6C6EA", "#F9F9F9", "#CDF0EA"],
      ["#354259", "#CDC2AE", "#ECE5C7", "#C2DED1"],
      ["#FFF2F2", "#FAD4D4", "#EF9F9F", "#F47C7C"],
      ["#8CC0DE", "#FAF0D7", "#FFD9C0", "#F4BFBF"],
      ["#97C4B8", "#CCF3EE", "#F9F3EE", "#F9CEEE"],
      ["#A0BCC2", "#DAE5D0", "#FEFBE7", "#F9EBC8"],
      ["#A2B38B", "#E4E9BE", "#FAFDD6", "#E6BA95"],
      ["#EBD8C3", "#F7E9D7", "#FFF6EA", "#FFEEEE"],
      ["#B7CADB", "#FDF6EC", "#F3E9DD", "#DAB88B"],
      ["#EEEEEE", "#ECA6A6", "#D18CE0", "#E2DEA9"],
      ["#FFFBE9", "#E3CAA5", "#CEAB93", "#AD8B73"],
      ["#789395", "#94B49F", "#B4CFB0", "#E5E3C9"],
      ["#EFEFEF", "#D1D1D1", "#C0A080", "#7882A4"],
      ["#54BAB9", "#E9DAC1", "#F7ECDE", "#FBF8F1"],
      ["#BF9270", "#E3B7A0", "#EDCDBB", "#FFEDDB"],
      ["#694E4E", "#886F6F", "#C1A3A3", "#F3C5C5"],
      ["#FFCBCB", "#E7FBBE", "#FFFDDE", "#D9D7F1"],
      ["#FFEFEF", "#7C99AC", "#92A9BD", "#D3DEDC"],
      ["#FFF9F9", "#D6E5FA", "#BAABDA", "#D77FA1"],
      ["#A68DAD", "#C7B198", "#DFD3C3", "#F0ECE3"],
      ["#FFE6BC", "#E4CDA7", "#C3B091", "#8E806A"],
      ["#E2C2B9", "#F2DDC1", "#99A799", "#D3E4CD"],
      ["#FFFEA9", "#F3ED9E", "#E6DF9A", "#DAD992"],
      ["#181D31", "#678983", "#E6DDC4", "#F0E9D2"],
      ["#D0CAB2", "#DED9C4", "#96C7C1", "#89B5AF"],
      ["#FFBF86", "#FED2AA", "#F3F0D7", "#CEE5D0"],
      ["#87AAAA", "#C8E3D4", "#F6EABE", "#F6D7A7"],
      ["#99A799", "#ADC2A9", "#D3E4CD", "#FEF5ED"],
      ["#EAE7C6", "#BCCC9A", "#C37B89", "#D1E8E4"],
      ["#EDEDED", "#DDBEBE", "#C89595", "#6C4A4A"],
      ["#9A9483", "#AAA492", "#C7BEA2", "#E5DCC3"],
      ["#F9CF93", "#F9E4C8", "#FAEEE0", "#DBD0C0"],
      ["#FFFEB7", "#FFEFBC", "#FFDAC7", "#FFADAD"],
      ["#9D9D9D", "#F8F0DF", "#FEFBF3", "#79B4B7"],
      ["#4B6587", "#C8C6C6", "#F7F6F2", "#F0E5CF"],
      ["#FFE3E3", "#E4D8DC", "#C9CCD5", "#93B5C6"],
      ["#6F4C5B", "#9E7777", "#DEBA9D", "#F5E8C7"],
      ["#FEFFE2", "#F0F0CB", "#DAD5AB", "#C3BA85"],
      ["#CDBBA7", "#DAD0C2", "#F4DFD0", "#FDEFEF"],
      ["#F5C6AA", "#F8E2CF", "#FDF6F0", "#FCD8D4"],
      ["#A19882", "#C2B8A3", "#E6DDC6", "#FEF7DC"],
      ["#5D534A", "#D6D2C4", "#FFF5DA", "#F7DAD9"],
      ["#C490E4", "#F6C6EA", "#F9F9F9", "#CDF0EA"],
      ["#5E454B", "#D8B384", "#F3F0D7", "#CEE5D0"],
      ["#B2B8A3", "#F4C7AB", "#DEEDF0", "#FFF5EB"],
      ["#E4BAD4", "#F6DFEB", "#F8EDED", "#CAF7E3"],
      ["#064420", "#E4EFE7", "#FDFAF6", "#FAF1E6"],
      ["#AFB9C8", "#CE97B0", "#F4A9A8", "#FBC6A4"],
      ["#DA7F8F", "#A7BBC7", "#E1E5EA", "#FAF3F3"],
      ["#E3CDC1", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#E1F1DD", "#CDC7BE", "#87A7B3", "#766161"],
      ["#FF6701", "#FEA82F", "#FFC288", "#FCECDD"],
      ["#FFAAA7", "#FFD3B4", "#D5ECC2", "#98DDCA"],
      ["#F05945", "#5EAAA8", "#A3D2CA", "#F7F3E9"],
      ["#C06014", "#424642", "#536162", "#F3F4ED"],
      ["#B67162", "#E2BCB7", "#E4D3CF", "#9E9D89"],
      ["#E4BAD4", "#F6DFEB", "#EDFFEC", "#CAF7E3"],
      ["#E2D5D5", "#BBBBBB", "#8E7F7F", "#865858"],
      ["#9FD8DF", "#FF7171", "#F5C0C0", "#F0E4D7"],
      ["#435560", "#6E7C7C", "#92967D", "#C8C6A7"],
      ["#F25287", "#F7D9D9", "#F9F3F3", "#DDDDDD"],
      ["#EAE3C8", "#CFC5A5", "#C2B092", "#A1CAE2"],
      ["#9A8194", "#C6A9A3", "#EBD8B7", "#99BBAD"],
      ["#314E52", "#F2A154", "#F7F6E7", "#E7E6E1"],
      ["#FFE8E8", "#FFDCDC", "#FFB4B4", "#FF9292"],
      ["#AEE1E1", "#D3E0DC", "#ECE2E1", "#FCD1D1"],
      ["#557174", "#9DAD7F", "#C7CFB7", "#F7F7E8"],
      ["#23689B", "#487E95", "#D9DAB0", "#F4F5DB"],
      ["#3B5360", "#8B5E83", "#D6B0B1", "#BEE5D3"],
      ["#FFC1B6", "#FFDCB8", "#FFEEBB", "#FDFFBC"],
      ["#E8EFEB", "#ADEECF", "#E9B0DF", "#295939"],
      ["#CDD0CB", "#E8EAE6", "#CFDAC8", "#7C9473"],
      ["#DF7861", "#ECB390", "#D4E2D4", "#FCF8E8"],
      ["#F4EEED", "#EFD9D1", "#D8AC9C", "#999B84"],
      ["#C6FCED", "#8AD7C1", "#825959", "#A07676"],
      ["#FCA3CC", "#FBBEDF", "#FDCFDF", "#BCE6EB"],
      ["#AAAAAA", "#BBBBBB", "#F2DCBB", "#F9F7CF"],
      ["#9088D4", "#706897", "#EBCFC4", "#FFF3E2"],
      ["#495464", "#BBBFCA", "#E8E8E8", "#F4F4F2"],
      ["#F7DAD9", "#FFF5EA", "#D6D2C4", "#968C83"],
      ["#555555", "#CDC9C3", "#FBF7F0", "#D9E4DD"],
      ["#463333", "#835858", "#EBD4D4", "#FFF0F0"],
      ["#AAAAAA", "#FFC7C7", "#FFE2E2", "#F6F6F6"],
      ["#AA4A30", "#D57149", "#E89F71", "#EDCFA9"],
      ["#393B44", "#8D93AB", "#D6E0F0", "#F1F3F8"],
      ["#797A7E", "#D8D3CD", "#F7F2E7", "#E0ECE4"],
      ["#FCCBCB", "#D9ADAD", "#AD9D9D", "#838383"],
      ["#EEBB4D", "#96BB7C", "#F5F1DA", "#E3DFC8"],
      ["#596E79", "#C7B198", "#DFD3C3", "#F0ECE3"],
    ],
  },
  {
    name: "dark",
    palettes: [
      ["#A64D79", "#6A1E55", "#3B1C32", "#1A1A1D"],
      ["#FFF4B7", "#006A67", "#003161", "#000B58"],
      ["#000000", "#0B192C", "#1E3E62", "#FF6500"],
      ["#ECDFCC", "#697565", "#3C3D37", "#181C14"],
      ["#EBD3F8", "#AD49E1", "#7A1CAC", "#2E073F"],
      ["#ECDFCC", "#697565", "#3C3D37", "#1E201E"],
      ["#D95F59", "#C63C51", "#8C3061", "#522258"],
      ["#FB773C", "#EB3678", "#4F1787", "#180161"],
      ["#E2E2B6", "#6EACDA", "#03346E", "#021526"],
      ["#D6BD98", "#677D6A", "#40534C", "#1A3636"],
      ["#C8ACD6", "#433D8B", "#2E236C", "#17153B"],
      ["#3DC2EC", "#4B70F5", "#4C3BCF", "#402E7A"],
      ["#57A6A1", "#577B8D", "#344C64", "#240750"],
      ["#F97300", "#E2DFD0", "#524C42", "#32012F"],
      ["#DBAFA0", "#BB8493", "#704264", "#49243E"],
      ["#F2613F", "#9B3922", "#481E14", "#0C0C0C"],
      ["#00224D", "#5D0E41", "#A0153E", "#FF204E"],
      ["#EEE4B1", "#8C6A5D", "#5F374B", "#430A5D"],
      ["#EEEEEE", "#76ABAE", "#31363F", "#222831"],
      ["#78A083", "#50727B", "#344955", "#35374B"],
      ["#9290C3", "#535C91", "#1B1A55", "#070F2B"],
      ["#910A67", "#720455", "#3C0753", "#030637"],
      ["#A87C7C", "#7E6363", "#503C3C", "#3E3232"],
      ["#F6B17A", "#7077A1", "#424769", "#2D3250"],
      ["#9EC8B9", "#5C8374", "#1B4242", "#092635"],
      ["#BED754", "#E3651D", "#750E21", "#191919"],
      ["#F05941", "#BE3144", "#872341", "#22092C"],
      ["#F5E8C7", "#818FB4", "#435585", "#363062"],
      ["#E95793", "#DA0C81", "#940B92", "#610C9F"],
      ["#008170", "#005B41", "#232D3F", "#0F0F0F"],
      ["#F39F5A", "#AE445A", "#662549", "#451952"],
      ["#93B1A6", "#5C8374", "#183D3D", "#040D12"],
      ["#FAF0E6", "#B9B4C7", "#5C5470", "#352F44"],
      ["#3F1D38", "#4D3C77", "#A2678A", "#E19898"],
      ["#8CABFF", "#4477CE", "#512B81", "#35155D"],
      ["#EFE1D1", "#A78295", "#3F2E3E", "#331D2C"],
      ["#EAB2A0", "#A76F6F", "#435B66", "#2D4356"],
      ["#84A7A1", "#2E8A99", "#1F6E8C", "#0E2954"],
      ["#CD1818", "#4E3636", "#321E1E", "#116D6E"],
      ["#DDE6ED", "#9DB2BF", "#526D82", "#27374D"],
      ["#D4ADFC", "#5C469C", "#1D267D", "#0C134F"],
      ["#B0A4A4", "#957777", "#643A6B", "#5F264A"],
      ["#F15A59", "#ED2B2A", "#D21312", "#070A52"],
      ["#F4EEE0", "#6D5D6E", "#4F4557", "#393646"],
      ["#A5D7E8", "#576CBC", "#19376D", "#0B2447"],
      ["#D27685", "#9E4784", "#66347F", "#37306B"],
      ["#CBE4DE", "#0E8388", "#2E4F4F", "#2C3333"],
      ["#3795BD", "#2F58CD", "#4E31AA", "#3A1078"],
      ["#18122B", "#393053", "#443C68", "#635985"],
      ["#B6EADA", "#5B8FB9", "#301E67", "#03001C"],
      ["#03C988", "#1C82AD", "#00337C", "#13005A"],
      ["#2C74B3", "#205295", "#144272", "#0A2647"],
      ["#E5E5CB", "#D5CEA3", "#3C2A21", "#1A120B"],
      ["#F2F7A1", "#46C2CB", "#6D67E4", "#453C67"],
      ["#E5B8F4", "#C147E9", "#810CA8", "#2D033B"],
      ["#F0CAA3", "#C060A1", "#3B185F", "#00005C"],
      ["#C69749", "#735F32", "#282A3A", "#000000"],
      ["#6B728E", "#50577A", "#474E68", "#404258"],
      ["#A3C7D6", "#9F73AB", "#624F82", "#3F3B6C"],
      ["#FB2576", "#3F0071", "#150050", "#000000"],
      ["#D8D8D8", "#647E68", "#562B08", "#182747"],
      ["#E80F88", "#AF0171", "#790252", "#4C0033"],
      ["#E94560", "#533483", "#0F3460", "#16213E"],
      ["#A77979", "#704F4F", "#553939", "#472D2D"],
      ["#E7F6F2", "#A5C9CA", "#395B64", "#2C3333"],
      ["#DCD7C9", "#A27B5C", "#3F4E4F", "#2C3639"],
      ["#E7AB79", "#B25068", "#774360", "#4C3A51"],
      ["#D6D5A8", "#816797", "#51557E", "#1B2430"],
      ["#7858A6", "#5B4B8A", "#4C3575", "#371B58"],
      ["#F806CC", "#A91079", "#570A57", "#2E0249"],
      ["#7F8487", "#413F42", "#16003B", "#F73D93"],
      ["#D49B54", "#C74B50", "#712B75", "#46244C"],
      ["#F582A7", "#F10086", "#711A75", "#180A0A"],
      ["#FA58B6", "#7A0BC0", "#270082", "#1A1A40"],
      ["#980F5A", "#750550", "#570530", "#4C0027"],
      ["#EEEEEE", "#8B9A46", "#541212", "#0F0E0E"],
      ["#ECDBBA", "#C84B31", "#2D4263", "#191919"],
      ["#ECB365", "#064663", "#04293A", "#041C32"],
      ["#B3541E", "#A13333", "#461111", "#040303"],
      ["#160040", "#4C0070", "#79018C", "#9A0680"],
      ["#E9A6A6", "#864879", "#3F3351", "#1F1D36"],
      ["#8E05C2", "#700B97", "#3E065F", "#000000"],
      ["#D8E9A8", "#4E9F3D", "#1E5128", "#191A19"],
      ["#FF0000", "#950101", "#3D0000", "#000000"],
      ["#C996CC", "#916BBF", "#3D2C8D", "#1C0C5B"],
      ["#610094", "#3F0071", "#150050", "#000000"],
      ["#FEC260", "#A12568", "#3B185F", "#2A0944"],
      ["#E63E6D", "#B42B51", "#7D1935", "#420516"],
      ["#6E85B2", "#5C527F", "#3E2C41", "#261C2C"],
      ["#E0C097", "#B85C38", "#5C3D2E", "#2D2424"],
      ["#FF4C29", "#334756", "#2C394B", "#082032"],
      ["#EEEEEE", "#787A91", "#141E61", "#0F044C"],
      ["#2B2B2B", "#423F3E", "#171010", "#362222"],
      ["#EDEDED", "#DA0037", "#444444", "#171717"],
      ["#ECDBBA", "#C84B31", "#346751", "#161616"],
      ["#8FD6E1", "#1597BB", "#150E56", "#7B113A"],
      ["#310B0B", "#9C3D54", "#E2703A", "#EEB76B"],
      ["#B4A5A5", "#3C415C", "#301B3F", "#151515"],
      ["#A0C1B8", "#719FB0", "#726A95", "#351F39"],
      ["#E1D89F", "#D89216", "#374045", "#2C061F"],
      ["#F05454", "#30475E", "#222831", "#DDDDDD"],
      ["#FFE3D8", "#BBBBBB", "#03506F", "#0A043C"],
      ["#EEEEEE", "#FFD369", "#393E46", "#222831"],
      ["#FFDD93", "#C4B6B6", "#7E7474", "#39311D"],
      ["#BC6FF1", "#892CDC", "#52057B", "#000000"],
      ["#E79E4F", "#A05344", "#734046", "#321F28"],
      ["#060930", "#333456", "#595B83", "#F4ABC4"],
      ["#F1D4D4", "#C060A1", "#6A097D", "#000000"],
      ["#03C4A1", "#C62A88", "#590995", "#150485"],
      ["#FCDAB7", "#1E5F74", "#133B5C", "#1D2D50"],
      ["#FBDCC4", "#F2A07B", "#31112C", "#7D0633"],
      ["#DBCBBD", "#C87941", "#87431D", "#290001"],
      ["#E94560", "#0F3460", "#16213E", "#1A1A2E"],
      ["#A4B494", "#519872", "#3B5249", "#382933"],
      ["#59405C", "#3C2C3E", "#B52B65", "#ED6663"],
      ["#87556F", "#59405C", "#322F3D", "#4B5D67"],
      ["#FF4301", "#FA7D09", "#4A3F35", "#2F2519"],
      ["#F1EBBB", "#B5076B", "#5C2A9D", "#45046A"],
      ["#E43F5A", "#1B1B2F", "#1F4068", "#162447"],
      ["#FF5733", "#C70039", "#900C3F", "#511845"],
    ],
  },
  {
    name: "earth",
    palettes: [
      ["#54473F", "#9A7E6F", "#CBD2A4", "#E9EED9"],
      ["#493628", "#AB886D", "#D6C0B3", "#E4E0E1"],
      ["#DA8359", "#FCFAEE", "#ECDFCC", "#A5B68D"],
      ["#626F47", "#F2EED7", "#FEFAE0", "#798645"],
      ["#4A4947", "#B17457", "#D8D2C2", "#FAF7F0"],
      ["#674636", "#AAB396", "#F7EED3", "#FFF8E8"],
      ["#B99470", "#FEFAE0", "#A6B37D", "#C0C78C"],
      ["#E7FBE6", "#CBE2B5", "#86AB89", "#A28B55"],
      ["#E7E8D8", "#CADABF", "#B5CFB7", "#BC9F8B"],
      ["#B5C18E", "#F7DCB9", "#DEAC80", "#914F1E"],
      ["#698474", "#FCF8F3", "#FFD3B6", "#DCA47C"],
      ["#2F3645", "#939185", "#E6B9A6", "#EEEDEB"],
      ["#B6C7AA", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#DAD3BE", "#B7B597", "#6B8A7A", "#254336"],
      ["#FED8B1", "#ECB176", "#A67B5B", "#6F4E37"],
      ["#F1F1F1", "#C7B7A3", "#EADBC8", "#B5C18E"],
      ["#B99470", "#DEAC80", "#F7DCB9", "#B5C18E"],
      ["#AFD198", "#E8EFCF", "#ECCA9C", "#DBA979"],
      ["#9CAFAA", "#D6DAC8", "#FBF3D5", "#EFBC9B"],
      ["#F1EF99", "#EBC49F", "#D37676", "#B0C5A4"],
      ["#FFC374", "#F9E897", "#7F9F80", "#124076"],
      ["#EED3D9", "#F5E8DD", "#CCD3CA", "#B5C0D0"],
      ["#B3A398", "#BBC3A4", "#C6DCBA", "#D7E4C0"],
      ["#B67352", "#ECB159", "#FEFBF6", "#8CB9BD"],
      ["#EEEEEE", "#A3C9AA", "#C68484", "#9B4444"],
      ["#638889", "#9DBC98", "#EBD9B4", "#F9EFDB"],
      ["#76453B", "#B19470", "#F8FAE5", "#43766C"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#B2A59B", "#DED0B6", "#FAEED1", "#607274"],
      ["#F2F1EB", "#EEE7DA", "#AFC8AD", "#88AB8E"],
      ["#FFEFE8", "#F8DFD4", "#C69774", "#637E76"],
      ["#B99470", "#FEFAE0", "#A9B388", "#5F6F52"],
      ["#3A4D39", "#4F6F52", "#739072", "#ECE3CE"],
      ["#EBF3E8", "#D2E3C8", "#B2C8BA", "#86A789"],
      ["#D6C7AE", "#BFB29E", "#B3A492", "#DADDB1"],
      ["#FDE5D4", "#D6CC99", "#445D48", "#001524"],
      ["#D0E7D2", "#B0D9B1", "#79AC78", "#618264"],
      ["#FFEEF4", "#E4E4D0", "#AEC3AE", "#94A684"],
      ["#F1F0E8", "#EEE0C9", "#ADC4CE", "#96B6C5"],
      ["#65451F", "#765827", "#C8AE7D", "#EAC696"],
      ["#CEE6F3", "#DFA878", "#BA704F", "#6C3428"],
      ["#FFC95F", "#FFF9C9", "#862B0D", "#B5C99A"],
      ["#85A389", "#A2CDB0", "#FFD89C", "#F1C27B"],
      ["#F7FFE5", "#E1ECC8", "#C4D7B2", "#A0C49D"],
      ["#116A7B", "#CDC2AE", "#ECE5C7", "#C2DEDC"],
      ["#F9E0BB", "#FFC26F", "#C38154", "#884A39"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#9BABB8"],
      ["#73A9AD", "#B3C890", "#DBDFAA", "#F5F0BB"],
      ["#EEEEEE", "#E1D4BB", "#CBB279", "#537188"],
      ["#83764F", "#A2A378", "#A0D8B3", "#E5F9DB"],
      ["#D4FAFC", "#B8E7E1", "#FFEEB3", "#9E6F21"],
      ["#EDC6B1", "#B7B7B7", "#7C96AB", "#BFCCB5"],
      ["#675D50", "#ABC4AA", "#F3DEBA", "#A9907E"],
      ["#F5EBEB", "#E4D0D0", "#D5B4B4", "#867070"],
      ["#DBE4C6", "#94AF9F", "#AEC2B6", "#BBD6B8"],
      ["#F1DEC9", "#C8B6A6", "#A4907C", "#8D7B68"],
      ["#40513B", "#609966", "#9DC08B", "#EDF1D6"],
      ["#EEE9DA", "#BDCDD6", "#93BFCF", "#6096B4"],
      ["#AA5656", "#F1DBBF", "#B99B6B", "#698269"],
      ["#FAD6A5", "#CFB997", "#7B8FA1", "#567189"],
      ["#EAE0DA", "#F7F5EB", "#A0C3D2", "#EAC7C7"],
      ["#F5F5DC", "#ECA869", "#B08BBB", "#B5D5C5"],
      ["#FFFBE9", "#F4EAD5", "#DAE2B6", "#CCD6A6"],
      ["#F1D3B3", "#C7BCA1", "#8B7E74", "#65647C"],
      ["#285430", "#5F8D4E", "#A4BE7B", "#E5D9B6"],
      ["#E6E5A3", "#A9AF7E", "#7D8F69", "#557153"],
      ["#FAD6A5", "#CFB997", "#9BA17B", "#61764B"],
      ["#F0EBCE", "#AA8B56", "#4E6C50", "#395144"],
      ["#594545", "#815B5B", "#9E7676", "#FFF8EA"],
      ["#665A48", "#9F8772", "#C8DBBE", "#EDE4E0"],
      ["#FCFFB2", "#B6E388", "#C7F2A4", "#E1FFB1"],
      ["#7D6E83", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#B7C4CF"],
      ["#EEF2E6", "#D6CDA4", "#3D8361", "#1C6758"],
      ["#874C62", "#C98474", "#F2D388", "#A7D2CB"],
      ["#A77979", "#704F4F", "#553939", "#472D2D"],
      ["#F0F2B6", "#C1D5A4", "#224B0C", "#AC4425"],
      ["#AC7088", "#DEB6AB", "#ECCCB2", "#F5E8C7"],
      ["#EDDFB3", "#CA955C", "#876445", "#76BA99"],
      ["#E6B325", "#BF9742", "#A47E3B", "#61481C"],
      ["#ECB390", "#FCF8E8", "#CEE5D0", "#94B49F"],
      ["#DCD7C9", "#A27B5C", "#3F4E4F", "#2C3639"],
      ["#EDDFB3", "#D8CCA3", "#B09B71", "#87805E"],
      ["#D5D8B5", "#CC9C75", "#A25B5B", "#809A6F"],
      ["#F1F0C0", "#B7E5DD", "#B1BCE6", "#9A86A4"],
      ["#D0C9C0", "#EFEAD8", "#6D8B74", "#5F7161"],
      ["#FFEBC1", "#D7A86E", "#A64B2A", "#8E3200"],
      ["#614124", "#CC704B", "#E8C07D", "#9FC088"],
      ["#A97155", "#BE8C63", "#E4D1B9", "#8FBDD3"],
      ["#534340", "#BB9981", "#C5D8A4", "#F4FCD9"],
      ["#FFFBE9", "#E3CAA5", "#CEAB93", "#AD8B73"],
      ["#CC9544", "#603601", "#361500", "#1C0A00"],
      ["#789395", "#94B49F", "#B4CFB0", "#E5E3C9"],
      ["#E9E5D6", "#ACB992", "#464E2E", "#362706"],
      ["#EFEFEF", "#D1D1D1", "#C0A080", "#7882A4"],
      ["#BF9270", "#E3B7A0", "#EDCDBB", "#FFEDDB"],
      ["#DACC96", "#BF8B67", "#9D5353", "#632626"],
      ["#F4DFBA", "#EEC373", "#CA965C", "#876445"],
      ["#146356", "#A3DA8D", "#FFF1BD", "#F3C892"],
      ["#A68DAD", "#C7B198", "#DFD3C3", "#F0ECE3"],
      ["#FFE6BC", "#E4CDA7", "#C3B091", "#8E806A"],
      ["#E9C891", "#8A8635", "#AE431E", "#D06224"],
      ["#FFF1AF", "#FFC286", "#B4C6A6", "#66806A"],
      ["#FAFAFA", "#F7D08A", "#E5890A", "#9D5C0D"],
      ["#FFF3E4", "#EED6C4", "#6B4F4F", "#483434"],
      ["#99A799", "#ADC2A9", "#D3E4CD", "#FEF5ED"],
      ["#9A9483", "#AAA492", "#C7BEA2", "#E5DCC3"],
      ["#A09F57", "#C56824", "#CFB784", "#EADEB8"],
      ["#F6F6F6", "#D9CAB3", "#6D9886", "#212121"],
      ["#FFE1AF", "#A2CDCD", "#D57E7E", "#C6D57E"],
      ["#506D84", "#889EAF", "#D4B499", "#F3D5C0"],
      ["#E8D0B3", "#86340A", "#C36839", "#7EB5A6"],
      ["#5E454B", "#F7D59C", "#F3F0D7", "#CEE5D0"],
      ["#E0C097", "#B85C38", "#5C3D2E", "#2D2424"],
      ["#FFEBC9", "#D79771", "#B05B3B", "#753422"],
      ["#C1AC95", "#B5CDA3", "#C9E4C5", "#FAEBE0"],
      ["#D7B19D", "#C68B59", "#865439", "#402218"],
      ["#F2EDD7", "#A0937D", "#3A6351", "#5F939A"],
      ["#FCDEC0", "#E5B299", "#B4846C", "#7D5A50"],
      ["#5E454B", "#D8B384", "#F3F0D7", "#CEE5D0"],
    ],
  },
  {
    name: "fall",
    palettes: [
      ["#F87A53", "#E6C767", "#898121", "#4C4B16"],
      ["#493628", "#AB886D", "#D6C0B3", "#E4E0E1"],
      ["#DA8359", "#FCFAEE", "#ECDFCC", "#A5B68D"],
      ["#4A4947", "#B17457", "#D8D2C2", "#FAF7F0"],
      ["#674636", "#AAB396", "#F7EED3", "#FFF8E8"],
      ["#603F26", "#6C4E31", "#FFDBB5", "#FFEAC5"],
      ["#E7E8D8", "#CADABF", "#B5CFB7", "#BC9F8B"],
      ["#B5C18E", "#F7DCB9", "#DEAC80", "#914F1E"],
      ["#698474", "#FCF8F3", "#FFD3B6", "#DCA47C"],
      ["#2F3645", "#939185", "#E6B9A6", "#EEEDEB"],
      ["#B6C7AA", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#987070", "#C39898", "#DBB5B5", "#F1E5D1"],
      ["#FED8B1", "#ECB176", "#A67B5B", "#6F4E37"],
      ["#FFF8DC", "#F7C566", "#DC6B19", "#6C0345"],
      ["#F2613F", "#9B3922", "#481E14", "#0C0C0C"],
      ["#B67352", "#ECB159", "#FEFBF6", "#8CB9BD"],
      ["#76453B", "#B19470", "#F8FAE5", "#43766C"],
      ["#597E52", "#C6A969", "#F1E4C3", "#FFFFEC"],
      ["#B99470", "#FEFAE0", "#A9B388", "#5F6F52"],
      ["#776B5D", "#B0A695", "#EBE3D5", "#F3EEEA"],
      ["#FAE7C9", "#E1C78F", "#B0926A", "#706233"],
      ["#D6C7AE", "#BFB29E", "#B3A492", "#DADDB1"],
      ["#9A4444", "#DE8F5F", "#F4DFB6", "#D6D46D"],
      ["#6A9C89", "#C1D8C3", "#F5E8B7", "#CD5C08"],
      ["#B2533E", "#FCE09B", "#B5CB99", "#186F65"],
      ["#C63D2F", "#E25E3E", "#FF9B50", "#FFBB5C"],
      ["#65451F", "#765827", "#C8AE7D", "#EAC696"],
      ["#CEE6F3", "#DFA878", "#BA704F", "#6C3428"],
      ["#FFC95F", "#FFF9C9", "#862B0D", "#B5C99A"],
      ["#F7F1E5", "#E7B10A", "#898121", "#4C4B16"],
      ["#85A389", "#A2CDB0", "#FFD89C", "#F1C27B"],
      ["#F1C376", "#F7E6C4", "#FFF4F4", "#606C5D"],
      ["#F9E0BB", "#FFC26F", "#C38154", "#884A39"],
      ["#FFF8DE", "#D6E8DB", "#C1D0B5", "#99A98F"],
      ["#7C9070", "#9CA777", "#FEE8B0", "#F97B22"],
      ["#675D50", "#ABC4AA", "#F3DEBA", "#A9907E"],
      ["#DFA67B", "#F4B183", "#FFD966", "#FFF2CC"],
      ["#F5EBEB", "#E4D0D0", "#D5B4B4", "#867070"],
      ["#4C4B16", "#898121", "#E7B10A", "#F7F1E5"],
      ["#473C33", "#FDA769", "#FEC868", "#ABC270"],
      ["#85586F", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#F1D3B3", "#C7BCA1", "#8B7E74", "#65647C"],
      ["#FAD6A5", "#CFB997", "#9BA17B", "#61764B"],
      ["#594545", "#815B5B", "#9E7676", "#FFF8EA"],
      ["#665A48", "#9F8772", "#C8DBBE", "#EDE4E0"],
      ["#7D6E83", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#B7C4CF"],
      ["#F3E0B5", "#FFAE6D", "#FECD70", "#E3C770"],
      ["#E38B29", "#F1A661", "#FFD8A9", "#FDEEDC"],
      ["#AC7088", "#DEB6AB", "#ECCCB2", "#F5E8C7"],
      ["#DCD7C9", "#A27B5C", "#3F4E4F", "#2C3639"],
      ["#DF7861", "#ECB390", "#94B49F", "#FCF8E8"],
      ["#D5D8B5", "#CC9C75", "#A25B5B", "#809A6F"],
      ["#614124", "#CC704B", "#E8C07D", "#9FC088"],
      ["#A97155", "#BE8C63", "#E4D1B9", "#8FBDD3"],
      ["#B7CADB", "#FDF6EC", "#F3E9DD", "#DAB88B"],
      ["#E6D5B8", "#E45826", "#F0A500", "#1B1A17"],
      ["#FFFBE9", "#E3CAA5", "#CEAB93", "#AD8B73"],
      ["#CC9544", "#603601", "#361500", "#1C0A00"],
      ["#470D21", "#9C0F48", "#D67D3E", "#F9E4D4"],
      ["#ECB390", "#DD4A48", "#F5EEDC", "#C0D8C0"],
      ["#DACC96", "#BF8B67", "#9D5353", "#632626"],
      ["#F4DFBA", "#EEC373", "#CA965C", "#876445"],
      ["#95CD41", "#F6D860", "#FF7F3F", "#EA5C2B"],
      ["#E2C2B9", "#F2DDC1", "#99A799", "#D3E4CD"],
      ["#FFFEA9", "#F3ED9E", "#E6DF9A", "#DAD992"],
      ["#E9C891", "#8A8635", "#AE431E", "#D06224"],
      ["#FFF1AF", "#FFC286", "#B4C6A6", "#66806A"],
      ["#FAFAFA", "#F7D08A", "#E5890A", "#9D5C0D"],
      ["#FFBF86", "#FED2AA", "#F3F0D7", "#CEE5D0"],
      ["#FFF3E4", "#EED6C4", "#6B4F4F", "#483434"],
      ["#FBFBFB", "#EADCA6", "#E2C275", "#C36A2D"],
      ["#9A9483", "#AAA492", "#C7BEA2", "#E5DCC3"],
      ["#A09F57", "#C56824", "#CFB784", "#EADEB8"],
      ["#F6F6F6", "#D9CAB3", "#6D9886", "#212121"],
      ["#506D84", "#889EAF", "#D4B499", "#F3D5C0"],
      ["#E8D0B3", "#86340A", "#C36839", "#7EB5A6"],
      ["#11324D", "#6B7AA1", "#C1CFC0", "#E7E0C9"],
      ["#6F4C5B", "#9E7777", "#DEBA9D", "#F5E8C7"],
      ["#E0C097", "#B85C38", "#5C3D2E", "#2D2424"],
      ["#F2E1C1", "#F6AE99", "#B97A95", "#716F81"],
      ["#FFEBC9", "#D79771", "#B05B3B", "#753422"],
      ["#A19882", "#C2B8A3", "#E6DDC6", "#FEF7DC"],
      ["#FCDEC0", "#E5B299", "#B4846C", "#7D5A50"],
      ["#C8C2BC", "#F2DAC3", "#F1CA89", "#CC9B6D"],
      ["#E3CDC1", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#FFE9D6", "#A7D0CD", "#7B6079", "#DE8971"],
      ["#E1F1DD", "#CDC7BE", "#87A7B3", "#766161"],
      ["#45526C", "#5AA897", "#F8A488", "#F8F5F1"],
      ["#798777", "#A2B29F", "#BDD2B6", "#F8EDE3"],
      ["#FFEFCF", "#FF7A00", "#D44000", "#864000"],
      ["#310B0B", "#9C3D54", "#E2703A", "#EEB76B"],
      ["#B67162", "#E2BCB7", "#E4D3CF", "#9E9D89"],
      ["#046582", "#6E7582", "#BB8082", "#F39189"],
      ["#BECA5C", "#EF8D32", "#CC561E", "#AA2B1D"],
      ["#E3D18A", "#BD9354", "#9E7540", "#85603F"],
      ["#FB743E", "#383E56", "#9FB8AD", "#C5D7BD"],
      ["#EAE3C8", "#CFC5A5", "#C2B092", "#A1CAE2"],
      ["#FDF1D6", "#C39E5C", "#DA723C", "#91091E"],
      ["#314E52", "#F2A154", "#F7F6E7", "#E7E6E1"],
      ["#3B5360", "#8B5E83", "#D6B0B1", "#BEE5D3"],
      ["#58391C", "#6A492B", "#E27802", "#F88F01"],
      ["#00303F", "#CDAC81", "#CAE4DB", "#F5F4F4"],
      ["#9DAB86", "#DED7B1", "#E08F62", "#CC7351"],
      ["#86ABA1", "#DFF3E3", "#F1AE89", "#D68060"],
      ["#682C0E", "#C24914", "#FC8621", "#F9E0AE"],
      ["#534E52", "#965D62", "#C7956D", "#F2D974"],
      ["#ADB36E", "#FAD5AD", "#FF9A8C", "#EA2C62"],
      ["#637373", "#679B9B", "#FFEADB", "#FF9A76"],
      ["#AA4A30", "#D57149", "#E89F71", "#EDCFA9"],
      ["#F4F4F4", "#F7D1BA", "#D49A89", "#557571"],
      ["#767C77", "#9CADA4", "#FBE2E5", "#FABEA7"],
      ["#D4B5B0", "#EEDAD1", "#F69E7B", "#383E56"],
      ["#7C3C21", "#EC823A", "#F9C49A", "#E8E4E1"],
      ["#F0DAA4", "#EAAC9D", "#B49C73", "#565D47"],
      ["#F6EEC9", "#FED39F", "#FE8761", "#AF460F"],
      ["#9D2503", "#DF8543", "#F1F1B0", "#7FA998"],
      ["#697C37", "#CCDA46", "#F4EEC7", "#FE9801"],
      ["#C9753D", "#EB8242", "#E6A157", "#9DAB86"],
      ["#232020", "#3A3535", "#FF7315", "#F4F4F4"],
    ],
  },
  {
    name: "food",
    palettes: [
      ["#EEE2B5", "#D7B26D", "#CA7373", "#3C552D"],
      ["#F09319", "#FFE31A", "#ABBA7C", "#3D5300"],
      ["#F6FCDF", "#859F3D", "#31511E", "#1A1A19"],
      ["#F87A53", "#E6C767", "#898121", "#4C4B16"],
      ["#54473F", "#9A7E6F", "#CBD2A4", "#E9EED9"],
      ["#493628", "#AB886D", "#D6C0B3", "#E4E0E1"],
      ["#DA8359", "#FCFAEE", "#ECDFCC", "#A5B68D"],
      ["#FFB0B0", "#FFD09B", "#FFECC8", "#FFF7D1"],
      ["#FCCD2A", "#FFFBE6", "#C0EBA6", "#347928"],
      ["#C1CFA1", "#A5B68D", "#EDE8DC", "#E7CCCC"],
      ["#33372C", "#557C56", "#FFE5CF", "#FF885B"],
      ["#185519", "#E8B86D", "#FCDE70", "#F5F7F8"],
      ["#E8B86D", "#F1F3C2", "#CEDF9F", "#A1D6B2"],
      ["#674636", "#AAB396", "#F7EED3", "#FFF8E8"],
      ["#6A9C89", "#C1D8C3", "#FFF5E4", "#CD5C08"],
      ["#B99470", "#FEFAE0", "#A6B37D", "#C0C78C"],
      ["#E7FBE6", "#CBE2B5", "#86AB89", "#A28B55"],
      ["#C5705D", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FEFAE0", "#FAEDCE", "#E0E5B6", "#CCD5AE"],
      ["#F4D9D0", "#D9ABAB", "#C75B7A", "#921A40"],
      ["#E7E8D8", "#CADABF", "#B5CFB7", "#BC9F8B"],
      ["#5F6F65", "#808D7C", "#9CA986", "#C9DABF"],
      ["#B5C18E", "#F7DCB9", "#DEAC80", "#914F1E"],
      ["#D6BD98", "#677D6A", "#40534C", "#1A3636"],
      ["#FF7777", "#FFAAAA", "#ECFFE6", "#399918"],
      ["#1A5319", "#508D4E", "#80AF81", "#D6EFD8"],
      ["#698474", "#FCF8F3", "#FFD3B6", "#DCA47C"],
      ["#2F3645", "#939185", "#E6B9A6", "#EEEDEB"],
      ["#729762", "#658147", "#597445", "#E7F0DC"],
      ["#B6C7AA", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#F5E7B2", "#F9D689", "#E0A75E", "#973131"],
      ["#F1F8E8", "#55AD9B", "#95D2B3", "#D8EFD3"],
      ["#FD9B63", "#E7D37F", "#81A263", "#365E32"],
      ["#97BE5A", "#FFE8C5", "#FFA27F", "#FF0000"],
      ["#ACD793", "#FFE8C8", "#FFC96F", "#FFA62F"],
      ["#E6FF94", "#9DDE8B", "#40A578", "#006769"],
      ["#F1F1F1", "#C7B7A3", "#EADBC8", "#B5C18E"],
      ["#7ABA78", "#0A6847", "#F6E9B2", "#F3CA52"],
      ["#FFD0D0", "#E1ACAC", "#CA8787", "#A87676"],
      ["#75A47F", "#BACD92", "#FCFFE0", "#F5DAD2"],
      ["#F5EFE6", "#E8DFCA", "#4F6F52", "#1A4D2E"],
      ["#322C2B", "#803D3B", "#AF8260", "#E4C59E"],
      ["#AFD198", "#E8EFCF", "#ECCA9C", "#DBA979"],
      ["#FFF2E1", "#EAD8C0", "#D1BB9E", "#A79277"],
      ["#A1C398", "#C6EBC5", "#FEFDED", "#FA7070"],
      ["#90D26D", "#2C7865", "#FF9800", "#D9EDBF"],
      ["#9CAFAA", "#D6DAC8", "#FBF3D5", "#EFBC9B"],
      ["#E5C287", "#898121", "#E8751A", "#FDA403"],
      ["#F1F5A8", "#E5E483", "#D2D180", "#B2B377"],
      ["#F7F6BB", "#FCDC2A", "#87A922", "#114232"],
      ["#F2C18D", "#F6F193", "#C5EBAA", "#A5DD9B"],
      ["#416D19", "#9BCF53", "#BFEA7C", "#FFF67E"],
      ["#B3A398", "#BBC3A4", "#C6DCBA", "#D7E4C0"],
      ["#EEEEEE", "#A3C9AA", "#C68484", "#9B4444"],
      ["#FFE7E7", "#CAA6A6", "#B47B84", "#944E63"],
      ["#FBFADA", "#ADBC9F", "#436850", "#12372A"],
      ["#E8D8C4", "#C7B7A3", "#6D2932", "#561C24"],
      ["#638889", "#9DBC98", "#EBD9B4", "#F9EFDB"],
      ["#E4DEBE", "#E6BAA3", "#D24545", "#A94438"],
      ["#76453B", "#B19470", "#F8FAE5", "#43766C"],
      ["#597E52", "#C6A969", "#F1E4C3", "#FFFFEC"],
      ["#D2E3C8", "#86A789", "#739072", "#4F6F52"],
      ["#820300", "#B80000", "#FF9800", "#5F8670"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#9A031E", "#E36414", "#FB8B24", "#5F0F40"],
      ["#F2F1EB", "#EEE7DA", "#AFC8AD", "#88AB8E"],
      ["#65B741", "#C1F2B0", "#FBF6EE", "#FFB534"],
      ["#EE7214", "#F7B787", "#F9E8D9", "#527853"],
      ["#B99470", "#FEFAE0", "#A9B388", "#5F6F52"],
      ["#9FBB73", "#F1EB90", "#F3B664", "#EC8F5E"],
      ["#FA7070", "#557C55", "#A6CF98", "#F2FFE9"],
      ["#FF5B22", "#FF9130", "#FECDA6", "#A9A9A9"],
      ["#3A4D39", "#4F6F52", "#739072", "#ECE3CE"],
      ["#F6F1EE", "#4F4A45", "#6C5F5B", "#ED7D31"],
      ["#FAF8ED", "#748E63", "#99B080", "#F9B572"],
      ["#9A4444", "#DE8F5F", "#F4DFB6", "#D6D46D"],
      ["#B2533E", "#FCE09B", "#B5CB99", "#186F65"],
      ["#D0E7D2", "#B0D9B1", "#79AC78", "#618264"],
      ["#FFCF9D", "#FFB000", "#F5F5DC", "#004225"],
      ["#EBEF95", "#EFD595", "#EFB495", "#EF9595"],
      ["#F2E8C6", "#DAD4B5", "#A73121", "#952323"],
      ["#FFEEF4", "#E4E4D0", "#AEC3AE", "#94A684"],
      ["#435334", "#9EB384", "#CEDEBD", "#FAF1E4"],
      ["#FFBFBF", "#FFE5E5", "#F3FDE8", "#A8DF8E"],
      ["#FFD9B7", "#7EAA92", "#9ED2BE", "#C8E4B2"],
      ["#9E9FA5", "#C4C1A4", "#FFF6DC", "#FFC6AC"],
      ["#C51605", "#FD8D14", "#FFE17B", "#CECE5A"],
      ["#8C3333", "#557A46", "#7A9D54", "#F2EE9D"],
      ["#FAE392", "#FBD85D", "#F1C93B", "#1A5D1A"],
      ["#FFC95F", "#FFF9C9", "#862B0D", "#B5C99A"],
      ["#FF6666", "#FF8989", "#FCAEAE", "#FFEADD"],
      ["#85A389", "#A2CDB0", "#FFD89C", "#F1C27B"],
      ["#F1C376", "#F7E6C4", "#FFF4F4", "#606C5D"],
      ["#E7CBCB", "#C88EA7", "#99627A", "#643843"],
      ["#FEA1A1", "#ECCDB4", "#F0EDD4", "#F9FBE7"],
      ["#617A55", "#A4D0A4", "#F7E1AE", "#FFF8D6"],
      ["#7C9070", "#9CA777", "#FEE8B0", "#F97B22"],
      ["#EDC6B1", "#B7B7B7", "#7C96AB", "#BFCCB5"],
      ["#E86A33", "#263A29", "#41644A", "#F2E3DB"],
      ["#FEF2F4", "#FCC8D1", "#FFABAB", "#D14D72"],
      ["#675D50", "#ABC4AA", "#F3DEBA", "#A9907E"],
      ["#DFA67B", "#F4B183", "#FFD966", "#FFF2CC"],
      ["#5D9C59", "#C7E8CA", "#DDF7E3", "#DF2E38"],
      ["#4C4B16", "#898121", "#E7B10A", "#F7F1E5"],
      ["#FCC2FC", "#C9F4AA", "#E5FDD1", "#B5F1CC"],
      ["#F9F5E7", "#F8EAD8", "#EDDBC7", "#A7727D"],
      ["#AACB73", "#CDE990", "#FFFFE8", "#FFD4D4"],
      ["#FAECD6", "#F2DEBA", "#4E6C50", "#820000"],
      ["#FFC3A1", "#F0997D", "#D3756B", "#A75D5D"],
      ["#A3BB98", "#F0ECCF", "#FBC252", "#FFB100"],
      ["#EEEEEE", "#F9B5D0", "#FF8E9E", "#FF597B"],
      ["#FFFBE9", "#F4EAD5", "#DAE2B6", "#CCD6A6"],
      ["#FAAB78", "#FFDCA9", "#FCF9BE", "#E8F3D6"],
      ["#B6E2A1", "#FFFBC1", "#FEBE8C", "#F7A4A4"],
      ["#EEEEEE", "#829460", "#90A17D", "#FFE1E1"],
      ["#F0EBCE", "#AA8B56", "#4E6C50", "#395144"],
      ["#EDDBC0", "#DBC8AC", "#B73E3E", "#DD5353"],
      ["#EEEEEE", "#829460", "#674747", "#F96666"],
      ["#FF9494", "#FFD1D1", "#FFE3E1", "#FFF5E4"],
      ["#A1C298", "#C6EBC5", "#FBF2CF", "#FA7070"],
    ],
  },
  {
    name: "gold",
    palettes: [
      ["#FA4032", "#FA812F", "#FAB12F", "#FEF3E2"],
      ["#FF4545", "#FF9C73", "#FBD288", "#FCF596"],
      ["#10375C", "#EB8317", "#F3C623", "#F4F6FF"],
      ["#EEDF7A", "#D8A25E", "#A04747", "#343131"],
      ["#821131", "#C7253E", "#E85C0D", "#FABC3F"],
      ["#173B45", "#B43F3F", "#FF8225", "#F8EDED"],
      ["#FF4C4C", "#FFB22C", "#FFDE4D", "#F3FEB8"],
      ["#B60071", "#E4003A", "#EB5B00", "#FFB200"],
      ["#F5E7B2", "#F9D689", "#E0A75E", "#973131"],
      ["#FF7D29", "#FFBF78", "#FFEEA9", "#FEFFD2"],
      ["#FFC100", "#FF8A08", "#FF6500", "#C40C0C"],
      ["#ED9455", "#FFBB70", "#FFEC9E", "#FFFBDA"],
      ["#FFF8DC", "#F7C566", "#DC6B19", "#6C0345"],
      ["#453F78", "#795458", "#C08B5C", "#FFC94A"],
      ["#673F69", "#D74B76", "#FB6D48", "#FFAF45"],
      ["#FFFDCB", "#FFB38E", "#FF8E8F", "#E178C5"],
      ["#9A031E", "#E36414", "#FB8B24", "#5F0F40"],
      ["#F5CCA0", "#E48F45", "#994D1C", "#6B240C"],
      ["#1F1717", "#CE5A67", "#F4BF96", "#FCF5ED"],
      ["#9A4444", "#DE8F5F", "#F4DFB6", "#D6D46D"],
      ["#FFCF9D", "#FFB000", "#F5F5DC", "#004225"],
      ["#F39F5A", "#AE445A", "#662549", "#451952"],
      ["#EBEF95", "#EFD595", "#EFB495", "#EF9595"],
      ["#C63D2F", "#E25E3E", "#FF9B50", "#FFBB5C"],
      ["#F2ECBE", "#E2C799", "#C08261", "#9A3B3B"],
      ["#FFBA86", "#F6635C", "#C23373", "#79155B"],
      ["#F8DE22", "#F94C10", "#C70039", "#900C3F"],
      ["#102C57", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#3F2305", "#DFD7BF", "#F2EAD3", "#F5F5F5"],
      ["#F1C376", "#F7E6C4", "#FFF4F4", "#606C5D"],
      ["#F9E0BB", "#FFC26F", "#C38154", "#884A39"],
      ["#FFE569", "#F79327", "#DB005B", "#B70404"],
      ["#FEA1A1", "#ECCDB4", "#F0EDD4", "#F9FBE7"],
      ["#4C3D3D", "#C07F00", "#FFD95A", "#FFF7D4"],
      ["#E74646", "#FA9884", "#FFE5CA", "#FFF3E2"],
      ["#FFE6C7", "#FFA559", "#FF6000", "#454545"],
      ["#4F200D", "#FF8400", "#FFD93D", "#F6F1E9"],
      ["#DFA67B", "#F4B183", "#FFD966", "#FFF2CC"],
      ["#4D4D4D", "#B46060", "#FFBF9B", "#FFF4E0"],
      ["#7A3E65", "#A84448", "#E9A178", "#F6E1C3"],
      ["#400E32", "#A61F69", "#F2921D", "#F2CD5C"],
      ["#FFC3A1", "#F0997D", "#D3756B", "#A75D5D"],
      ["#939B62", "#FFD56F", "#FFB26B", "#FF7B54"],
      ["#FAAB78", "#FFD495", "#FFFBAC", "#D7E9B9"],
      ["#C58940", "#E5BA73", "#FAEAB1", "#FAF8F1"],
      ["#B01E68", "#DC3535", "#F49D1A", "#FFE15D"],
      ["#FFF9B0", "#FFD384", "#FF884B", "#FF577F"],
      ["#9C2C77", "#CD104D", "#E14D2A", "#FD841F"],
      ["#E38B29", "#F1A661", "#FFD8A9", "#FDEEDC"],
      ["#F1EFDC", "#E6D2AA", "#D36B00", "#42032C"],
      ["#F5E8E4", "#F5C7A9", "#D1512D", "#411530"],
      ["#E6B325", "#BF9742", "#A47E3B", "#61481C"],
      ["#FFEE63", "#D4D925", "#FF5B00", "#990000"],
      ["#D61C4E", "#F77E21", "#FAC213", "#FEF9A7"],
      ["#F1EEE9", "#EC994B", "#73777B", "#15133C"],
      ["#CE9461", "#DEA057", "#FCFFE7", "#E0D8B0"],
      ["#FFEBC1", "#D7A86E", "#A64B2A", "#8E3200"],
      ["#EEEEEE", "#00092C", "#B20600", "#FF5F00"],
      ["#B7CADB", "#FDF6EC", "#F3E9DD", "#DAB88B"],
      ["#E6D5B8", "#E45826", "#F0A500", "#1B1A17"],
      ["#BF9270", "#E3B7A0", "#EDCDBB", "#FFEDDB"],
      ["#470D21", "#9C0F48", "#D67D3E", "#F9E4D4"],
      ["#F4DFBA", "#EEC373", "#CA965C", "#876445"],
      ["#95CD41", "#F6D860", "#FF7F3F", "#EA5C2B"],
      ["#F0D290", "#DE834D", "#A3423C", "#781D42"],
      ["#FFF323", "#FFCA03", "#FF5403", "#F90716"],
      ["#FFE300", "#FF7800", "#FFBC97", "#F7F7F7"],
      ["#EADEDE", "#B85252", "#F58840", "#000000"],
      ["#7E370C", "#B05E27", "#D4AC2B", "#FFCE45"],
      ["#FAFAFA", "#F7D08A", "#E5890A", "#9D5C0D"],
      ["#FFD07F", "#FDA65D", "#FF8243", "#E26A2C"],
      ["#FBFBFB", "#EADCA6", "#E2C275", "#C36A2D"],
      ["#51050F", "#AB6D23", "#F78812", "#E02401"],
      ["#852747", "#A2416B", "#FF7777", "#F5C6A5"],
      ["#EFEFEF", "#FFC069", "#A45D5D", "#4A403A"],
      ["#D5DBB3", "#FFF8D9", "#BB371A", "#EBA83A"],
      ["#FFBBCC", "#FFDDCC", "#FFC93C", "#99154E"],
      ["#FC5404", "#F98404", "#F9B208", "#F7FD04"],
      ["#C8C2BC", "#F2DAC3", "#F1CA89", "#CC9B6D"],
      ["#AAAAAA", "#F6DCBF", "#F7A440", "#E1701A"],
      ["#FF6701", "#FEA82F", "#FFC288", "#FCECDD"],
      ["#1B1A17", "#A35709", "#FF8303", "#F0E3CA"],
      ["#FFEFCF", "#FF7A00", "#D44000", "#864000"],
      ["#310B0B", "#9C3D54", "#E2703A", "#EEB76B"],
      ["#AC0D0D", "#F48B29", "#F0C929", "#FBE6C2"],
      ["#FFBE0F", "#FA1E0E", "#BD2000", "#8C0000"],
      ["#FFAEC0", "#FFF9B0", "#FFD384", "#FFAB73"],
      ["#E6D5B8", "#E45826", "#F0A500", "#4A3933"],
      ["#1E212D", "#B68973", "#EABF9F", "#FAF3E0"],
      ["#314E52", "#F2A154", "#F7F6E7", "#E7E6E1"],
      ["#58391C", "#6A492B", "#E27802", "#F88F01"],
      ["#0F0F0F", "#532E1C", "#C5A880", "#E6E6E6"],
      ["#682C0E", "#C24914", "#FC8621", "#F9E0AE"],
      ["#C75643", "#D08752", "#D1C145", "#F8F7DE"],
      ["#534E52", "#965D62", "#C7956D", "#F2D974"],
      ["#F8F1F1", "#FFA62B", "#DB6400", "#16697A"],
      ["#AEE6E6", "#FBF6F0", "#FFDA77", "#FFA45B"],
      ["#646464", "#FF9642", "#FFE05D", "#FFF8CD"],
      ["#AC4B1C", "#FCA652", "#FFD57E", "#FFEFA0"],
      ["#30475E", "#7E8A97", "#CBAF87", "#E7DEC8"],
      ["#1A1C20", "#CF7500", "#F0A500", "#F4F4F4"],
      ["#DBCBBD", "#C87941", "#87431D", "#290001"],
      ["#FBE6D4", "#FECB89", "#FFA931", "#B9AC92"],
      ["#000000", "#CF7500", "#F0A500", "#F4F4F4"],
      ["#7C3C21", "#EC823A", "#F9C49A", "#E8E4E1"],
      ["#F6EEDF", "#F57B51", "#D63447", "#FFD31D"],
      ["#581C0C", "#CA5116", "#F9B384", "#F1E3CB"],
      ["#FF926B", "#FFC38B", "#FFF3CD", "#4D3E3E"],
      ["#000000", "#CF7500", "#F0A500", "#DBDBDB"],
      ["#5A3F11", "#9C5518", "#EF962D", "#FAF4F4"],
      ["#D15A7C", "#EE8972", "#F7D695", "#F6EEC7"],
      ["#F6EEC9", "#FED39F", "#FE8761", "#AF460F"],
      ["#DEDEF0", "#FFAD87", "#FA877F", "#BD574E"],
      ["#2B2B28", "#E3B04B", "#F1D6AB", "#F8F8F8"],
      ["#FBE555", "#FB9224", "#F45905", "#2A1A5E"],
      ["#A72461", "#DC5353", "#FF935C", "#F9E090"],
      ["#0B032D", "#843B62", "#F67E7D", "#FFB997"],
      ["#C36A2D", "#E2C275", "#EADCA6", "#F4F4F4"],
      ["#DFDDC7", "#F58B54", "#A34A28", "#211717"],
      ["#FF8364", "#FFB677", "#FFD98E", "#616F39"],
    ],
  },
  {
    name: "gradient",
    palettes: [
      ["#A64D79", "#6A1E55", "#3B1C32", "#1A1A1D"],
      ["#FF4545", "#FF9C73", "#FBD288", "#FCF596"],
      ["#DE7C7D", "#CC2B52", "#AF1740", "#740938"],
      ["#F6FCDF", "#859F3D", "#31511E", "#1A1A19"],
      ["#4A628A", "#7AB2D3", "#B9E5E8", "#DFF2EB"],
      ["#EEEEEE", "#D4BEE4", "#9B7EBD", "#3B1E54"],
      ["#54473F", "#9A7E6F", "#CBD2A4", "#E9EED9"],
      ["#493628", "#AB886D", "#D6C0B3", "#E4E0E1"],
      ["#E2F1E7", "#629584", "#387478", "#243642"],
      ["#FEFF9F", "#D3EE98", "#A0D683", "#72BF78"],
      ["#FFB0B0", "#FFD09B", "#FFECC8", "#FFF7D1"],
      ["#15B392", "#54C392", "#73EC8B", "#D2FF72"],
      ["#7CF5FF", "#00CCDD", "#4F75FF", "#6439FF"],
      ["#E6D9A2", "#CB80AB", "#8967B3", "#624E88"],
      ["#FFF7F7", "#C68FE6", "#6C48C5", "#1230AE"],
      ["#E9EFEC", "#C4DAD2", "#6A9C89", "#16423C"],
      ["#EEDF7A", "#D8A25E", "#A04747", "#343131"],
      ["#C7FFD8", "#98DED9", "#0B2F9F", "#161D6F"],
      ["#821131", "#C7253E", "#E85C0D", "#FABC3F"],
      ["#EBD3F8", "#AD49E1", "#7A1CAC", "#2E073F"],
      ["#ECDFCC", "#697565", "#3C3D37", "#1E201E"],
      ["#F7F7F8", "#3795BD", "#4E31AA", "#3A1078"],
      ["#674188", "#C8A1E0", "#E2BFD9", "#F7EFE5"],
      ["#C5705D", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#387F39", "#A2CA71", "#BEDC74", "#F6E96B"],
      ["#F4D9D0", "#D9ABAB", "#C75B7A", "#921A40"],
      ["#FFEBD4", "#FFC6C6", "#F7B5CA", "#F0A8D0"],
      ["#5F6F65", "#808D7C", "#9CA986", "#C9DABF"],
      ["#E2E2B6", "#6EACDA", "#03346E", "#021526"],
      ["#694F8E", "#B692C2", "#E3A5C7", "#FFDFD6"],
      ["#EEEEEE", "#508C9B", "#134B70", "#201E43"],
      ["#D6BD98", "#677D6A", "#40534C", "#1A3636"],
      ["#8D493A", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#C8ACD6", "#433D8B", "#2E236C", "#17153B"],
      ["#FEFBD8", "#EECEB9", "#BB9AB1", "#987D9A"],
      ["#4535C1", "#478CCF", "#36C2CE", "#77E4C8"],
      ["#468585", "#50B498", "#9CDBA6", "#DEF9C4"],
      ["#1A5319", "#508D4E", "#80AF81", "#D6EFD8"],
      ["#304463", "#7D8ABC", "#FFC7ED", "#FFF8DB"],
      ["#EBF4F6", "#37B7C3", "#088395", "#071952"],
      ["#F5E7B2", "#F9D689", "#E0A75E", "#973131"],
      ["#3DC2EC", "#4B70F5", "#4C3BCF", "#402E7A"],
      ["#E2BBE9", "#9B86BD", "#7776B3", "#5A639C"],
      ["#F3FF90", "#9BEC00", "#06D001", "#059212"],
      ["#B3E2A7", "#80B9AD", "#6295A2", "#538392"],
      ["#A7E6FF", "#3ABEF9", "#3572EF", "#050C9C"],
      ["#DAD3BE", "#B7B597", "#6B8A7A", "#254336"],
      ["#FF7D29", "#FFBF78", "#FFEEA9", "#FEFFD2"],
      ["#543310", "#74512D", "#AF8F6F", "#F8F4E1"],
      ["#57A6A1", "#577B8D", "#344C64", "#240750"],
      ["#E6FF94", "#9DDE8B", "#40A578", "#006769"],
      ["#E0FBE2", "#BFF6C3", "#B0EBB4", "#ACE1AF"],
      ["#FFE6E6", "#E1AFD1", "#AD88C6", "#7469B6"],
      ["#F8D082", "#E65C19", "#B51B75", "#640D6B"],
      ["#F27BBD", "#C65BCF", "#874CCC", "#10439F"],
      ["#322C2B", "#803D3B", "#AF8260", "#E4C59E"],
      ["#C5FF95", "#5DEBD7", "#1679AB", "#074173"],
      ["#A3FFD6", "#7BC9FF", "#8576FF", "#1C1678"],
      ["#E3FEF7", "#77B0AA", "#135D66", "#003C43"],
      ["#FFF2E1", "#EAD8C0", "#D1BB9E", "#A79277"],
      ["#FFCDEA", "#FB9AD1", "#BC7FCD", "#86469C"],
      ["#912BBC", "#D875C7", "#E9A89B", "#FFEBB2"],
      ["#C4E4FF", "#D895DA", "#D6589F", "#D20062"],
      ["#673F69", "#D74B76", "#FB6D48", "#FFAF45"],
      ["#FDAF7B", "#BE7B72", "#824D74", "#401F71"],
      ["#E2F4C5", "#A8CD9F", "#58A399", "#496989"],
      ["#F7EEDD", "#ACE2E1", "#41C9E2", "#008DDA"],
      ["#00224D", "#5D0E41", "#A0153E", "#FF204E"],
      ["#EEE4B1", "#8C6A5D", "#5F374B", "#430A5D"],
      ["#DFF5FF", "#67C6E3", "#378CE7", "#5356FF"],
      ["#FFFDCB", "#FFB38E", "#FF8E8F", "#E178C5"],
      ["#F7418F", "#FC819E", "#FEC7B4", "#FFF3C7"],
      ["#F4EDCC", "#A4CE95", "#6196A6", "#5F5D9C"],
      ["#F1F5A8", "#E5E483", "#D2D180", "#B2B377"],
      ["#7469B6", "#AD88C6", "#E1AFD1", "#FFE6E6"],
      ["#FFB5DA", "#FF7ED4", "#FF3EA5", "#6420AA"],
      ["#F6F5F5", "#FFE3CA", "#EE99C2", "#0C359E"],
      ["#F1FADA", "#9AD0C2", "#2D9596", "#265073"],
      ["#EEA5A6", "#E493B3", "#B784B7", "#8E7AB5"],
      ["#9290C3", "#535C91", "#1B1A55", "#070F2B"],
      ["#B3A398", "#BBC3A4", "#C6DCBA", "#D7E4C0"],
      ["#EFF396", "#74E291", "#59B4C3", "#211C6A"],
      ["#FF8080", "#FFCF96", "#F6FDC3", "#CDFADB"],
      ["#FFE7E7", "#CAA6A6", "#B47B84", "#944E63"],
      ["#FBFADA", "#ADBC9F", "#436850", "#12372A"],
      ["#FFD0EC", "#81689D", "#474F7A", "#1F2544"],
      ["#643843", "#85586F", "#AC7D88", "#FDF0D1"],
      ["#96E9C6", "#83C0C1", "#6962AD", "#6C22A6"],
      ["#99BC85", "#BFD8AF", "#D4E7C5", "#E1F0DA"],
      ["#DBE7C9", "#789461", "#50623A", "#294B29"],
      ["#E4DEBE", "#E6BAA3", "#D24545", "#A94438"],
      ["#A87C7C", "#7E6363", "#503C3C", "#3E3232"],
      ["#F9F7C9", "#D5F0C1", "#AAD9BB", "#80BCBD"],
      ["#0F1035", "#365486", "#7FC7D9", "#DCF2F1"],
      ["#F6B17A", "#7077A1", "#424769", "#2D3250"],
      ["#176B87", "#86B6F6", "#B4D4FF", "#EEF5FF"],
      ["#D2E3C8", "#86A789", "#739072", "#4F6F52"],
      ["#33186B", "#7360DF", "#C499F3", "#F2AFEF"],
      ["#F6ECA9", "#C6CF9B", "#596FB7", "#11235A"],
      ["#FFE5E5", "#E0AED0", "#AC87C5", "#756AB6"],
      ["#200E3A", "#38419D", "#3887BE", "#52D3D8"],
      ["#FFD1E3", "#A367B1", "#5D3587", "#392467"],
      ["#EEF0E5", "#B6C4B6", "#304D30", "#163020"],
      ["#49108B", "#7E30E1", "#E26EE5", "#F3F8FF"],
      ["#FFA732", "#EF4040", "#C21292", "#711DB0"],
      ["#67729D", "#BB9CC0", "#E7BCDE", "#FED9ED"],
      ["#7B66FF", "#5FBDFF", "#96EFFF", "#C5FFF8"],
      ["#F5CCA0", "#E48F45", "#994D1C", "#6B240C"],
      ["#F3F3F3", "#C5E898", "#29ADB2", "#0766AD"],
      ["#F05941", "#BE3144", "#872341", "#22092C"],
      ["#776B5D", "#B0A695", "#EBE3D5", "#F3EEEA"],
      ["#D0A2F7", "#DCBFFF", "#E5D4FF", "#F1EAFF"],
      ["#DDF2FD", "#9BBEC8", "#427D9D", "#164863"],
      ["#7743DB", "#C3ACD0", "#F7EFE5", "#FFFBF5"],
      ["#C2D9FF", "#8E8FFA", "#7752FE", "#190482"],
      ["#687EFF", "#80B3FF", "#98E4FF", "#B6FFFA"],
      ["#B0578D", "#D988B9", "#FACBEA", "#FFE4D6"],
      ["#BEFFF7", "#A6F6FF", "#9EDDFF", "#6499E9"],
      ["#F5FCCD", "#78D6C6", "#419197", "#12486B"],
      ["#E5CFF7", "#9D76C1", "#713ABE", "#5B0888"],
    ],
  },
  {
    name: "halloween",
    palettes: [
      ["#A64D79", "#6A1E55", "#3B1C32", "#1A1A1D"],
      ["#DE7C7D", "#CC2B52", "#AF1740", "#740938"],
      ["#000000", "#0B192C", "#1E3E62", "#FF6500"],
      ["#EEDF7A", "#D8A25E", "#A04747", "#343131"],
      ["#EBD3F8", "#AD49E1", "#7A1CAC", "#2E073F"],
      ["#ECDFCC", "#697565", "#3C3D37", "#1E201E"],
      ["#F97300", "#E2DFD0", "#524C42", "#32012F"],
      ["#DBAFA0", "#BB8493", "#704264", "#49243E"],
      ["#F2613F", "#9B3922", "#481E14", "#0C0C0C"],
      ["#FDAF7B", "#BE7B72", "#824D74", "#401F71"],
      ["#EEE4B1", "#8C6A5D", "#5F374B", "#430A5D"],
      ["#78A083", "#50727B", "#344955", "#35374B"],
      ["#9290C3", "#535C91", "#1B1A55", "#070F2B"],
      ["#910A67", "#720455", "#3C0753", "#030637"],
      ["#FAEF5D", "#FF004D", "#7E2553", "#1D2B53"],
      ["#F6B17A", "#7077A1", "#424769", "#2D3250"],
      ["#FFD1E3", "#A367B1", "#5D3587", "#392467"],
      ["#BED754", "#E3651D", "#750E21", "#191919"],
      ["#EEE2DE", "#EA906C", "#B31312", "#2B2A4C"],
      ["#F05941", "#BE3144", "#872341", "#22092C"],
      ["#F6F1EE", "#4F4A45", "#6C5F5B", "#ED7D31"],
      ["#008170", "#005B41", "#232D3F", "#0F0F0F"],
      ["#F39F5A", "#AE445A", "#662549", "#451952"],
      ["#EC53B0", "#9D44C0", "#4D2DB7", "#0E21A0"],
      ["#FAF0E6", "#B9B4C7", "#5C5470", "#352F44"],
      ["#FE7BE5", "#974EC3", "#504099", "#313866"],
      ["#F79BD3", "#EA1179", "#9F0D7F", "#241468"],
      ["#AED8CC", "#CD6688", "#7A316F", "#461959"],
      ["#EFE1D1", "#A78295", "#3F2E3E", "#331D2C"],
      ["#EAB2A0", "#A76F6F", "#435B66", "#2D4356"],
      ["#CD1818", "#4E3636", "#321E1E", "#116D6E"],
      ["#E7CBCB", "#C88EA7", "#99627A", "#643843"],
      ["#D4ADFC", "#5C469C", "#1D267D", "#0C134F"],
      ["#B0A4A4", "#957777", "#643A6B", "#5F264A"],
      ["#FFE6C7", "#FFA559", "#FF6000", "#454545"],
      ["#E3CCAE", "#B8621B", "#262A56", "#000000"],
      ["#D27685", "#9E4784", "#66347F", "#37306B"],
      ["#E5E5CB", "#D5CEA3", "#3C2A21", "#1A120B"],
      ["#EEEEEE", "#DC5F00", "#CF0A0A", "#000000"],
      ["#D8D8D8", "#647E68", "#562B08", "#182747"],
      ["#A77979", "#704F4F", "#553939", "#472D2D"],
      ["#E7AB79", "#B25068", "#774360", "#4C3A51"],
      ["#FFEE63", "#D4D925", "#FF5B00", "#990000"],
      ["#7858A6", "#5B4B8A", "#4C3575", "#371B58"],
      ["#FA58B6", "#7A0BC0", "#270082", "#1A1A40"],
      ["#980F5A", "#750550", "#570530", "#4C0027"],
      ["#EEEEEE", "#8B9A46", "#541212", "#0F0E0E"],
      ["#ECDBBA", "#C84B31", "#2D4263", "#191919"],
      ["#B3541E", "#A13333", "#461111", "#040303"],
      ["#EADEDE", "#B85252", "#F58840", "#000000"],
      ["#E9A6A6", "#864879", "#3F3351", "#1F1D36"],
      ["#51050F", "#AB6D23", "#F78812", "#E02401"],
      ["#FF0000", "#950101", "#3D0000", "#000000"],
      ["#610094", "#3F0071", "#150050", "#000000"],
      ["#E63E6D", "#B42B51", "#7D1935", "#420516"],
      ["#FFA900", "#FF7600", "#CD113B", "#52006A"],
      ["#FFE9D6", "#A7D0CD", "#7B6079", "#DE8971"],
      ["#583D72", "#9F5F80", "#FF8474", "#FFC996"],
      ["#FFEFCF", "#FF7A00", "#D44000", "#864000"],
      ["#310B0B", "#9C3D54", "#E2703A", "#EEB76B"],
      ["#B4A5A5", "#3C415C", "#301B3F", "#151515"],
      ["#BECA5C", "#EF8D32", "#CC561E", "#AA2B1D"],
      ["#FDF1D6", "#C39E5C", "#DA723C", "#91091E"],
      ["#E79E4F", "#A05344", "#734046", "#321F28"],
      ["#30475E", "#BA6B57", "#F1935C", "#E7B2A5"],
      ["#6C567B", "#C06C84", "#F67280", "#F8B195"],
      ["#63B7AF", "#347474", "#35495E", "#EE8572"],
      ["#D2EBE9", "#ED8240", "#90303D", "#230444"],
      ["#FCB2BF", "#CF56A1", "#8B2F97", "#561F55"],
      ["#EE4540", "#C72C41", "#801336", "#2D132C"],
      ["#F6DA63", "#EB8242", "#DA2D2D", "#9D0B0B"],
      ["#FB5B5A", "#BC4873", "#472B62", "#003F5C"],
      ["#45969B", "#512C62", "#C70D3A", "#F45905"],
      ["#FFA069", "#C400C6", "#57007E", "#090057"],
      ["#211717", "#A34A28", "#F58B54", "#DFDDC7"],
      ["#FF4D00", "#FF0000", "#272121", "#443737"],
      ["#CE2E6C", "#FFB5B5", "#F0DECB", "#504658"],
      ["#B030B0", "#602080", "#202060", "#202040"],
      ["#02383C", "#230338", "#ED5107", "#C70D3A"],
      ["#FF6464", "#8B4367", "#543864", "#01024E"],
      ["#414141", "#AF0404", "#FF0000", "#252525"],
      ["#6E9086", "#FDC8B7", "#DE356A", "#540E33"],
      ["#CA3E47", "#313131", "#414141", "#525252"],
      ["#EEEEEE", "#D65A31", "#393E46", "#222831"],
      ["#FFD6C2", "#FF8B6A", "#DF0054", "#480032"],
      ["#141414", "#CF3030", "#E88A1A", "#D9D9D9"],
      ["#E9EEC9", "#BBBBBB", "#913535", "#283148"],
      ["#FFFE9A", "#F3A333", "#F16821", "#CD4545"],
      ["#F89D13", "#1B120F", "#8F1D14", "#E6DEDD"],
      ["#000000", "#841818", "#E0BB20", "#FAFAFA"],
      ["#362207", "#5F685A", "#EA9C1B", "#F3F6C8"],
      ["#FFB997", "#F67E7D", "#843B62", "#0B032D"],
      ["#F7F7F2", "#C5D86D", "#F05D23", "#260C1A"],
      ["#FFCCCC", "#FB7777", "#FF0000", "#05004E"],
      ["#FACF5A", "#F95959", "#455D7A", "#233142"],
      ["#EEC89F", "#EA5F2D", "#BB3939", "#860F44"],
      ["#34222E", "#E2434B", "#F9BF8F", "#FEE9D7"],
      ["#FFD2BB", "#FF8B6A", "#DF0054", "#480032"],
      ["#4B125C", "#A02A63", "#FFF7C2", "#FFA258"],
      ["#272E4F", "#2C365D", "#FF5E3A", "#F2F2F0"],
      ["#FF8260", "#FF4057", "#900048", "#240041"],
      ["#6C5B7C", "#C06C84", "#F67280", "#F8B595"],
      ["#B7E1B5", "#DE774E", "#684656", "#1C1124"],
      ["#0A0944", "#681A1E", "#B32C50", "#F56A47"],
      ["#8A1253", "#C51350", "#E8751A", "#FDA403"],
      ["#FFC300", "#EC610A", "#A40A3C", "#6B0848"],
      ["#ED8D8D", "#8D6262", "#4D4545", "#393232"],
      ["#FF6C00", "#A0204C", "#23103A", "#282D4F"],
      ["#03002C", "#BB0029", "#F5841A", "#F2E8C6"],
      ["#C6CD78", "#F1F08A", "#EC9454", "#8E3343"],
      ["#F4E557", "#F5A855", "#DC4444", "#97124B"],
      ["#FF8B8B", "#FF5151", "#0F4392", "#000249"],
      ["#F78F1E", "#8253D7", "#6A3CBC", "#461B93"],
      ["#FF5733", "#C70039", "#900C3F", "#581845"],
      ["#F6E9E9", "#E16428", "#272121", "#363333"],
      ["#E8D5B7", "#F9B248", "#FC3A52", "#0E2431"],
      ["#EECA98", "#DE7D48", "#CC085E", "#331621"],
      ["#F05941", "#BE3144", "#872341", "#2F1B41"],
      ["#7B3C59", "#FFB563", "#D53939", "#3C3B5C"],
      ["#5C2626", "#B74242", "#EA7362", "#FFD6B6"],
      ["#FECEA8", "#FF847C", "#E84A5F", "#45171D"],
      ["#530C0C", "#8F0E0E", "#FA7E0A", "#F7F6DE"],
      ["#6A2634", "#943939", "#E18237", "#FFE0A3"],
      ["#36162E", "#594057", "#B14B4B", "#FF6D3F"],
      ["#53354A", "#903749", "#E84545", "#2B2E4A"],
    ],
  },
  {
    name: "happy",
    palettes: [
      ["#FA4032", "#FA812F", "#FAB12F", "#FEF3E2"],
      ["#FFF9BF", "#FDDBBB", "#F0C1E1", "#CB9DF0"],
      ["#F09319", "#FFE31A", "#ABBA7C", "#3D5300"],
      ["#FFCCEA", "#FFF6E3", "#CDC1FF", "#BFECFF"],
      ["#FFFECB", "#F5F4B3", "#4CC9FE", "#37AFE1"],
      ["#FF77B7", "#FFA24C", "#FEEC37", "#B1D690"],
      ["#C62E2E", "#F95454", "#77CDFF", "#0D92F4"],
      ["#BCF2F6", "#08C2FF", "#006BFF", "#FFF100"],
      ["#FEFF9F", "#D3EE98", "#A0D683", "#72BF78"],
      ["#E78F81", "#FFCFB3", "#FFF5CD", "#B7E0FF"],
      ["#15B392", "#54C392", "#73EC8B", "#D2FF72"],
      ["#FCCD2A", "#FFFBE6", "#C0EBA6", "#347928"],
      ["#FDFAD9", "#C1E2A4", "#86D293", "#6256CA"],
      ["#604CC3", "#8FD14F", "#F5F5F5", "#FF6600"],
      ["#117554", "#6EC207", "#FFEB00", "#4379F2"],
      ["#CCE0AC", "#F0EAAC", "#F4DEB3", "#FF8A8A"],
      ["#FF9100", "#FFFBE6", "#D5ED9F", "#00712D"],
      ["#EECAD5", "#F1D3CE", "#F6EACB", "#D1E9F6"],
      ["#FEFAE0", "#FAEDCE", "#E0E5B6", "#CCD5AE"],
      ["#F5004F", "#FFAF00", "#F9E400", "#7C00FE"],
      ["#73BBA3", "#88D66C", "#B4E380", "#F6FB7A"],
      ["#FF7777", "#FFAAAA", "#ECFFE6", "#399918"],
      ["#FF4C4C", "#FFB22C", "#FFDE4D", "#F3FEB8"],
      ["#FF7EE2", "#FFA38F", "#FFD18E", "#E9FF97"],
      ["#E76F51", "#F4A261", "#E9C46A", "#36BA98"],
      ["#B1AFFF", "#BBE9FF", "#FFFED3", "#FFE9D0"],
      ["#F19ED2", "#E8C5E5", "#F7F9F2", "#91DDCF"],
      ["#EE4E4E", "#FFC700", "#FFF455", "#219C90"],
      ["#F3FF90", "#9BEC00", "#06D001", "#059212"],
      ["#F9F9E0", "#FF9EAA", "#FFD0D0", "#3AA6B9"],
      ["#5C2FC2", "#5C88C4", "#6FDCE3", "#FFFDB5"],
      ["#CAE6B2", "#F6FAB9", "#D2649A", "#8E3E63"],
      ["#FF0080", "#FF5580", "#FFAA80", "#FFFF80"],
      ["#FF70AB", "#FFAF61", "#FFDB5C", "#C3FF93"],
      ["#5AB2FF", "#A0DEFF", "#CAF4FF", "#FFF9D0"],
      ["#FEEFAD", "#FDDE55", "#68D2E8", "#03AED2"],
      ["#FFC100", "#FF8A08", "#FF6500", "#C40C0C"],
      ["#A3D8FF", "#94FFD8", "#FDFFC2", "#FF76CE"],
      ["#ED9455", "#FFBB70", "#FFEC9E", "#FFFBDA"],
      ["#7EA1FF", "#FFD1E3", "#FFFAB7", "#5BBCFF"],
      ["#8644A2", "#D862BC", "#E59BE9", "#FFEBB2"],
      ["#FFFDD7", "#FFE4CF", "#FF5BAE", "#E72929"],
      ["#F7EEDD", "#ACE2E1", "#41C9E2", "#008DDA"],
      ["#FFF455", "#FFC700", "#4CCD99", "#007F73"],
      ["#FFFDCB", "#FFB38E", "#FF8E8F", "#E178C5"],
      ["#F4538A", "#FAA300", "#F5DD61", "#59D5E0"],
      ["#FB88B4", "#F9F07A", "#B7C9F2", "#9195F6"],
      ["#416D19", "#9BCF53", "#BFEA7C", "#FFF67E"],
      ["#FF8911", "#FDBF60", "#9F70FD", "#7F27FF"],
      ["#FF8080", "#FFCF96", "#F6FDC3", "#CDFADB"],
      ["#B7E5B4", "#FFFC9B", "#FFA447", "#F28585"],
      ["#FFEAA7", "#FFBB64", "#FF6868", "#DCFFB7"],
      ["#3468C0", "#86A7FC", "#FFDD95", "#FF9843"],
      ["#FDFFAB", "#FFCF81", "#FFB996", "#D9EDBF"],
      ["#F6D6D6", "#F6F7C4", "#A1EEBD", "#7BD3EA"],
      ["#49108B", "#7E30E1", "#E26EE5", "#F3F8FF"],
      ["#65B741", "#C1F2B0", "#FBF6EE", "#FFB534"],
      ["#FFAD84", "#FFC47E", "#FFE382", "#FFF78A"],
      ["#8ADAB2", "#D0F288", "#F8FFD2", "#DF826C"],
      ["#89B9AD", "#C7DCA7", "#FFEBD8", "#FFC5C5"],
      ["#FFEED9", "#39A7FF", "#87C4FF", "#E0F4FF"],
      ["#A2C579", "#D2DE32", "#FFFFDD", "#61A3BA"],
      ["#FFCD4B", "#FF7676", "#FF4B91", "#0802A3"],
      ["#CDFAD5", "#F6FDC3", "#FFCF96", "#FF8080"],
      ["#8EACCD", "#D7E5CA", "#F9F3CC", "#D2E0FB"],
      ["#EDB7ED", "#82A0D8", "#8DDFCB", "#ECEE81"],
      ["#A6FF96", "#F8FF95", "#BC7AF9", "#FFA1F5"],
      ["#D67BFF", "#FFB6D9", "#FEFFAC", "#45FFCA"],
      ["#CAEDFF", "#D8B4F8", "#FFC7EA", "#FBF0B2"],
      ["#FFBFBF", "#FFE5E5", "#F3FDE8", "#A8DF8E"],
      ["#D5FFE4", "#8BE8E5", "#A084E8", "#6F61C0"],
      ["#916DB3", "#E48586", "#FCBAAD", "#FDE5EC"],
      ["#FBEEAC", "#F4D160", "#75C2F6", "#1D5D9B"],
      ["#FFECAF", "#FFB07F", "#FF52A2", "#F31559"],
      ["#F8FDCF", "#E2F6CA", "#9BE8D8", "#78C1F3"],
      ["#FDFFAE", "#E9FFC2", "#C3EDC0", "#AAC8A7"],
      ["#CBFFA9", "#FFFEC4", "#FFD6A5", "#FF9B9B"],
      ["#A4907C", "#FBFFDC", "#D0F5BE", "#98EECC"],
      ["#C1ECE4", "#FF9EAA", "#FFD0D0", "#3AA6B9"],
      ["#FFF6F4", "#FFA41B", "#F86F03", "#525FE1"],
    ],
  },
  {
    name: "kids",
    palettes: [
      ["#FFF8DE", "#D0E8C5", "#C5D3E8", "#A6AEBF"],
      ["#FFF9BF", "#FDDBBB", "#F0C1E1", "#CB9DF0"],
      ["#FFDDAE", "#FBFBFB", "#D4F6FF", "#C6E7FF"],
      ["#FFCCEA", "#FFF6E3", "#CDC1FF", "#BFECFF"],
      ["#FFFECB", "#F5F4B3", "#4CC9FE", "#37AFE1"],
      ["#FF77B7", "#FFA24C", "#FEEC37", "#B1D690"],
      ["#FF9D3D", "#FFBD73", "#FEEE91", "#C4E1F6"],
      ["#C9E9D2", "#FEF9F2", "#FFE3E3", "#789DBC"],
      ["#FFE700", "#FEFFA7", "#B6FFA1", "#00FF9C"],
      ["#BCF2F6", "#08C2FF", "#006BFF", "#FFF100"],
      ["#96CEB4", "#FFEEAD", "#FFAD60", "#A66E38"],
      ["#E78F81", "#FFCFB3", "#FFF5CD", "#B7E0FF"],
      ["#15B392", "#54C392", "#73EC8B", "#D2FF72"],
      ["#FFF4B5", "#FFD7C4", "#C4D7FF", "#87A2FF"],
      ["#FFEB55", "#EE66A6", "#D91656", "#640D5F"],
      ["#FDFAD9", "#C1E2A4", "#86D293", "#6256CA"],
      ["#604CC3", "#8FD14F", "#F5F5F5", "#FF6600"],
      ["#117554", "#6EC207", "#FFEB00", "#4379F2"],
      ["#7EACB5", "#FFF4EA", "#FADFA1", "#C96868"],
      ["#FFF6EA", "#FEECB3", "#ED3EF7", "#BF2EF0"],
      ["#FF9874", "#FFD7C4", "#9DBDFF", "#7695FF"],
      ["#0B8494", "#125B9A", "#F05A7E", "#FFBE98"],
      ["#D7C3F1", "#BDE8CA", "#41B3A2", "#0D7C66"],
      ["#B99470", "#FEFAE0", "#A6B37D", "#C0C78C"],
      ["#CCE0AC", "#F0EAAC", "#F4DEB3", "#FF8A8A"],
      ["#674188", "#C8A1E0", "#E2BFD9", "#F7EFE5"],
      ["#E7FBE6", "#CBE2B5", "#86AB89", "#A28B55"],
      ["#EECAD5", "#F1D3CE", "#F6EACB", "#D1E9F6"],
      ["#96CEB4", "#FFEEAD", "#FFAD60", "#A02334"],
      ["#4158A6", "#179BAE", "#F1DEC6", "#FF8343"],
      ["#387F39", "#A2CA71", "#BEDC74", "#F6E96B"],
      ["#FF4E88", "#FF8C9E", "#FFDA76", "#B4D6CD"],
      ["#FFEBD4", "#FFC6C6", "#F7B5CA", "#F0A8D0"],
      ["#536493", "#D4BDAC", "#FFF1DB", "#EF5A6F"],
      ["#FF7777", "#FFAAAA", "#ECFFE6", "#399918"],
      ["#0F67B1", "#3FA2F6", "#96C9F4", "#FAFFAF"],
      ["#304463", "#7D8ABC", "#FFC7ED", "#FFF8DB"],
      ["#DA7297", "#FFB4C2", "#FDFFD2", "#667BC6"],
      ["#B1AFFF", "#BBE9FF", "#FFFED3", "#FFE9D0"],
      ["#F19ED2", "#E8C5E5", "#F7F9F2", "#91DDCF"],
      ["#604CC3", "#80C4E9", "#FFF6E9", "#FF7F3E"],
      ["#F1F8E8", "#55AD9B", "#95D2B3", "#D8EFD3"],
      ["#78ABA8", "#C8CFA0", "#FCDC94", "#EF9C66"],
      ["#FFDB00", "#FF8F00", "#AF47D2", "#26355D"],
      ["#F9F9E0", "#FF9EAA", "#FFD0D0", "#3AA6B9"],
      ["#97BE5A", "#FFE8C5", "#FFA27F", "#FF0000"],
      ["#ACD793", "#FFE8C8", "#FFC96F", "#FFA62F"],
      ["#002379", "#FF5F00", "#FF9F66", "#FFFAE6"],
      ["#DD761C", "#FEB941", "#FDE49E", "#6DC5D1"],
      ["#FF70AB", "#FFAF61", "#FFDB5C", "#C3FF93"],
      ["#FFE6E6", "#E1AFD1", "#AD88C6", "#7469B6"],
      ["#FFD0D0", "#E1ACAC", "#CA8787", "#A87676"],
      ["#2C4E80", "#00215E", "#FFC55A", "#FC4100"],
      ["#A3D8FF", "#94FFD8", "#FDFFC2", "#FF76CE"],
      ["#B99470", "#DEAC80", "#F7DCB9", "#B5C18E"],
      ["#FFEFEF", "#F3D0D7", "#F0EBE3", "#F6F5F2"],
      ["#8B322C", "#DD5746", "#FFC470", "#4793AF"],
      ["#7EA1FF", "#FFD1E3", "#FFFAB7", "#5BBCFF"],
      ["#FFCDEA", "#FB9AD1", "#BC7FCD", "#86469C"],
      ["#912BBC", "#D875C7", "#E9A89B", "#FFEBB2"],
      ["#C4E4FF", "#D895DA", "#D6589F", "#D20062"],
      ["#A1C398", "#C6EBC5", "#FEFDED", "#FA7070"],
      ["#FFFDD7", "#FFE4CF", "#FF5BAE", "#E72929"],
      ["#F7EEDD", "#ACE2E1", "#41C9E2", "#008DDA"],
      ["#FFF455", "#FFC700", "#4CCD99", "#007F73"],
      ["#E5C287", "#898121", "#E8751A", "#FDA403"],
      ["#F1EF99", "#EBC49F", "#D37676", "#B0C5A4"],
      ["#F4EDCC", "#A4CE95", "#6196A6", "#5F5D9C"],
      ["#F6995C", "#51829B", "#9BB0C1", "#EADFB4"],
      ["#FFB5DA", "#FF7ED4", "#FF3EA5", "#6420AA"],
      ["#F4538A", "#FAA300", "#F5DD61", "#59D5E0"],
      ["#F2C18D", "#F6F193", "#C5EBAA", "#A5DD9B"],
      ["#337357", "#FFD23F", "#EE4266", "#5E1675"],
      ["#50C4ED", "#387ADF", "#333A73", "#FBA834"],
      ["#F6F5F5", "#FFE3CA", "#EE99C2", "#0C359E"],
      ["#FB88B4", "#F9F07A", "#B7C9F2", "#9195F6"],
      ["#416D19", "#9BCF53", "#BFEA7C", "#FFF67E"],
      ["#F0F3FF", "#15F5BA", "#836FFF", "#211951"],
      ["#FF8911", "#FDBF60", "#9F70FD", "#7F27FF"],
      ["#EFF396", "#74E291", "#59B4C3", "#211C6A"],
      ["#BED1CF", "#E78895", "#FFE4C9", "#FFF7F1"],
      ["#FF8080", "#FFCF96", "#F6FDC3", "#CDFADB"],
      ["#0D9276", "#BBE2EC", "#FFF6E9", "#40A2E3"],
      ["#637A9F", "#C9D7DD", "#FFF3CF", "#E8C872"],
      ["#B7E5B4", "#FFFC9B", "#FFA447", "#F28585"],
      ["#6895D2", "#FDE767", "#F3B95F", "#D04848"],
      ["#F2F597", "#37B5B6", "#492E87", "#0A1D56"],
      ["#E9F6FF", "#280274", "#3652AD", "#FE7A36"],
      ["#FFEAA7", "#FFBB64", "#FF6868", "#DCFFB7"],
      ["#402B3A", "#D63484", "#FF9BD2", "#F8F4EC"],
      ["#3468C0", "#86A7FC", "#FFDD95", "#FF9843"],
      ["#F9F7C9", "#D5F0C1", "#AAD9BB", "#80BCBD"],
      ["#DC84F3", "#E9A8F2", "#F3CCF3", "#FFE7C1"],
      ["#FFE5E5", "#E0AED0", "#AC87C5", "#756AB6"],
      ["#F6D6D6", "#F6F7C4", "#A1EEBD", "#7BD3EA"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#0F2167", "#3559E0", "#4CB9E7", "#FFECD6"],
      ["#F2F1EB", "#EEE7DA", "#AFC8AD", "#88AB8E"],
      ["#B1C381", "#EEC759", "#FFF7D4", "#9BB8CD"],
      ["#8ACDD7", "#F9F9E0", "#FFC0D9", "#FF90BC"],
      ["#8ADAB2", "#D0F288", "#F8FFD2", "#DF826C"],
      ["#3081D0", "#6DB9EF", "#F4F27E", "#FFF5C2"],
      ["#B06161", "#DC8686", "#F0DBAF", "#7ED7C1"],
      ["#508D69", "#9ADE7B", "#EEF296", "#FF8F8F"],
      ["#89B9AD", "#C7DCA7", "#FFEBD8", "#FFC5C5"],
      ["#FFD28F", "#FFE3BB", "#B4BDFF", "#83A2FF"],
      ["#9FBB73", "#F1EB90", "#F3B664", "#EC8F5E"],
      ["#FFEED9", "#39A7FF", "#87C4FF", "#E0F4FF"],
      ["#FFD099", "#FF9209", "#FF6C22", "#2B3499"],
      ["#FFFB73", "#FFA33C", "#B15EFF", "#3D30A2"],
      ["#CDF5FD", "#A0E9FF", "#89CFF3", "#00A9FF"],
      ["#AEDEFC", "#FFF6F6", "#FFDFDF", "#F875AA"],
      ["#072541", "#5272F2", "#F8BDEB", "#FBECB2"],
      ["#FFF8C9", "#DFCCFB", "#D0BFFF", "#BEADFA"],
      ["#FFCD4B", "#FF7676", "#FF4B91", "#0802A3"],
      ["#CDFAD5", "#F6FDC3", "#FFCF96", "#FF8080"],
      ["#FDF0F0", "#F1B4BB", "#1F4172", "#132043"],
      ["#EEEEEE", "#2E97A7", "#1AACAC", "#362FD9"],
      ["#B0578D", "#D988B9", "#FACBEA", "#FFE4D6"],
      ["#8EACCD", "#D7E5CA", "#F9F3CC", "#D2E0FB"],
    ],
  },
  {
    name: "light",
    palettes: [
      ["#FFF8DE", "#D0E8C5", "#C5D3E8", "#A6AEBF"],
      ["#FFF9BF", "#FDDBBB", "#F0C1E1", "#CB9DF0"],
      ["#FFDDAE", "#FBFBFB", "#D4F6FF", "#C6E7FF"],
      ["#FFCCEA", "#FFF6E3", "#CDC1FF", "#BFECFF"],
      ["#FFFECB", "#F5F4B3", "#4CC9FE", "#37AFE1"],
      ["#FF9D3D", "#FFBD73", "#FEEE91", "#C4E1F6"],
      ["#C9E9D2", "#FEF9F2", "#FFE3E3", "#789DBC"],
      ["#FFE700", "#FEFFA7", "#B6FFA1", "#00FF9C"],
      ["#FEFF9F", "#D3EE98", "#A0D683", "#72BF78"],
      ["#FFB0B0", "#FFD09B", "#FFECC8", "#FFF7D1"],
      ["#E78F81", "#FFCFB3", "#FFF5CD", "#B7E0FF"],
      ["#FFF4B5", "#FFD7C4", "#C4D7FF", "#87A2FF"],
      ["#C1CFA1", "#A5B68D", "#EDE8DC", "#E7CCCC"],
      ["#A594F9", "#CDC1FF", "#F5EFFF", "#E5D9F2"],
      ["#8EACCD", "#DEE5D4", "#FEF9D9", "#D2E0FB"],
      ["#E8B86D", "#F1F3C2", "#CEDF9F", "#A1D6B2"],
      ["#CCE0AC", "#F0EAAC", "#F4DEB3", "#FF8A8A"],
      ["#E7FBE6", "#CBE2B5", "#86AB89", "#A28B55"],
      ["#EECAD5", "#F1D3CE", "#F6EACB", "#D1E9F6"],
      ["#FEFAE0", "#FAEDCE", "#E0E5B6", "#CCD5AE"],
      ["#FFEBD4", "#FFC6C6", "#F7B5CA", "#F0A8D0"],
      ["#F5EDED", "#E2DAD6", "#7FA1C3", "#6482AD"],
      ["#E7E8D8", "#CADABF", "#B5CFB7", "#BC9F8B"],
      ["#73BBA3", "#88D66C", "#B4E380", "#F6FB7A"],
      ["#FEFBD8", "#EECEB9", "#BB9AB1", "#987D9A"],
      ["#FF7EE2", "#FFA38F", "#FFD18E", "#E9FF97"],
      ["#B1AFFF", "#BBE9FF", "#FFFED3", "#FFE9D0"],
      ["#B6C7AA", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#F19ED2", "#E8C5E5", "#F7F9F2", "#91DDCF"],
      ["#F1F8E8", "#55AD9B", "#95D2B3", "#D8EFD3"],
      ["#FF7D29", "#FFBF78", "#FFEEA9", "#FEFFD2"],
      ["#ACD793", "#FFE8C8", "#FFC96F", "#FFA62F"],
      ["#E0FBE2", "#BFF6C3", "#B0EBB4", "#ACE1AF"],
      ["#F1F1F1", "#C7B7A3", "#EADBC8", "#B5C18E"],
      ["#5AB2FF", "#A0DEFF", "#CAF4FF", "#FFF9D0"],
      ["#FEEFAD", "#FDDE55", "#68D2E8", "#03AED2"],
      ["#D8AE7E", "#F8C794", "#FFE0B5", "#FFF2D7"],
      ["#A3D8FF", "#94FFD8", "#FDFFC2", "#FF76CE"],
      ["#75A47F", "#BACD92", "#FCFFE0", "#F5DAD2"],
      ["#ED9455", "#FFBB70", "#FFEC9E", "#FFFBDA"],
      ["#FFEFEF", "#F3D0D7", "#F0EBE3", "#F6F5F2"],
      ["#7EA1FF", "#FFD1E3", "#FFFAB7", "#5BBCFF"],
      ["#E5DDC5", "#F1EEDC", "#BED7DC", "#B3C8CF"],
      ["#7AA2E3", "#6AD4DD", "#97E7E1", "#F8F6E3"],
      ["#A1C398", "#C6EBC5", "#FEFDED", "#FA7070"],
      ["#9CAFAA", "#D6DAC8", "#FBF3D5", "#EFBC9B"],
      ["#FFFDCB", "#FFB38E", "#FF8E8F", "#E178C5"],
      ["#F7418F", "#FC819E", "#FEC7B4", "#FFF3C7"],
      ["#F1F5A8", "#E5E483", "#D2D180", "#B2B377"],
      ["#F2C18D", "#F6F193", "#C5EBAA", "#A5DD9B"],
      ["#E2BFB3", "#F7DED0", "#FEECE2", "#FFBE98"],
      ["#EED3D9", "#F5E8DD", "#CCD3CA", "#B5C0D0"],
      ["#B3A398", "#BBC3A4", "#C6DCBA", "#D7E4C0"],
      ["#BED1CF", "#E78895", "#FFE4C9", "#FFF7F1"],
      ["#FF8080", "#FFCF96", "#F6FDC3", "#CDFADB"],
      ["#99BC85", "#BFD8AF", "#D4E7C5", "#E1F0DA"],
      ["#FFEAA7", "#FFBB64", "#FF6868", "#DCFFB7"],
      ["#E6A4B4", "#F3D7CA", "#FFF8E3", "#F5EEE6"],
      ["#FDFFAB", "#FFCF81", "#FFB996", "#D9EDBF"],
      ["#F9F7C9", "#D5F0C1", "#AAD9BB", "#80BCBD"],
      ["#E5E1DA", "#FBF9F1", "#AAD7D9", "#92C7CF"],
      ["#F6D6D6", "#F6F7C4", "#A1EEBD", "#7BD3EA"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#F2F1EB", "#EEE7DA", "#AFC8AD", "#88AB8E"],
      ["#BBAB8C", "#DED0B6", "#FAEED1", "#FDF7E4"],
      ["#89B9AD", "#C7DCA7", "#FFEBD8", "#FFC5C5"],
      ["#D0A2F7", "#DCBFFF", "#E5D4FF", "#F1EAFF"],
      ["#7743DB", "#C3ACD0", "#F7EFE5", "#FFFBF5"],
      ["#EBF3E8", "#D2E3C8", "#B2C8BA", "#86A789"],
      ["#CDF5FD", "#A0E9FF", "#89CFF3", "#00A9FF"],
      ["#AEDEFC", "#FFF6F6", "#FFDFDF", "#F875AA"],
      ["#FAF8ED", "#748E63", "#99B080", "#F9B572"],
      ["#D6C7AE", "#BFB29E", "#B3A492", "#DADDB1"],
      ["#FFF8C9", "#DFCCFB", "#D0BFFF", "#BEADFA"],
      ["#8EACCD", "#D7E5CA", "#F9F3CC", "#D2E0FB"],
      ["#FFEEF4", "#E4E4D0", "#AEC3AE", "#94A684"],
      ["#D67BFF", "#FFB6D9", "#FEFFAC", "#45FFCA"],
      ["#CAEDFF", "#D8B4F8", "#FFC7EA", "#FBF0B2"],
      ["#FFBFBF", "#FFE5E5", "#F3FDE8", "#A8DF8E"],
      ["#BEADFA", "#D0BFFF", "#DFCCFB", "#FFF3DA"],
      ["#F1F0E8", "#EEE0C9", "#ADC4CE", "#96B6C5"],
      ["#FFD9B7", "#7EAA92", "#9ED2BE", "#C8E4B2"],
      ["#FEBBCC", "#FFCCCC", "#FFDDCC", "#FFEECC"],
      ["#FFDBAA", "#FFF0F5", "#FFD1DA", "#FBA1B7"],
      ["#DBC4F0", "#FFCACC", "#D4E2D4", "#FAF3F0"],
      ["#CCEEBC", "#8CC0DE", "#FFD9C0", "#FAF0D7"],
      ["#F8FDCF", "#E2F6CA", "#9BE8D8", "#78C1F3"],
      ["#FDFFAE", "#E9FFC2", "#C3EDC0", "#AAC8A7"],
      ["#CBFFA9", "#FFFEC4", "#FFD6A5", "#FF9B9B"],
      ["#A4907C", "#FBFFDC", "#D0F5BE", "#98EECC"],
      ["#F7FFE5", "#E1ECC8", "#C4D7B2", "#A0C49D"],
      ["#FFEEBB", "#A7ECEE", "#99DBF5", "#9AC5F4"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#9BABB8"],
      ["#F6FFA6", "#F3BCC8", "#E893CF", "#9376E0"],
      ["#F2BED1", "#FDCEDF", "#F8E8EE", "#F9F5F6"],
      ["#FBFFDC", "#D0F5BE", "#98EECC", "#79E0EE"],
      ["#F8F6F4", "#E3F4F4", "#D2E9E9", "#C4DFDF"],
      ["#E6FFFD", "#AEE2FF", "#ACBCFF", "#B799FF"],
      ["#FEA1A1", "#ECCDB4", "#F0EDD4", "#F9FBE7"],
      ["#FFF8DE", "#D6E8DB", "#C1D0B5", "#99A98F"],
      ["#AAC8A7", "#C9DBB2", "#E3F2C1", "#F6FFDE"],
      ["#FFF9DE", "#FFD3B0", "#FF6969", "#A6D0DD"],
      ["#C0DBEA", "#BA90C6", "#E8A0BF", "#FDF4F5"],
      ["#A4BC92", "#B3C99C", "#C7E9B0", "#DDFFBB"],
      ["#DAF5FF", "#B9E9FC", "#B0DAFF", "#FEFF86"],
      ["#FDF7C3", "#FFDEB4", "#FFB4B4", "#B2A4FF"],
      ["#DFA67B", "#F4B183", "#FFD966", "#FFF2CC"],
      ["#FAEDCD", "#FEFAE0", "#E9EDC9", "#CCD5AE"],
      ["#FBFFB1", "#FFEBB4", "#FFBFA9", "#FFACAC"],
      ["#EEEEEE", "#E8D5C4", "#FFF1DC", "#3A98B9"],
      ["#95BDFF", "#B4E4FF", "#DFFFD8", "#F7C8E0"],
      ["#FFF4D2", "#E5D1FA", "#E3DFFD", "#ECF2FF"],
      ["#F6E6C2", "#FFAACF", "#EA8FEA", "#B9F3E4"],
      ["#F9F5E7", "#F8EAD8", "#EDDBC7", "#A7727D"],
      ["#F8CBA6", "#FFE7CC", "#FFFBEB", "#ECF9FF"],
      ["#FDEBED", "#FFCEFE", "#D9ACF5", "#AAE3E2"],
      ["#FEDEFF", "#93C6E7", "#AEE2FF", "#B9F3FC"],
      ["#FFF2F2", "#E5E0FF", "#8EA7E9", "#7286D3"],
      ["#AACB73", "#CDE990", "#FFFFE8", "#FFD4D4"],
      ["#E3F6FF", "#9DF1DF", "#8DCBE6", "#FFEA20"],
    ],
  },
  {
    name: "nature",
    palettes: [
      ["#EEE2B5", "#D7B26D", "#CA7373", "#3C552D"],
      ["#F09319", "#FFE31A", "#ABBA7C", "#3D5300"],
      ["#F6FCDF", "#859F3D", "#31511E", "#1A1A19"],
      ["#F87A53", "#E6C767", "#898121", "#4C4B16"],
      ["#54473F", "#9A7E6F", "#CBD2A4", "#E9EED9"],
      ["#626F47", "#F2EED7", "#FEFAE0", "#798645"],
      ["#96CEB4", "#FFEEAD", "#FFAD60", "#A66E38"],
      ["#FCCD2A", "#FFFBE6", "#C0EBA6", "#347928"],
      ["#33372C", "#557C56", "#FFE5CF", "#FF885B"],
      ["#E8B86D", "#F1F3C2", "#CEDF9F", "#A1D6B2"],
      ["#674636", "#AAB396", "#F7EED3", "#FFF8E8"],
      ["#6A9C89", "#C1D8C3", "#FFF5E4", "#CD5C08"],
      ["#B99470", "#FEFAE0", "#A6B37D", "#C0C78C"],
      ["#FF9100", "#FFFBE6", "#D5ED9F", "#00712D"],
      ["#E7FBE6", "#CBE2B5", "#86AB89", "#A28B55"],
      ["#387F39", "#A2CA71", "#BEDC74", "#F6E96B"],
      ["#FEFAE0", "#FAEDCE", "#E0E5B6", "#CCD5AE"],
      ["#5F6F65", "#808D7C", "#9CA986", "#C9DABF"],
      ["#B5C18E", "#F7DCB9", "#DEAC80", "#914F1E"],
      ["#73BBA3", "#88D66C", "#B4E380", "#F6FB7A"],
      ["#D6BD98", "#677D6A", "#40534C", "#1A3636"],
      ["#FF7777", "#FFAAAA", "#ECFFE6", "#399918"],
      ["#468585", "#50B498", "#9CDBA6", "#DEF9C4"],
      ["#1A5319", "#508D4E", "#80AF81", "#D6EFD8"],
      ["#698474", "#FCF8F3", "#FFD3B6", "#DCA47C"],
      ["#2F3645", "#939185", "#E6B9A6", "#EEEDEB"],
      ["#729762", "#658147", "#597445", "#E7F0DC"],
      ["#B6C7AA", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#F3FF90", "#9BEC00", "#06D001", "#059212"],
      ["#FD9B63", "#E7D37F", "#81A263", "#365E32"],
      ["#F075AA", "#BC5A94", "#ADD899", "#FFDE95"],
      ["#DAD3BE", "#B7B597", "#6B8A7A", "#254336"],
      ["#D10363", "#FF9A00", "#FFBF00", "#808836"],
      ["#97BE5A", "#FFE8C5", "#FFA27F", "#FF0000"],
      ["#EE4E4E", "#F6EEC9", "#A1DD70", "#799351"],
      ["#E6FF94", "#9DDE8B", "#40A578", "#006769"],
      ["#E0FBE2", "#BFF6C3", "#B0EBB4", "#ACE1AF"],
      ["#F1F1F1", "#C7B7A3", "#EADBC8", "#B5C18E"],
      ["#FF70AB", "#FFAF61", "#FFDB5C", "#C3FF93"],
      ["#7ABA78", "#0A6847", "#F6E9B2", "#F3CA52"],
      ["#75A47F", "#BACD92", "#FCFFE0", "#F5DAD2"],
      ["#F5EFE6", "#E8DFCA", "#4F6F52", "#1A4D2E"],
      ["#B99470", "#DEAC80", "#F7DCB9", "#B5C18E"],
      ["#141E46", "#41B06E", "#8DECB4", "#FFF5E0"],
      ["#AFD198", "#E8EFCF", "#ECCA9C", "#DBA979"],
      ["#A1C398", "#C6EBC5", "#FEFDED", "#FA7070"],
      ["#90D26D", "#2C7865", "#FF9800", "#D9EDBF"],
      ["#E5C287", "#898121", "#E8751A", "#FDA403"],
      ["#F1EF99", "#EBC49F", "#D37676", "#B0C5A4"],
      ["#F1F5A8", "#E5E483", "#D2D180", "#B2B377"],
      ["#F7F6BB", "#FCDC2A", "#87A922", "#114232"],
      ["#F2C18D", "#F6F193", "#C5EBAA", "#A5DD9B"],
      ["#B3A398", "#BBC3A4", "#C6DCBA", "#D7E4C0"],
      ["#FBFADA", "#ADBC9F", "#436850", "#12372A"],
      ["#99BC85", "#BFD8AF", "#D4E7C5", "#E1F0DA"],
      ["#DBE7C9", "#789461", "#50623A", "#294B29"],
      ["#FDFFAB", "#FFCF81", "#FFB996", "#D9EDBF"],
      ["#F9F7C9", "#D5F0C1", "#AAD9BB", "#80BCBD"],
      ["#76453B", "#B19470", "#F8FAE5", "#43766C"],
      ["#597E52", "#C6A969", "#F1E4C3", "#FFFFEC"],
      ["#D2E3C8", "#86A789", "#739072", "#4F6F52"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#EEF0E5", "#B6C4B6", "#304D30", "#163020"],
      ["#F2F1EB", "#EEE7DA", "#AFC8AD", "#88AB8E"],
      ["#65B741", "#C1F2B0", "#FBF6EE", "#FFB534"],
      ["#B1C381", "#EEC759", "#FFF7D4", "#9BB8CD"],
      ["#EE7214", "#F7B787", "#F9E8D9", "#527853"],
      ["#8ADAB2", "#D0F288", "#F8FFD2", "#DF826C"],
      ["#508D69", "#9ADE7B", "#EEF296", "#FF8F8F"],
      ["#89B9AD", "#C7DCA7", "#FFEBD8", "#FFC5C5"],
      ["#9FBB73", "#F1EB90", "#F3B664", "#EC8F5E"],
      ["#FA7070", "#557C55", "#A6CF98", "#F2FFE9"],
      ["#A2C579", "#D2DE32", "#FFFFDD", "#61A3BA"],
      ["#3A4D39", "#4F6F52", "#739072", "#ECE3CE"],
      ["#EBF3E8", "#D2E3C8", "#B2C8BA", "#86A789"],
      ["#FAF8ED", "#748E63", "#99B080", "#F9B572"],
      ["#555843", "#D0D4CA", "#F5EEC8", "#A7D397"],
      ["#D0E7D2", "#B0D9B1", "#79AC78", "#618264"],
      ["#A2C579", "#D2DE32", "#FFFFDD", "#016A70"],
      ["#FFEEF4", "#E4E4D0", "#AEC3AE", "#94A684"],
      ["#F4EEEE", "#FFB7B7", "#FFDBAA", "#96C291"],
      ["#FFBFBF", "#FFE5E5", "#F3FDE8", "#A8DF8E"],
      ["#FFD9B7", "#7EAA92", "#9ED2BE", "#C8E4B2"],
      ["#65451F", "#765827", "#C8AE7D", "#EAC696"],
      ["#C51605", "#FD8D14", "#FFE17B", "#CECE5A"],
      ["#CEE6F3", "#DFA878", "#BA704F", "#6C3428"],
      ["#8C3333", "#557A46", "#7A9D54", "#F2EE9D"],
      ["#CCEEBC", "#8CC0DE", "#FFD9C0", "#FAF0D7"],
      ["#FFC95F", "#FFF9C9", "#862B0D", "#B5C99A"],
      ["#CBFFA9", "#FFFEC4", "#FFD6A5", "#FF9B9B"],
      ["#F7F1E5", "#E7B10A", "#898121", "#4C4B16"],
      ["#F7FFE5", "#E1ECC8", "#C4D7B2", "#A0C49D"],
      ["#D3D04F", "#8EAC50", "#17594A", "#213363"],
      ["#EEEEEE", "#7A3E3E", "#482121", "#47A992"],
      ["#FBFFDC", "#D0F5BE", "#98EECC", "#79E0EE"],
      ["#73A9AD", "#B3C890", "#DBDFAA", "#F5F0BB"],
      ["#FFF8DE", "#D6E8DB", "#C1D0B5", "#99A98F"],
      ["#83764F", "#A2A378", "#A0D8B3", "#E5F9DB"],
      ["#617A55", "#A4D0A4", "#F7E1AE", "#FFF8D6"],
      ["#AAC8A7", "#C9DBB2", "#E3F2C1", "#F6FFDE"],
      ["#7C9070", "#9CA777", "#FEE8B0", "#F97B22"],
      ["#EDC6B1", "#B7B7B7", "#7C96AB", "#BFCCB5"],
      ["#E86A33", "#263A29", "#41644A", "#F2E3DB"],
      ["#A4BC92", "#B3C99C", "#C7E9B0", "#DDFFBB"],
      ["#675D50", "#ABC4AA", "#F3DEBA", "#A9907E"],
      ["#D864A9", "#EBB02D", "#F7DB6A", "#7AA874"],
      ["#DBE4C6", "#94AF9F", "#AEC2B6", "#BBD6B8"],
      ["#FAEDCD", "#FEFAE0", "#E9EDC9", "#CCD5AE"],
      ["#4C4B16", "#898121", "#E7B10A", "#F7F1E5"],
      ["#40513B", "#609966", "#9DC08B", "#EDF1D6"],
      ["#FCC2FC", "#C9F4AA", "#E5FDD1", "#B5F1CC"],
      ["#FC7300", "#BFDB38", "#1F8A70", "#00425A"],
      ["#EEEEEE", "#DDDDDD", "#B7B78A", "#658864"],
      ["#473C33", "#FDA769", "#FEC868", "#ABC270"],
      ["#FFC3A1", "#F0997D", "#D3756B", "#A75D5D"],
      ["#939B62", "#FFD56F", "#FFB26B", "#FF7B54"],
      ["#EAE7B1", "#A6BB8D", "#61876E", "#3C6255"],
      ["#E6E2C3", "#88A47C", "#227C70", "#1C315E"],
      ["#FFFBE9", "#F4EAD5", "#DAE2B6", "#CCD6A6"],
      ["#3D5656", "#68B984", "#CFFDE1", "#FED049"],
      ["#F3ECB0", "#ADE792", "#6ECCAF", "#344D67"],
      ["#285430", "#5F8D4E", "#A4BE7B", "#E5D9B6"],
      ["#E6E5A3", "#A9AF7E", "#7D8F69", "#557153"],
      ["#EEEEEE", "#829460", "#90A17D", "#FFE1E1"],
      ["#FAD6A5", "#CFB997", "#9BA17B", "#61764B"],
      ["#379237", "#54B435", "#82CD47", "#F0FF42"],
      ["#F0EBCE", "#AA8B56", "#4E6C50", "#395144"],
      ["#BCE29E", "#E5EBB2", "#F8C4B4", "#FF8787"],
      ["#CFFF8D", "#A8E890", "#749F82", "#425F57"],
      ["#665A48", "#9F8772", "#C8DBBE", "#EDE4E0"],
      ["#FCFFB2", "#B6E388", "#C7F2A4", "#E1FFB1"],
      ["#A1C298", "#C6EBC5", "#FBF2CF", "#FA7070"],
      ["#EEF2E6", "#D6CDA4", "#3D8361", "#1C6758"],
      ["#F3E0B5", "#FFAE6D", "#FECD70", "#E3C770"],
      ["#D2D79F", "#90B77D", "#42855B", "#483838"],
      ["#F0F2B6", "#C1D5A4", "#224B0C", "#AC4425"],
      ["#2B7A0B", "#5BB318", "#7DCE13", "#EAE509"],
      ["#E6B325", "#BF9742", "#A47E3B", "#61481C"],
      ["#F7EDDB", "#DFE8CC", "#DAE2B6", "#CCD6A6"],
      ["#73A9AD", "#90C8AC", "#C4DFAA", "#F5F0BB"],
      ["#DCD7C9", "#A27B5C", "#3F4E4F", "#2C3639"],
      ["#FFDCAE", "#CED89E", "#ADCF9F", "#76BA99"],
      ["#4CACBC", "#6CC4A1", "#A0D995", "#F6E3C5"],
      ["#E0DECA", "#FCF9C6", "#C7D36F", "#9EB23B"],
      ["#4B8673", "#5FD068", "#F5DF99", "#F6FBF4"],
      ["#D5D8B5", "#CC9C75", "#A25B5B", "#809A6F"],
      ["#D0C9C0", "#EFEAD8", "#6D8B74", "#5F7161"],
      ["#82954B", "#BABD42", "#EFD345", "#FFEF82"],
      ["#FFC4DD", "#E4AEC5", "#82A284", "#446A46"],
      ["#4E944F", "#83BD75", "#B4E197", "#E9EFC0"],
      ["#614124", "#CC704B", "#E8C07D", "#9FC088"],
      ["#A2B38B", "#E4E9BE", "#FAFDD6", "#E6BA95"],
      ["#A97155", "#BE8C63", "#E4D1B9", "#8FBDD3"],
      ["#243D25", "#5F7464", "#E4AEC5", "#FAD9E6"],
      ["#534340", "#BB9981", "#C5D8A4", "#F4FCD9"],
      ["#FDFFA9", "#FFD365", "#00C897", "#019267"],
      ["#B5FE83", "#FFE61B", "#FFB72B", "#F7F7F7"],
      ["#E9E5D6", "#ACB992", "#464E2E", "#362706"],
      ["#C8F2EF", "#C3DBD9", "#CDB699", "#BB6464"],
      ["#19282F", "#B33030", "#A1B57D", "#D3ECA7"],
      ["#DACC96", "#BF8B67", "#9D5353", "#632626"],
      ["#FFAD60", "#D9534F", "#FFEEAD", "#96CEB4"],
      ["#F4DFBA", "#EEC373", "#CA965C", "#876445"],
      ["#95CD41", "#F6D860", "#FF7F3F", "#EA5C2B"],
      ["#146356", "#A3DA8D", "#FFF1BD", "#F3C892"],
      ["#557C55", "#A6CF98", "#F2FFE9", "#DB6B97"],
      ["#F4EEA9", "#F0BB62", "#519259", "#064635"],
      ["#E2C2B9", "#F2DDC1", "#99A799", "#D3E4CD"],
      ["#FFFEA9", "#F3ED9E", "#E6DF9A", "#DAD992"],
      ["#E8E8CC", "#116530", "#0B4619", "#FFCC1D"],
      ["#E9C891", "#8A8635", "#AE431E", "#D06224"],
      ["#FFF1AF", "#FFC286", "#B4C6A6", "#66806A"],
      ["#B2EA70", "#FBD148", "#F9975D", "#C85C5C"],
      ["#FAFAFA", "#F7D08A", "#E5890A", "#9D5C0D"],
      ["#FFBF86", "#FED2AA", "#F3F0D7", "#CEE5D0"],
      ["#FFF3E4", "#EED6C4", "#6B4F4F", "#483434"],
      ["#E8E1D9", "#F4A442", "#3E7C17", "#125C13"],
      ["#EAE7C6", "#BCCC9A", "#C37B89", "#D1E8E4"],
      ["#9A9483", "#AAA492", "#C7BEA2", "#E5DCC3"],
      ["#A09F57", "#C56824", "#CFB784", "#EADEB8"],
      ["#F6F6F6", "#D9CAB3", "#6D9886", "#212121"],
      ["#F3EFCC", "#ECE7B4", "#406343", "#32502E"],
      ["#FFE1AF", "#A2CDCD", "#D57E7E", "#C6D57E"],
      ["#EC9CD3", "#6ECB63", "#B1E693", "#FBF4E9"],
      ["#F1F7E7", "#E7EAB5", "#BFD8B8", "#E2C2B9"],
      ["#444941", "#5F7A61", "#D5EEBB", "#7FC8A9"],
      ["#FEFFE2", "#F0F0CB", "#DAD5AB", "#C3BA85"],
      ["#ACFFAD", "#71EFA3", "#50CB93", "#54436B"],
      ["#3E215D", "#284E78", "#5D8233", "#ECD662"],
      ["#C1AC95", "#B5CDA3", "#C9E4C5", "#FAEBE0"],
      ["#66DE93", "#FFEAC9", "#FF616D", "#D83A56"],
      ["#EAE2B6", "#7F8B52", "#A3A847", "#558776"],
      ["#01937C", "#B6C867", "#FFC074", "#FAF1E6"],
      ["#ECDBBA", "#C84B31", "#346751", "#161616"],
      ["#5E454B", "#D8B384", "#F3F0D7", "#CEE5D0"],
      ["#DFEEEA", "#A7C4BC", "#5E8B7E", "#2F5D62"],
      ["#B2B8A3", "#F4C7AB", "#DEEDF0", "#FFF5EB"],
      ["#D5DBB3", "#FFF8D9", "#BB371A", "#EBA83A"],
      ["#064420", "#E4EFE7", "#FDFAF6", "#FAF1E6"],
      ["#564A4A", "#4AA96C", "#9FE6A0", "#F55C47"],
      ["#E28F83", "#4A503D", "#8E9775", "#FAF2DA"],
      ["#F6E6E4", "#E2BCB7", "#CA8A8B", "#5B6D5B"],
      ["#D2E69C", "#8FD9A8", "#28B5B5", "#4B778D"],
      ["#F58634", "#FFCC29", "#81B214", "#206A5D"],
      ["#798777", "#A2B29F", "#BDD2B6", "#F8EDE3"],
      ["#464F41", "#56776C", "#5B8A72", "#BFCBA8"],
      ["#52734D", "#91C788", "#DDFFBC", "#FEFFDE"],
      ["#E6DD3B", "#29BB89", "#289672", "#1E6F5C"],
      ["#1C1427", "#40394A", "#7ECA9C", "#CCFFBD"],
      ["#FFE6CA", "#D0AF84", "#966C3B", "#CDC733"],
      ["#9B3675", "#9ECCA4", "#CEE6B4", "#1F441E"],
      ["#FDE8CD", "#00917C", "#025955", "#433520"],
      ["#BECA5C", "#EF8D32", "#CC561E", "#AA2B1D"],
      ["#E3D18A", "#BD9354", "#9E7540", "#85603F"],
      ["#FB743E", "#383E56", "#9FB8AD", "#C5D7BD"],
      ["#FFC478", "#F0E5D8", "#BBDFC8", "#75CFB8"],
      ["#557174", "#9DAD7F", "#C7CFB7", "#F7F7E8"],
      ["#FAD586", "#D6EFC7", "#96BB7C", "#184D47"],
      ["#939B62", "#FFD56B", "#FFB26B", "#FF7B54"],
      ["#9DAB86", "#DED7B1", "#E08F62", "#CC7351"],
      ["#AA8976", "#F0E2D0", "#70AF85", "#C6EBC9"],
      ["#86ABA1", "#DFF3E3", "#F1AE89", "#D68060"],
      ["#EA97AD", "#045762", "#4E8D7C", "#F3F2DA"],
      ["#F4EEED", "#EFD9D1", "#D8AC9C", "#999B84"],
      ["#FFCE89", "#FFF76A", "#ADCE74", "#61B15A"],
      ["#707070", "#92817A", "#8DB596", "#BEDBBB"],
      ["#F4D9C6", "#E5C5B5", "#D2F5E3", "#94B4A4"],
      ["#7EA04D", "#335D2D", "#FE7171", "#FFB0B0"],
      ["#EFF48E", "#D2E603", "#3E978B", "#2EC1AC"],
      ["#F1F1E8", "#BFDCAE", "#81B214", "#206A5D"],
      ["#A4B494", "#519872", "#3B5249", "#382933"],
      ["#065446", "#197163", "#158467", "#FADCAC"],
      ["#523906", "#535204", "#62760C", "#CDB30C"],
      ["#D3DE32", "#CBEAED", "#FFFFDD", "#006A71"],
      ["#3F4441", "#5E6F64", "#BA7967", "#FFDECF"],
      ["#E84A5F", "#FF847C", "#FECEAB", "#99B898"],
      ["#EEBB4D", "#D6EFC7", "#96BB7C", "#184D47"],
      ["#436F8A", "#438A5E", "#BAC964", "#F7FBE1"],
      ["#17706E", "#FB7813", "#F7F7EE", "#B6EB7A"],
      ["#ABC2E8", "#DBC6EB", "#D1EAA3", "#EFEE9D"],
    ],
  },
  {
    name: "neon",
    palettes: [
      ["#FFE700", "#FEFFA7", "#B6FFA1", "#00FF9C"],
      ["#15B392", "#54C392", "#73EC8B", "#D2FF72"],
      ["#FFEB55", "#EE66A6", "#D91656", "#640D5F"],
      ["#F5004F", "#FFAF00", "#F9E400", "#7C00FE"],
      ["#FF7EE2", "#FFA38F", "#FFD18E", "#E9FF97"],
      ["#F3FF90", "#9BEC00", "#06D001", "#059212"],
      ["#FFDB00", "#FF8F00", "#AF47D2", "#26355D"],
      ["#FF0080", "#FF5580", "#FFAA80", "#FFFF80"],
      ["#FF70AB", "#FFAF61", "#FFDB5C", "#C3FF93"],
      ["#A3D8FF", "#94FFD8", "#FDFFC2", "#FF76CE"],
      ["#00224D", "#5D0E41", "#A0153E", "#FF204E"],
      ["#FFF455", "#FFC700", "#4CCD99", "#007F73"],
      ["#FFB5DA", "#FF7ED4", "#FF3EA5", "#6420AA"],
      ["#F0F3FF", "#15F5BA", "#836FFF", "#211951"],
      ["#EBF400", "#F57D1F", "#F72798", "#000000"],
      ["#FFEAA7", "#FFBB64", "#FF6868", "#DCFFB7"],
      ["#FAEF5D", "#FF004D", "#7E2553", "#1D2B53"],
      ["#49108B", "#7E30E1", "#E26EE5", "#F3F8FF"],
      ["#687EFF", "#80B3FF", "#98E4FF", "#B6FFFA"],
      ["#BEFFF7", "#A6F6FF", "#9EDDFF", "#6499E9"],
      ["#E4F1FF", "#AED2FF", "#9400FF", "#27005D"],
      ["#A6FF96", "#F8FF95", "#BC7AF9", "#FFA1F5"],
      ["#D67BFF", "#FFB6D9", "#FEFFAC", "#45FFCA"],
      ["#FFFD8C", "#97FFF4", "#7091F5", "#793FDF"],
      ["#F8DE22", "#F94C10", "#C70039", "#900C3F"],
      ["#D5FFE4", "#8BE8E5", "#A084E8", "#6F61C0"],
      ["#7C73C0", "#94ADD7", "#ACFADF", "#E8FFCE"],
      ["#FFE5AD", "#3E001F", "#982176", "#F11A7B"],
      ["#EDE4FF", "#D7BBF5", "#A076F9", "#6528F7"],
      ["#97FEED", "#35A29F", "#0B666A", "#071952"],
      ["#FDFFAE", "#E9FFC2", "#C3EDC0", "#AAC8A7"],
      ["#FFE79B", "#DD58D6", "#9336B4", "#40128B"],
      ["#FF0060", "#F6FA70", "#00DFA2", "#0079FF"],
      ["#FBFFDC", "#D0F5BE", "#98EECC", "#79E0EE"],
      ["#2CD3E1", "#A459D1", "#F266AB", "#FFB84C"],
      ["#B6EAFA", "#FCFFB2", "#FFD3A3", "#FF55BB"],
      ["#4F200D", "#FF8400", "#FFD93D", "#F6F1E9"],
      ["#FFDEB9", "#FE6244", "#FC2947", "#7149C6"],
      ["#FBFFB1", "#FFEBB4", "#FFBFA9", "#FFACAC"],
      ["#FFA3FD", "#E384FF", "#865DFF", "#191825"],
      ["#FF5F9E", "#E90064", "#B3005E", "#060047"],
      ["#FFED00", "#16FF00", "#0F6292", "#000000"],
      ["#E3F6FF", "#9DF1DF", "#8DCBE6", "#FFEA20"],
      ["#F5EA5A", "#FF78F0", "#A31ACB", "#39B5E0"],
      ["#A555EC", "#D09CFA", "#F3CCFF", "#FFFFD0"],
      ["#FF9E9E", "#FFCAC8", "#F8F988", "#C0EEE4"],
      ["#0002A1", "#332FD0", "#FB2576", "#3F0071"],
      ["#379237", "#54B435", "#82CD47", "#F0FF42"],
      ["#FF7D7D", "#FF6464", "#F8FFDB", "#B3FFAE"],
      ["#00F5FF", "#FCE700", "#FF6D28", "#EA047E"],
      ["#FDFF00", "#9CFF2E", "#38E54D", "#2192FF"],
      ["#FF1E1E", "#FFFF00", "#31C6D4", "#00FFD1"],
      ["#FF7777", "#D800A6", "#400D51", "#31E1F7"],
      ["#FF4A4A", "#FF9551", "#6FEDD6", "#B9FFF8"],
      ["#D61C4E", "#FEDB39", "#1CD6CE", "#293462"],
      ["#72FFFF", "#00D7FF", "#0096FF", "#5800FF"],
      ["#FAEA48", "#FBB454", "#F637EC", "#3330E4"],
      ["#A149FA", "#3B44F6", "#3EC70B", "#F7EC09"],
      ["#FBCB0A", "#C70A80", "#590696", "#37E2D5"],
      ["#E3FCBF", "#B8F1B0", "#14C38E", "#00FFAB"],
      ["#F806CC", "#A91079", "#570A57", "#2E0249"],
      ["#FFCD38", "#FF8D29", "#FF4949", "#541690"],
      ["#F6F54D", "#FEB139", "#F55353", "#143F6B"],
      ["#FF85B3", "#F900BF", "#9900F0", "#4700D8"],
      ["#00FFC6", "#30AADD", "#43919B", "#247881"],
      ["#9FB4FF", "#99FFCD", "#FFF56D", "#FFD36E"],
      ["#6EDCD9", "#E15FED", "#9254C8", "#332FD0"],
      ["#F2FA5A", "#5EE6EB", "#56BBF1", "#4D77FF"],
      ["#E8FFC2", "#00FFDD", "#2FA4FF", "#0E185F"],
      ["#EEEEEE", "#91C483", "#FF6464", "#FFE162"],
      ["#FFC600", "#E900FF", "#5800FF", "#000000"],
      ["#0C1E7F", "#612897", "#D22779", "#FF008E"],
      ["#BAFFB4", "#FFFDA2", "#FFAB76", "#FF6363"],
      ["#8843F2", "#EF2F88", "#F47340", "#F9D371"],
      ["#CFFFDC", "#93FFD8", "#548CFF", "#7900FF"],
      ["#06FF00", "#FFE400", "#FF8E00", "#FF1700"],
      ["#FFF323", "#FFCA03", "#FF5403", "#F90716"],
      ["#99FEFF", "#94DAFF", "#94B3FD", "#B983FF"],
      ["#998CEB", "#77E4D4", "#B4FE98", "#FBF46D"],
      ["#99DDCC", "#FF5DA2", "#9C19E0", "#000D6B"],
      ["#FF0000", "#FF9300", "#FBFF00", "#49FF00"],
      ["#EEEEEE", "#77D970", "#172774", "#FF0075"],
      ["#F56FAD", "#C32BAD", "#7027A0", "#1DB9C3"],
      ["#FFEDED", "#FDB9FC", "#ED50F1", "#FF00E4"],
      ["#FFF6CD", "#FF95C5", "#FF0000", "#3B0000"],
      ["#F8F8F8", "#F037A5", "#2D46B9", "#1E3163"],
      ["#B5FFD9", "#AE00FB", "#3E00FF", "#170055"],
      ["#9DDAC6", "#C2FFD9", "#FFD371", "#FF4848"],
      ["#F7E6AD", "#F5FDB0", "#BCFFB9", "#28FFBF"],
      ["#C400FF", "#FF67E7", "#FFF338", "#0CECDD"],
      ["#88FFF7", "#7DEDFF", "#96BAFF", "#7C83FD"],
      ["#FBC7F7", "#FB7AFC", "#D62AD0", "#78DEC7"],
      ["#5C33F6", "#A239EA", "#FF94CC", "#F0D9E7"],
      ["#F0EBCC", "#3EDBF0", "#77ACF1", "#04009A"],
      ["#005F99", "#FF449F", "#FFF5B7", "#00EAD3"],
      ["#F2F4C3", "#FC92E3", "#005792", "#480032"],
      ["#FC5404", "#F98404", "#F9B208", "#F7FD04"],
      ["#FFFFC7", "#A9F1DF", "#1EAE98", "#233E8B"],
      ["#C0FEFC", "#3EDBF0", "#77ACF1", "#04009A"],
      ["#CF0000", "#890596", "#1CC5DC", "#F5F7B2"],
      ["#FFDF6B", "#FF79CD", "#AA2EE6", "#23049D"],
      ["#FF5200", "#FA9905", "#F21170", "#72147E"],
      ["#A799B7", "#FDCA40", "#FB3640", "#542E71"],
      ["#CE1F6A", "#FF9A8C", "#D8F8B7", "#C67ACE"],
      ["#52734D", "#91C788", "#DDFFBC", "#FEFFDE"],
      ["#BE0000", "#E48900", "#F7EA00", "#9EDE73"],
      ["#1C1427", "#40394A", "#7ECA9C", "#CCFFBD"],
      ["#FFE3FE", "#B4AEE8", "#93329E", "#440A67"],
      ["#EDEEF7", "#7868E6", "#B8B5FF", "#E4FBFF"],
      ["#FFF600", "#FF005C", "#810034", "#26001B"],
      ["#FFBE0F", "#FA1E0E", "#BD2000", "#8C0000"],
      ["#161D6F", "#98DED9", "#C7FFD8", "#F6F6F6"],
      ["#FF5E78", "#F9F871", "#FFC75F", "#845EC2"],
      ["#121013", "#4D375D", "#EB596E", "#FFE227"],
      ["#80FFDB", "#64DFDF", "#6930C3", "#252525"],
      ["#FFCC29", "#F58634", "#007965", "#00AF91"],
      ["#CDFFFC", "#FFC764", "#FF884B", "#FF577F"],
      ["#C0E218", "#F37121", "#C70039", "#111D5E"],
      ["#23120B", "#21209C", "#FDB827", "#F1F1F1"],
      ["#FFFAA4", "#54E346", "#0E49B5", "#153E90"],
      ["#EEEEEE", "#FFD369", "#393E46", "#222831"],
      ["#FECD1A", "#FD3A69", "#9D0191", "#120078"],
      ["#BC6FF1", "#892CDC", "#52057B", "#000000"],
      ["#3797A4", "#8BCDCD", "#CEE397", "#FCF876"],
      ["#B9FFFC", "#A3D8F4", "#9AB3F5", "#7579E7"],
      ["#213E3B", "#41AEA9", "#A6F6F1", "#E8FFFF"],
      ["#212121", "#0D7377", "#32E0C4", "#EEEEEE"],
      ["#F6F7D4", "#D2F6C5", "#99F3BD", "#28DF99"],
      ["#EFF48E", "#D2E603", "#3E978B", "#2EC1AC"],
      ["#FFC1F3", "#FCE2CE", "#F9F7D9", "#CFFFFE"],
      ["#FDCB9E", "#00B7C2", "#0F4C75", "#1B262C"],
      ["#FFF591", "#A3F7BF", "#05DFD7", "#FA26A0"],
      ["#C4FB6D", "#76EAD7", "#393E46", "#EEEEEE"],
      ["#17706E", "#FB7813", "#F7F7EE", "#B6EB7A"],
      ["#222831", "#393E46", "#32E0C4", "#EEEEEE"],
      ["#0A97B0", "#4CD3C2", "#F54291", "#FAEEE7"],
      ["#F17808", "#E71414", "#12947F", "#2FC4B2"],
      ["#00A1AB", "#00263B", "#6F0000", "#FF5200"],
      ["#100303", "#95389E", "#43D8C9", "#F7F7F7"],
      ["#97E5EF", "#FFF0F5", "#F8E1F4", "#EFA8E4"],
      ["#F1E7B6", "#FE346E", "#400082", "#00BDAA"],
      ["#FAFBA4", "#ECA0B6", "#A1E6E3", "#D7FFFD"],
      ["#E8F044", "#DC2ADE", "#323EDD", "#4D089A"],
      ["#FFF591", "#A3F7BF", "#05DFD7", "#F35588"],
      ["#FFBD69", "#FE346E", "#B21F66", "#381460"],
      ["#9818D6", "#FF5151", "#FFA41B", "#EDEDED"],
      ["#81F5FF", "#A0FFE6", "#FFFFDD", "#FFD5E5"],
      ["#FF5D6C", "#FBCEB5", "#DEFF8B", "#8CBA51"],
      ["#40BFC1", "#F5F0E3", "#FF6F5E", "#F0134D"],
      ["#2C7873", "#52DE97", "#C0FFB3", "#FFBA5A"],
      ["#E4F9FF", "#0FABBC", "#12CAD6", "#FA163F"],
      ["#F5DEA3", "#42E6A4", "#02A8A8", "#018383"],
      ["#C3F584", "#FFF3AF", "#FFD271", "#F65C78"],
      ["#FCC169", "#9B45E4", "#E13A9D", "#FA697C"],
      ["#EF4339", "#FF9D76", "#FFDBC5", "#51EAEA"],
      ["#EEF5B2", "#6DECB9", "#42DEE1", "#3FC5F0"],
      ["#F1FA3C", "#C355F5", "#AA26DA", "#851DE0"],
      ["#FFD739", "#C299FC", "#9852F9", "#6807F9"],
      ["#3E64FF", "#5EDFFF", "#B2FCFF", "#ECFCFF"],
      ["#FF4D00", "#FF0000", "#272121", "#443737"],
      ["#FE59D7", "#ED0CEF", "#8105D8", "#3B064D"],
      ["#FF6363", "#A32F80", "#341677", "#000272"],
      ["#FFDCF7", "#FFB3B3", "#FCE2AE", "#B6FFEA"],
      ["#723881", "#E0E0E0", "#FFFFFF", "#61F2F5"],
      ["#204969", "#DADADA", "#FFF7F7", "#08FFC8"],
      ["#414141", "#AF0404", "#FF0000", "#252525"],
      ["#FCB1B1", "#F0F696", "#96F7D2", "#F5B5FC"],
      ["#FFF0F0", "#FFCECE", "#FF00C8", "#F38EFF"],
      ["#27AA80", "#32FF6A", "#A8FF3E", "#F4FF61"],
      ["#FFBD39", "#E4007C", "#930077", "#090088"],
      ["#0D3F67", "#6B48FF", "#1EE3CF", "#F2F4F6"],
      ["#24009C", "#0028FF", "#10EAF0", "#F1F4DF"],
      ["#FFC46B", "#FF82C3", "#D527B7", "#8A00D4"],
      ["#032D3C", "#4BE3AC", "#94FC13", "#F7FF56"],
      ["#FF9E74", "#EE5A5A", "#B31E6F", "#22EACA"],
      ["#FFF591", "#FF8A5C", "#F5587B", "#E41749"],
      ["#7572F4", "#FF3E6D", "#FF7BB0", "#FFF3A3"],
      ["#EAEAEA", "#00D1CD", "#F30067", "#444444"],
      ["#DB66E4", "#F77FEE", "#ACEACF", "#C5FAD9"],
      ["#FFFDAF", "#00FAAC", "#FF3796", "#302387"],
      ["#207DFF", "#00BD56", "#85EF47", "#F9FD50"],
      ["#8BFFFF", "#82ACFF", "#8F71FF", "#FF5D9E"],
      ["#AAAAAA", "#B824A4", "#F36A7B", "#FAF562"],
      ["#FB3569", "#FF9D76", "#FFFDE1", "#51EAEA"],
      ["#A4F6A5", "#F1EB9A", "#F8A978", "#F68787"],
      ["#FFD581", "#BF81FF", "#837DFF", "#6927FF"],
      ["#40514E", "#2F89FC", "#30E3CA", "#F5F5F5"],
      ["#1EAFED", "#612570", "#EA168E", "#EEF2F5"],
      ["#FFFA67", "#FFCD60", "#FF8162", "#D34848"],
      ["#EAEAEA", "#FF0000", "#BC2525", "#0C005A"],
      ["#FFE98A", "#E61C5D", "#930077", "#3A0088"],
      ["#FCF2DB", "#FFC5E6", "#DCAEE8", "#6EFFBF"],
      ["#3161A3", "#13ABC4", "#7EFAFF", "#EBFFFB"],
      ["#FFFCAB", "#9AEBED", "#A275E3", "#FA86BE"],
      ["#8ACBBB", "#55968F", "#DBFF3D", "#C9F658"],
      ["#5E227F", "#F8B500", "#D22780", "#F2F4FB"],
      ["#84253E", "#E00543", "#A6CB12", "#FDF1DB"],
      ["#FA0559", "#A93199", "#43C0AC", "#EAEC96"],
      ["#DFBAF7", "#FFE6CC", "#ECFFC1", "#B8FFD0"],
      ["#C2FCF6", "#5FF4EE", "#4A9FF5", "#033FFF"],
      ["#FFF8A1", "#FF008B", "#FAF7E6", "#73DBC4"],
      ["#1E2A78", "#F9FF21", "#FFD615", "#FF1F5A"],
      ["#FB9A40", "#FBD341", "#F1FFAB", "#A3F3EB"],
      ["#A74FAF", "#DB456F", "#FF9668", "#F5E965"],
      ["#99DDCC", "#FF5DA2", "#B643CD", "#35013F"],
      ["#CC99F9", "#DEC8ED", "#F5FAC7", "#89F8CE"],
      ["#262525", "#FC6B3F", "#84F2D6", "#FFF6DA"],
      ["#04322E", "#C93D1B", "#F8C43A", "#35D0BA"],
      ["#FEFF89", "#FF9F68", "#F85959", "#7C203A"],
      ["#3D6CB9", "#00D1FF", "#00FFF0", "#FAFAF6"],
      ["#FEF4A9", "#3B9A9C", "#4BC2C5", "#78FEE0"],
      ["#38C6BD", "#2FE1D6", "#97FFCF", "#FFFB97"],
      ["#2C2C2C", "#FF5F5F", "#83FFE6", "#FCFCFC"],
      ["#F7B236", "#E7455F", "#D80E70", "#5628B4"],
      ["#474744", "#FF5722", "#65EEB7", "#F4F4F4"],
      ["#4EF037", "#128494", "#00B7C2", "#ECFEFF"],
      ["#E8FCF6", "#FFF07A", "#A2EF44", "#08085E"],
      ["#A7FF83", "#17B978", "#086972", "#071A52"],
      ["#7CBD1E", "#BCEB3C", "#F1F864", "#FCF9F9"],
      ["#FF4273", "#FF9D76", "#FFFDE1", "#1FFFFF"],
      ["#FCFFC8", "#D5FFFB", "#7CDFFF", "#E9007F"],
      ["#291F71", "#C82586", "#4EEAF6", "#F7F7F8"],
      ["#FCF4D9", "#50E3C2", "#FF7A5A", "#432160"],
      ["#B7ABFB", "#85FEDE", "#6FE8C8", "#FEFF9A"],
      ["#FFB6FF", "#DB97FF", "#A100FF", "#9FFBFB"],
      ["#F3F798", "#EAB4F8", "#FCC8F8", "#C7F5FE"],
      ["#FF9DE2", "#8C82FC", "#B693FE", "#7EFFDB"],
      ["#A58BFF", "#F6F6F6", "#FFF46E", "#FF6F6F"],
      ["#FBD0F5", "#F7F680", "#94F6F2", "#D7AEF3"],
      ["#F6FCAE", "#D89FFF", "#C7F5FF", "#FFABE5"],
      ["#14FFEC", "#0D7377", "#323232", "#212121"],
      ["#7BC7FA", "#76E3FF", "#FBFF64", "#FF8FE5"],
      ["#FFF8B5", "#B5FF7D", "#52D681", "#00AD7C"],
      ["#BA52ED", "#FF99FE", "#A4F6F9", "#E4FFFE"],
      ["#84E9FF", "#C244FB", "#FFA1D0", "#FFF9C1"],
      ["#E8FFE8", "#A6FFF2", "#74F9FF", "#00E0FF"],
      ["#89C4FF", "#FFFA62", "#FFCF7F", "#FF9090"],
      ["#FFF024", "#FF004D", "#7E2553", "#1D2B53"],
      ["#20194A", "#512E5E", "#F40968", "#FDEA2E"],
      ["#00FFF5", "#00ADB5", "#393E46", "#222831"],
      ["#EAEAEA", "#FF2E63", "#252A34", "#08D9D6"],
      ["#FF165D", "#FF9A00", "#F6F7D7", "#3EC1D3"],
      ["#63F5EF", "#41B3FF", "#7459DC", "#FFF395"],
      ["#44FADD", "#534C98", "#B767FF", "#FFEE7D"],
      ["#FFADED", "#C768FF", "#7045FF", "#83FFE1"],
      ["#BCFFF2", "#88D8EC", "#AD64C5", "#FEFF94"],
      ["#F67FF5", "#5F81E4", "#47E4E0", "#F6F6D9"],
    ],
  },
  {
    name: "night",
    palettes: [
      ["#A64D79", "#6A1E55", "#3B1C32", "#1A1A1D"],
      ["#FFF4B7", "#006A67", "#003161", "#000B58"],
      ["#E2F1E7", "#629584", "#387478", "#243642"],
      ["#ECDFCC", "#697565", "#3C3D37", "#181C14"],
      ["#EBD3F8", "#AD49E1", "#7A1CAC", "#2E073F"],
      ["#ECDFCC", "#697565", "#3C3D37", "#1E201E"],
      ["#D95F59", "#C63C51", "#8C3061", "#522258"],
      ["#FB773C", "#EB3678", "#4F1787", "#180161"],
      ["#C8ACD6", "#433D8B", "#2E236C", "#17153B"],
      ["#E3FEF7", "#77B0AA", "#135D66", "#003C43"],
      ["#DBAFA0", "#BB8493", "#704264", "#49243E"],
      ["#F2613F", "#9B3922", "#481E14", "#0C0C0C"],
      ["#FDAF7B", "#BE7B72", "#824D74", "#401F71"],
      ["#EEE4B1", "#8C6A5D", "#5F374B", "#430A5D"],
      ["#78A083", "#50727B", "#344955", "#35374B"],
      ["#F9E8C9", "#98ABEE", "#1D24CA", "#201658"],
      ["#9290C3", "#535C91", "#1B1A55", "#070F2B"],
      ["#FFD0EC", "#81689D", "#474F7A", "#1F2544"],
      ["#910A67", "#720455", "#3C0753", "#030637"],
      ["#A87C7C", "#7E6363", "#503C3C", "#3E3232"],
      ["#F6B17A", "#7077A1", "#424769", "#2D3250"],
      ["#200E3A", "#38419D", "#3887BE", "#52D3D8"],
      ["#FFD1E3", "#A367B1", "#5D3587", "#392467"],
      ["#9EC8B9", "#5C8374", "#1B4242", "#092635"],
      ["#F05941", "#BE3144", "#872341", "#22092C"],
      ["#F5E8C7", "#818FB4", "#435585", "#363062"],
      ["#C2D9FF", "#8E8FFA", "#7752FE", "#190482"],
      ["#F5F5F5", "#F99417", "#4D4C7D", "#363062"],
      ["#008170", "#005B41", "#232D3F", "#0F0F0F"],
      ["#E5C3A6", "#7C81AD", "#4B527E", "#2E4374"],
      ["#F39F5A", "#AE445A", "#662549", "#451952"],
      ["#93B1A6", "#5C8374", "#183D3D", "#040D12"],
      ["#FAF0E6", "#B9B4C7", "#5C5470", "#352F44"],
      ["#3F1D38", "#4D3C77", "#A2678A", "#E19898"],
      ["#F7E987", "#5B9A8B", "#445069", "#252B48"],
      ["#8CABFF", "#4477CE", "#512B81", "#35155D"],
      ["#FFF6E0", "#D8D9DA", "#61677A", "#272829"],
      ["#FFD2D7", "#9288F8", "#8062D6", "#322653"],
      ["#EFE1D1", "#A78295", "#3F2E3E", "#331D2C"],
      ["#EAB2A0", "#A76F6F", "#435B66", "#2D4356"],
      ["#84A7A1", "#2E8A99", "#1F6E8C", "#0E2954"],
      ["#F4D3D3", "#E8A9A9", "#46458C", "#090580"],
      ["#CD1818", "#4E3636", "#321E1E", "#116D6E"],
      ["#DDE6ED", "#9DB2BF", "#526D82", "#27374D"],
      ["#E7CBCB", "#C88EA7", "#99627A", "#643843"],
      ["#9BA4B5", "#212A3E", "#394867", "#F1F6F9"],
      ["#B0A4A4", "#957777", "#643A6B", "#5F264A"],
      ["#F4EEE0", "#6D5D6E", "#4F4557", "#393646"],
      ["#A5D7E8", "#576CBC", "#19376D", "#0B2447"],
      ["#E3CCAE", "#B8621B", "#262A56", "#000000"],
      ["#D27685", "#9E4784", "#66347F", "#37306B"],
      ["#CBE4DE", "#0E8388", "#2E4F4F", "#2C3333"],
      ["#FFA3FD", "#E384FF", "#865DFF", "#191825"],
      ["#3795BD", "#2F58CD", "#4E31AA", "#3A1078"],
      ["#18122B", "#393053", "#443C68", "#635985"],
      ["#03C988", "#1C82AD", "#00337C", "#13005A"],
      ["#E5E5CB", "#D5CEA3", "#3C2A21", "#1A120B"],
      ["#F0E9D2", "#E6DDC4", "#678983", "#181D31"],
      ["#F2F7A1", "#46C2CB", "#6D67E4", "#453C67"],
      ["#E5B8F4", "#C147E9", "#810CA8", "#2D033B"],
      ["#F0CAA3", "#C060A1", "#3B185F", "#00005C"],
      ["#C69749", "#735F32", "#282A3A", "#000000"],
      ["#6B728E", "#50577A", "#474E68", "#404258"],
      ["#A3C7D6", "#9F73AB", "#624F82", "#3F3B6C"],
      ["#C47AFF", "#7978FF", "#4649FF", "#1D1CE5"],
      ["#000000", "#5C2E7E", "#4C6793", "#8BBCCC"],
      ["#D8D8D8", "#647E68", "#562B08", "#182747"],
      ["#D2D79F", "#90B77D", "#42855B", "#483838"],
      ["#F1EFDC", "#E6D2AA", "#D36B00", "#42032C"],
      ["#F1F1F1", "#E2DCC8", "#0F3D3E", "#100F0F"],
      ["#E7F6F2", "#A5C9CA", "#395B64", "#2C3333"],
      ["#C8B6E2", "#A8A4CE", "#7A86B6", "#495C83"],
      ["#DCD7C9", "#A27B5C", "#3F4E4F", "#2C3639"],
      ["#D6D5A8", "#816797", "#51557E", "#1B2430"],
      ["#7858A6", "#5B4B8A", "#4C3575", "#371B58"],
      ["#363062", "#4D4C7D", "#827397", "#E9D5CA"],
      ["#363062", "#4D4C7D", "#827397", "#E9D5DA"],
      ["#826F66", "#C69B7B", "#F7CCAC", "#3A3845"],
      ["#D49B54", "#C74B50", "#712B75", "#46244C"],
      ["#F0F0F0", "#F1D00A", "#3E497A", "#21325E"],
      ["#F5F2E7", "#2666CF", "#395B64", "#2C3333"],
      ["#DACC96", "#BF8B67", "#9D5353", "#632626"],
      ["#980F5A", "#750550", "#570530", "#4C0027"],
      ["#FFB5B5", "#FF7272", "#9B0000", "#000B49"],
      ["#ECB365", "#064663", "#04293A", "#041C32"],
      ["#B3541E", "#A13333", "#461111", "#040303"],
      ["#EBE645", "#577BC1", "#344CB7", "#000957"],
      ["#EADEDE", "#B85252", "#F58840", "#000000"],
      ["#E9A6A6", "#864879", "#3F3351", "#1F1D36"],
      ["#8E05C2", "#700B97", "#3E065F", "#000000"],
      ["#FF0000", "#950101", "#3D0000", "#000000"],
      ["#C996CC", "#916BBF", "#3D2C8D", "#1C0C5B"],
      ["#610094", "#3F0071", "#150050", "#000000"],
      ["#112031", "#152D35", "#345B63", "#D4ECDD"],
      ["#FEC260", "#A12568", "#3B185F", "#2A0944"],
      ["#E63E6D", "#B42B51", "#7D1935", "#420516"],
      ["#6E85B2", "#5C527F", "#3E2C41", "#261C2C"],
      ["#FFAA4C", "#5089C6", "#035397", "#001E6C"],
      ["#EEEEEE", "#787A91", "#141E61", "#0F044C"],
      ["#FFD523", "#B2B1B9", "#595260", "#2C2E43"],
      ["#EEEEEE", "#548CA8", "#476072", "#334257"],
      ["#2B2B2B", "#423F3E", "#171010", "#362222"],
      ["#EDEDED", "#DA0037", "#444444", "#171717"],
      ["#B2AB8C", "#DBE6FD", "#47597E", "#293B5F"],
      ["#A5E1AD", "#4CA1A3", "#511281", "#21094E"],
      ["#132C33", "#126E82", "#51C4D3", "#D8E3E7"],
      ["#8FD6E1", "#1597BB", "#150E56", "#7B113A"],
      ["#310B0B", "#9C3D54", "#E2703A", "#EEB76B"],
      ["#C06014", "#424642", "#536162", "#F3F4ED"],
      ["#B4A5A5", "#3C415C", "#301B3F", "#151515"],
      ["#046582", "#6E7582", "#BB8082", "#F39189"],
      ["#E6D5B8", "#E45826", "#F0A500", "#4A3933"],
      ["#A7C5EB", "#709FB0", "#4A47A3", "#413C69"],
      ["#FFE3DE", "#A3DDCB", "#03506F", "#0A043C"],
      ["#A0C1B8", "#719FB0", "#726A95", "#351F39"],
      ["#F1AA9B", "#F0C38E", "#48426D", "#312C51"],
      ["#E1D89F", "#D89216", "#374045", "#2C061F"],
      ["#0F0F0F", "#532E1C", "#C5A880", "#E6E6E6"],
      ["#FFE3D8", "#BBBBBB", "#03506F", "#0A043C"],
      ["#EEEEEE", "#FFD369", "#393E46", "#222831"],
    ],
  },
  {
    name: "pastel",
    palettes: [
      ["#FFF8DE", "#D0E8C5", "#C5D3E8", "#A6AEBF"],
      ["#FFF9BF", "#FDDBBB", "#F0C1E1", "#CB9DF0"],
      ["#FFDDAE", "#FBFBFB", "#D4F6FF", "#C6E7FF"],
      ["#FFCCEA", "#FFF6E3", "#CDC1FF", "#BFECFF"],
      ["#DE8F5F", "#FFB26F", "#FFCF9D", "#FFB38E"],
      ["#BC7C7C", "#E4C087", "#F6EFBD", "#A2D2DF"],
      ["#C9E9D2", "#FEF9F2", "#FFE3E3", "#789DBC"],
      ["#493628", "#AB886D", "#D6C0B3", "#E4E0E1"],
      ["#DA8359", "#FCFAEE", "#ECDFCC", "#A5B68D"],
      ["#FFB0B0", "#FFD09B", "#FFECC8", "#FFF7D1"],
      ["#705C53", "#B7B7B7", "#F5F5F7", "#EDDFE0"],
      ["#C1CFA1", "#A5B68D", "#EDE8DC", "#E7CCCC"],
      ["#A594F9", "#CDC1FF", "#F5EFFF", "#E5D9F2"],
      ["#E6D9A2", "#CB80AB", "#8967B3", "#624E88"],
      ["#7EACB5", "#FFF4EA", "#FADFA1", "#C96868"],
      ["#8EACCD", "#DEE5D4", "#FEF9D9", "#D2E0FB"],
      ["#EAE4DD", "#E1D7C6", "#CDC2A5", "#295F98"],
      ["#E8B86D", "#F1F3C2", "#CEDF9F", "#A1D6B2"],
      ["#B99470", "#FEFAE0", "#A6B37D", "#C0C78C"],
      ["#CCE0AC", "#F0EAAC", "#F4DEB3", "#FF8A8A"],
      ["#E7FBE6", "#CBE2B5", "#86AB89", "#A28B55"],
      ["#EECAD5", "#F1D3CE", "#F6EACB", "#D1E9F6"],
      ["#C5705D", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FEFAE0", "#FAEDCE", "#E0E5B6", "#CCD5AE"],
      ["#FFEBD4", "#FFC6C6", "#F7B5CA", "#F0A8D0"],
      ["#E7E8D8", "#CADABF", "#B5CFB7", "#BC9F8B"],
      ["#5F6F65", "#808D7C", "#9CA986", "#C9DABF"],
      ["#606676", "#708871", "#BEC6A0", "#FEF3E2"],
      ["#8D493A", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FEFBD8", "#EECEB9", "#BB9AB1", "#987D9A"],
      ["#729762", "#658147", "#597445", "#E7F0DC"],
      ["#B1AFFF", "#BBE9FF", "#FFFED3", "#FFE9D0"],
      ["#B6C7AA", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#F19ED2", "#E8C5E5", "#F7F9F2", "#91DDCF"],
      ["#F1F8E8", "#55AD9B", "#95D2B3", "#D8EFD3"],
      ["#78ABA8", "#C8CFA0", "#FCDC94", "#EF9C66"],
      ["#987070", "#C39898", "#DBB5B5", "#F1E5D1"],
      ["#E0FBE2", "#BFF6C3", "#B0EBB4", "#ACE1AF"],
      ["#F1F1F1", "#C7B7A3", "#EADBC8", "#B5C18E"],
      ["#FFD0D0", "#E1ACAC", "#CA8787", "#A87676"],
      ["#75A47F", "#BACD92", "#FCFFE0", "#F5DAD2"],
      ["#B99470", "#DEAC80", "#F7DCB9", "#B5C18E"],
      ["#FFEFEF", "#F3D0D7", "#F0EBE3", "#F6F5F2"],
      ["#AFD198", "#E8EFCF", "#ECCA9C", "#DBA979"],
      ["#FFF2E1", "#EAD8C0", "#D1BB9E", "#A79277"],
      ["#E5DDC5", "#F1EEDC", "#BED7DC", "#B3C8CF"],
      ["#9CAFAA", "#D6DAC8", "#FBF3D5", "#EFBC9B"],
      ["#F1EF99", "#EBC49F", "#D37676", "#B0C5A4"],
      ["#F6995C", "#51829B", "#9BB0C1", "#EADFB4"],
      ["#7469B6", "#AD88C6", "#E1AFD1", "#FFE6E6"],
      ["#F2C18D", "#F6F193", "#C5EBAA", "#A5DD9B"],
      ["#E2BFB3", "#F7DED0", "#FEECE2", "#FFBE98"],
      ["#EED3D9", "#F5E8DD", "#CCD3CA", "#B5C0D0"],
      ["#EEA5A6", "#E493B3", "#B784B7", "#8E7AB5"],
      ["#B3A398", "#BBC3A4", "#C6DCBA", "#D7E4C0"],
      ["#F2EFE5", "#E3E1D9", "#C7C8CC", "#B4B4B8"],
      ["#99BC85", "#BFD8AF", "#D4E7C5", "#E1F0DA"],
      ["#E6A4B4", "#F3D7CA", "#FFF8E3", "#F5EEE6"],
      ["#638889", "#9DBC98", "#EBD9B4", "#F9EFDB"],
      ["#FDFFAB", "#FFCF81", "#FFB996", "#D9EDBF"],
      ["#F9F7C9", "#D5F0C1", "#AAD9BB", "#80BCBD"],
      ["#E5E1DA", "#FBF9F1", "#AAD7D9", "#92C7CF"],
      ["#FFE5E5", "#E0AED0", "#AC87C5", "#756AB6"],
      ["#F6D6D6", "#F6F7C4", "#A1EEBD", "#7BD3EA"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#F2F1EB", "#EEE7DA", "#AFC8AD", "#88AB8E"],
      ["#B1C381", "#EEC759", "#FFF7D4", "#9BB8CD"],
      ["#8ACDD7", "#F9F9E0", "#FFC0D9", "#FF90BC"],
      ["#B06161", "#DC8686", "#F0DBAF", "#7ED7C1"],
      ["#89B9AD", "#C7DCA7", "#FFEBD8", "#FFC5C5"],
      ["#776B5D", "#B0A695", "#EBE3D5", "#F3EEEA"],
      ["#3A4D39", "#4F6F52", "#739072", "#ECE3CE"],
      ["#FAF8ED", "#748E63", "#99B080", "#F9B572"],
      ["#D6C7AE", "#BFB29E", "#B3A492", "#DADDB1"],
      ["#FFF8C9", "#DFCCFB", "#D0BFFF", "#BEADFA"],
      ["#CDFAD5", "#F6FDC3", "#FFCF96", "#FF8080"],
      ["#8EACCD", "#D7E5CA", "#F9F3CC", "#D2E0FB"],
      ["#D0E7D2", "#B0D9B1", "#79AC78", "#618264"],
      ["#EDB7ED", "#82A0D8", "#8DDFCB", "#ECEE81"],
      ["#EBEF95", "#EFD595", "#EFB495", "#EF9595"],
    ],
  },
  {
    name: "rainbow",
    palettes: [
      ["#E78F81", "#FFCFB3", "#FFF5CD", "#B7E0FF"],
      ["#7EACB5", "#FFF4EA", "#FADFA1", "#C96868"],
      ["#EECAD5", "#F1D3CE", "#F6EACB", "#D1E9F6"],
      ["#96CEB4", "#FFEEAD", "#FFAD60", "#A02334"],
      ["#B1AFFF", "#BBE9FF", "#FFFED3", "#FFE9D0"],
      ["#2C4E80", "#00215E", "#FFC55A", "#FC4100"],
      ["#A3D8FF", "#94FFD8", "#FDFFC2", "#FF76CE"],
      ["#8B322C", "#DD5746", "#FFC470", "#4793AF"],
      ["#C0D6E8", "#FBF8DD", "#E9C874", "#A34343"],
      ["#F4538A", "#FAA300", "#F5DD61", "#59D5E0"],
      ["#337357", "#FFD23F", "#EE4266", "#5E1675"],
      ["#FB88B4", "#F9F07A", "#B7C9F2", "#9195F6"],
      ["#FF8080", "#FFCF96", "#F6FDC3", "#CDFADB"],
      ["#B7E5B4", "#FFFC9B", "#FFA447", "#F28585"],
      ["#6895D2", "#FDE767", "#F3B95F", "#D04848"],
      ["#F6D6D6", "#F6F7C4", "#A1EEBD", "#7BD3EA"],
      ["#B06161", "#DC8686", "#F0DBAF", "#7ED7C1"],
      ["#072541", "#5272F2", "#F8BDEB", "#FBECB2"],
      ["#FFCD4B", "#FF7676", "#FF4B91", "#0802A3"],
      ["#CDFAD5", "#F6FDC3", "#FFCF96", "#FF8080"],
      ["#EDB7ED", "#82A0D8", "#8DDFCB", "#ECEE81"],
      ["#CAEDFF", "#D8B4F8", "#FFC7EA", "#FBF0B2"],
      ["#836096", "#ED7B7B", "#F0B86E", "#EBE76C"],
      ["#D71313", "#F0DE36", "#EEEDED", "#0D1282"],
      ["#FF0060", "#F6FA70", "#00DFA2", "#0079FF"],
      ["#F6FFA6", "#F3BCC8", "#E893CF", "#9376E0"],
      ["#B6EAFA", "#FCFFB2", "#FFD3A3", "#FF55BB"],
      ["#FFF9DE", "#FFD3B0", "#FF6969", "#A6D0DD"],
      ["#98D8AA", "#F3E99F", "#F7D060", "#FF6D60"],
      ["#F45050", "#F9D949", "#F0F0F0", "#3C486B"],
      ["#FDF7C3", "#FFDEB4", "#FFB4B4", "#B2A4FF"],
      ["#98DFD6", "#FFDD83", "#E21818", "#00235B"],
      ["#F6E6C2", "#FFAACF", "#EA8FEA", "#B9F3E4"],
      ["#30E3DF", "#FCE22A", "#F94A29", "#D61355"],
      ["#9EA1D4", "#A8D1D1", "#F1F7B5", "#FD8A8A"],
      ["#FFF8E1", "#FFE5F1", "#C0DEFF", "#ADA2FF"],
      ["#FF9E9E", "#FFCAC8", "#F8F988", "#C0EEE4"],
      ["#00F5FF", "#FCE700", "#FF6D28", "#EA047E"],
      ["#FF1E1E", "#FFFF00", "#31C6D4", "#00FFD1"],
      ["#89CFFD", "#FBDF07", "#FF7F3F", "#F94892"],
      ["#C1EFFF", "#FFE9AE", "#FFDBA4", "#FFB3B3"],
      ["#B2A4FF", "#FFB4B4", "#FFDEB4", "#FFF9CA"],
      ["#FFC54D", "#BD4291", "#F94C66", "#53BF9D"],
      ["#FAF4B7", "#F6C6EA", "#F9F9F9", "#CDF0EA"],
      ["#FBCB0A", "#C70A80", "#590696", "#37E2D5"],
      ["#F7D716", "#EC9B3B", "#F24C4C", "#293462"],
      ["#187498", "#36AE7C", "#F9D923", "#EB5353"],
      ["#FCF69C", "#55D8C1", "#FF6FB5", "#AB46D2"],
      ["#FFA1A1", "#FFD59E", "#F9FFA4", "#B4FF9F"],
      ["#EAEA7F", "#FDAF75", "#F24A72", "#333C83"],
      ["#4D96FF", "#6BCB77", "#FFD93D", "#FF6B6B"],
      ["#FF8AAE", "#FFB2A6", "#FFF89A", "#9ADCFF"],
      ["#FFBBBB", "#FFE4C0", "#F0FFC2", "#BFFFF0"],
      ["#FFAD60", "#D9534F", "#FFEEAD", "#96CEB4"],
      ["#BAFFB4", "#FFFDA2", "#FFAB76", "#FF6363"],
      ["#FFCBCB", "#E7FBBE", "#FFFDDE", "#D9D7F1"],
      ["#8843F2", "#EF2F88", "#F47340", "#F9D371"],
      ["#06FF00", "#FFE400", "#FF8E00", "#FF1700"],
      ["#FD6F96", "#FFEBA1", "#95DAC1", "#6F69AC"],
      ["#FF2442", "#FFB830", "#FFEDDA", "#3DB2FF"],
      ["#9DDAC6", "#C2FFD9", "#FFD371", "#FF4848"],
      ["#FFA900", "#FF7600", "#CD113B", "#52006A"],
      ["#B3E283", "#E8E46E", "#F3C583", "#E99497"],
      ["#3B14A7", "#AC66CC", "#F6B8B8", "#FFED99"],
      ["#005F99", "#FF449F", "#FFF5B7", "#00EAD3"],
      ["#F2F4C3", "#FC92E3", "#005792", "#480032"],
      ["#CF0000", "#890596", "#1CC5DC", "#F5F7B2"],
      ["#FFDF6B", "#FF79CD", "#AA2EE6", "#23049D"],
      ["#A799B7", "#FDCA40", "#FB3640", "#542E71"],
      ["#CE1F6A", "#FF9A8C", "#D8F8B7", "#C67ACE"],
      ["#FFAAA7", "#FFD3B4", "#D5ECC2", "#98DDCA"],
      ["#C64756", "#FAD586", "#96BB7C", "#184D47"],
      ["#BE0000", "#E48900", "#F7EA00", "#9EDE73"],
      ["#D49D42", "#D97642", "#C15050", "#693C72"],
      ["#95E1D3", "#EAFFD0", "#FCE38A", "#FF75A0"],
      ["#FF5E78", "#F9F871", "#FFC75F", "#845EC2"],
      ["#CDFFFC", "#FFC764", "#FF884B", "#FF577F"],
      ["#74C7B8", "#FFCDA3", "#EE9595", "#EF4F4F"],
      ["#C0E218", "#F37121", "#C70039", "#111D5E"],
      ["#E5707E", "#E6B566", "#E8E9A1", "#A3DDCB"],
      ["#FFF8C1", "#FFC85C", "#C05555", "#59886B"],
      ["#93ABD3", "#EDF285", "#FD8C04", "#EC5858"],
      ["#EC524B", "#F5B461", "#F3EAC2", "#9AD3BC"],
      ["#B2DEEC", "#FADCAA", "#C56183", "#794C74"],
      ["#799351", "#EBDC87", "#FFA36C", "#D54062"],
      ["#F6EFA6", "#F6AB6C", "#DF5E88", "#8FCFD1"],
      ["#14B1AB", "#F3ECC2", "#F9D56E", "#E8505B"],
      ["#AACDBE", "#F4F7C5", "#FBC687", "#EA907A"],
      ["#35D0BA", "#FFCD3C", "#FF9234", "#D92027"],
      ["#D92027", "#F37121", "#F4EA8E", "#5FDDE5"],
      ["#F5A7A7", "#F9D89C", "#82C4C3", "#BC658D"],
      ["#B4F2E1", "#FFE9C5", "#FA9191", "#EB6383"],
      ["#B9EBCC", "#F2A51A", "#EA6227", "#342EAD"],
      ["#98D6EA", "#BAE5E5", "#F5FCC1", "#F3D1F4"],
      ["#F3ECB8", "#F5CAB3", "#A8D3DA", "#B590CA"],
      ["#FFF591", "#A3F7BF", "#05DFD7", "#F35588"],
      ["#FD2EB3", "#FB8D62", "#FDD365", "#61D4B3"],
      ["#66CCCC", "#CC0066", "#FF6666", "#FFCC00"],
      ["#C3F584", "#FFF3AF", "#FFD271", "#F65C78"],
      ["#ECCD8F", "#CC6A87", "#8D448B", "#5026A7"],
      ["#64E291", "#E6E56C", "#FEC771", "#EB7070"],
      ["#C6F1D6", "#E0F5B9", "#FFBA92", "#FF8080"],
      ["#FCB1B1", "#F0F696", "#96F7D2", "#F5B5FC"],
      ["#239F95", "#FABC74", "#EB5F5D", "#BB1542"],
      ["#D8EFF0", "#B0E0A8", "#F0F69F", "#F3C1C6"],
      ["#72D6C9", "#FFC785", "#DF7599", "#7189BF"],
      ["#FF5959", "#FACF5A", "#49BEB7", "#085F63"],
      ["#556FB5", "#E4508F", "#AEDDCD", "#FFFEEC"],
      ["#FFFDAF", "#00FAAC", "#FF3796", "#302387"],
      ["#F17E7E", "#EFCA8C", "#DFE38E", "#7FE7CC"],
      ["#E16262", "#FABC60", "#3A9679", "#11144C"],
      ["#FFA1AC", "#FFE0A3", "#FFF6BE", "#B7FBFF"],
      ["#FFE26F", "#E4734F", "#CC4165", "#6D3580"],
      ["#FB3569", "#FF9D76", "#FFFDE1", "#51EAEA"],
      ["#21174A", "#127681", "#FAC70B", "#DF0E62"],
      ["#C5F8C8", "#FAF096", "#FCCB8F", "#F9989F"],
      ["#FFE98A", "#E61C5D", "#930077", "#3A0088"],
      ["#5B2E35", "#34A7B2", "#F5E4C3", "#F59AA3"],
      ["#FFFCAB", "#9AEBED", "#A275E3", "#FA86BE"],
      ["#FFF78F", "#94D2E6", "#F16F6F", "#C1224F"],
    ],
  },
  {
    name: "retro",
    palettes: [
      ["#3D0301", "#B03052", "#D76C82", "#7ED4AD"],
      ["#FF77B7", "#FFA24C", "#FEEC37", "#B1D690"],
      ["#C62E2E", "#F95454", "#77CDFF", "#0D92F4"],
      ["#DBD3D3", "#EC8305", "#024CAA", "#091057"],
      ["#10375C", "#EB8317", "#F3C623", "#F4F6FF"],
      ["#FFEB55", "#EE66A6", "#D91656", "#640D5F"],
      ["#FDFAD9", "#C1E2A4", "#86D293", "#6256CA"],
      ["#B8001F", "#FCFAEE", "#507687", "#384B70"],
      ["#604CC3", "#8FD14F", "#F5F5F5", "#FF6600"],
      ["#424242", "#229799", "#48CFCB", "#F5F5F5"],
      ["#FF9874", "#FFD7C4", "#9DBDFF", "#7695FF"],
      ["#0B8494", "#125B9A", "#F05A7E", "#FFBE98"],
      ["#D7C3F1", "#BDE8CA", "#41B3A2", "#0D7C66"],
      ["#1F316F", "#1A4870", "#5B99C2", "#F9DBBA"],
      ["#96CEB4", "#FFEEAD", "#FFAD60", "#A02334"],
      ["#4158A6", "#179BAE", "#F1DEC6", "#FF8343"],
      ["#FF4E88", "#FF8C9E", "#FFDA76", "#B4D6CD"],
      ["#FB773C", "#EB3678", "#4F1787", "#180161"],
      ["#E2E2B6", "#6EACDA", "#03346E", "#021526"],
      ["#DC0083", "#FFA823", "#FFD35A", "#6C946F"],
      ["#FBF6E2", "#ECCEAE", "#E68369", "#131842"],
      ["#F6F5F5", "#F9E2AF", "#009FBD", "#4A249D"],
      ["#E76F51", "#F4A261", "#E9C46A", "#36BA98"],
      ["#45474B", "#379777", "#F4CE14", "#F5F7F8"],
      ["#E2BBE9", "#9B86BD", "#7776B3", "#5A639C"],
      ["#604CC3", "#80C4E9", "#FFF6E9", "#FF7F3E"],
      ["#EE4E4E", "#FFC700", "#FFF455", "#219C90"],
      ["#F075AA", "#BC5A94", "#ADD899", "#FFDE95"],
      ["#005C78", "#006989", "#F3F7EC", "#E88D67"],
      ["#DC5F00", "#373A40", "#686D76", "#EEEEEE"],
      ["#D10363", "#FF9A00", "#FFBF00", "#808836"],
      ["#CAE6B2", "#F6FAB9", "#D2649A", "#8E3E63"],
      ["#002379", "#FF5F00", "#FF9F66", "#FFFAE6"],
      ["#EEEEEE", "#C73659", "#A91D3A", "#151515"],
      ["#FF70AB", "#FFAF61", "#FFDB5C", "#C3FF93"],
      ["#7ABA78", "#0A6847", "#F6E9B2", "#F3CA52"],
      ["#F8D082", "#E65C19", "#B51B75", "#640D6B"],
      ["#2C4E80", "#00215E", "#FFC55A", "#FC4100"],
      ["#F27BBD", "#C65BCF", "#874CCC", "#10439F"],
      ["#141E46", "#41B06E", "#8DECB4", "#FFF5E0"],
      ["#8644A2", "#D862BC", "#E59BE9", "#FFEBB2"],
      ["#C4E4FF", "#D895DA", "#D6589F", "#D20062"],
      ["#90D26D", "#2C7865", "#FF9800", "#D9EDBF"],
      ["#FF71CD", "#5755FE", "#8B93FF", "#FFF7FC"],
      ["#EEE4B1", "#8C6A5D", "#5F374B", "#430A5D"],
      ["#FFEDD8", "#EABE6C", "#891652", "#240A34"],
      ["#FFB5DA", "#FF7ED4", "#FF3EA5", "#6420AA"],
      ["#F4538A", "#FAA300", "#F5DD61", "#59D5E0"],
      ["#337357", "#FFD23F", "#EE4266", "#5E1675"],
      ["#50C4ED", "#387ADF", "#333A73", "#FBA834"],
      ["#F0F3FF", "#15F5BA", "#836FFF", "#211951"],
      ["#EBF400", "#F57D1F", "#F72798", "#000000"],
      ["#FF8911", "#FDBF60", "#9F70FD", "#7F27FF"],
      ["#EFECEC", "#FFB0B0", "#FC6736", "#0C2D57"],
      ["#96E9C6", "#83C0C1", "#6962AD", "#6C22A6"],
      ["#6895D2", "#FDE767", "#F3B95F", "#D04848"],
      ["#F2F597", "#37B5B6", "#492E87", "#0A1D56"],
      ["#E9F6FF", "#280274", "#3652AD", "#FE7A36"],
      ["#402B3A", "#D63484", "#FF9BD2", "#F8F4EC"],
      ["#3468C0", "#86A7FC", "#FFDD95", "#FF9843"],
      ["#F8E559", "#864AF9", "#3B3486", "#332941"],
      ["#647D87", "#6DA4AA", "#F6D776", "#FAEF9B"],
      ["#F8EDFF", "#BFCFE7", "#525CEB", "#3D3B40"],
      ["#F3EDC8", "#EAD196", "#BF3131", "#7D0A0A"],
      ["#49108B", "#7E30E1", "#E26EE5", "#F3F8FF"],
      ["#FFA732", "#EF4040", "#C21292", "#711DB0"],
      ["#EE7214", "#F7B787", "#F9E8D9", "#527853"],
      ["#EEE2DE", "#EA906C", "#B31312", "#2B2A4C"],
      ["#B06161", "#DC8686", "#F0DBAF", "#7ED7C1"],
      ["#FFC7C7", "#ED9ED6", "#C683D7", "#7071E8"],
      ["#FF90C2", "#ED5AB3", "#1640D6", "#001B79"],
      ["#FFD099", "#FF9209", "#FF6C22", "#2B3499"],
      ["#1F1717", "#CE5A67", "#F4BF96", "#FCF5ED"],
      ["#45474B", "#495E57", "#F4CE14", "#F5F7F8"],
      ["#072541", "#5272F2", "#F8BDEB", "#FBECB2"],
      ["#F5F5F5", "#F99417", "#4D4C7D", "#363062"],
      ["#FFCD4B", "#FF7676", "#FF4B91", "#0802A3"],
      ["#FFE5E5", "#FF6AC2", "#B931FC", "#5D12D2"],
      ["#D83F31", "#EE9322", "#E9B824", "#219C90"],
      ["#5CD2E6", "#3085C3", "#F4E869", "#FAF2D3"],
      ["#EC53B0", "#9D44C0", "#4D2DB7", "#0E21A0"],
      ["#141E46", "#BB2525", "#FF6969", "#FFF5E0"],
      ["#836096", "#ED7B7B", "#F0B86E", "#EBE76C"],
      ["#3D246C", "#5C4B99", "#9F91CC", "#FFDBC3"],
      ["#D5FFE4", "#8BE8E5", "#A084E8", "#6F61C0"],
      ["#FFE5AD", "#3E001F", "#982176", "#F11A7B"],
      ["#AED8CC", "#CD6688", "#7A316F", "#461959"],
      ["#D71313", "#F0DE36", "#EEEDED", "#0D1282"],
      ["#FE0000", "#7D7463", "#A8A196", "#F4E0B9"],
      ["#F3AA60", "#EF6262", "#468B97", "#1D5B79"],
      ["#FAE392", "#FBD85D", "#F1C93B", "#1A5D1A"],
      ["#FFC95F", "#FFF9C9", "#862B0D", "#B5C99A"],
      ["#A7EDE7", "#45CFDD", "#9681EB", "#6527BE"],
      ["#C1ECE4", "#FF9EAA", "#FFD0D0", "#3AA6B9"],
      ["#FFF6F4", "#FFA41B", "#F86F03", "#525FE1"],
      ["#9575DE", "#6554AF", "#2B2730", "#E966A0"],
      ["#F24C3D", "#F29727", "#F2BE22", "#22A699"],
      ["#2B2A4C", "#B31312", "#EA906C", "#EEE2DE"],
      ["#080202", "#73BBC9", "#F1D4E5", "#FCE9F1"],
      ["#374259", "#545B77", "#5C8984", "#F2D8D8"],
      ["#E55807", "#7E1717", "#068DA9", "#ECF8F9"],
      ["#2CD3E1", "#A459D1", "#F266AB", "#FFB84C"],
      ["#FFE194", "#E8F6EF", "#1B9C85", "#4C4C6D"],
      ["#F79540", "#FC4F00", "#B71375", "#8B1874"],
      ["#98D8AA", "#F3E99F", "#F7D060", "#FF6D60"],
      ["#F45050", "#F9D949", "#F0F0F0", "#3C486B"],
      ["#77037B", "#210062", "#009FBD", "#F9E2AF"],
      ["#F5C6EC", "#FFEAEA", "#E11299", "#9A208C"],
      ["#D8D8D8", "#E49393", "#408E91", "#245953"],
      ["#002B5B", "#EA5455", "#E4DCCF", "#F9F5EB"],
      ["#F0EB8D", "#8F43EE", "#413543", "#2D2727"],
      ["#F5FFC9", "#B3E5BE", "#A86464", "#804674"],
      ["#2E3840", "#FF0303", "#F9DBBB", "#4E6E81"],
      ["#EB455F", "#FCFFE7", "#BAD7E9", "#2B3467"],
      ["#7DB9B6", "#F5E9CF", "#E96479", "#4D455D"],
      ["#FF5F9E", "#E90064", "#B3005E", "#060047"],
      ["#332C39", "#C92C6D", "#609EA2", "#F0EEED"],
      ["#E9E8E8", "#CD5888", "#913175", "#20262E"],
      ["#F5F5F5", "#E8E2E2", "#F99417", "#5D3891"],
      ["#B05A7A", "#C27664", "#E4C988", "#84D2C5"],
      ["#CF4DCE", "#F273E6", "#ECECEC", "#FF8B13"],
      ["#F5EA5A", "#FF78F0", "#A31ACB", "#39B5E0"],
      ["#1A0000", "#58287F", "#89C4E1", "#F5EDCE"],
      ["#AD8E70", "#FFEBB7", "#FF6E31", "#243763"],
      ["#2B3467", "#BAD7E9", "#FCFFE7", "#EB455F"],
      ["#A555EC", "#D09CFA", "#F3CCFF", "#FFFFD0"],
      ["#FFBF00", "#FF7000", "#540375", "#10A19D"],
      ["#22A39F", "#222222", "#434242", "#F3EFE0"],
      ["#001253", "#3E6D9C", "#FD841F", "#E14D2A"],
      ["#EEEEEE", "#DC5F00", "#CF0A0A", "#000000"],
      ["#E26868", "#FF8787", "#EDEDED", "#D8D9CF"],
      ["#1746A2", "#5F9DF7", "#FFF7E9", "#FF731D"],
      ["#FFD372", "#FF99D7", "#D58BDD", "#905E96"],
      ["#EEEEEE", "#ADDDD0", "#87A2FB", "#6F38C5"],
      ["#FF7777", "#D800A6", "#400D51", "#31E1F7"],
      ["#F2F2F2", "#5CB8E4", "#8758FF", "#181818"],
      ["#EFEFEF", "#FFFAE7", "#FFDE00", "#D2001A"],
      ["#EEEEEE", "#9FC9F3", "#A460ED", "#F07DEA"],
      ["#EB1D36", "#FA9494", "#CFD2CF", "#F5EDDC"],
      ["#FF4A4A", "#FF9551", "#6FEDD6", "#B9FFF8"],
      ["#000000", "#59CE8F", "#E8F9FD", "#FF1E00"],
      ["#7A4495", "#B270A2", "#FF8FB1", "#FCE2DB"],
      ["#3CCF4E", "#D4F6CC", "#EF5B0C", "#003865"],
      ["#EB1D36", "#A2B5BB", "#CFD2CF", "#F5EDDC"],
      ["#FAF3E3", "#FF9F29", "#1A4D2E", "#000000"],
      ["#F87474", "#FFB562", "#3AB0FF", "#F9F2ED"],
      ["#FF06B7", "#3C2C3E", "#4B5D67", "#BDF2D5"],
      ["#FBCB0A", "#C70A80", "#590696", "#37E2D5"],
      ["#CA82FF", "#A760FF", "#FFDAAF", "#FFCC8F"],
      ["#7F8487", "#413F42", "#16003B", "#F73D93"],
      ["#BDE6F1", "#FFE59D", "#EEB0B0", "#7D1E6A"],
      ["#EEF3D2", "#B689C0", "#947EC3", "#6A67CE"],
      ["#F32424", "#F2F2F2", "#9772FB", "#764AF1"],
      ["#B22727", "#EE5007", "#F8CB2E", "#006E7F"],
      ["#92BA92", "#78938A", "#525E75", "#F1DDBF"],
      ["#F56D91", "#F7F5F2", "#DFDFDE", "#8D8DAA"],
      ["#874356", "#C65D7B", "#F68989", "#F6E7D8"],
      ["#DADBBD", "#6EBF8B", "#D82148", "#151D3B"],
      ["#E7ED9B", "#8BDB81", "#ED5EDD", "#6A5495"],
      ["#C8F2EF", "#C3DBD9", "#CDB699", "#BB6464"],
      ["#19282F", "#B33030", "#A1B57D", "#D3ECA7"],
      ["#EEE6CE", "#2EB086", "#B8405E", "#313552"],
      ["#EEEEEE", "#91C483", "#FF6464", "#FFE162"],
      ["#D96098", "#325288", "#FAEEE7", "#24A19C"],
      ["#FFAD60", "#D9534F", "#FFEEAD", "#96CEB4"],
      ["#FED1EF", "#1C6DD0", "#A3E4DB", "#FFF8F3"],
      ["#CFFFDC", "#93FFD8", "#548CFF", "#7900FF"],
      ["#EEF2FF", "#CDDEFF", "#676FA3", "#FF5959"],
      ["#121212", "#30475E", "#F05454", "#F5F5F5"],
      ["#FAEDC6", "#FABB51", "#7CD1B8", "#3E8E7E"],
      ["#EC255A", "#FAEDF0", "#292C6D", "#161853"],
      ["#FFE652", "#C2FFF9", "#71DFE7", "#009DAE"],
      ["#E9C891", "#8A8635", "#AE431E", "#D06224"],
      ["#FF6D6D", "#AE4CCF", "#533535", "#E6CCA9"],
      ["#9AE66E", "#E59934", "#753188", "#2C272E"],
      ["#B000B9", "#FF5F7E", "#FFAB4C", "#142F43"],
      ["#FFF7E0", "#9CC094", "#8E0505", "#FFA6D5"],
      ["#B2F9FC", "#D5D5D5", "#9D84B7", "#091353"],
      ["#FEE440", "#FF865E", "#FEF9EF", "#A2D2FF"],
      ["#EEEEEE", "#77D970", "#172774", "#FF0075"],
      ["#FFFD95", "#1C7947", "#39A388", "#2C2891"],
      ["#FFE1AF", "#A2CDCD", "#D57E7E", "#C6D57E"],
      ["#290FBA", "#8236CB", "#B97A95", "#FDD2BF"],
      ["#FFE459", "#F43B86", "#3D087B", "#11052C"],
      ["#F1E9E5", "#B4B897", "#368B85", "#464660"],
      ["#00C1D4", "#EEEEEE", "#F8485E", "#512D6D"],
      ["#FFE3E3", "#FFA0A0", "#262A53", "#628395"],
      ["#EFEFEF", "#FFC947", "#185ADB", "#0A1931"],
      ["#F2EDD7", "#A0937D", "#3A6351", "#5F939A"],
      ["#E1E8EB", "#FFC107", "#7952B3", "#343A40"],
      ["#F5A962", "#3C8DAD", "#125D98", "#DDDDDD"],
      ["#BF1363", "#DEEEEA", "#39A6A3", "#231E23"],
      ["#FFEDA3", "#39A9CB", "#2940D3", "#232323"],
      ["#FFBBCC", "#FFDDCC", "#FFC93C", "#99154E"],
      ["#F2F4C3", "#FC92E3", "#005792", "#480032"],
      ["#564A4A", "#4AA96C", "#9FE6A0", "#F55C47"],
      ["#E28F83", "#4A503D", "#8E9775", "#FAF2DA"],
      ["#FFE9D6", "#A7D0CD", "#7B6079", "#DE8971"],
      ["#583D72", "#9F5F80", "#FF8474", "#FFC996"],
      ["#FF6701", "#FEA82F", "#FFC288", "#FCECDD"],
      ["#E6C4C0", "#CA8A8B", "#926E6F", "#999B84"],
      ["#114E60", "#F5CEBE", "#F4EEE8", "#325288"],
      ["#45526C", "#5AA897", "#F8A488", "#F8F5F1"],
      ["#31326F", "#9DDFD3", "#DBF6E9", "#FFC93C"],
      ["#343F56", "#387C6D", "#E9896A", "#F8F5F1"],
      ["#393232", "#E48257", "#F2EDD7", "#3A6351"],
      ["#9B3675", "#9ECCA4", "#CEE6B4", "#1F441E"],
      ["#D49D42", "#D97642", "#C15050", "#693C72"],
      ["#FDE8CD", "#00917C", "#025955", "#433520"],
      ["#EFF0B6", "#BFB051", "#8F4068", "#763857"],
      ["#290149", "#6B011F", "#FFD880", "#F14668"],
      ["#EE99A0", "#AD6C80", "#350B40", "#94B5C0"],
      ["#EB5E0B", "#5EAAA8", "#A3D2CA", "#F8F1F1"],
      ["#A0C1B8", "#719FB0", "#726A95", "#351F39"],
      ["#0F1123", "#4E3D53", "#A98B98", "#A6F0C6"],
      ["#16C79A", "#19456B", "#11698E", "#F8F1F1"],
      ["#C1A1D3", "#0D335D", "#1A508B", "#FFF3E6"],
      ["#E8EFEB", "#ADEECF", "#E9B0DF", "#295939"],
      ["#F6D887", "#C7753D", "#A9294F", "#6F9EAF"],
      ["#DB6400", "#5EAAA8", "#A3D2CA", "#F8F1F1"],
      ["#FFE9D6", "#65D6CE", "#654062", "#FA9579"],
      ["#C75643", "#D08752", "#D1C145", "#F8F7DE"],
      ["#5AA469", "#F8D49D", "#EFB08C", "#D35D6E"],
      ["#FFF8C1", "#FFC85C", "#C05555", "#59886B"],
      ["#222831", "#30475E", "#F05454", "#E8E8E8"],
      ["#31326F", "#9DDFD3", "#DBF6E9", "#FFDADA"],
      ["#CE6262", "#AF2D2D", "#F05454", "#214252"],
      ["#389393", "#FA7F72", "#F5A25D", "#EBEBEB"],
      ["#132743", "#D7385E", "#EDC988", "#F8EFD4"],
      ["#03C4A1", "#C62A88", "#590995", "#150485"],
      ["#F4EBC1", "#A0C1B8", "#709FB0", "#726A95"],
      ["#821752", "#DE4463", "#EDC988", "#F8EFD4"],
      ["#1AA6B7", "#FF414D", "#F56A79", "#D9ECF2"],
      ["#637373", "#679B9B", "#FFEADB", "#FF9A76"],
      ["#132743", "#407088", "#70ADB5", "#FFCBCB"],
      ["#FFA372", "#ED6663", "#43658B", "#4E89AE"],
      ["#EE6F57", "#00334E", "#145374", "#F6F5F5"],
      ["#440047", "#E11D74", "#91D18B", "#FBECEC"],
      ["#065446", "#197163", "#158467", "#FADCAC"],
      ["#FBD46D", "#4F8A8B", "#222831", "#EEEEEE"],
      ["#FF5F40", "#FFC7C7", "#6EBFB5", "#24A19C"],
      ["#E4F9FF", "#0FABBC", "#12CAD6", "#FA1616"],
      ["#07031A", "#4F8A8B", "#FBD46D", "#F4F6FF"],
      ["#E79C2A", "#5A3D55", "#3CA59D", "#A2DE96"],
      ["#0A97B0", "#4CD3C2", "#F54291", "#FAEEE7"],
      ["#FDCB9E", "#3F3F44", "#CCEABB", "#F7F7F7"],
      ["#DEF4F0", "#74D4C0", "#D9455F", "#9A1F40"],
      ["#F40552", "#FC7E2F", "#C3EDEA", "#F8F3EB"],
      ["#862A5C", "#F4A548", "#F6D198", "#7EBDB4"],
      ["#024249", "#16817A", "#FA744F", "#F79071"],
      ["#B9EBCC", "#F2A51A", "#EA6227", "#342EAD"],
      ["#F8EEEE", "#AEEFEC", "#F78259", "#EB4559"],
      ["#F1E7B6", "#FE346E", "#400082", "#00BDAA"],
      ["#18B0B0", "#116979", "#DEE3E2", "#DE7119"],
      ["#F67575", "#FFA34D", "#D4F8E8", "#1EB2A6"],
      ["#F3ECB8", "#F5CAB3", "#A8D3DA", "#B590CA"],
      ["#D1CEBD", "#F6EEDF", "#F57B51", "#D63447"],
      ["#29C7AC", "#C02739", "#84142D", "#54123B"],
      ["#88E1F2", "#FFD082", "#FF7C7C", "#21243D"],
      ["#FFE196", "#D8B5B5", "#EC7373", "#05DFD7"],
      ["#FAAFFF", "#BBCFFF", "#F0EFEF", "#192965"],
      ["#FF5D6C", "#FBCEB5", "#DEFF8B", "#8CBA51"],
      ["#F5EAEA", "#7FCD91", "#5B5656", "#4D4646"],
      ["#40BFC1", "#F5F0E3", "#FF6F5E", "#F0134D"],
      ["#232020", "#3A3535", "#FF7315", "#F4F4F4"],
      ["#E9EA77", "#BBBBBB", "#297CA0", "#46185F"],
      ["#ECECEB", "#E25822", "#000000", "#10316B"],
      ["#55AE95", "#3F4D71", "#FD7792", "#FFAC8E"],
      ["#F7BE16", "#EC9B3B", "#00818A", "#293462"],
      ["#F9C6BA", "#DD6892", "#3C6F9C", "#512C96"],
      ["#512C62", "#C93838", "#F75F00", "#43AB92"],
      ["#EEEEEE", "#E5E5E5", "#1089FF", "#23374D"],
      ["#730068", "#434982", "#01D28E", "#F6F078"],
      ["#757575", "#979797", "#F3FE7E", "#DDF796"],
      ["#000000", "#E8647C", "#EC9B3B", "#47E4BB"],
      ["#E5D429", "#A0C334", "#5D5D5D", "#F7F7F7"],
      ["#032535", "#61234E", "#FF5858", "#EBFFFB"],
      ["#8559A5", "#00BDAA", "#A1DD70", "#EAEAEA"],
      ["#FFB961", "#F3826F", "#C05C7E", "#2D3561"],
      ["#FCB1B1", "#F0F696", "#96F7D2", "#F5B5FC"],
      ["#F96D15", "#45969B", "#50B6BB", "#F8F8F8"],
      ["#FFF1C1", "#F76262", "#216583", "#293462"],
      ["#F30CD4", "#0C99C1", "#22B9CA", "#FFF78F"],
      ["#5D13E7", "#7AD9F5", "#BDF2D5", "#F6F6F6"],
      ["#C10000", "#FF4949", "#DEDEDE", "#EEEEEE"],
      ["#FF9E74", "#EE5A5A", "#B31E6F", "#22EACA"],
      ["#FFEBD3", "#40A798", "#226B80", "#FFEAA5"],
      ["#085F63", "#49BEB7", "#F1F1F1", "#F5E1DA"],
      ["#FF5959", "#FACF5A", "#49BEB7", "#085F63"],
      ["#621E81", "#257AA6", "#00BDAA", "#EAEAEA"],
      ["#34314F", "#692DB7", "#8158FC", "#FFC15E"],
      ["#FFFDAF", "#00FAAC", "#FF3796", "#302387"],
      ["#DEE0D9", "#6B76FF", "#FFE5AE", "#FF62A5"],
      ["#E16262", "#FABC60", "#3A9679", "#11144C"],
      ["#621295", "#C40B13", "#F69314", "#DADDDF"],
      ["#D5DAEB", "#EE4266", "#1C1259", "#FCD307"],
      ["#CEF9E2", "#CBBCF6", "#A06EE1", "#421B9B"],
      ["#F3AE4B", "#DE4383", "#774898", "#00A8B5"],
      ["#21174A", "#127681", "#FAC70B", "#DF0E62"],
      ["#40514E", "#2F89FC", "#30E3CA", "#F5F5F5"],
      ["#1EAFED", "#612570", "#EA168E", "#EEF2F5"],
      ["#0D0CB5", "#BECBFF", "#DEE1EC", "#C82121"],
      ["#CB3737", "#EE6F57", "#E3E3E3", "#FAFAFA"],
      ["#FD5F00", "#05004E", "#76B39D", "#F9F8EB"],
      ["#F3ECD3", "#51E3D4", "#474F85", "#F3F9FB"],
      ["#EFE784", "#6CBBB3", "#AE7C7C", "#3E3838"],
      ["#F7AA00", "#C7F2E3", "#87E5DA", "#DB2D43"],
      ["#5E227F", "#F8B500", "#D22780", "#F2F4FB"],
      ["#F7DE1C", "#2C3848", "#706381", "#45B7B8"],
      ["#F57665", "#8B4C8C", "#45B7B7", "#D1F6C1"],
      ["#393E46", "#00ADB5", "#ECE8D9", "#F8B500"],
      ["#4A0E5C", "#7C4789", "#BCA3CA", "#CCF0C3"],
      ["#F2F2B0", "#EEC2C2", "#D7ACD4", "#BAE5D5"],
      ["#99DDCC", "#FF5DA2", "#B643CD", "#35013F"],
      ["#FBB901", "#E62A76", "#774898", "#00A8B5"],
      ["#1A0841", "#4F9DA6", "#FFAD5A", "#FF5959"],
      ["#68BDE1", "#F0F0E4", "#DB5F29", "#7B3C3C"],
      ["#1ABB9C", "#5F1854", "#3B0944", "#F7F7F7"],
      ["#060608", "#2470A0", "#A696C8", "#FAD3CF"],
      ["#262525", "#FC6B3F", "#84F2D6", "#FFF6DA"],
      ["#D7FBF6", "#48E0E4", "#E40475", "#0E0220"],
      ["#04322E", "#C93D1B", "#F8C43A", "#35D0BA"],
      ["#FFBE00", "#E6E6D4", "#1C819E", "#005874"],
      ["#EA5455", "#F6F6F6", "#FFB400", "#2D4059"],
      ["#EEF0F2", "#FFAF87", "#13293D", "#0D7E83"],
      ["#D9FAFF", "#005792", "#00204A", "#FD5F00"],
      ["#FDB44B", "#76B39D", "#962071", "#702283"],
      ["#F4E5C2", "#6DB193", "#8559A5", "#323232"],
      ["#2E383F", "#07617D", "#F9A828", "#ECECEB"],
      ["#F2855E", "#D7EEF2", "#F6F6E9", "#194769"],
      ["#283E56", "#1989AC", "#FEF4E8", "#970747"],
      ["#351F39", "#726A95", "#A0C1B8", "#F4E8C1"],
      ["#F2F2F2", "#FFE5E1", "#DD3E3E", "#17139C"],
      ["#020205", "#E43A19", "#F2F4F7", "#111F4D"],
      ["#537791", "#C1C0B9", "#F7F6E7", "#E7E6E1"],
      ["#4D3664", "#BAB5F6", "#CFCBF1", "#E1FFCF"],
      ["#BFF4ED", "#280F34", "#B30753", "#F6C667"],
      ["#8785A2", "#F6F6F6", "#FFE2E2", "#FFC7C7"],
      ["#FFE578", "#DEDEDE", "#48C0D3", "#211572"],
      ["#FAF9F0", "#1DCED8", "#000249", "#F6490D"],
      ["#FAFCDB", "#9FEBEB", "#492645", "#734488"],
      ["#756C83", "#F38181", "#B9E1DC", "#FBFBFB"],
      ["#3DC7BE", "#364857", "#334252", "#F75940"],
      ["#305973", "#EF7E56", "#DED5C4", "#F9F9F9"],
      ["#F6C667", "#B30753", "#280F34", "#BFF4ED"],
      ["#60366F", "#5F72B2", "#6FA3A9", "#FFDAA9"],
      ["#C5E3F6", "#FC5C9C", "#FCCDE2", "#FCEFEE"],
      ["#E23E57", "#555273", "#65799B", "#E2EFF1"],
      ["#FAF7F2", "#2BB3C0", "#161C2E", "#EF6C35"],
      ["#80185F", "#FA67AB", "#F7E6B5", "#19215E"],
      ["#446878", "#13445A", "#FEF4E8", "#970747"],
      ["#10828C", "#0E3047", "#F97300", "#F8F5E4"],
      ["#3A0077", "#8983F3", "#A5F2E7", "#FFFCEA"],
      ["#7A57D1", "#4FC1E9", "#5BE7C4", "#F5F7FA"],
      ["#F2F2F2", "#FECD51", "#9F1E49", "#2F1B41"],
      ["#475762", "#FBD490", "#00AD7C", "#ECEFF4"],
      ["#FEA096", "#F25292", "#59569D", "#EEEEEE"],
      ["#8C54A1", "#AEA1EA", "#B2EBF9", "#FAFBD4"],
      ["#305C5C", "#5C835A", "#FBF27C", "#586E72"],
      ["#FFC85B", "#379956", "#234C63", "#EEEEEE"],
      ["#E6E993", "#26C6D0", "#604FDD", "#3A3A62"],
      ["#321313", "#F4991A", "#F2EAD3", "#F9F5F0"],
      ["#2E383F", "#07617D", "#ECECEB", "#F9A828"],
      ["#F6F1F8", "#C1EA9F", "#BB8FA9", "#70198A"],
      ["#E8E2E2", "#FDFCFC", "#F35B25", "#2A3356"],
      ["#FCFFC1", "#FF917B", "#F469A9", "#65EAD1"],
      ["#F0E48E", "#FD89DD", "#BF68F6", "#B8EEF1"],
      ["#373331", "#FFF3E1", "#29CDB5", "#9BF4D5"],
      ["#171332", "#2A5D67", "#EEEEEE", "#4AE3B5"],
      ["#FFD717", "#0DA574", "#083358", "#001F3F"],
      ["#FF5722", "#EEEEEE", "#00ADB5", "#303841"],
      ["#0D132A", "#4F1C4C", "#DA4949", "#E6EE75"],
      ["#F66095", "#393E46", "#38817A", "#2BCDC1"],
      ["#00FFCC", "#0CCA98", "#5E366A", "#331940"],
      ["#F7F7CF", "#C1D343", "#FF6600", "#990000"],
      ["#F35E3E", "#3B2E40", "#6173F4", "#FCEFED"],
      ["#3A3D44", "#0D8549", "#F9C11C", "#B2085D"],
      ["#1BF5AF", "#93A7D1", "#4F7097", "#BEF2FF"],
      ["#FFD944", "#94DD4D", "#49B47E", "#54447B"],
      ["#DDDDC7", "#70D4B4", "#B51A62", "#3D065A"],
      ["#DECE9C", "#20615B", "#1A1831", "#A21232"],
      ["#6F7777", "#72B896", "#CD4439", "#F7DEAD"],
      ["#574B9B", "#6A7FF5", "#9CE3CF", "#F7F4EA"],
      ["#EFEFEF", "#D2D86E", "#95956E", "#3F3F3F"],
      ["#53354A", "#903749", "#E84545", "#2B2E4A"],
    ],
  },
  {
    name: "sea",
    palettes: [
      ["#CBDCEB", "#608BC1", "#133E87", "#F3F3E0"],
      ["#4A628A", "#7AB2D3", "#B9E5E8", "#DFF2EB"],
      ["#EAD8B1", "#6A9AB0", "#3A6D8C", "#001F3F"],
      ["#7CF5FF", "#00CCDD", "#4F75FF", "#6439FF"],
      ["#C7FFD8", "#98DED9", "#0B2F9F", "#161D6F"],
      ["#FFDC7F", "#78B7D0", "#227B94", "#16325B"],
      ["#E1D7B7", "#1E2A5E", "#55679C", "#7C93C3"],
      ["#1F316F", "#1A4870", "#5B99C2", "#F9DBBA"],
      ["#EEEEEE", "#508C9B", "#134B70", "#201E43"],
      ["#4535C1", "#478CCF", "#36C2CE", "#77E4C8"],
      ["#EBF4F6", "#37B7C3", "#088395", "#071952"],
      ["#3DC2EC", "#4B70F5", "#4C3BCF", "#402E7A"],
      ["#1A2130", "#5A72A0", "#83B4FF", "#FDFFE2"],
      ["#B3E2A7", "#80B9AD", "#6295A2", "#538392"],
      ["#A7E6FF", "#3ABEF9", "#3572EF", "#050C9C"],
      ["#5C2FC2", "#5C88C4", "#6FDCE3", "#FFFDB5"],
      ["#FEAE6F", "#F6DCAC", "#028391", "#01204E"],
      ["#57A6A1", "#577B8D", "#344C64", "#240750"],
      ["#4D869C", "#7AB2B2", "#EEF7FF", "#CDE8E5"],
      ["#DFD0B8", "#948979", "#3C5B6F", "#153448"],
      ["#E1F7F5", "#9AC8CD", "#0E46A3", "#1E0342"],
      ["#C5FF95", "#5DEBD7", "#1679AB", "#074173"],
      ["#E3FEF7", "#77B0AA", "#135D66", "#003C43"],
      ["#E5DDC5", "#F1EEDC", "#BED7DC", "#B3C8CF"],
      ["#7AA2E3", "#6AD4DD", "#97E7E1", "#F8F6E3"],
      ["#E2F4C5", "#A8CD9F", "#58A399", "#496989"],
      ["#F7EEDD", "#ACE2E1", "#41C9E2", "#008DDA"],
      ["#F9E8C9", "#98ABEE", "#1D24CA", "#201658"],
      ["#F1FADA", "#9AD0C2", "#2D9596", "#265073"],
      ["#637A9F", "#C9D7DD", "#FFF3CF", "#E8C872"],
      ["#96E9C6", "#83C0C1", "#6962AD", "#6C22A6"],
      ["#F0EDCF", "#40A2D8", "#0B60B0", "#000000"],
      ["#0F1035", "#365486", "#7FC7D9", "#DCF2F1"],
      ["#176B87", "#86B6F6", "#B4D4FF", "#EEF5FF"],
      ["#200E3A", "#38419D", "#3887BE", "#52D3D8"],
      ["#0F2167", "#3559E0", "#4CB9E7", "#FFECD6"],
      ["#9EC8B9", "#5C8374", "#1B4242", "#092635"],
      ["#3081D0", "#6DB9EF", "#F4F27E", "#FFF5C2"],
      ["#7B66FF", "#5FBDFF", "#96EFFF", "#C5FFF8"],
      ["#265073", "#2D9596", "#9AD0C2", "#ECF4D6"],
      ["#F3F3F3", "#C5E898", "#29ADB2", "#0766AD"],
      ["#FFD28F", "#FFE3BB", "#B4BDFF", "#83A2FF"],
      ["#F5E8C7", "#818FB4", "#435585", "#363062"],
      ["#FFEED9", "#39A7FF", "#87C4FF", "#E0F4FF"],
      ["#CDF5FD", "#A0E9FF", "#89CFF3", "#00A9FF"],
      ["#F3F0CA", "#E1AA74", "#3876BF", "#192655"],
      ["#DAFFFB", "#64CCC5", "#176B87", "#04364A"],
      ["#E5C3A6", "#7C81AD", "#4B527E", "#2E4374"],
      ["#F5FCCD", "#78D6C6", "#419197", "#12486B"],
      ["#5CD2E6", "#3085C3", "#F4E869", "#FAF2D3"],
      ["#F2F7A1", "#35A29F", "#088395", "#071952"],
      ["#93B1A6", "#5C8374", "#183D3D", "#040D12"],
      ["#FFC436", "#337CCF", "#1450A3", "#191D88"],
      ["#D5FFD0", "#40F8FF", "#279EFF", "#0C356A"],
      ["#F1F0E8", "#EEE0C9", "#ADC4CE", "#96B6C5"],
      ["#8CABFF", "#4477CE", "#512B81", "#35155D"],
      ["#4682A9", "#749BC2", "#91C8E4", "#F6F4EB"],
      ["#97FEED", "#35A29F", "#0B666A", "#071952"],
      ["#EEEEEE", "#068FFF", "#4E4FEB", "#000000"],
      ["#C5DFF8", "#A0BFE0", "#7895CB", "#4A55A2"],
      ["#A2FF86", "#4FC0D0", "#1B6B93", "#164B60"],
      ["#DAFFFB", "#64CCC5", "#176B87", "#001C30"],
      ["#A1C2F1", "#5A96E3", "#0A6EBD", "#E7CEA6"],
      ["#FFEEBB", "#A7ECEE", "#99DBF5", "#9AC5F4"],
      ["#116A7B", "#CDC2AE", "#ECE5C7", "#C2DEDC"],
      ["#FFF5B8", "#FFE7A0", "#00C4FF", "#30A2FF"],
      ["#C4B0FF", "#8696FE", "#4942E4", "#11009E"],
      ["#DDE6ED", "#9DB2BF", "#526D82", "#27374D"],
      ["#FFEAD2", "#DBDFEA", "#ACB1D6", "#8294C4"],
      ["#146C94", "#19A7CE", "#AFD3E2", "#F6F1F1"],
      ["#D4FAFC", "#B8E7E1", "#FFEEB3", "#9E6F21"],
      ["#EBD8B2", "#A5C0DD", "#6C9BCF", "#654E92"],
      ["#00FFCA", "#05BFDB", "#088395", "#0A4D68"],
      ["#577D86", "#569DAA", "#87CBB9", "#B9EDDD"],
      ["#2F0F5D", "#0EA293", "#27E1C1", "#F5F3C1"],
      ["#A5D7E8", "#576CBC", "#19376D", "#0B2447"],
      ["#002B5B", "#1A5F7A", "#159895", "#57C5B6"],
      ["#000000", "#146C94", "#19A7CE", "#F6F1F1"],
      ["#62CDFF", "#97DEFF", "#C9EEFF", "#AA77FF"],
      ["#CBE4DE", "#0E8388", "#2E4F4F", "#2C3333"],
      ["#3795BD", "#2F58CD", "#4E31AA", "#3A1078"],
      ["#EEEEEE", "#E8D5C4", "#FFF1DC", "#3A98B9"],
      ["#E9F8F9", "#C0EEF2", "#537FE7", "#181823"],
      ["#DEFCF9", "#85CDFD", "#3C84AB", "#362FD9"],
      ["#EEE9DA", "#BDCDD6", "#93BFCF", "#6096B4"],
      ["#F8CBA6", "#FFE7CC", "#FFFBEB", "#ECF9FF"],
      ["#B6EADA", "#5B8FB9", "#301E67", "#03001C"],
      ["#FAD6A5", "#CFB997", "#7B8FA1", "#567189"],
      ["#03C988", "#1C82AD", "#00337C", "#13005A"],
      ["#FFC93C", "#86E5FF", "#5BC0F8", "#0081C9"],
      ["#2C74B3", "#205295", "#144272", "#0A2647"],
      ["#E6E2C3", "#88A47C", "#227C70", "#1C315E"],
      ["#F1F6F5", "#82C3EC", "#4B56D2", "#472183"],
      ["#CBEDD5", "#97DECE", "#62B6B7", "#439A97"],
      ["#0014FF", "#009EFF", "#00E7FF", "#00FFF6"],
      ["#0D4C92", "#59C1BD", "#A0E4CB", "#CFF5E7"],
      ["#5837D0", "#5DA7DB", "#81C6E8", "#7DE5ED"],
      ["#C47AFF", "#7978FF", "#4649FF", "#1D1CE5"],
      ["#DFF6FF", "#47B5FF", "#256D85", "#06283D"],
      ["#000000", "#5C2E7E", "#4C6793", "#8BBCCC"],
      ["#D8D8D8", "#647E68", "#562B08", "#182747"],
      ["#8FE3CF", "#256D85", "#2B4865", "#002B5B"],
      ["#E7F6F2", "#A5C9CA", "#395B64", "#2C3333"],
      ["#B3E8E5", "#82DBD8", "#3BACB6", "#2F8F9D"],
      ["#F7FF93", "#A1E3D8", "#069A8E", "#005555"],
      ["#00FFC6", "#30AADD", "#43919B", "#247881"],
      ["#FAFFAF", "#95D1CC", "#5584AC", "#22577E"],
      ["#C1F8CF", "#4FD3C4", "#488FB1", "#533E85"],
      ["#B1D0E0", "#6998AB", "#406882", "#1A374D"],
      ["#69DADB", "#1597E5", "#113CFC", "#193498"],
      ["#E6E6E6", "#5C7AEA", "#3D56B2", "#14279B"],
      ["#D5BFBF", "#8CA1A5", "#6D8299", "#316B83"],
      ["#112031", "#152D35", "#345B63", "#D4ECDD"],
      ["#80ED99", "#57CC99", "#38A3A5", "#22577A"],
      ["#444941", "#5F7A61", "#D5EEBB", "#7FC8A9"],
      ["#EEEEEE", "#548CA8", "#476072", "#334257"],
      ["#E8F0F2", "#A2DBFA", "#39A2DB", "#053742"],
      ["#DFEEEA", "#A7C4BC", "#5E8B7E", "#2F5D62"],
      ["#A5E1AD", "#4CA1A3", "#511281", "#21094E"],
      ["#D2E69C", "#8FD9A8", "#28B5B5", "#4B778D"],
    ],
  },
  {
    name: "skin",
    palettes: [
      ["#DE8F5F", "#FFB26F", "#FFCF9D", "#FFB38E"],
      ["#493628", "#AB886D", "#D6C0B3", "#E4E0E1"],
      ["#4A4947", "#B17457", "#D8D2C2", "#FAF7F0"],
      ["#3B3030", "#664343", "#795757", "#FFF0D1"],
      ["#603F26", "#6C4E31", "#FFDBB5", "#FFEAC5"],
      ["#C5705D", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FFEBD4", "#FFC6C6", "#F7B5CA", "#F0A8D0"],
      ["#8D493A", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FED8B1", "#ECB176", "#A67B5B", "#6F4E37"],
      ["#D8AE7E", "#F8C794", "#FFE0B5", "#FFF2D7"],
      ["#FFD0D0", "#E1ACAC", "#CA8787", "#A87676"],
      ["#322C2B", "#803D3B", "#AF8260", "#E4C59E"],
      ["#FFEFEF", "#F3D0D7", "#F0EBE3", "#F6F5F2"],
      ["#FFF2E1", "#EAD8C0", "#D1BB9E", "#A79277"],
      ["#F7418F", "#FC819E", "#FEC7B4", "#FFF3C7"],
      ["#E2BFB3", "#F7DED0", "#FEECE2", "#FFBE98"],
      ["#FFE7E7", "#CAA6A6", "#B47B84", "#944E63"],
      ["#643843", "#85586F", "#AC7D88", "#FDF0D1"],
      ["#E8D8C4", "#C7B7A3", "#6D2932", "#561C24"],
      ["#E6A4B4", "#F3D7CA", "#FFF8E3", "#F5EEE6"],
      ["#A87C7C", "#7E6363", "#503C3C", "#3E3232"],
      ["#BBAB8C", "#DED0B6", "#FAEED1", "#FDF7E4"],
      ["#F5CCA0", "#E48F45", "#994D1C", "#6B240C"],
      ["#FAE7C9", "#E1C78F", "#B0926A", "#706233"],
      ["#113946", "#BCA37F", "#EAD7BB", "#FFF2D8"],
      ["#F39F5A", "#AE445A", "#662549", "#451952"],
      ["#0F2C59", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#F2ECBE", "#E2C799", "#C08261", "#9A3B3B"],
      ["#3F1D38", "#4D3C77", "#A2678A", "#E19898"],
      ["#102C57", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#65451F", "#765827", "#C8AE7D", "#EAC696"],
      ["#FEBBCC", "#FFCCCC", "#FFDDCC", "#FFEECC"],
      ["#CEE6F3", "#DFA878", "#BA704F", "#6C3428"],
      ["#F9E0BB", "#FFC26F", "#C38154", "#884A39"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#9BABB8"],
      ["#F2BED1", "#FDCEDF", "#F8E8EE", "#F9F5F6"],
      ["#E7CBCB", "#C88EA7", "#99627A", "#643843"],
      ["#B0A4A4", "#957777", "#643A6B", "#5F264A"],
      ["#FEF2F4", "#FCC8D1", "#FFABAB", "#D14D72"],
      ["#E74646", "#FA9884", "#FFE5CA", "#FFF3E2"],
      ["#F5EBEB", "#E4D0D0", "#D5B4B4", "#867070"],
      ["#F9F5E7", "#F8EAD8", "#EDDBC7", "#A7727D"],
      ["#FFC3A1", "#F0997D", "#D3756B", "#A75D5D"],
      ["#E98EAD", "#FEA1BF", "#FFC6D3", "#3A4F7A"],
      ["#85586F", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#F2E5E5", "#E8C4C4", "#CE7777", "#2B3A55"],
      ["#DBA39A", "#F0DBDB", "#F5EBE0", "#FEFCF3"],
      ["#F1D3B3", "#C7BCA1", "#8B7E74", "#65647C"],
      ["#FFFAD7", "#FCDDB0", "#FF9F9F", "#E97777"],
      ["#FF8DC7", "#FFACC7", "#FFDDD2", "#FFB9B9"],
      ["#594545", "#815B5B", "#9E7676", "#FFF8EA"],
      ["#7D6E83", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FF9494", "#FFD1D1", "#FFE3E1", "#FFF5E4"],
      ["#E38B29", "#F1A661", "#FFD8A9", "#FDEEDC"],
      ["#F1EFDC", "#E6D2AA", "#D36B00", "#42032C"],
      ["#AC7088", "#DEB6AB", "#ECCCB2", "#F5E8C7"],
      ["#F5E8E4", "#F5C7A9", "#D1512D", "#411530"],
      ["#E6B325", "#BF9742", "#A47E3B", "#61481C"],
      ["#EDDFB3", "#D8CCA3", "#B09B71", "#87805E"],
      ["#E7AB79", "#B25068", "#774360", "#4C3A51"],
      ["#C6DCE4", "#DAEAF1", "#F2D1D1", "#FFE6E6"],
      ["#FFF2F2", "#FAD4D4", "#EF9F9F", "#F47C7C"],
      ["#CE9461", "#DEA057", "#FCFFE7", "#E0D8B0"],
      ["#85586F", "#AC7D88", "#DEB6AB", "#F8ECD1"],
      ["#FFEBC1", "#D7A86E", "#A64B2A", "#8E3200"],
      ["#EBD8C3", "#F7E9D7", "#FFF6EA", "#FFEEEE"],
      ["#826F66", "#C69B7B", "#F7CCAC", "#3A3845"],
      ["#B7CADB", "#FDF6EC", "#F3E9DD", "#DAB88B"],
      ["#874356", "#C65D7B", "#F68989", "#F6E7D8"],
      ["#FFFBE9", "#E3CAA5", "#CEAB93", "#AD8B73"],
      ["#CC9544", "#603601", "#361500", "#1C0A00"],
      ["#BF9270", "#E3B7A0", "#EDCDBB", "#FFEDDB"],
      ["#470D21", "#9C0F48", "#D67D3E", "#F9E4D4"],
      ["#694E4E", "#886F6F", "#C1A3A3", "#F3C5C5"],
      ["#DACC96", "#BF8B67", "#9D5353", "#632626"],
      ["#980F5A", "#750550", "#570530", "#4C0027"],
      ["#AEFEFF", "#4FBDBA", "#35858B", "#072227"],
      ["#FFEDED", "#FFCCD2", "#B3541E", "#362222"],
      ["#F4DFBA", "#EEC373", "#CA965C", "#876445"],
      ["#FFF9F9", "#D6E5FA", "#BAABDA", "#D77FA1"],
      ["#A68DAD", "#C7B198", "#DFD3C3", "#F0ECE3"],
      ["#F2789F", "#F999B7", "#F9C5D5", "#FEE3EC"],
      ["#F0D290", "#DE834D", "#A3423C", "#781D42"],
      ["#FFE6BC", "#E4CDA7", "#C3B091", "#8E806A"],
      ["#FAFAFA", "#F7D08A", "#E5890A", "#9D5C0D"],
      ["#FFF3E4", "#EED6C4", "#6B4F4F", "#483434"],
      ["#FBFBFB", "#EADCA6", "#E2C275", "#C36A2D"],
      ["#EDEDED", "#DDBEBE", "#C89595", "#6C4A4A"],
      ["#F9CF93", "#F9E4C8", "#FAEEE0", "#DBD0C0"],
      ["#F4D19B", "#D7E9F7", "#FDFCE5", "#F9F3DF"],
      ["#8FC1D4", "#FCDEC0", "#C68B59", "#865439"],
      ["#6F4C5B", "#9E7777", "#DEBA9D", "#F5E8C7"],
      ["#E0C097", "#B85C38", "#5C3D2E", "#2D2424"],
      ["#CDBBA7", "#DAD0C2", "#F4DFD0", "#FDEFEF"],
      ["#FFEBC9", "#D79771", "#B05B3B", "#753422"],
      ["#F5C6AA", "#F8E2CF", "#FDF6F0", "#FCD8D4"],
      ["#835151", "#F08FC0", "#FFBCBC", "#FFEACA"],
      ["#5D534A", "#D6D2C4", "#FFF5DA", "#F7DAD9"],
      ["#D7B19D", "#C68B59", "#865439", "#402218"],
      ["#FCDEC0", "#E5B299", "#B4846C", "#7D5A50"],
      ["#0A1D37", "#FFBD9B", "#FFD8CC", "#FFEEDB"],
      ["#343F56", "#F54748", "#FB9300", "#F5E6CA"],
      ["#E4BAD4", "#F6DFEB", "#F8EDED", "#CAF7E3"],
      ["#000000", "#D99879", "#F4CCA4", "#BA135D"],
      ["#B6C9F0", "#FFE5E2", "#F5ABC9", "#E93B81"],
      ["#AFB9C8", "#CE97B0", "#F4A9A8", "#FBC6A4"],
      ["#D1D9D9", "#F29191", "#EEC4C4", "#94D0CC"],
      ["#C8C2BC", "#F2DAC3", "#F1CA89", "#CC9B6D"],
      ["#583D72", "#9F5F80", "#FF8474", "#FFC996"],
      ["#FF6701", "#FEA82F", "#FFC288", "#FCECDD"],
      ["#E6C4C0", "#CA8A8B", "#926E6F", "#999B84"],
      ["#F6E6E4", "#E2BCB7", "#CA8A8B", "#5B6D5B"],
      ["#1B1A17", "#A35709", "#FF8303", "#F0E3CA"],
      ["#1B2021", "#EAC8AF", "#D8AC9C", "#5F939A"],
      ["#FFEFCF", "#FF7A00", "#D44000", "#864000"],
      ["#310B0B", "#9C3D54", "#E2703A", "#EEB76B"],
      ["#B67162", "#E2BCB7", "#E4D3CF", "#9E9D89"],
      ["#FFE6CA", "#D0AF84", "#966C3B", "#CDC733"],
      ["#F2B4B4", "#B7657B", "#8F4F4F", "#78C4D4"],
      ["#F25287", "#F7D9D9", "#F9F3F3", "#DDDDDD"],
    ],
  },
  {
    name: "sky",
    palettes: [
      ["#FFDDAE", "#FBFBFB", "#D4F6FF", "#C6E7FF"],
      ["#FFFECB", "#F5F4B3", "#4CC9FE", "#37AFE1"],
      ["#BCF2F6", "#08C2FF", "#006BFF", "#FFF100"],
      ["#A594F9", "#CDC1FF", "#F5EFFF", "#E5D9F2"],
      ["#8EACCD", "#DEE5D4", "#FEF9D9", "#D2E0FB"],
      ["#0F67B1", "#3FA2F6", "#96C9F4", "#FAFFAF"],
      ["#5C2FC2", "#5C88C4", "#6FDCE3", "#FFFDB5"],
      ["#FFDA78", "#FF7F3E", "#2A629A", "#003285"],
      ["#5AB2FF", "#A0DEFF", "#CAF4FF", "#FFF9D0"],
      ["#7AA2E3", "#6AD4DD", "#97E7E1", "#F8F6E3"],
      ["#F7EEDD", "#ACE2E1", "#41C9E2", "#008DDA"],
      ["#0D9276", "#BBE2EC", "#FFF6E9", "#40A2E3"],
      ["#E5E1DA", "#FBF9F1", "#AAD7D9", "#92C7CF"],
      ["#176B87", "#86B6F6", "#B4D4FF", "#EEF5FF"],
      ["#FFE5E5", "#E0AED0", "#AC87C5", "#756AB6"],
      ["#7B66FF", "#5FBDFF", "#96EFFF", "#C5FFF8"],
      ["#CDF5FD", "#A0E9FF", "#89CFF3", "#00A9FF"],
      ["#687EFF", "#80B3FF", "#98E4FF", "#B6FFFA"],
      ["#BEFFF7", "#A6F6FF", "#9EDDFF", "#6499E9"],
      ["#F2F7A1", "#35A29F", "#088395", "#071952"],
      ["#4682A9", "#749BC2", "#91C8E4", "#F6F4EB"],
      ["#FBEEAC", "#F4D160", "#75C2F6", "#1D5D9B"],
      ["#F8FDCF", "#E2F6CA", "#9BE8D8", "#78C1F3"],
      ["#FFEEBB", "#A7ECEE", "#99DBF5", "#9AC5F4"],
      ["#E6FFFD", "#AEE2FF", "#ACBCFF", "#B799FF"],
      ["#146C94", "#19A7CE", "#B0DAFF", "#FEFF86"],
      ["#FFEAD2", "#DBDFEA", "#ACB1D6", "#8294C4"],
      ["#146C94", "#19A7CE", "#AFD3E2", "#F6F1F1"],
      ["#DAF5FF", "#B9E9FC", "#B0DAFF", "#FEFF86"],
      ["#FFF4D2", "#E5D1FA", "#E3DFFD", "#ECF2FF"],
      ["#205E61", "#3F979B", "#8BF5FA", "#F9F54B"],
      ["#FEDEFF", "#93C6E7", "#AEE2FF", "#B9F3FC"],
      ["#FFF2F2", "#E5E0FF", "#8EA7E9", "#7286D3"],
      ["#EAFDFC", "#BFEAF5", "#91D8E4", "#82AAE3"],
      ["#F2F7A1", "#46C2CB", "#6D67E4", "#453C67"],
      ["#FFEBAD", "#FFF6BF", "#7FE9DE", "#A5F1E9"],
      ["#FFADBC", "#D989B5", "#975C8D", "#863A6F"],
      ["#0D4C92", "#59C1BD", "#A0E4CB", "#CFF5E7"],
      ["#8EC3B0", "#9ED5C5", "#BCEAD5", "#DEF5E5"],
      ["#5837D0", "#5DA7DB", "#81C6E8", "#7DE5ED"],
      ["#98A8F8", "#BCCEF8", "#CDFCF6", "#FAF7F0"],
      ["#EEF1FF", "#D2DAFF", "#AAC4FF", "#B1B2FF"],
      ["#FFEBAD", "#FFF6BF", "#ABD9FF", "#C3F8FF"],
      ["#FEF5AC", "#97D2EC", "#5F6F94", "#25316D"],
      ["#EBC7E8", "#BFACE0", "#A084CA", "#645CAA"],
      ["#FFEEAF", "#E1FFEE", "#A5F1E9", "#7FBCD2"],
      ["#FFC18E", "#CA4E79", "#7A4069", "#513252"],
      ["#F8F9D7", "#C4D7E0", "#B2C8DF", "#6E85B7"],
      ["#FCC5C0", "#E8A0BF", "#C689C6", "#937DC2"],
      ["#748DA6", "#9CB4CC", "#D3CEDF", "#F2D7D9"],
      ["#DFF6FF", "#47B5FF", "#1363DF", "#06283D"],
      ["#F806CC", "#A91079", "#570A57", "#2E0249"],
      ["#EEE4AB", "#E5CB9F", "#99C4C8", "#68A7AD"],
      ["#2155CD", "#0AA1DD", "#79DAE8", "#E8F9FD"],
      ["#FAFFAF", "#95D1CC", "#5584AC", "#22577E"],
      ["#F2FA5A", "#5EE6EB", "#56BBF1", "#4D77FF"],
      ["#42C2FF", "#85F4FF", "#B8FFF9", "#EFFFFD"],
      ["#F6F2D4", "#95D1CC", "#5584AC", "#22577E"],
      ["#A9E4D7", "#63B4B8", "#664E88", "#4B3869"],
      ["#FFE3E3", "#E4D8DC", "#C9CCD5", "#93B5C6"],
      ["#88FFF7", "#7DEDFF", "#96BAFF", "#7C83FD"],
      ["#BEAEE2", "#F7DBF0", "#F9F9F9", "#CDF0EA"],
      ["#EDEEF7", "#7868E6", "#B8B5FF", "#E4FBFF"],
      ["#AAAAAA", "#A4EBF3", "#CCF2F4", "#F4F9F9"],
      ["#AEE1E1", "#D3E0DC", "#ECE2E1", "#FCD1D1"],
      ["#456268", "#79A3B1", "#D0E8F2", "#FCF8EC"],
      ["#B9FFFC", "#A3D8F4", "#9AB3F5", "#7579E7"],
      ["#FBFD8A", "#87DFD6", "#01A9B4", "#086972"],
      ["#EFF3C6", "#77D8D8", "#4CBBB9", "#0779E4"],
      ["#8AC6D1", "#FFE3ED", "#FFFDF9", "#BEEBE9"],
      ["#F8C3AF", "#FEA5AD", "#EA9ABB", "#BE8ABF"],
      ["#46B5D1", "#515585", "#32407B", "#151965"],
      ["#EEF5B2", "#6DECB9", "#42DEE1", "#3FC5F0"],
      ["#3E64FF", "#5EDFFF", "#B2FCFF", "#ECFCFF"],
      ["#FFEAFE", "#CCC1FF", "#9EA9F0", "#553C8B"],
      ["#B7FBFF", "#82ACFF", "#8F71FF", "#866EC7"],
      ["#E3E7F1", "#C6CBEF", "#8186D5", "#494CA2"],
      ["#E8D3FF", "#C6CFFF", "#DEECFF", "#F3F8FF"],
      ["#D7EAEA", "#ACDBDF", "#9692AF", "#69779B"],
      ["#FFFF9D", "#FFB480", "#F18A9B", "#A26EA1"],
      ["#EDF9FC", "#D1F4FA", "#53CDE2", "#005792"],
      ["#E8FFC1", "#A5ECD7", "#51ADCF", "#0278AE"],
      ["#539092", "#8BCFCC", "#AEE8E6", "#F5FAC8"],
      ["#CABBE9", "#FFCEF3", "#FDFDFD", "#A1EAFB"],
      ["#878ECD", "#B9BBDF", "#DDE7F2", "#DFF4F3"],
      ["#0245A3", "#8FBAF3", "#BDF1F6", "#F2FCFC"],
      ["#6892D5", "#79D1C3", "#C9FDD7", "#F8FCFB"],
      ["#CCA8E9", "#C3BEF0", "#CADEFC", "#DEFCF9"],
      ["#3B577D", "#44679F", "#C0D9E5", "#DDF5F7"],
      ["#0DCEDA", "#6EF3D6", "#C6FCE5", "#EBFFFA"],
      ["#5A92AF", "#86C1D4", "#9CD9DE", "#D9F9F4"],
      ["#E0E7E9", "#A3C6C4", "#6C7A89", "#354649"],
      ["#4993FA", "#9DC6FF", "#A0E4F1", "#F1FAFB"],
      ["#E8FFE8", "#A6FFF2", "#74F9FF", "#00E0FF"],
      ["#769FCD", "#B9D7EA", "#D6E6F2", "#F7FBFC"],
      ["#393737", "#4D6DE3", "#C7EEFF", "#F1FCFD"],
      ["#BBE4E9", "#79C2D0", "#53A8B6", "#5585B5"],
      ["#DAF4F5", "#C4DCE0", "#ABCECF", "#80A3A2"],
      ["#574B9B", "#6A7FF5", "#9CE3CF", "#F7F4EA"],
      ["#214177", "#3667A6", "#82A6CB", "#BDD8F1"],
      ["#484D51", "#818D97", "#8FACC0", "#D2E4F1"],
      ["#4636FC", "#5170FD", "#60ABFB", "#AEE1FC"],
      ["#344986", "#3576A7", "#63A1D0", "#4EC9DF"],
      ["#E8FFB1", "#9EF5CF", "#51DACF", "#48829E"],
    ],
  },
  {
    name: "space",
    palettes: [
      ["#EEEEEE", "#D4BEE4", "#9B7EBD", "#3B1E54"],
      ["#DBD3D3", "#EC8305", "#024CAA", "#091057"],
      ["#000000", "#0B192C", "#1E3E62", "#FF6500"],
      ["#FFE1FF", "#E4B1F0", "#7E60BF", "#433878"],
      ["#FFF7F7", "#C68FE6", "#6C48C5", "#1230AE"],
      ["#EBD3F8", "#AD49E1", "#7A1CAC", "#2E073F"],
      ["#FB773C", "#EB3678", "#4F1787", "#180161"],
      ["#C8ACD6", "#433D8B", "#2E236C", "#17153B"],
      ["#FFF078", "#E90074", "#FF4191", "#000000"],
      ["#A7E6FF", "#3ABEF9", "#3572EF", "#050C9C"],
      ["#FFDB00", "#FF8F00", "#AF47D2", "#26355D"],
      ["#F8F9D7", "#E49BFF", "#C738BD", "#850F8D"],
      ["#EEEEEE", "#C73659", "#A91D3A", "#151515"],
      ["#F27BBD", "#C65BCF", "#874CCC", "#10439F"],
      ["#A3FFD6", "#7BC9FF", "#8576FF", "#1C1678"],
      ["#FF71CD", "#5755FE", "#8B93FF", "#FFF7FC"],
      ["#FDAF7B", "#BE7B72", "#824D74", "#401F71"],
      ["#00224D", "#5D0E41", "#A0153E", "#FF204E"],
      ["#FFB5DA", "#FF7ED4", "#FF3EA5", "#6420AA"],
      ["#337357", "#FFD23F", "#EE4266", "#5E1675"],
      ["#9290C3", "#535C91", "#1B1A55", "#070F2B"],
      ["#FFD0EC", "#81689D", "#474F7A", "#1F2544"],
      ["#EFECEC", "#FFB0B0", "#FC6736", "#0C2D57"],
      ["#910A67", "#720455", "#3C0753", "#030637"],
      ["#F8E559", "#864AF9", "#3B3486", "#332941"],
      ["#FAEF5D", "#FF004D", "#7E2553", "#1D2B53"],
      ["#F8EDFF", "#BFCFE7", "#525CEB", "#3D3B40"],
      ["#FAE7F3", "#E6B9DE", "#4942E4", "#11009E"],
      ["#FFD1E3", "#A367B1", "#5D3587", "#392467"],
      ["#FFA732", "#EF4040", "#C21292", "#711DB0"],
      ["#EEE2DE", "#EA906C", "#B31312", "#2B2A4C"],
      ["#FF90C2", "#ED5AB3", "#1640D6", "#001B79"],
      ["#C2D9FF", "#8E8FFA", "#7752FE", "#190482"],
      ["#E95793", "#DA0C81", "#940B92", "#610C9F"],
      ["#008170", "#005B41", "#232D3F", "#0F0F0F"],
      ["#FFE5E5", "#FF6AC2", "#B931FC", "#5D12D2"],
      ["#E5CFF7", "#9D76C1", "#713ABE", "#5B0888"],
      ["#F39F5A", "#AE445A", "#662549", "#451952"],
      ["#EC53B0", "#9D44C0", "#4D2DB7", "#0E21A0"],
      ["#FE7BE5", "#974EC3", "#504099", "#313866"],
      ["#AED8CC", "#CD6688", "#7A316F", "#461959"],
      ["#EDE4FF", "#D7BBF5", "#A076F9", "#6528F7"],
      ["#97FEED", "#35A29F", "#0B666A", "#071952"],
      ["#DAFFFB", "#64CCC5", "#176B87", "#001C30"],
      ["#D3D04F", "#8EAC50", "#17594A", "#213363"],
      ["#9575DE", "#6554AF", "#2B2730", "#E966A0"],
      ["#84A7A1", "#2E8A99", "#1F6E8C", "#0E2954"],
      ["#2B2A4C", "#B31312", "#EA906C", "#EEE2DE"],
      ["#FFE79B", "#DD58D6", "#9336B4", "#40128B"],
      ["#C4B0FF", "#8696FE", "#4942E4", "#11009E"],
      ["#D4ADFC", "#5C469C", "#1D267D", "#0C134F"],
      ["#00FFCA", "#05BFDB", "#088395", "#0A4D68"],
      ["#FDE2F3", "#E5BEEC", "#917FB3", "#2A2F4F"],
      ["#F45050", "#F9D949", "#F0F0F0", "#3C486B"],
      ["#F15A59", "#ED2B2A", "#D21312", "#070A52"],
      ["#FFE6C7", "#FFA559", "#FF6000", "#454545"],
      ["#F0EB8D", "#8F43EE", "#413543", "#2D2727"],
      ["#D27685", "#9E4784", "#66347F", "#37306B"],
      ["#2E3840", "#FF0303", "#F9DBBB", "#4E6E81"],
      ["#FFA3FD", "#E384FF", "#865DFF", "#191825"],
      ["#EB455F", "#FCFFE7", "#BAD7E9", "#2B3467"],
      ["#18122B", "#393053", "#443C68", "#635985"],
      ["#FF5F9E", "#E90064", "#B3005E", "#060047"],
      ["#E9E8E8", "#CD5888", "#913175", "#20262E"],
      ["#B6EADA", "#5B8FB9", "#301E67", "#03001C"],
      ["#CD0404", "#FF0032", "#6F1AB6", "#3D1766"],
      ["#1A0000", "#58287F", "#89C4E1", "#F5EDCE"],
      ["#460C68", "#7F167F", "#CB1C8D", "#F56EB3"],
      ["#F2F7A1", "#46C2CB", "#6D67E4", "#453C67"],
      ["#E5B8F4", "#C147E9", "#810CA8", "#2D033B"],
      ["#0002A1", "#332FD0", "#FB2576", "#3F0071"],
      ["#F06292", "#D23369", "#9C254D", "#2A3990"],
      ["#A3C7D6", "#9F73AB", "#624F82", "#3F3B6C"],
      ["#EEEEEE", "#DC5F00", "#CF0A0A", "#000000"],
      ["#EAEAEA", "#B2B2B2", "#3C4048", "#00ABB3"],
      ["#FB2576", "#3F0071", "#150050", "#000000"],
      ["#000000", "#5C2E7E", "#4C6793", "#8BBCCC"],
      ["#EEEEEE", "#ADDDD0", "#87A2FB", "#6F38C5"],
      ["#FF7777", "#D800A6", "#400D51", "#31E1F7"],
      ["#F2F2F2", "#5CB8E4", "#8758FF", "#181818"],
      ["#E94560", "#533483", "#0F3460", "#16213E"],
      ["#FFC23C", "#FA2FB5", "#31087B", "#100720"],
      ["#FFF9D7", "#EE81B3", "#810955", "#610C63"],
      ["#FAF3E3", "#FF9F29", "#1A4D2E", "#000000"],
      ["#DFF6FF", "#47B5FF", "#1363DF", "#06283D"],
      ["#EEEEEE", "#34B3F1", "#000000", "#F15412"],
      ["#F806CC", "#A91079", "#570A57", "#2E0249"],
      ["#7F8487", "#413F42", "#16003B", "#F73D93"],
      ["#F32424", "#F2F2F2", "#9772FB", "#764AF1"],
      ["#FF7700", "#E04D01", "#2A2550", "#251D3A"],
      ["#363062", "#4D4C7D", "#827397", "#E9D5DA"],
      ["#FCD900", "#E8630A", "#035397", "#001E6C"],
      ["#EEEEEE", "#DDDDDD", "#FF8C32", "#06113C"],
      ["#EEEEEE", "#00092C", "#B20600", "#FF5F00"],
      ["#F582A7", "#F10086", "#711A75", "#180A0A"],
      ["#CAF0F8", "#90E0EF", "#00B4D8", "#03045E"],
      ["#FA58B6", "#7A0BC0", "#270082", "#1A1A40"],
      ["#EEEEEE", "#DA1212", "#11468F", "#041562"],
      ["#FFC600", "#E900FF", "#5800FF", "#000000"],
      ["#FFBCD1", "#CE7BB0", "#A267AC", "#6867AC"],
      ["#AEFEFF", "#4FBDBA", "#35858B", "#072227"],
      ["#CFFFDC", "#93FFD8", "#548CFF", "#7900FF"],
      ["#FC9918", "#F14A16", "#35589A", "#370665"],
      ["#FF5677", "#B958A5", "#9145B6", "#4C3F91"],
      ["#EC255A", "#FAEDF0", "#292C6D", "#161853"],
      ["#160040", "#4C0070", "#79018C", "#9A0680"],
      ["#B8E4F0", "#98BAE7", "#7267CB", "#6E3CBC"],
      ["#9AE66E", "#E59934", "#753188", "#2C272E"],
      ["#B000B9", "#FF5F7E", "#FFAB4C", "#142F43"],
      ["#EEEEEE", "#BC8CF2", "#AA14F0", "#000000"],
      ["#E9A6A6", "#864879", "#3F3351", "#1F1D36"],
      ["#99DDCC", "#FF5DA2", "#9C19E0", "#000D6B"],
      ["#FF9B6A", "#FF5151", "#161E54", "#88E0EF"],
      ["#B2F9FC", "#D5D5D5", "#9D84B7", "#091353"],
      ["#FF0000", "#950101", "#3D0000", "#000000"],
      ["#000000", "#082032", "#334756", "#F0A500"],
      ["#FFF9B2", "#ECAC5D", "#B24080", "#3F0713"],
      ["#FEC260", "#A12568", "#3B185F", "#2A0944"],
      ["#B5FFD9", "#AE00FB", "#3E00FF", "#170055"],
      ["#FFE459", "#F43B86", "#3D087B", "#11052C"],
    ],
  },
  {
    name: "spring",
    palettes: [
      ["#FFF9BF", "#FDDBBB", "#F0C1E1", "#CB9DF0"],
      ["#FFCCEA", "#FFF6E3", "#CDC1FF", "#BFECFF"],
      ["#FF77B7", "#FFA24C", "#FEEC37", "#B1D690"],
      ["#C1CFA1", "#A5B68D", "#EDE8DC", "#E7CCCC"],
      ["#CCE0AC", "#F0EAAC", "#F4DEB3", "#FF8A8A"],
      ["#EECAD5", "#F1D3CE", "#F6EACB", "#D1E9F6"],
      ["#FEFAE0", "#FAEDCE", "#E0E5B6", "#CCD5AE"],
      ["#FF7EE2", "#FFA38F", "#FFD18E", "#E9FF97"],
      ["#DA7297", "#FFB4C2", "#FDFFD2", "#667BC6"],
      ["#B1AFFF", "#BBE9FF", "#FFFED3", "#FFE9D0"],
      ["#78ABA8", "#C8CFA0", "#FCDC94", "#EF9C66"],
      ["#F075AA", "#BC5A94", "#ADD899", "#FFDE95"],
      ["#97BE5A", "#FFE8C5", "#FFA27F", "#FF0000"],
      ["#FF70AB", "#FFAF61", "#FFDB5C", "#C3FF93"],
      ["#A3D8FF", "#94FFD8", "#FDFFC2", "#FF76CE"],
      ["#A1C398", "#C6EBC5", "#FEFDED", "#FA7070"],
      ["#F7418F", "#FC819E", "#FEC7B4", "#FFF3C7"],
      ["#F1EF99", "#EBC49F", "#D37676", "#B0C5A4"],
      ["#F4538A", "#FAA300", "#F5DD61", "#59D5E0"],
      ["#FF8080", "#FFCF96", "#F6FDC3", "#CDFADB"],
      ["#B7E5B4", "#FFFC9B", "#FFA447", "#F28585"],
      ["#FFEAA7", "#FFBB64", "#FF6868", "#DCFFB7"],
      ["#E6A4B4", "#F3D7CA", "#FFF8E3", "#F5EEE6"],
      ["#FDFFAB", "#FFCF81", "#FFB996", "#D9EDBF"],
      ["#DC84F3", "#E9A8F2", "#F3CCF3", "#FFE7C1"],
      ["#F6D6D6", "#F6F7C4", "#A1EEBD", "#7BD3EA"],
      ["#8ADAB2", "#D0F288", "#F8FFD2", "#DF826C"],
      ["#508D69", "#9ADE7B", "#EEF296", "#FF8F8F"],
      ["#89B9AD", "#C7DCA7", "#FFEBD8", "#FFC5C5"],
      ["#FA7070", "#557C55", "#A6CF98", "#F2FFE9"],
      ["#A2C579", "#D2DE32", "#FFFFDD", "#61A3BA"],
      ["#FFF8C9", "#DFCCFB", "#D0BFFF", "#BEADFA"],
      ["#CDFAD5", "#F6FDC3", "#FFCF96", "#FF8080"],
      ["#EDB7ED", "#82A0D8", "#8DDFCB", "#ECEE81"],
      ["#A6FF96", "#F8FF95", "#BC7AF9", "#FFA1F5"],
      ["#F4EEEE", "#FFB7B7", "#FFDBAA", "#96C291"],
      ["#FFBFBF", "#FFE5E5", "#F3FDE8", "#A8DF8E"],
      ["#FEBBCC", "#FFCCCC", "#FFDDCC", "#FFEECC"],
      ["#FFECAF", "#FFB07F", "#FF52A2", "#F31559"],
      ["#CCEEBC", "#8CC0DE", "#FFD9C0", "#FAF0D7"],
      ["#CBFFA9", "#FFFEC4", "#FFD6A5", "#FF9B9B"],
      ["#FF2171", "#FF90BB", "#FFE4A7", "#FFFAD7"],
      ["#FFECEC", "#FFBDF7", "#E1AEFF", "#FF78C4"],
      ["#FF0060", "#F6FA70", "#00DFA2", "#0079FF"],
      ["#F2BED1", "#FDCEDF", "#F8E8EE", "#F9F5F6"],
      ["#F6C391", "#E08E6D", "#D25380", "#FFFAF4"],
      ["#FFF9DE", "#FFD3B0", "#FF6969", "#A6D0DD"],
      ["#F6BA6F", "#6DA9E4", "#ADE4DB", "#FFEBEB"],
      ["#98D8AA", "#F3E99F", "#F7D060", "#FF6D60"],
      ["#FDF7C3", "#FFDEB4", "#FFB4B4", "#B2A4FF"],
      ["#D864A9", "#EBB02D", "#F7DB6A", "#7AA874"],
      ["#C04A82", "#DC8449", "#FDD36A", "#BE6DB7"],
      ["#95BDFF", "#B4E4FF", "#DFFFD8", "#F7C8E0"],
      ["#F6E6C2", "#FFAACF", "#EA8FEA", "#B9F3E4"],
      ["#C1AEFC", "#D1FFF3", "#BEF0CB", "#F6F7C1"],
      ["#FCC2FC", "#C9F4AA", "#E5FDD1", "#B5F1CC"],
      ["#30E3DF", "#FCE22A", "#F94A29", "#D61355"],
      ["#AACB73", "#CDE990", "#FFFFE8", "#FFD4D4"],
      ["#FBF1D3", "#FADA9D", "#E3ACF9", "#C780FA"],
      ["#FAAB78", "#FFD495", "#FFFBAC", "#D7E9B9"],
      ["#86C8BC", "#CEEDC7", "#FFF6BD", "#FFD4B2"],
      ["#B6E2A1", "#FFFBC1", "#FEBE8C", "#F7A4A4"],
      ["#FF8DC7", "#FFACC7", "#FFDDD2", "#FFB9B9"],
      ["#BCE29E", "#E5EBB2", "#F8C4B4", "#FF8787"],
      ["#B1AFFF", "#B8E8FC", "#C8FFD4", "#FDFDBD"],
      ["#FAF4B7", "#ECC5FB", "#F9F9F9", "#CDF0EA"],
      ["#C3FF99", "#E0D98C", "#F7A76C", "#EC7272"],
      ["#89CFFD", "#FBDF07", "#FF7F3F", "#F94892"],
      ["#B2A4FF", "#FFB4B4", "#FFDEB4", "#FFF9CA"],
      ["#F7EDDB", "#DFE8CC", "#DAE2B6", "#CCD6A6"],
      ["#F37878", "#FAD9A1", "#F9F9C5", "#D9F8C4"],
      ["#FFDCAE", "#CED89E", "#ADCF9F", "#76BA99"],
      ["#C499BA", "#FFFFDE", "#F4E06D", "#FF7396"],
      ["#FAF4B7", "#F6C6EA", "#F9F9F9", "#CDF0EA"],
      ["#FF5D5D", "#FF8C8C", "#FFC3C3", "#FFE3A9"],
      ["#97C4B8", "#CCF3EE", "#F9F3EE", "#F9CEEE"],
      ["#187498", "#36AE7C", "#F9D923", "#EB5353"],
      ["#FFA1A1", "#FFD59E", "#F9FFA4", "#B4FF9F"],
      ["#FFA8A8", "#FDD7AA", "#F6FFA4", "#B6FFCE"],
      ["#C0EDA6", "#FFF7BC", "#FF8080", "#FD5D5D"],
    ],
  },
  {
    name: "summer",
    palettes: [
      ["#FF9D3D", "#FFBD73", "#FEEE91", "#C4E1F6"],
      ["#BC7C7C", "#E4C087", "#F6EFBD", "#A2D2DF"],
      ["#FFE700", "#FEFFA7", "#B6FFA1", "#00FF9C"],
      ["#FEFF9F", "#D3EE98", "#A0D683", "#72BF78"],
      ["#96CEB4", "#FFEEAD", "#FFAD60", "#A66E38"],
      ["#E78F81", "#FFCFB3", "#FFF5CD", "#B7E0FF"],
      ["#15B392", "#54C392", "#73EC8B", "#D2FF72"],
      ["#FFF4B5", "#FFD7C4", "#C4D7FF", "#87A2FF"],
      ["#FCCD2A", "#FFFBE6", "#C0EBA6", "#347928"],
      ["#8EACCD", "#DEE5D4", "#FEF9D9", "#D2E0FB"],
      ["#E8B86D", "#F1F3C2", "#CEDF9F", "#A1D6B2"],
      ["#B99470", "#FEFAE0", "#A6B37D", "#C0C78C"],
      ["#FF9100", "#FFFBE6", "#D5ED9F", "#00712D"],
      ["#387F39", "#A2CA71", "#BEDC74", "#F6E96B"],
      ["#73BBA3", "#88D66C", "#B4E380", "#F6FB7A"],
      ["#E76F51", "#F4A261", "#E9C46A", "#36BA98"],
      ["#FD9B63", "#E7D37F", "#81A263", "#365E32"],
      ["#FF7D29", "#FFBF78", "#FFEEA9", "#FEFFD2"],
      ["#5C2FC2", "#5C88C4", "#6FDCE3", "#FFFDB5"],
      ["#97BE5A", "#FFE8C5", "#FFA27F", "#FF0000"],
      ["#ACD793", "#FFE8C8", "#FFC96F", "#FFA62F"],
      ["#E6FF94", "#9DDE8B", "#40A578", "#006769"],
      ["#FF70AB", "#FFAF61", "#FFDB5C", "#C3FF93"],
      ["#5AB2FF", "#A0DEFF", "#CAF4FF", "#FFF9D0"],
      ["#FEEFAD", "#FDDE55", "#68D2E8", "#03AED2"],
      ["#7ABA78", "#0A6847", "#F6E9B2", "#F3CA52"],
      ["#75A47F", "#BACD92", "#FCFFE0", "#F5DAD2"],
      ["#ED9455", "#FFBB70", "#FFEC9E", "#FFFBDA"],
      ["#AFD198", "#E8EFCF", "#ECCA9C", "#DBA979"],
      ["#E5DDC5", "#F1EEDC", "#BED7DC", "#B3C8CF"],
      ["#7AA2E3", "#6AD4DD", "#97E7E1", "#F8F6E3"],
      ["#90D26D", "#2C7865", "#FF9800", "#D9EDBF"],
      ["#F7EEDD", "#ACE2E1", "#41C9E2", "#008DDA"],
      ["#F7F6BB", "#FCDC2A", "#87A922", "#114232"],
      ["#F2C18D", "#F6F193", "#C5EBAA", "#A5DD9B"],
      ["#416D19", "#9BCF53", "#BFEA7C", "#FFF67E"],
      ["#637A9F", "#C9D7DD", "#FFF3CF", "#E8C872"],
      ["#99BC85", "#BFD8AF", "#D4E7C5", "#E1F0DA"],
      ["#3468C0", "#86A7FC", "#FFDD95", "#FF9843"],
      ["#FDFFAB", "#FFCF81", "#FFB996", "#D9EDBF"],
      ["#597E52", "#C6A969", "#F1E4C3", "#FFFFEC"],
      ["#D2E3C8", "#86A789", "#739072", "#4F6F52"],
      ["#F2F1EB", "#EEE7DA", "#AFC8AD", "#88AB8E"],
      ["#65B741", "#C1F2B0", "#FBF6EE", "#FFB534"],
      ["#B1C381", "#EEC759", "#FFF7D4", "#9BB8CD"],
      ["#FFAD84", "#FFC47E", "#FFE382", "#FFF78A"],
      ["#FFD28F", "#FFE3BB", "#B4BDFF", "#83A2FF"],
      ["#FFEED9", "#39A7FF", "#87C4FF", "#E0F4FF"],
      ["#A2C579", "#D2DE32", "#FFFFDD", "#61A3BA"],
      ["#687EFF", "#80B3FF", "#98E4FF", "#B6FFFA"],
      ["#CDFAD5", "#F6FDC3", "#FFCF96", "#FF8080"],
      ["#5CD2E6", "#3085C3", "#F4E869", "#FAF2D3"],
      ["#22668D", "#8ECDDD", "#FFFADD", "#FFCC70"],
      ["#EBEF95", "#EFD595", "#EFB495", "#EF9595"],
      ["#A2C579", "#D2DE32", "#FFFFDD", "#016A70"],
      ["#CAEDFF", "#D8B4F8", "#FFC7EA", "#FBF0B2"],
      ["#FFBFBF", "#FFE5E5", "#F3FDE8", "#A8DF8E"],
      ["#FFD9B7", "#7EAA92", "#9ED2BE", "#C8E4B2"],
      ["#FEBBCC", "#FFCCCC", "#FFDDCC", "#FFEECC"],
      ["#C51605", "#FD8D14", "#FFE17B", "#CECE5A"],
      ["#7C9D96", "#E9B384", "#F4F2DE", "#A1CCD1"],
      ["#FBEEAC", "#F4D160", "#75C2F6", "#1D5D9B"],
      ["#CCEEBC", "#8CC0DE", "#FFD9C0", "#FAF0D7"],
      ["#F8FDCF", "#E2F6CA", "#9BE8D8", "#78C1F3"],
      ["#FAE392", "#FBD85D", "#F1C93B", "#1A5D1A"],
      ["#FDFFAE", "#E9FFC2", "#C3EDC0", "#AAC8A7"],
      ["#A1C2F1", "#5A96E3", "#0A6EBD", "#E7CEA6"],
      ["#FFEEBB", "#A7ECEE", "#99DBF5", "#9AC5F4"],
      ["#FF0060", "#F6FA70", "#00DFA2", "#0079FF"],
      ["#FBFFDC", "#D0F5BE", "#98EECC", "#79E0EE"],
      ["#2CD3E1", "#A459D1", "#F266AB", "#FFB84C"],
      ["#73A9AD", "#B3C890", "#DBDFAA", "#F5F0BB"],
      ["#617A55", "#A4D0A4", "#F7E1AE", "#FFF8D6"],
      ["#D4FAFC", "#B8E7E1", "#FFEEB3", "#9E6F21"],
      ["#DAF5FF", "#B9E9FC", "#B0DAFF", "#FEFF86"],
      ["#DFA67B", "#F4B183", "#FFD966", "#FFF2CC"],
      ["#FAEDCD", "#FEFAE0", "#E9EDC9", "#CCD5AE"],
      ["#FBFFB1", "#FFEBB4", "#FFBFA9", "#FFACAC"],
      ["#5D9C59", "#C7E8CA", "#DDF7E3", "#DF2E38"],
      ["#4C4B16", "#898121", "#E7B10A", "#F7F1E5"],
      ["#EEEEEE", "#E8D5C4", "#FFF1DC", "#3A98B9"],
      ["#F8CBA6", "#FFE7CC", "#FFFBEB", "#ECF9FF"],
      ["#E3F6FF", "#9DF1DF", "#8DCBE6", "#FFEA20"],
      ["#FFC93C", "#86E5FF", "#5BC0F8", "#0081C9"],
      ["#A3BB98", "#F0ECCF", "#FBC252", "#FFB100"],
      ["#86C8BC", "#CEEDC7", "#FFF6BD", "#FFD4B2"],
      ["#FFF8E1", "#FFE5F1", "#C0DEFF", "#ADA2FF"],
      ["#C58940", "#E5BA73", "#FAEAB1", "#FAF8F1"],
      ["#FFFBE9", "#F4EAD5", "#DAE2B6", "#CCD6A6"],
      ["#FAAB78", "#FFDCA9", "#FCF9BE", "#E8F3D6"],
      ["#B6E2A1", "#FFFBC1", "#FEBE8C", "#F7A4A4"],
      ["#379237", "#54B435", "#82CD47", "#F0FF42"],
      ["#BCE29E", "#E5EBB2", "#F8C4B4", "#FF8787"],
      ["#FDFF00", "#9CFF2E", "#38E54D", "#2192FF"],
      ["#FCFFB2", "#B6E388", "#C7F2A4", "#E1FFB1"],
      ["#FF9494", "#FFD1D1", "#FFE3E1", "#FFF5E4"],
      ["#FFEBAD", "#FFF6BF", "#ABD9FF", "#C3F8FF"],
      ["#FFEEAF", "#E1FFEE", "#A5F1E9", "#7FBCD2"],
      ["#FFC090", "#F7F6DC", "#B1D7B4", "#7FB77E"],
      ["#FFF4CF", "#FFCB42", "#FFB200", "#277BC0"],
      ["#CFE8A9", "#FFFDE3", "#E64848", "#C21010"],
      ["#3120E0", "#3B9AE1", "#21E1E1", "#F0EABE"],
      ["#B2A4FF", "#FFB4B4", "#FFDEB4", "#FFF9CA"],
      ["#F6F6F6", "#F2DF3A", "#3AB4F2", "#0078AA"],
      ["#73A9AD", "#90C8AC", "#C4DFAA", "#F5F0BB"],
      ["#FFDCAE", "#CED89E", "#ADCF9F", "#76BA99"],
      ["#4CACBC", "#6CC4A1", "#A0D995", "#F6E3C5"],
      ["#D61C4E", "#F77E21", "#FAC213", "#FEF9A7"],
      ["#FF5D5D", "#FF8C8C", "#FFC3C3", "#FFE3A9"],
      ["#D5D8B5", "#CC9C75", "#A25B5B", "#809A6F"],
      ["#8CC0DE", "#FAF0D7", "#FFD9C0", "#F4BFBF"],
      ["#FFD24C", "#FFE69A", "#FFFFFF", "#92B4EC"],
      ["#82954B", "#BABD42", "#EFD345", "#FFEF82"],
      ["#9FB4FF", "#99FFCD", "#FFF56D", "#FFD36E"],
      ["#F4BBBB", "#F1E1A6", "#C3E5AE", "#97DBAE"],
      ["#B5FE83", "#FFE61B", "#FFB72B", "#F7F7F7"],
      ["#470D21", "#9C0F48", "#D67D3E", "#F9E4D4"],
      ["#FFCBCB", "#E7FBBE", "#FFFDDE", "#D9D7F1"],
      ["#FED1EF", "#1C6DD0", "#A3E4DB", "#FFF8F3"],
      ["#F2F013", "#2FDD92", "#34BE82", "#2F86A6"],
    ],
  },
  {
    name: "sunset",
    palettes: [
      ["#FFF9BF", "#FDDBBB", "#F0C1E1", "#CB9DF0"],
      ["#FFB0B0", "#FFD09B", "#FFECC8", "#FFF7D1"],
      ["#E6D9A2", "#CB80AB", "#8967B3", "#624E88"],
      ["#FFEBD4", "#FFC6C6", "#F7B5CA", "#F0A8D0"],
      ["#B60071", "#E4003A", "#EB5B00", "#FFB200"],
      ["#FFDA78", "#FF7F3E", "#2A629A", "#003285"],
      ["#F8D082", "#E65C19", "#B51B75", "#640D6B"],
      ["#FFF8DC", "#F7C566", "#DC6B19", "#6C0345"],
      ["#673F69", "#D74B76", "#FB6D48", "#FFAF45"],
      ["#EEA5A6", "#E493B3", "#B784B7", "#8E7AB5"],
      ["#3468C0", "#86A7FC", "#FFDD95", "#FF9843"],
      ["#FFE5E5", "#E0AED0", "#AC87C5", "#756AB6"],
      ["#FFA732", "#EF4040", "#C21292", "#711DB0"],
      ["#FFAD84", "#FFC47E", "#FFE382", "#FFF78A"],
      ["#FFFB73", "#FFA33C", "#B15EFF", "#3D30A2"],
      ["#EBEF95", "#EFD595", "#EFB495", "#EF9595"],
      ["#F2ECBE", "#E2C799", "#C08261", "#9A3B3B"],
      ["#CAEDFF", "#D8B4F8", "#FFC7EA", "#FBF0B2"],
      ["#FFBA86", "#F6635C", "#C23373", "#79155B"],
      ["#916DB3", "#E48586", "#FCBAAD", "#FDE5EC"],
      ["#FEBBCC", "#FFCCCC", "#FFDDCC", "#FFEECC"],
      ["#FFECEC", "#FFBDF7", "#E1AEFF", "#FF78C4"],
      ["#FFE7CE", "#FFCDA8", "#FFAAC9", "#E4A5FF"],
      ["#F9E0BB", "#FFC26F", "#C38154", "#884A39"],
      ["#F6FFA6", "#F3BCC8", "#E893CF", "#9376E0"],
      ["#FFE569", "#F79327", "#DB005B", "#B70404"],
      ["#DEDEA7", "#F2B6A0", "#E06469", "#BE5A83"],
      ["#F79540", "#FC4F00", "#B71375", "#8B1874"],
      ["#9384D1", "#C9A7EB", "#ECC9EE", "#FFDCB6"],
      ["#FDF7C3", "#FFDEB4", "#FFB4B4", "#B2A4FF"],
      ["#FFDEB9", "#FE6244", "#FC2947", "#7149C6"],
      ["#FBFFB1", "#FFEBB4", "#FFBFA9", "#FFACAC"],
      ["#FFC3A1", "#F0997D", "#D3756B", "#A75D5D"],
      ["#FFBABA", "#C85C8E", "#9D3C72", "#7B2869"],
      ["#FFADBC", "#D989B5", "#975C8D", "#863A6F"],
      ["#B01E68", "#DC3535", "#F49D1A", "#FFE15D"],
      ["#FF97C1", "#FF5858", "#E0144C", "#9A1663"],
      ["#FFD372", "#FF99D7", "#D58BDD", "#905E96"],
      ["#9C2C77", "#CD104D", "#E14D2A", "#FD841F"],
      ["#FEE0C0", "#FF7C7C", "#B9005B", "#820000"],
      ["#FFF8BC", "#FFE898", "#FF87B2", "#F65A83"],
      ["#FFF80A", "#FEB139", "#D61C4E", "#293462"],
      ["#FFC18E", "#CA4E79", "#7A4069", "#513252"],
      ["#FFF9D7", "#EE81B3", "#810955", "#610C63"],
      ["#E7AB79", "#B25068", "#774360", "#4C3A51"],
      ["#D61C4E", "#F77E21", "#FAC213", "#FEF9A7"],
      ["#F7D716", "#EC9B3B", "#F24C4C", "#293462"],
      ["#F6F54D", "#FEB139", "#F55353", "#143F6B"],
      ["#A63EC5", "#CE49BF", "#F190B7", "#FBD6D2"],
      ["#874356", "#C65D7B", "#F68989", "#F6E7D8"],
      ["#FC4F4F", "#FFBC80", "#FF9F45", "#F76E11"],
      ["#FF5677", "#B958A5", "#9145B6", "#4C3F91"],
      ["#F0D290", "#DE834D", "#A3423C", "#781D42"],
      ["#F4E185", "#F3950D", "#CD1818", "#0F2C67"],
      ["#FFF9B2", "#ECAC5D", "#B24080", "#3F0713"],
      ["#FFA900", "#FF7600", "#CD113B", "#52006A"],
      ["#3B14A7", "#AC66CC", "#F6B8B8", "#FFED99"],
      ["#FEDDBE", "#FFC947", "#185ADB", "#0A1931"],
      ["#FC5404", "#F98404", "#F9B208", "#F7FD04"],
      ["#583D72", "#9F5F80", "#FF8474", "#FFC996"],
      ["#FF5200", "#FA9905", "#F21170", "#72147E"],
      ["#FF6701", "#FEA82F", "#FFC288", "#FCECDD"],
      ["#1B1A17", "#A35709", "#FF8303", "#F0E3CA"],
      ["#FFEFCF", "#FF7A00", "#D44000", "#864000"],
      ["#310B0B", "#9C3D54", "#E2703A", "#EEB76B"],
      ["#046582", "#6E7582", "#BB8082", "#F39189"],
      ["#AC0D0D", "#F48B29", "#F0C929", "#FBE6C2"],
      ["#D49D42", "#D97642", "#C15050", "#693C72"],
      ["#FFF600", "#FF005C", "#810034", "#26001B"],
      ["#FFBE0F", "#FA1E0E", "#BD2000", "#8C0000"],
      ["#FDF1D6", "#C39E5C", "#DA723C", "#91091E"],
      ["#FFDF91", "#EAAC7F", "#91684A", "#493323"],
      ["#121013", "#4D375D", "#EB596E", "#FFE227"],
      ["#F6C065", "#55B3B1", "#AF0069", "#09015F"],
      ["#FF8E71", "#FFBA93", "#9F5F80", "#583D72"],
      ["#FFF5C0", "#FFB396", "#FF8585", "#FF4646"],
      ["#93ABD3", "#EDF285", "#FD8C04", "#EC5858"],
      ["#646464", "#FF9642", "#FFE05D", "#FFF8CD"],
      ["#B2DEEC", "#FADCAA", "#C56183", "#794C74"],
      ["#6A2C70", "#B83B5E", "#F08A5D", "#EEECDA"],
      ["#FFBD69", "#F37121", "#C70039", "#111D5E"],
      ["#E8EAD3", "#FBD46D", "#FF9C71", "#654062"],
      ["#562349", "#AD6989", "#FFA299", "#FEE2B3"],
      ["#FFDCB4", "#C060A1", "#6A097D", "#00005C"],
      ["#FF5733", "#C70039", "#900C3F", "#511845"],
      ["#E7D39F", "#FB7B6B", "#D7385E", "#522D5B"],
      ["#FFD868", "#F8615A", "#B80D57", "#721B65"],
      ["#FFBD69", "#FE346E", "#B21F66", "#381460"],
      ["#6F5A7E", "#CD6684", "#FF677D", "#FFAE8F"],
      ["#D15A7C", "#EE8972", "#F7D695", "#F6EEC7"],
      ["#F8C3AF", "#FEA5AD", "#EA9ABB", "#BE8ABF"],
      ["#FFBD69", "#FE346E", "#B21F66", "#111D5E"],
      ["#FFA372", "#ED6663", "#B52B65", "#621055"],
      ["#F6DA63", "#EB8242", "#DA2D2D", "#9D0B0B"],
      ["#5B0909", "#940A37", "#E26241", "#FFD369"],
      ["#F1BC31", "#E25822", "#B22222", "#7C0A02"],
      ["#FBE555", "#FB9224", "#F45905", "#2A1A5E"],
      ["#ECCD8F", "#CC6A87", "#8D448B", "#5026A7"],
      ["#F6D365", "#E15249", "#C83660", "#3A1F5D"],
      ["#FFD692", "#E16363", "#943855", "#6E2142"],
      ["#FFB961", "#F3826F", "#C05C7E", "#2D3561"],
      ["#FFBD39", "#E4007C", "#930077", "#090088"],
      ["#FFF1C1", "#FE5F55", "#A64942", "#293462"],
      ["#FFE26F", "#E4734F", "#CC4165", "#6D3580"],
      ["#FFC300", "#FF5733", "#C70039", "#900C3F"],
      ["#FFE98A", "#E61C5D", "#930077", "#3A0088"],
      ["#F3CF7A", "#BE6A15", "#AC3F21", "#6E3B3B"],
      ["#FBD5AF", "#E76838", "#DB2D43", "#A8026F"],
      ["#7C064D", "#F12D2D", "#F79F24", "#FFEED0"],
      ["#FFFF9D", "#FFB480", "#F18A9B", "#A26EA1"],
      ["#FBF9AF", "#E76838", "#DB2D43", "#A8026F"],
      ["#A74FAF", "#DB456F", "#FF9668", "#F5E965"],
      ["#520556", "#8B104E", "#CA431D", "#FF9900"],
      ["#FEFF89", "#FF9F68", "#F85959", "#7C203A"],
      ["#8A79AF", "#D38CAD", "#FFD2A5", "#FFFFC1"],
      ["#FDE8CB", "#FF9797", "#B72A67", "#360982"],
      ["#EDE862", "#FA9856", "#F27370", "#22559C"],
      ["#FFF5A5", "#FFAA64", "#FF8264", "#FF6464"],
      ["#FFC300", "#EC610A", "#A40A3C", "#6B0848"],
      ["#F7B236", "#E7455F", "#D80E70", "#5628B4"],
    ],
  },
  {
    name: "vintage",
    palettes: [
      ["#EEE2B5", "#D7B26D", "#CA7373", "#3C552D"],
      ["#FFDDAE", "#FBFBFB", "#D4F6FF", "#C6E7FF"],
      ["#F6FCDF", "#859F3D", "#31511E", "#1A1A19"],
      ["#F87A53", "#E6C767", "#898121", "#4C4B16"],
      ["#536493", "#D4BDAC", "#FFF1DB", "#88C273"],
      ["#54473F", "#9A7E6F", "#CBD2A4", "#E9EED9"],
      ["#FFE6A5", "#FFBF61", "#8ABFA3", "#605678"],
      ["#CB6040", "#FD8B51", "#F2E5BF", "#257180"],
      ["#DA8359", "#FCFAEE", "#ECDFCC", "#A5B68D"],
      ["#705C53", "#B7B7B7", "#F5F5F7", "#EDDFE0"],
      ["#4A4947", "#B17457", "#D8D2C2", "#FAF7F0"],
      ["#C1CFA1", "#A5B68D", "#EDE8DC", "#E7CCCC"],
      ["#3B3030", "#664343", "#795757", "#FFF0D1"],
      ["#7EACB5", "#FFF4EA", "#FADFA1", "#C96868"],
      ["#81DAE3", "#6CBEC7", "#825B32", "#654520"],
      ["#185519", "#E8B86D", "#FCDE70", "#F5F7F8"],
      ["#EAE4DD", "#E1D7C6", "#CDC2A5", "#295F98"],
      ["#674636", "#AAB396", "#F7EED3", "#FFF8E8"],
      ["#603F26", "#6C4E31", "#FFDBB5", "#FFEAC5"],
      ["#6A9C89", "#C1D8C3", "#FFF5E4", "#CD5C08"],
      ["#B99470", "#FEFAE0", "#A6B37D", "#C0C78C"],
      ["#F2E8C6", "#DAD4B5", "#982B1C", "#800000"],
      ["#C5705D", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#E7E8D8", "#CADABF", "#B5CFB7", "#BC9F8B"],
      ["#5F6F65", "#808D7C", "#9CA986", "#C9DABF"],
      ["#606676", "#708871", "#BEC6A0", "#FEF3E2"],
      ["#536493", "#D4BDAC", "#FFF1DB", "#EF5A6F"],
      ["#FF7777", "#FFAAAA", "#ECFFE6", "#399918"],
      ["#FFF8F3", "#F7E7DC", "#758694", "#405D72"],
      ["#698474", "#FCF8F3", "#FFD3B6", "#DCA47C"],
      ["#2F3645", "#939185", "#E6B9A6", "#EEEDEB"],
      ["#DA7297", "#FFB4C2", "#FDFFD2", "#667BC6"],
      ["#729762", "#658147", "#597445", "#E7F0DC"],
      ["#B6C7AA", "#F6E6CB", "#E7D4B5", "#A0937D"],
      ["#FFCBCB", "#FFB1B1", "#1679AB", "#102C57"],
      ["#005C78", "#006989", "#F3F7EC", "#E88D67"],
      ["#F9F9E0", "#FF9EAA", "#FFD0D0", "#3AA6B9"],
      ["#DAD3BE", "#B7B597", "#6B8A7A", "#254336"],
      ["#987070", "#C39898", "#DBB5B5", "#F1E5D1"],
      ["#FEAE6F", "#F6DCAC", "#028391", "#01204E"],
      ["#EE4E4E", "#F6EEC9", "#A1DD70", "#799351"],
      ["#543310", "#74512D", "#AF8F6F", "#F8F4E1"],
      ["#F97300", "#E2DFD0", "#524C42", "#32012F"],
      ["#F5EFE6", "#E8DFCA", "#4F6F52", "#1A4D2E"],
      ["#DFD0B8", "#948979", "#3C5B6F", "#153448"],
      ["#322C2B", "#803D3B", "#AF8260", "#E4C59E"],
      ["#102C57", "#DAC0A3", "#EADBC8", "#FEFAF6"],
      ["#8B322C", "#DD5746", "#FFC470", "#4793AF"],
      ["#AFD198", "#E8EFCF", "#ECCA9C", "#DBA979"],
      ["#FFF2E1", "#EAD8C0", "#D1BB9E", "#A79277"],
      ["#A1C398", "#C6EBC5", "#FEFDED", "#FA7070"],
      ["#9CAFAA", "#D6DAC8", "#FBF3D5", "#EFBC9B"],
      ["#EEEEEE", "#DDDDDD", "#76885B", "#627254"],
      ["#EEE4B1", "#8C6A5D", "#5F374B", "#430A5D"],
      ["#EEEEEE", "#76ABAE", "#31363F", "#222831"],
      ["#F1EF99", "#EBC49F", "#D37676", "#B0C5A4"],
      ["#FFC374", "#F9E897", "#7F9F80", "#124076"],
      ["#F6995C", "#51829B", "#9BB0C1", "#EADFB4"],
      ["#F6F5F5", "#FFE3CA", "#EE99C2", "#0C359E"],
      ["#EED3D9", "#F5E8DD", "#CCD3CA", "#B5C0D0"],
      ["#EEA5A6", "#E493B3", "#B784B7", "#8E7AB5"],
      ["#B67352", "#ECB159", "#FEFBF6", "#8CB9BD"],
      ["#EEEEEE", "#A3C9AA", "#C68484", "#9B4444"],
      ["#BED1CF", "#E78895", "#FFE4C9", "#FFF7F1"],
      ["#F2EFE5", "#E3E1D9", "#C7C8CC", "#B4B4B8"],
      ["#3C3633", "#747264", "#E0CCBE", "#EEEDEB"],
      ["#638889", "#9DBC98", "#EBD9B4", "#F9EFDB"],
      ["#A87C7C", "#7E6363", "#503C3C", "#3E3232"],
      ["#FDFFAB", "#FFCF81", "#FFB996", "#D9EDBF"],
      ["#F6B17A", "#7077A1", "#424769", "#2D3250"],
      ["#76453B", "#B19470", "#F8FAE5", "#43766C"],
      ["#D2E3C8", "#86A789", "#739072", "#4F6F52"],
      ["#820300", "#B80000", "#FF9800", "#5F8670"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#B2A59B", "#DED0B6", "#FAEED1", "#607274"],
      ["#EE7214", "#F7B787", "#F9E8D9", "#527853"],
      ["#FFEFE8", "#F8DFD4", "#C69774", "#637E76"],
      ["#B99470", "#FEFAE0", "#A9B388", "#5F6F52"],
      ["#89B9AD", "#C7DCA7", "#FFEBD8", "#FFC5C5"],
      ["#FAF6F0", "#F4EAE0", "#F4DFC8", "#000000"],
      ["#F3F3F3", "#A3B763", "#AF2655", "#860A35"],
      ["#9FBB73", "#F1EB90", "#F3B664", "#EC8F5E"],
      ["#776B5D", "#B0A695", "#EBE3D5", "#F3EEEA"],
      ["#FF5B22", "#FF9130", "#FECDA6", "#A9A9A9"],
      ["#3A4D39", "#4F6F52", "#739072", "#ECE3CE"],
      ["#F6F1EE", "#4F4A45", "#6C5F5B", "#ED7D31"],
      ["#D6C7AE", "#BFB29E", "#B3A492", "#DADDB1"],
      ["#F3F0CA", "#E1AA74", "#3876BF", "#192655"],
      ["#555843", "#D0D4CA", "#F5EEC8", "#A7D397"],
      ["#6A9C89", "#C1D8C3", "#F5E8B7", "#CD5C08"],
      ["#FDF0F0", "#F1B4BB", "#1F4172", "#132043"],
      ["#113946", "#BCA37F", "#EAD7BB", "#FFF2D8"],
      ["#FDE5D4", "#D6CC99", "#445D48", "#001524"],
      ["#B2533E", "#FCE09B", "#B5CB99", "#186F65"],
      ["#D83F31", "#EE9322", "#E9B824", "#219C90"],
      ["#191717", "#7D7C7C", "#CCC8AA", "#F1EFEF"],
      ["#E55604", "#26577C", "#B4B4B3", "#EBE4D1"],
      ["#FFCF9D", "#FFB000", "#F5F5DC", "#004225"],
      ["#0F2C59", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#E5D283", "#4F709C", "#213555", "#F0F0F0"],
      ["#A2C579", "#D2DE32", "#FFFFDD", "#016A70"],
      ["#FFEEF4", "#E4E4D0", "#AEC3AE", "#94A684"],
      ["#F2ECBE", "#E2C799", "#C08261", "#9A3B3B"],
      ["#F4EEEE", "#FFB7B7", "#FFDBAA", "#96C291"],
      ["#435334", "#9EB384", "#CEDEBD", "#FAF1E4"],
      ["#3F1D38", "#4D3C77", "#A2678A", "#E19898"],
      ["#F1F0E8", "#EEE0C9", "#ADC4CE", "#96B6C5"],
      ["#102C57", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#F7E987", "#5B9A8B", "#445069", "#252B48"],
      ["#9E9FA5", "#C4C1A4", "#FFF6DC", "#FFC6AC"],
      ["#65451F", "#765827", "#C8AE7D", "#EAC696"],
      ["#916DB3", "#E48586", "#FCBAAD", "#FDE5EC"],
      ["#CEE6F3", "#DFA878", "#BA704F", "#6C3428"],
      ["#8C3333", "#557A46", "#7A9D54", "#F2EE9D"],
      ["#7C9D96", "#E9B384", "#F4F2DE", "#A1CCD1"],
      ["#EFE1D1", "#A78295", "#3F2E3E", "#331D2C"],
      ["#FFC95F", "#FFF9C9", "#862B0D", "#B5C99A"],
      ["#3F2305", "#DFD7BF", "#F2EAD3", "#F5F5F5"],
      ["#F7F1E5", "#E7B10A", "#898121", "#4C4B16"],
      ["#EAB2A0", "#A76F6F", "#435B66", "#2D4356"],
      ["#85A389", "#A2CDB0", "#FFD89C", "#F1C27B"],
      ["#FFDEDE", "#FF8551", "#9BCDD2", "#FAF0E4"],
      ["#F1C376", "#F7E6C4", "#FFF4F4", "#606C5D"],
      ["#213555", "#4F709C", "#D8C4B6", "#F5EFE7"],
      ["#F9E0BB", "#FFC26F", "#C38154", "#884A39"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#9BABB8"],
      ["#CD1818", "#4E3636", "#321E1E", "#116D6E"],
      ["#374259", "#545B77", "#5C8984", "#F2D8D8"],
      ["#EEEEEE", "#7A3E3E", "#482121", "#47A992"],
      ["#F8F1F1", "#E8AA42", "#E57C23", "#025464"],
      ["#E7CBCB", "#C88EA7", "#99627A", "#643843"],
      ["#EEEEEE", "#E1D4BB", "#CBB279", "#537188"],
      ["#FFF8DE", "#D6E8DB", "#C1D0B5", "#99A98F"],
      ["#8BACAA", "#B04759", "#E76161", "#F99B7D"],
      ["#DEDEA7", "#F2B6A0", "#E06469", "#BE5A83"],
      ["#D4FAFC", "#B8E7E1", "#FFEEB3", "#9E6F21"],
      ["#7C9070", "#9CA777", "#FEE8B0", "#F97B22"],
      ["#F6BA6F", "#6DA9E4", "#ADE4DB", "#FFEBEB"],
      ["#EDC6B1", "#B7B7B7", "#7C96AB", "#BFCCB5"],
      ["#E86A33", "#263A29", "#41644A", "#F2E3DB"],
      ["#F3E8FF", "#BACDDB", "#CE5959", "#89375F"],
      ["#675D50", "#ABC4AA", "#F3DEBA", "#A9907E"],
      ["#E3CCAE", "#B8621B", "#262A56", "#000000"],
      ["#002B5B", "#EA5455", "#E4DCCF", "#F9F5EB"],
      ["#F1DEC9", "#C8B6A6", "#A4907C", "#8D7B68"],
      ["#617143", "#DF7857", "#E7AB9A", "#EDE9D5"],
      ["#7A3E65", "#A84448", "#E9A178", "#F6E1C3"],
      ["#4C4B16", "#898121", "#E7B10A", "#F7F1E5"],
      ["#F5FFC9", "#B3E5BE", "#A86464", "#804674"],
      ["#7DB9B6", "#F5E9CF", "#E96479", "#4D455D"],
      ["#183A1D", "#F0A04B", "#E1EEDD", "#FEFBE9"],
      ["#EEE9DA", "#BDCDD6", "#93BFCF", "#6096B4"],
      ["#AA5656", "#F1DBBF", "#B99B6B", "#698269"],
      ["#E9E8E8", "#CD5888", "#913175", "#20262E"],
      ["#674188", "#C3ACD0", "#F7EFE5", "#FFFBF5"],
      ["#FC7300", "#BFDB38", "#1F8A70", "#00425A"],
      ["#F55050", "#F48484", "#E8D2A6", "#86A3B8"],
      ["#B05A7A", "#C27664", "#E4C988", "#84D2C5"],
      ["#FAECD6", "#F2DEBA", "#4E6C50", "#820000"],
      ["#939B62", "#FFD56F", "#FFB26B", "#FF7B54"],
      ["#F5F5DC", "#ECA869", "#B08BBB", "#B5D5C5"],
      ["#F8F4EA", "#ECE8DD", "#E1D7C6", "#579BB1"],
      ["#85586F", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#F0E9D2", "#E6DDC4", "#678983", "#181D31"],
      ["#251749", "#263159", "#495579", "#FFFBEB"],
      ["#F2E5E5", "#E8C4C4", "#CE7777", "#2B3A55"],
      ["#7F669D", "#BA94D1", "#DEBACE", "#FBFACD"],
      ["#852999", "#C539B4", "#EF9A53", "#F5D5AE"],
      ["#F7F7F7", "#F2E7D5", "#6D9886", "#393E46"],
      ["#3A8891", "#0E5E6F", "#FFEFD6", "#F2DEBA"],
      ["#FAD6A5", "#CFB997", "#9BA17B", "#61764B"],
      ["#F0EBCE", "#AA8B56", "#4E6C50", "#395144"],
      ["#594545", "#815B5B", "#9E7676", "#FFF8EA"],
      ["#665A48", "#9F8772", "#C8DBBE", "#EDE4E0"],
      ["#E26868", "#FF8787", "#EDEDED", "#D8D9CF"],
      ["#F5EFE6", "#B4CDE6", "#628E90", "#3C2317"],
      ["#EDDBC0", "#DBC8AC", "#B73E3E", "#DD5353"],
      ["#FFECEF", "#FFCACA", "#372948", "#251B37"],
      ["#7D6E83", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#EEEEEE", "#829460", "#674747", "#F96666"],
      ["#D8D8D8", "#647E68", "#562B08", "#182747"],
      ["#CC3636", "#F57328", "#367E18", "#FFE9A0"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#B7C4CF"],
      ["#874C62", "#C98474", "#F2D388", "#A7D2CB"],
      ["#A77979", "#704F4F", "#553939", "#472D2D"],
      ["#96E5D1", "#C55300", "#D07000", "#A62349"],
      ["#FFF5E1", "#FFD39A", "#319DA0", "#781C68"],
      ["#F1EFDC", "#E6D2AA", "#D36B00", "#42032C"],
      ["#A10035", "#FEC260", "#3FA796", "#2A0944"],
      ["#E4D192", "#CBA0AE", "#AF7AB3", "#80558C"],
      ["#76549A", "#DF7861", "#94B49F", "#FCF8E8"],
      ["#3D3C42", "#7F5283", "#A6D1E6", "#FEFBF6"],
      ["#8879B0", "#FBA1A1", "#FBC5C5", "#377D71"],
      ["#576F72", "#7D9D9C", "#E4DCCF", "#F0EBE3"],
      ["#DCD7C9", "#A27B5C", "#3F4E4F", "#2C3639"],
      ["#F7ECDE", "#E9DAC1", "#18978F", "#54BAB9"],
      ["#A084CF", "#9DD6DF", "#F7E2D6", "#DFBB9D"],
      ["#EDDFB3", "#D8CCA3", "#B09B71", "#87805E"],
      ["#4CACBC", "#6CC4A1", "#A0D995", "#F6E3C5"],
      ["#D6D5A8", "#816797", "#51557E", "#1B2430"],
      ["#DF7861", "#ECB390", "#94B49F", "#FCF8E8"],
      ["#243A73", "#A5BECC", "#F2EBE9", "#7C3E66"],
      ["#354259", "#CDC2AE", "#ECE5C7", "#C2DED1"],
      ["#363062", "#4D4C7D", "#827397", "#E9D5CA"],
      ["#D5D8B5", "#CC9C75", "#A25B5B", "#809A6F"],
      ["#F1F0C0", "#B7E5DD", "#B1BCE6", "#9A86A4"],
      ["#BDE6F1", "#FFE59D", "#EEB0B0", "#7D1E6A"],
      ["#F1EEE9", "#EC994B", "#73777B", "#15133C"],
      ["#D0C9C0", "#EFEAD8", "#6D8B74", "#5F7161"],
      ["#97C4B8", "#CCF3EE", "#F9F3EE", "#F9CEEE"],
      ["#82954B", "#BABD42", "#EFD345", "#FFEF82"],
      ["#A0BCC2", "#DAE5D0", "#FEFBE7", "#F9EBC8"],
      ["#CE9461", "#DEA057", "#FCFFE7", "#E0D8B0"],
      ["#85586F", "#AC7D88", "#DEB6AB", "#F8ECD1"],
      ["#FF6363", "#FAF5E4", "#F8B400", "#125B50"],
      ["#1A3C40", "#1D5C63", "#417D7A", "#EDE6DB"],
      ["#363062", "#4D4C7D", "#827397", "#E9D5DA"],
      ["#FFEBC1", "#D7A86E", "#A64B2A", "#8E3200"],
      ["#F2F2F2", "#CDBE78", "#066163", "#383838"],
      ["#A2B38B", "#E4E9BE", "#FAFDD6", "#E6BA95"],
      ["#A97155", "#BE8C63", "#E4D1B9", "#8FBDD3"],
      ["#FFF8D5", "#B4ECE3", "#8479E1", "#733C3C"],
      ["#826F66", "#C69B7B", "#F7CCAC", "#3A3845"],
      ["#1A132F", "#5B7DB1", "#61A4BC", "#F7E2E2"],
      ["#FFFBE9", "#E3CAA5", "#CEAB93", "#AD8B73"],
      ["#141E27", "#203239", "#E0DDAA", "#EEEDDE"],
      ["#CC9544", "#603601", "#361500", "#1C0A00"],
      ["#789395", "#94B49F", "#B4CFB0", "#E5E3C9"],
      ["#F1E0AC", "#98B4AA", "#74959A", "#495371"],
      ["#E9E5D6", "#ACB992", "#464E2E", "#362706"],
      ["#C8F2EF", "#C3DBD9", "#CDB699", "#BB6464"],
      ["#EFEFEF", "#D1D1D1", "#C0A080", "#7882A4"],
      ["#54BAB9", "#E9DAC1", "#F7ECDE", "#FBF8F1"],
      ["#BF9270", "#E3B7A0", "#EDCDBB", "#FFEDDB"],
      ["#FDCEB9", "#D885A3", "#7897AB", "#655D8A"],
      ["#E3BEC6", "#EFDAD7", "#9AD0EC", "#1572A1"],
      ["#ECB390", "#DD4A48", "#F5EEDC", "#C0D8C0"],
      ["#694E4E", "#886F6F", "#C1A3A3", "#F3C5C5"],
      ["#DACC96", "#BF8B67", "#9D5353", "#632626"],
      ["#980F5A", "#750550", "#570530", "#4C0027"],
      ["#FFEDED", "#FFCCD2", "#B3541E", "#362222"],
      ["#F4DFBA", "#EEC373", "#CA965C", "#876445"],
      ["#2F3A8F", "#FE7E6D", "#CCD1E4", "#FEECE9"],
      ["#EEEEEE", "#8B9A46", "#541212", "#0F0E0E"],
      ["#557C55", "#A6CF98", "#F2FFE9", "#DB6B97"],
      ["#502064", "#8267BE", "#3FA796", "#FFBD35"],
      ["#ECDBBA", "#C84B31", "#2D4263", "#191919"],
      ["#F0D290", "#DE834D", "#A3423C", "#781D42"],
      ["#FFE6BC", "#E4CDA7", "#C3B091", "#8E806A"],
      ["#F4EEA9", "#F0BB62", "#519259", "#064635"],
      ["#4F091D", "#DD4A48", "#F5EEDC", "#97BFB4"],
      ["#FC997C", "#DADDFC", "#396EB0", "#2E4C6D"],
      ["#E9C891", "#8A8635", "#AE431E", "#D06224"],
      ["#FF6D6D", "#AE4CCF", "#533535", "#E6CCA9"],
      ["#D0CAB2", "#DED9C4", "#96C7C1", "#89B5AF"],
      ["#000000", "#630000", "#D8B6A4", "#EEEBDD"],
      ["#105652", "#FBF3E4", "#DFD8CA", "#B91646"],
      ["#87AAAA", "#C8E3D4", "#F6EABE", "#F6D7A7"],
      ["#EAE7C6", "#BCCC9A", "#C37B89", "#D1E8E4"],
      ["#F9CF93", "#F9E4C8", "#FAEEE0", "#DBD0C0"],
      ["#852747", "#A2416B", "#FF7777", "#F5C6A5"],
      ["#506D84", "#889EAF", "#D4B499", "#F3D5C0"],
      ["#F1F7E7", "#E7EAB5", "#BFD8B8", "#E2C2B9"],
      ["#4B6587", "#C8C6C6", "#F7F6F2", "#F0E5CF"],
      ["#E8D0B3", "#86340A", "#C36839", "#7EB5A6"],
      ["#FFE3E3", "#E4D8DC", "#C9CCD5", "#93B5C6"],
      ["#11324D", "#6B7AA1", "#C1CFC0", "#E7E0C9"],
      ["#6F4C5B", "#9E7777", "#DEBA9D", "#F5E8C7"],
      ["#EFE3D0", "#D9CAB3", "#87A8A4", "#986D8E"],
      ["#EEEEEE", "#EFB7B7", "#BD4B4B", "#000000"],
      ["#F2E1C1", "#F6AE99", "#B97A95", "#716F81"],
      ["#515E63", "#57837B", "#C9D8B6", "#F1ECC3"],
      ["#2B2B2B", "#423F3E", "#171010", "#362222"],
      ["#FBC7F7", "#FB7AFC", "#D62AD0", "#78DEC7"],
      ["#5D534A", "#D6D2C4", "#FFF5DA", "#F7DAD9"],
      ["#EAE2B6", "#7F8B52", "#A3A847", "#558776"],
      ["#FCDEC0", "#E5B299", "#B4846C", "#7D5A50"],
      ["#ECDBBA", "#C84B31", "#346751", "#161616"],
      ["#5E454B", "#D8B384", "#F3F0D7", "#CEE5D0"],
      ["#DFEEEA", "#A7C4BC", "#5E8B7E", "#2F5D62"],
      ["#B2B8A3", "#F4C7AB", "#DEEDF0", "#FFF5EB"],
      ["#000000", "#0C4271", "#0A81AB", "#F9DFDC"],
      ["#D5DBB3", "#FFF8D9", "#BB371A", "#EBA83A"],
      ["#D1D9D9", "#F29191", "#EEC4C4", "#94D0CC"],
      ["#EDEDD0", "#A6D6D6", "#A58FAA", "#907FA4"],
      ["#DA7F8F", "#A7BBC7", "#E1E5EA", "#FAF3F3"],
      ["#FFE9D6", "#A7D0CD", "#7B6079", "#DE8971"],
      ["#E1F1DD", "#CDC7BE", "#87A7B3", "#766161"],
      ["#F6E6E4", "#E2BCB7", "#CA8A8B", "#5B6D5B"],
      ["#45526C", "#5AA897", "#F8A488", "#F8F5F1"],
      ["#9DBEB9", "#FFC2B4", "#FF8882", "#194350"],
      ["#2B4F60", "#BDC7C9", "#EAD3CB", "#845460"],
      ["#F05945", "#5EAAA8", "#A3D2CA", "#F7F3E9"],
      ["#464F41", "#56776C", "#5B8A72", "#BFCBA8"],
      ["#1B2021", "#EAC8AF", "#D8AC9C", "#5F939A"],
      ["#C06014", "#424642", "#536162", "#F3F4ED"],
      ["#B4A5A5", "#3C415C", "#301B3F", "#151515"],
      ["#C8EED9", "#4E3620", "#897853", "#F8F4E1"],
      ["#1B1717", "#630000", "#810000", "#EEEBDD"],
      ["#E2D5D5", "#BBBBBB", "#8E7F7F", "#865858"],
      ["#9FD8DF", "#FF7171", "#F5C0C0", "#F0E4D7"],
      ["#F2B4B4", "#B7657B", "#8F4F4F", "#78C4D4"],
      ["#E6D5B8", "#E45826", "#F0A500", "#4A3933"],
      ["#484018", "#5B6D5B", "#F4C983", "#E40017"],
      ["#FB743E", "#383E56", "#9FB8AD", "#C5D7BD"],
      ["#9A8194", "#C6A9A3", "#EBD8B7", "#99BBAD"],
      ["#AAAAAA", "#A4EBF3", "#CCF2F4", "#F4F9F9"],
      ["#C19065", "#B67171", "#5B5B5B", "#D8C292"],
      ["#AEE1E1", "#D3E0DC", "#ECE2E1", "#FCD1D1"],
      ["#EE99A0", "#AD6C80", "#350B40", "#94B5C0"],
      ["#E3D0B9", "#E1BC91", "#C19277", "#62959C"],
      ["#FFDF91", "#EAAC7F", "#91684A", "#493323"],
      ["#3B5360", "#8B5E83", "#D6B0B1", "#BEE5D3"],
      ["#FAD586", "#D6EFC7", "#96BB7C", "#184D47"],
      ["#E1D89F", "#D89216", "#374045", "#2C061F"],
      ["#E8EFEB", "#ADEECF", "#E9B0DF", "#295939"],
      ["#DFE0DF", "#00917C", "#433520", "#FDE8CD"],
      ["#00303F", "#CDAC81", "#CAE4DB", "#F5F4F4"],
      ["#DF7861", "#ECB390", "#D4E2D4", "#FCF8E8"],
      ["#9DAB86", "#DED7B1", "#E08F62", "#CC7351"],
      ["#83A95C", "#E9C496", "#944E6C", "#433D3C"],
      ["#A6A9B6", "#C9CBFF", "#EFF8FF", "#FFE5B9"],
      ["#0F0F0F", "#532E1C", "#C5A880", "#E6E6E6"],
      ["#EA97AD", "#045762", "#4E8D7C", "#F3F2DA"],
      ["#C6FCED", "#8AD7C1", "#825959", "#A07676"],
      ["#FFE3D8", "#BBBBBB", "#03506F", "#0A043C"],
      ["#F8F1F1", "#FFA62B", "#DB6400", "#16697A"],
      ["#707070", "#92817A", "#8DB596", "#BEDBBB"],
      ["#FFF8C1", "#FFC85C", "#C05555", "#59886B"],
      ["#C6B497", "#CFD3CE", "#839B97", "#34626C"],
      ["#DD9866", "#8F384D", "#5C6E91", "#EEEDED"],
      ["#F2EFEA", "#CBBCB1", "#AF6B58", "#556052"],
      ["#F6F5F1", "#FAE0DF", "#898B8A", "#16A596"],
      ["#F8E4B7", "#F4F4F4", "#A4B787", "#AA3A3A"],
      ["#060930", "#333456", "#595B83", "#F4ABC4"],
      ["#E6D5B8", "#99A8B2", "#1F6F8B", "#1C2B2D"],
      ["#389393", "#FA7F72", "#F5A25D", "#EBEBEB"],
      ["#BB2205", "#F6830F", "#0E918C", "#D2D3C9"],
      ["#555555", "#CDC9C3", "#FBF7F0", "#D9E4DD"],
      ["#F4D9C6", "#E5C5B5", "#D2F5E3", "#94B4A4"],
      ["#3D7EA6", "#5C969E", "#FFA5A5", "#FCDADA"],
      ["#463333", "#835858", "#EBD4D4", "#FFF0F0"],
      ["#89BEB3", "#E8E8E8", "#A6A6A4", "#625261"],
      ["#4C4C4C", "#A37EBA", "#E8E8E8", "#E5DF88"],
      ["#FFBB91", "#065C6F", "#64958F", "#FAF3DD"],
      ["#FBDCC4", "#F2A07B", "#31112C", "#7D0633"],
      ["#F4F4F4", "#F7D1BA", "#D49A89", "#557571"],
      ["#056676", "#5EAAA8", "#A3D2CA", "#E8DED2"],
      ["#E5E5E5", "#DECDC3", "#EA5455", "#2D4059"],
      ["#767C77", "#9CADA4", "#FBE2E5", "#FABEA7"],
      ["#797A7E", "#D8D3CD", "#F7F2E7", "#E0ECE4"],
      ["#D3C09A", "#DBE3E5", "#F3E6E3", "#776D8A"],
      ["#C26565", "#A35D6A", "#D9C6A5", "#F7E7BD"],
      ["#3C2946", "#56556E", "#BBD196", "#FFD571"],
      ["#005086", "#318FB5", "#F7D6BF", "#B0CAC7"],
      ["#F3F3F3", "#86C4BA", "#CEDEBD", "#411F1F"],
      ["#FCCBCB", "#D9ADAD", "#AD9D9D", "#838383"],
      ["#251F44", "#D3DBFF", "#FE91CA", "#FFE0F7"],
      ["#3F4441", "#5E6F64", "#BA7967", "#FFDECF"],
      ["#596E79", "#C7B198", "#DFD3C3", "#F0ECE3"],
      ["#D4B5B0", "#EEDAD1", "#F69E7B", "#383E56"],
      ["#E84A5F", "#FF847C", "#FECEAB", "#99B898"],
      ["#4F8A8B", "#EA907A", "#FFCB74", "#F4F6FF"],
      ["#F6DEF6", "#E5CFE5", "#CFE5CF", "#CFF6CF"],
      ["#BBF1C8", "#80BDAB", "#342B38", "#FF9595"],
      ["#E5E5E5", "#F8B24F", "#EA9A96", "#303960"],
      ["#562349", "#AD6989", "#FFA299", "#FEE2B3"],
      ["#F5A7A7", "#F9D89C", "#82C4C3", "#BC658D"],
      ["#581C0C", "#CA5116", "#F9B384", "#F1E3CB"],
      ["#D8C593", "#708160", "#DD7631", "#BB3B0E"],
      ["#F0DAA4", "#EAAC9D", "#B49C73", "#565D47"],
      ["#ECECEC", "#C1A57B", "#30475E", "#222831"],
      ["#000000", "#CF7500", "#F0A500", "#DBDBDB"],
      ["#B7EFCD", "#4CD3C2", "#363636", "#FFBCBC"],
      ["#FFEB99", "#A4C5C6", "#D4EBD0", "#856C8B"],
      ["#698474", "#FFD3B6", "#FCF8F3", "#FFAAA5"],
      ["#D9BF77", "#D8EBB5", "#639A67", "#2B580C"],
      ["#FBC490", "#CD8D7B", "#687466", "#084177"],
      ["#231903", "#7D5E2A", "#9DC6A7", "#D1F5D3"],
      ["#CFD186", "#5B8C5A", "#596157", "#552244"],
      ["#30475E", "#BA6B57", "#F1935C", "#E7B2A5"],
      ["#50BDA1", "#E85F99", "#F18867", "#65587F"],
      ["#FD2EB3", "#FB8D62", "#FDD365", "#61D4B3"],
      ["#678A74", "#F7C5A8", "#FFEADB", "#FFBABA"],
      ["#FAAFFF", "#BBCFFF", "#F0EFEF", "#192965"],
      ["#96D1C7", "#F5C3BC", "#E89DA2", "#C9485B"],
      ["#9D2503", "#DF8543", "#F1F1B0", "#7FA998"],
      ["#004445", "#2C786C", "#FAF5E4", "#F8B400"],
      ["#BAE8E8", "#E3F6F5", "#FFFFFF", "#272343"],
      ["#6FC1A5", "#FFC0AD", "#E78FB3", "#594A4E"],
      ["#EA5E5E", "#1F6650", "#6F9A8D", "#EAFBEA"],
      ["#2B2B28", "#E3B04B", "#F1D6AB", "#F8F8F8"],
      ["#D55252", "#E47312", "#94AA2A", "#E5D8BF"],
      ["#BE7575", "#F6AD7B", "#F2EEE5", "#C2E8CE"],
      ["#FFAFB0", "#FC7978", "#96D1C7", "#5EB7B7"],
      ["#DFCDC3", "#719192", "#5F6769", "#3C4245"],
      ["#FDECED", "#EFCFB6", "#6B591D", "#233714"],
      ["#F9C6BA", "#DD6892", "#3C6F9C", "#512C96"],
      ["#A4D1C8", "#537D91", "#584B42", "#F77754"],
      ["#ED7575", "#BE6283", "#40DAB2", "#B7E778"],
      ["#FF502F", "#49BEB7", "#32DBC6", "#EBEFD0"],
      ["#132743", "#407088", "#FFB5B5", "#FFCBCB"],
      ["#992E24", "#D04925", "#E9E5DD", "#696464"],
      ["#957DAD", "#D291BC", "#FEC8D8", "#FFDFD3"],
      ["#6E9086", "#FDC8B7", "#DE356A", "#540E33"],
      ["#60204B", "#ED3833", "#63AABC", "#F9F3EC"],
      ["#63707E", "#93B5B3", "#C8DAD3", "#F2F6F5"],
      ["#4E3440", "#BB7171", "#FFCBCB", "#C1F6E7"],
      ["#854777", "#A773C3", "#FFA1C5", "#FFC6BE"],
      ["#212121", "#665C84", "#71A0A5", "#FAB95B"],
      ["#8F758E", "#735372", "#313848", "#8ADFDC"],
      ["#F6F5F5", "#E3C4A8", "#4592AF", "#33313B"],
      ["#311054", "#8E2E6A", "#EE7777", "#BFCD7E"],
      ["#D25959", "#681313", "#5C7658", "#E6D385"],
      ["#9C2C2C", "#F79C1D", "#F6E79C", "#7FA99B"],
      ["#F3FF93", "#C6CF65", "#76A21E", "#560D0D"],
      ["#72D6C9", "#FFC785", "#DF7599", "#7189BF"],
      ["#705772", "#F38181", "#FAD284", "#A9EEC2"],
      ["#5E0A0A", "#5CA0D3", "#C8E6F5", "#F2EEE0"],
      ["#9764C7", "#099A97", "#15CDA8", "#F1E4E4"],
      ["#8B5D5D", "#AA7070", "#C19191", "#F0E9E9"],
      ["#6D3939", "#5CA0D3", "#C8E6F5", "#F2EEE0"],
      ["#FFC3E7", "#01676B", "#21AA93", "#00028C"],
      ["#FDC57B", "#007880", "#62374E", "#33313B"],
      ["#77628C", "#71A0A5", "#ACC6AA", "#EEEEEE"],
      ["#DEE1EC", "#EAC100", "#0B8457", "#10316B"],
      ["#2B2726", "#0A516D", "#018790", "#F77754"],
      ["#E9EEC9", "#BBBBBB", "#913535", "#283148"],
      ["#E6F5FF", "#834C69", "#3C4F65", "#33313B"],
      ["#EFDFBF", "#FFC0D0", "#FFE0E0", "#FFFAFA"],
      ["#7D7D7D", "#C5C5C5", "#FED9CA", "#FFEFE0"],
      ["#774E26", "#EBF0C2", "#9BBFAB", "#4E2161"],
      ["#5B2E35", "#34A7B2", "#F5E4C3", "#F59AA3"],
      ["#F3CF7A", "#BE6A15", "#AC3F21", "#6E3B3B"],
      ["#F89D13", "#1B120F", "#8F1D14", "#E6DEDD"],
      ["#F2F1E7", "#A96851", "#915B4A", "#5C4D4D"],
      ["#FD5F00", "#05004E", "#76B39D", "#F9F8EB"],
      ["#EFE784", "#6CBBB3", "#AE7C7C", "#3E3838"],
      ["#FFEBD3", "#264E70", "#679186", "#FFB4AC"],
      ["#6E7DA2", "#DB996C", "#AEDADD", "#FCF8F3"],
      ["#000000", "#841818", "#E0BB20", "#FAFAFA"],
      ["#C19898", "#4A4A48", "#2B2B28", "#EBEBE3"],
      ["#362207", "#5F685A", "#EA9C1B", "#F3F6C8"],
      ["#665C84", "#FF7657", "#FFBA5A", "#FBEED7"],
      ["#E0E0EC", "#DECDC3", "#5C848E", "#1D2323"],
      ["#625757", "#9D8F8F", "#BCBAB8", "#F9F9F9"],
      ["#4A0E5C", "#7C4789", "#BCA3CA", "#CCF0C3"],
      ["#EBBB91", "#F08181", "#9A1BA0", "#2D3999"],
      ["#A6AA9C", "#F7D3BA", "#E6E7E5", "#F5EFE3"],
      ["#CACACA", "#FCFAF1", "#E6B31E", "#343434"],
      ["#596E79", "#C7B198", "#DFD3C3", "#F0ECE2"],
      ["#EFEDBB", "#882042", "#CD5555", "#DBF1F2"],
      ["#4F323B", "#6E5773", "#EA9085", "#E9E2D0"],
      ["#F8B500", "#5C636E", "#393E46", "#F7F7F7"],
      ["#BCDBDF", "#40A8C4", "#235784", "#F7AA00"],
      ["#00541A", "#935900", "#CE7D00", "#FEF9D9"],
      ["#E58CDB", "#F4ADAD", "#92A4C0", "#87E5DA"],
      ["#F3E595", "#2F3E75", "#EB7878", "#D0EFB5"],
      ["#E7B3B3", "#3F3B3B", "#575151", "#FEB062"],
      ["#312B30", "#7A5D7E", "#63AEBB", "#FBD685"],
      ["#427996", "#645C84", "#A2738C", "#EAAFAF"],
      ["#355C7D", "#C06C84", "#F67280", "#F8B195"],
      ["#B7E1B5", "#DE774E", "#684656", "#1C1124"],
      ["#A7D7C5", "#74B49B", "#693E52", "#1C1124"],
      ["#3D0240", "#137083", "#B7B7B7", "#FAE3E3"],
      ["#E2DED3", "#857671", "#4E413B", "#FF6D24"],
      ["#393939", "#FF7F5B", "#D2EBCD", "#FFFCEF"],
      ["#351F39", "#726A95", "#A0C1B8", "#F4E8C1"],
      ["#F6E1B8", "#C65F63", "#84577C", "#333644"],
      ["#7C73E6", "#C4C1E0", "#FFE9E3", "#FAFAFA"],
      ["#756C83", "#F38181", "#B9E1DC", "#FBFBFB"],
      ["#305973", "#EF7E56", "#DED5C4", "#F9F9F9"],
      ["#A6D0E4", "#F9FFEA", "#FFECDA", "#D4A5A5"],
      ["#403F48", "#596C68", "#95A792", "#E3D9CA"],
      ["#C2DBC1", "#F6F6E3", "#DF6A6A", "#6C5070"],
      ["#FFB174", "#EE5A5A", "#B31E6F", "#22EAAA"],
      ["#272637", "#474168", "#626F92", "#F9C4AC"],
      ["#625772", "#F38181", "#FEFAEC", "#A9EEE6"],
      ["#F6E1B5", "#E2BF81", "#B21E4B", "#3C1B1F"],
      ["#FD6378", "#F9B8BE", "#50666B", "#2E3B3E"],
      ["#FF8C94", "#FFAAA6", "#FFD3B5", "#DCEDC2"],
      ["#D0F0F7", "#71647C", "#4C5374", "#F1E58A"],
      ["#D9BF77", "#D8EBB5", "#639A67", "#205D67"],
      ["#2A014B", "#027B7E", "#D6E1A5", "#DAFAF8"],
      ["#BDC6B8", "#EBF5EE", "#BCE0DA", "#921224"],
      ["#FF8426", "#785E4D", "#BAAF92", "#F2EEE3"],
      ["#5C715E", "#B6CDBD", "#DDEEDF", "#F2F9F1"],
      ["#F6E8E8", "#887575", "#213458", "#1D7D81"],
      ["#796465", "#CB8589", "#E8D2AE", "#DDE8B9"],
      ["#476268", "#40A798", "#F1F1F1", "#F5E1DA"],
      ["#BB5A5A", "#E79E85", "#EACEB4", "#F2E9D0"],
      ["#7D5E3F", "#F5D97E", "#FEF2F2", "#F03861"],
      ["#ECECEC", "#F5D061", "#E6AF2E", "#282F44"],
      ["#EEEEEE", "#5C476F", "#AC4C5E", "#FFBC65"],
      ["#C72767", "#EC9454", "#FABC41", "#FAF4E1"],
      ["#8E415B", "#D15385", "#FAF3DF", "#798A65"],
      ["#283C63", "#928A97", "#FBE8D3", "#F85F73"],
      ["#E6D3A7", "#59A985", "#3A7563", "#392F2F"],
      ["#DE703B", "#FCB040", "#506E86", "#2F3C4F"],
      ["#F2F2F2", "#D8CBBB", "#986EAD", "#3F1263"],
      ["#F3F1F1", "#1AD7DB", "#1297BD", "#5F2F14"],
      ["#A63636", "#D3504A", "#E2E0A5", "#FCFAEF"],
      ["#662753", "#C576AC", "#EEBEE3", "#F8ECD9"],
      ["#EFE4E4", "#D0BAA8", "#AC7C7C", "#C4317B"],
      ["#FDE9C9", "#DA4949", "#A91D1D", "#4F1C4C"],
      ["#E0E7B8", "#BCD3C2", "#B36458", "#353E55"],
      ["#543A3A", "#C2CFD8", "#E1EEFF", "#FAFAFA"],
      ["#F6CAC9", "#9F5069", "#834655", "#C8B273"],
      ["#444036", "#686354", "#50C19A", "#E4F68F"],
      ["#FEBE7E", "#EC7263", "#A75265", "#57385C"],
      ["#3E4149", "#444F5A", "#FF9999", "#FFC8C8"],
      ["#363863", "#635270", "#C98B70", "#F8E796"],
      ["#AAAAAA", "#A2453D", "#F6BF4F", "#FAF6ED"],
      ["#7A6552", "#283644", "#4D727E", "#FBE0D8"],
      ["#25161B", "#453953", "#975A5E", "#F3CBA5"],
      ["#E1BF7F", "#C65F63", "#84577C", "#333644"],
      ["#955A47", "#B1BD5D", "#EDD690", "#F0F0EF"],
      ["#26271A", "#6A425C", "#FFA9A9", "#FFFBE3"],
      ["#BA6C65", "#F2BE8D", "#394359", "#303242"],
      ["#674F04", "#A28F70", "#D3BD9A", "#F5F0E8"],
      ["#C98C70", "#E3B587", "#456672", "#314357"],
      ["#D9B26E", "#9C563D", "#654534", "#3D322C"],
    ],
  },
  {
    name: "warm",
    palettes: [
      ["#FA4032", "#FA812F", "#FAB12F", "#FEF3E2"],
      ["#FF4545", "#FF9C73", "#FBD288", "#FCF596"],
      ["#DE7C7D", "#CC2B52", "#AF1740", "#740938"],
      ["#DE8F5F", "#FFB26F", "#FFCF9D", "#FFB38E"],
      ["#FFB0B0", "#FFD09B", "#FFECC8", "#FFF7D1"],
      ["#4A4947", "#B17457", "#D8D2C2", "#FAF7F0"],
      ["#E6D9A2", "#CB80AB", "#8967B3", "#624E88"],
      ["#EEDF7A", "#D8A25E", "#A04747", "#343131"],
      ["#674636", "#AAB396", "#F7EED3", "#FFF8E8"],
      ["#603F26", "#6C4E31", "#FFDBB5", "#FFEAC5"],
      ["#821131", "#C7253E", "#E85C0D", "#FABC3F"],
      ["#F2E8C6", "#DAD4B5", "#982B1C", "#800000"],
      ["#C5705D", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#F4D9D0", "#D9ABAB", "#C75B7A", "#921A40"],
      ["#D95F59", "#C63C51", "#8C3061", "#522258"],
      ["#8D493A", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#FF4C4C", "#FFB22C", "#FFDE4D", "#F3FEB8"],
      ["#B60071", "#E4003A", "#EB5B00", "#FFB200"],
      ["#E76F51", "#F4A261", "#E9C46A", "#36BA98"],
      ["#F5E7B2", "#F9D689", "#E0A75E", "#973131"],
      ["#0C1844", "#C80036", "#FF6969", "#FFF5E1"],
      ["#FF7D29", "#FFBF78", "#FFEEA9", "#FEFFD2"],
      ["#D10363", "#FF9A00", "#FFBF00", "#808836"],
      ["#987070", "#C39898", "#DBB5B5", "#F1E5D1"],
      ["#FED8B1", "#ECB176", "#A67B5B", "#6F4E37"],
      ["#543310", "#74512D", "#AF8F6F", "#F8F4E1"],
      ["#FF0080", "#FF5580", "#FFAA80", "#FFFF80"],
      ["#7ABA78", "#0A6847", "#F6E9B2", "#F3CA52"],
      ["#D8AE7E", "#F8C794", "#FFE0B5", "#FFF2D7"],
      ["#F8D082", "#E65C19", "#B51B75", "#640D6B"],
      ["#FFD0D0", "#E1ACAC", "#CA8787", "#A87676"],
      ["#FFC100", "#FF8A08", "#FF6500", "#C40C0C"],
      ["#ED9455", "#FFBB70", "#FFEC9E", "#FFFBDA"],
      ["#322C2B", "#803D3B", "#AF8260", "#E4C59E"],
      ["#FFF8DC", "#F7C566", "#DC6B19", "#6C0345"],
      ["#FFF2E1", "#EAD8C0", "#D1BB9E", "#A79277"],
      ["#C0D6E8", "#FBF8DD", "#E9C874", "#A34343"],
      ["#DBAFA0", "#BB8493", "#704264", "#49243E"],
      ["#453F78", "#795458", "#C08B5C", "#FFC94A"],
      ["#673F69", "#D74B76", "#FB6D48", "#FFAF45"],
      ["#FFFDD7", "#FFE4CF", "#FF5BAE", "#E72929"],
      ["#00224D", "#5D0E41", "#A0153E", "#FF204E"],
      ["#E5C287", "#898121", "#E8751A", "#FDA403"],
      ["#FFFDCB", "#FFB38E", "#FF8E8F", "#E178C5"],
      ["#F7418F", "#FC819E", "#FEC7B4", "#FFF3C7"],
      ["#E2BFB3", "#F7DED0", "#FEECE2", "#FFBE98"],
      ["#EEA5A6", "#E493B3", "#B784B7", "#8E7AB5"],
      ["#FFE7E7", "#CAA6A6", "#B47B84", "#944E63"],
      ["#643843", "#85586F", "#AC7D88", "#FDF0D1"],
      ["#E8D8C4", "#C7B7A3", "#6D2932", "#561C24"],
      ["#E4DEBE", "#E6BAA3", "#D24545", "#A94438"],
      ["#A87C7C", "#7E6363", "#503C3C", "#3E3232"],
      ["#DC84F3", "#E9A8F2", "#F3CCF3", "#FFE7C1"],
      ["#FFE5E5", "#E0AED0", "#AC87C5", "#756AB6"],
      ["#F3EDC8", "#EAD196", "#BF3131", "#7D0A0A"],
      ["#9A031E", "#E36414", "#FB8B24", "#5F0F40"],
      ["#FFA732", "#EF4040", "#C21292", "#711DB0"],
      ["#FFAD84", "#FFC47E", "#FFE382", "#FFF78A"],
      ["#BBAB8C", "#DED0B6", "#FAEED1", "#FDF7E4"],
      ["#F5CCA0", "#E48F45", "#994D1C", "#6B240C"],
      ["#FAF6F0", "#F4EAE0", "#F4DFC8", "#000000"],
      ["#F05941", "#BE3144", "#872341", "#22092C"],
      ["#9FBB73", "#F1EB90", "#F3B664", "#EC8F5E"],
      ["#FAE7C9", "#E1C78F", "#B0926A", "#706233"],
      ["#1F1717", "#CE5A67", "#F4BF96", "#FCF5ED"],
      ["#F6F1EE", "#4F4A45", "#6C5F5B", "#ED7D31"],
      ["#9A4444", "#DE8F5F", "#F4DFB6", "#D6D46D"],
      ["#E95793", "#DA0C81", "#940B92", "#610C9F"],
      ["#113946", "#BCA37F", "#EAD7BB", "#FFF2D8"],
      ["#F9DEC9", "#F78CA2", "#D80032", "#3D0C11"],
      ["#FFCF9D", "#FFB000", "#F5F5DC", "#004225"],
      ["#F39F5A", "#AE445A", "#662549", "#451952"],
      ["#FFC8C8", "#FF9B82", "#FF3FA4", "#57375D"],
      ["#EBEF95", "#EFD595", "#EFB495", "#EF9595"],
      ["#F2E8C6", "#DAD4B5", "#A73121", "#952323"],
      ["#C63D2F", "#E25E3E", "#FF9B50", "#FFBB5C"],
      ["#F2ECBE", "#E2C799", "#C08261", "#9A3B3B"],
      ["#FFBA86", "#F6635C", "#C23373", "#79155B"],
      ["#F8DE22", "#F94C10", "#C70039", "#900C3F"],
      ["#102C57", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#65451F", "#765827", "#C8AE7D", "#EAC696"],
      ["#CEE6F3", "#DFA878", "#BA704F", "#6C3428"],
      ["#FE0000", "#7D7463", "#A8A196", "#F4E0B9"],
      ["#EFE1D1", "#A78295", "#3F2E3E", "#331D2C"],
      ["#FF6666", "#FF8989", "#FCAEAE", "#FFEADD"],
      ["#F9E0BB", "#FFC26F", "#C38154", "#884A39"],
      ["#FFE569", "#F79327", "#DB005B", "#B70404"],
      ["#E7CBCB", "#C88EA7", "#99627A", "#643843"],
      ["#FEA1A1", "#ECCDB4", "#F0EDD4", "#F9FBE7"],
      ["#F79540", "#FC4F00", "#B71375", "#8B1874"],
      ["#FEF2F4", "#FCC8D1", "#FFABAB", "#D14D72"],
      ["#E74646", "#FA9884", "#FFE5CA", "#FFF3E2"],
      ["#FFE6C7", "#FFA559", "#FF6000", "#454545"],
      ["#4F200D", "#FF8400", "#FFD93D", "#F6F1E9"],
      ["#F3E8FF", "#BACDDB", "#CE5959", "#89375F"],
      ["#675D50", "#ABC4AA", "#F3DEBA", "#A9907E"],
      ["#DFA67B", "#F4B183", "#FFD966", "#FFF2CC"],
      ["#F5EBEB", "#E4D0D0", "#D5B4B4", "#867070"],
      ["#4D4D4D", "#B46060", "#FFBF9B", "#FFF4E0"],
      ["#F1DEC9", "#C8B6A6", "#A4907C", "#8D7B68"],
      ["#FBFFB1", "#FFEBB4", "#FFBFA9", "#FFACAC"],
      ["#7A3E65", "#A84448", "#E9A178", "#F6E1C3"],
      ["#4C4B16", "#898121", "#E7B10A", "#F7F1E5"],
      ["#FF5F9E", "#E90064", "#B3005E", "#060047"],
      ["#400E32", "#A61F69", "#F2921D", "#F2CD5C"],
      ["#F55050", "#F48484", "#E8D2A6", "#86A3B8"],
      ["#FFC3A1", "#F0997D", "#D3756B", "#A75D5D"],
      ["#F5F5DC", "#ECA869", "#B08BBB", "#B5D5C5"],
      ["#FFBABA", "#C85C8E", "#9D3C72", "#7B2869"],
      ["#E5E5CB", "#D5CEA3", "#3C2A21", "#1A120B"],
      ["#85586F", "#D0B8A8", "#DFD3C3", "#F8EDE3"],
      ["#C58940", "#E5BA73", "#FAEAB1", "#FAF8F1"],
      ["#F2F7A1", "#46C2CB", "#6D67E4", "#453C67"],
      ["#FAAB78", "#FFDCA9", "#FCF9BE", "#E8F3D6"],
      ["#FFADBC", "#D989B5", "#975C8D", "#863A6F"],
      ["#B01E68", "#DC3535", "#F49D1A", "#FFE15D"],
      ["#FCE2DB", "#FF8FB1", "#80489C", "#432C7A"],
      ["#FFFAD7", "#FCDDB0", "#FF9F9F", "#E97777"],
      ["#FF8DC7", "#FFACC7", "#FFDDD2", "#FFB9B9"],
      ["#FF97C1", "#FF5858", "#E0144C", "#9A1663"],
    ],
  },
  {
    name: "wedding",
    palettes: [
      ["#FFF4B7", "#006A67", "#003161", "#000B58"],
      ["#FFDDAE", "#FBFBFB", "#D4F6FF", "#C6E7FF"],
      ["#EEEEEE", "#D4BEE4", "#9B7EBD", "#3B1E54"],
      ["#C9E9D2", "#FEF9F2", "#FFE3E3", "#789DBC"],
      ["#10375C", "#EB8317", "#F3C623", "#F4F6FF"],
      ["#CB6040", "#FD8B51", "#F2E5BF", "#257180"],
      ["#FFE1FF", "#E4B1F0", "#7E60BF", "#433878"],
      ["#4A4947", "#B17457", "#D8D2C2", "#FAF7F0"],
      ["#E6D9A2", "#CB80AB", "#8967B3", "#624E88"],
      ["#8EACCD", "#DEE5D4", "#FEF9D9", "#D2E0FB"],
      ["#81DAE3", "#6CBEC7", "#825B32", "#654520"],
      ["#EAE4DD", "#E1D7C6", "#CDC2A5", "#295F98"],
      ["#FF9874", "#FFD7C4", "#9DBDFF", "#7695FF"],
      ["#E1D7B7", "#1E2A5E", "#55679C", "#7C93C3"],
      ["#6A9C89", "#C1D8C3", "#FFF5E4", "#CD5C08"],
      ["#F2E8C6", "#DAD4B5", "#982B1C", "#800000"],
      ["#674188", "#C8A1E0", "#E2BFD9", "#F7EFE5"],
      ["#1F316F", "#1A4870", "#5B99C2", "#F9DBBA"],
      ["#F5EDED", "#E2DAD6", "#7FA1C3", "#6482AD"],
      ["#694F8E", "#B692C2", "#E3A5C7", "#FFDFD6"],
      ["#606676", "#708871", "#BEC6A0", "#FEF3E2"],
      ["#FEFBD8", "#EECEB9", "#BB9AB1", "#987D9A"],
      ["#FFF8F3", "#F7E7DC", "#758694", "#405D72"],
      ["#698474", "#FCF8F3", "#FFD3B6", "#DCA47C"],
      ["#304463", "#7D8ABC", "#FFC7ED", "#FFF8DB"],
      ["#FFCBCB", "#FFB1B1", "#1679AB", "#102C57"],
      ["#DAD3BE", "#B7B597", "#6B8A7A", "#254336"],
      ["#EEEEEE", "#D1D8C5", "#7E8EF1", "#615EFC"],
      ["#F8F9D7", "#E49BFF", "#C738BD", "#850F8D"],
      ["#CAE6B2", "#F6FAB9", "#D2649A", "#8E3E63"],
      ["#F1F1F1", "#C7B7A3", "#EADBC8", "#B5C18E"],
      ["#FFE6E6", "#E1AFD1", "#AD88C6", "#7469B6"],
      ["#F5EFE6", "#E8DFCA", "#4F6F52", "#1A4D2E"],
      ["#FFB1B1", "#FFCBCB", "#FFEAE3", "#121481"],
      ["#102C57", "#DAC0A3", "#EADBC8", "#FEFAF6"],
      ["#E5DDC5", "#F1EEDC", "#BED7DC", "#B3C8CF"],
      ["#C4E4FF", "#D895DA", "#D6589F", "#D20062"],
      ["#FF71CD", "#5755FE", "#8B93FF", "#FFF7FC"],
      ["#EEEEEE", "#76ABAE", "#31363F", "#222831"],
      ["#FFEDD8", "#EABE6C", "#891652", "#240A34"],
      ["#F6995C", "#51829B", "#9BB0C1", "#EADFB4"],
      ["#7469B6", "#AD88C6", "#E1AFD1", "#FFE6E6"],
      ["#1B3C73", "#40679E", "#FFCAD4", "#FF407D"],
      ["#F6F5F5", "#FFE3CA", "#EE99C2", "#0C359E"],
      ["#F2EFE5", "#E3E1D9", "#C7C8CC", "#B4B4B8"],
      ["#643843", "#85586F", "#AC7D88", "#FDF0D1"],
      ["#F2F597", "#37B5B6", "#492E87", "#0A1D56"],
      ["#E9F6FF", "#280274", "#3652AD", "#FE7A36"],
      ["#E6A4B4", "#F3D7CA", "#FFF8E3", "#F5EEE6"],
      ["#3468C0", "#86A7FC", "#FFDD95", "#FF9843"],
      ["#F9F7C9", "#D5F0C1", "#AAD9BB", "#80BCBD"],
      ["#76453B", "#B19470", "#F8FAE5", "#43766C"],
      ["#FAE7F3", "#E6B9DE", "#4942E4", "#11009E"],
      ["#F6ECA9", "#C6CF9B", "#596FB7", "#11235A"],
      ["#820300", "#B80000", "#FF9800", "#5F8670"],
      ["#CD8D7A", "#DBCC95", "#EAECCC", "#C3E2C2"],
      ["#49108B", "#7E30E1", "#E26EE5", "#F3F8FF"],
      ["#F0ECE5", "#B6BBC4", "#31304D", "#161A30"],
      ["#67729D", "#BB9CC0", "#E7BCDE", "#FED9ED"],
      ["#FFC7C7", "#ED9ED6", "#C683D7", "#7071E8"],
      ["#A25772", "#7C93C3", "#9EB8D9", "#EEF5FF"],
      ["#F3F3F3", "#A3B763", "#AF2655", "#860A35"],
      ["#FF90C2", "#ED5AB3", "#1640D6", "#001B79"],
      ["#DDF2FD", "#9BBEC8", "#427D9D", "#164863"],
      ["#7743DB", "#C3ACD0", "#F7EFE5", "#FFFBF5"],
      ["#FFF0CE", "#FFC436", "#0174BE", "#0C356A"],
      ["#F3F0CA", "#E1AA74", "#3876BF", "#192655"],
      ["#F5F5F5", "#F99417", "#4D4C7D", "#363062"],
      ["#FFF8C9", "#DFCCFB", "#D0BFFF", "#BEADFA"],
      ["#6A9C89", "#C1D8C3", "#F5E8B7", "#CD5C08"],
      ["#FDF0F0", "#F1B4BB", "#1F4172", "#132043"],
      ["#EEEEEE", "#2E97A7", "#1AACAC", "#362FD9"],
      ["#113946", "#BCA37F", "#EAD7BB", "#FFF2D8"],
      ["#FDE5D4", "#D6CC99", "#445D48", "#001524"],
      ["#141E46", "#C70039", "#FF6969", "#FFF5E0"],
      ["#FFCF9D", "#FFB000", "#F5F5DC", "#004225"],
      ["#0F2C59", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#E5D283", "#4F709C", "#213555", "#F0F0F0"],
      ["#F2E8C6", "#DAD4B5", "#A73121", "#952323"],
      ["#435334", "#9EB384", "#CEDEBD", "#FAF1E4"],
      ["#102C57", "#DAC0A3", "#EADBC8", "#F8F0E5"],
      ["#3D246C", "#5C4B99", "#9F91CC", "#FFDBC3"],
      ["#916DB3", "#E48586", "#FCBAAD", "#FDE5EC"],
      ["#FFE5AD", "#3E001F", "#982176", "#F11A7B"],
      ["#3F2305", "#DFD7BF", "#F2EAD3", "#F5F5F5"],
      ["#9575DE", "#6554AF", "#2B2730", "#E966A0"],
      ["#F1C376", "#F7E6C4", "#FFF4F4", "#606C5D"],
      ["#116A7B", "#CDC2AE", "#ECE5C7", "#C2DEDC"],
      ["#080202", "#73BBC9", "#F1D4E5", "#FCE9F1"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#9BABB8"],
      ["#CD1818", "#4E3636", "#321E1E", "#116D6E"],
      ["#374259", "#545B77", "#5C8984", "#F2D8D8"],
      ["#E55807", "#7E1717", "#068DA9", "#ECF8F9"],
      ["#F8F1F1", "#E8AA42", "#E57C23", "#025464"],
      ["#F6C391", "#E08E6D", "#D25380", "#FFFAF4"],
      ["#FDE2F3", "#E5BEEC", "#917FB3", "#2A2F4F"],
      ["#E86A33", "#263A29", "#41644A", "#F2E3DB"],
      ["#77037B", "#210062", "#009FBD", "#F9E2AF"],
      ["#F3E8FF", "#BACDDB", "#CE5959", "#89375F"],
      ["#F5C6EC", "#FFEAEA", "#E11299", "#9A208C"],
      ["#002B5B", "#EA5455", "#E4DCCF", "#F9F5EB"],
      ["#F8F5E4", "#F7C04A", "#3F497F", "#539165"],
      ["#EB455F", "#FCFFE7", "#BAD7E9", "#2B3467"],
      ["#183A1D", "#F0A04B", "#E1EEDD", "#FEFBE9"],
      ["#F9F5E7", "#F8EAD8", "#EDDBC7", "#A7727D"],
      ["#F5F5F5", "#E8E2E2", "#F99417", "#5D3891"],
      ["#FDEBED", "#FFCEFE", "#D9ACF5", "#AAE3E2"],
      ["#FAECD6", "#F2DEBA", "#4E6C50", "#820000"],
      ["#F5F5DC", "#ECA869", "#B08BBB", "#B5D5C5"],
      ["#FFBABA", "#C85C8E", "#9D3C72", "#7B2869"],
      ["#F8F4EA", "#ECE8DD", "#E1D7C6", "#579BB1"],
      ["#22A39F", "#222222", "#434242", "#F3EFE0"],
      ["#852999", "#C539B4", "#EF9A53", "#F5D5AE"],
      ["#FCFDF2", "#FFE9B1", "#7743DB", "#3B3486"],
      ["#3A8891", "#0E5E6F", "#FFEFD6", "#F2DEBA"],
      ["#FF8DC7", "#FFACC7", "#FFDDD2", "#FFB9B9"],
      ["#F0EBCE", "#AA8B56", "#4E6C50", "#395144"],
      ["#FF97C1", "#FF5858", "#E0144C", "#9A1663"],
      ["#594545", "#815B5B", "#9E7676", "#FFF8EA"],
      ["#E26868", "#FF8787", "#EDEDED", "#D8D9CF"],
    ],
  },
  {
    name: "winter",
    palettes: [
      ["#FFF4B7", "#006A67", "#003161", "#000B58"],
      ["#CBDCEB", "#608BC1", "#133E87", "#F3F3E0"],
      ["#54473F", "#9A7E6F", "#CBD2A4", "#E9EED9"],
      ["#EAD8B1", "#6A9AB0", "#3A6D8C", "#001F3F"],
      ["#B8001F", "#FCFAEE", "#507687", "#384B70"],
      ["#ECDFCC", "#697565", "#3C3D37", "#181C14"],
      ["#C7FFD8", "#98DED9", "#0B2F9F", "#161D6F"],
      ["#FFDC7F", "#78B7D0", "#227B94", "#16325B"],
      ["#E1D7B7", "#1E2A5E", "#55679C", "#7C93C3"],
      ["#EEEEEE", "#508C9B", "#134B70", "#201E43"],
      ["#D6BD98", "#677D6A", "#40534C", "#1A3636"],
      ["#4535C1", "#478CCF", "#36C2CE", "#77E4C8"],
      ["#1A5319", "#508D4E", "#80AF81", "#D6EFD8"],
      ["#EBF4F6", "#37B7C3", "#088395", "#071952"],
      ["#B3E2A7", "#80B9AD", "#6295A2", "#538392"],
      ["#DAD3BE", "#B7B597", "#6B8A7A", "#254336"],
      ["#57A6A1", "#577B8D", "#344C64", "#240750"],
      ["#DFD0B8", "#948979", "#3C5B6F", "#153448"],
      ["#C5FF95", "#5DEBD7", "#1679AB", "#074173"],
      ["#EEEEEE", "#DDDDDD", "#76885B", "#627254"],
      ["#DFF5FF", "#67C6E3", "#378CE7", "#5356FF"],
      ["#EEEEEE", "#76ABAE", "#31363F", "#222831"],
      ["#F4EDCC", "#A4CE95", "#6196A6", "#5F5D9C"],
      ["#78A083", "#50727B", "#344955", "#35374B"],
      ["#50C4ED", "#387ADF", "#333A73", "#FBA834"],
      ["#F9E8C9", "#98ABEE", "#1D24CA", "#201658"],
      ["#F1FADA", "#9AD0C2", "#2D9596", "#265073"],
      ["#FBFADA", "#ADBC9F", "#436850", "#12372A"],
      ["#3C3633", "#747264", "#E0CCBE", "#EEEDEB"],
      ["#F0EDCF", "#40A2D8", "#0B60B0", "#000000"],
      ["#0F1035", "#365486", "#7FC7D9", "#DCF2F1"],
      ["#F6B17A", "#7077A1", "#424769", "#2D3250"],
      ["#F8EDFF", "#BFCFE7", "#525CEB", "#3D3B40"],
      ["#176B87", "#86B6F6", "#B4D4FF", "#EEF5FF"],
      ["#F6ECA9", "#C6CF9B", "#596FB7", "#11235A"],
      ["#200E3A", "#38419D", "#3887BE", "#52D3D8"],
      ["#0F2167", "#3559E0", "#4CB9E7", "#FFECD6"],
      ["#EEF0E5", "#B6C4B6", "#304D30", "#163020"],
      ["#9EC8B9", "#5C8374", "#1B4242", "#092635"],
      ["#BED754", "#E3651D", "#750E21", "#191919"],
      ["#F0ECE5", "#B6BBC4", "#31304D", "#161A30"],
      ["#7B66FF", "#5FBDFF", "#96EFFF", "#C5FFF8"],
      ["#265073", "#2D9596", "#9AD0C2", "#ECF4D6"],
      ["#A25772", "#7C93C3", "#9EB8D9", "#EEF5FF"],
      ["#F3F3F3", "#C5E898", "#29ADB2", "#0766AD"],
      ["#F5E8C7", "#818FB4", "#435585", "#363062"],
      ["#DDF2FD", "#9BBEC8", "#427D9D", "#164863"],
      ["#3A4D39", "#4F6F52", "#739072", "#ECE3CE"],
      ["#DAFFFB", "#64CCC5", "#176B87", "#04364A"],
      ["#EEEEEE", "#2E97A7", "#1AACAC", "#362FD9"],
      ["#FDE5D4", "#D6CC99", "#445D48", "#001524"],
      ["#E5C3A6", "#7C81AD", "#4B527E", "#2E4374"],
      ["#F2F7A1", "#35A29F", "#088395", "#071952"],
      ["#E5D283", "#4F709C", "#213555", "#F0F0F0"],
      ["#EEEEEE", "#64CCC5", "#176B87", "#053B50"],
      ["#93B1A6", "#5C8374", "#183D3D", "#040D12"],
      ["#D5FFD0", "#40F8FF", "#279EFF", "#0C356A"],
      ["#C8FFE0", "#85E6C5", "#33BBC5", "#614BC3"],
      ["#F7E987", "#5B9A8B", "#445069", "#252B48"],
      ["#8CABFF", "#4477CE", "#512B81", "#35155D"],
      ["#AED8CC", "#CD6688", "#7A316F", "#461959"],
      ["#116A7B", "#CDC2AE", "#ECE5C7", "#C2DEDC"],
      ["#213555", "#4F709C", "#D8C4B6", "#F5EFE7"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#9BABB8"],
      ["#DDE6ED", "#9DB2BF", "#526D82", "#27374D"],
      ["#EEEEEE", "#7A3E3E", "#482121", "#47A992"],
      ["#E6FFFD", "#AEE2FF", "#ACBCFF", "#B799FF"],
      ["#FFE194", "#E8F6EF", "#1B9C85", "#4C4C6D"],
      ["#83764F", "#A2A378", "#A0D8B3", "#E5F9DB"],
      ["#FFEAD2", "#DBDFEA", "#ACB1D6", "#8294C4"],
      ["#EBD8B2", "#A5C0DD", "#6C9BCF", "#654E92"],
      ["#EDC6B1", "#B7B7B7", "#7C96AB", "#BFCCB5"],
      ["#577D86", "#569DAA", "#87CBB9", "#B9EDDD"],
      ["#A5D7E8", "#576CBC", "#19376D", "#0B2447"],
      ["#002B5B", "#1A5F7A", "#159895", "#57C5B6"],
      ["#BFACE2", "#655DBB", "#3E54AC", "#ECF2FF"],
      ["#18122B", "#393053", "#443C68", "#635985"],
      ["#E9F8F9", "#C0EEF2", "#537FE7", "#181823"],
      ["#DEFCF9", "#85CDFD", "#3C84AB", "#362FD9"],
      ["#FC7300", "#BFDB38", "#1F8A70", "#00425A"],
      ["#B6EADA", "#5B8FB9", "#301E67", "#03001C"],
      ["#FAD6A5", "#CFB997", "#7B8FA1", "#567189"],
      ["#F2DEBA", "#2DCDDF", "#3C79F5", "#6C00FF"],
      ["#E6E2C3", "#88A47C", "#227C70", "#1C315E"],
      ["#F1F6F5", "#82C3EC", "#4B56D2", "#472183"],
      ["#CBEDD5", "#97DECE", "#62B6B7", "#439A97"],
      ["#251749", "#263159", "#495579", "#FFFBEB"],
      ["#F2F7A1", "#46C2CB", "#6D67E4", "#453C67"],
      ["#0014FF", "#009EFF", "#00E7FF", "#00FFF6"],
      ["#F1D3B3", "#C7BCA1", "#8B7E74", "#65647C"],
      ["#EB6440", "#497174", "#D6E4E5", "#EFF5F5"],
      ["#8EC3B0", "#9ED5C5", "#BCEAD5", "#DEF5E5"],
      ["#B9E0FF", "#8D9EFF", "#8D72E1", "#6C4AB6"],
      ["#6B728E", "#50577A", "#474E68", "#404258"],
      ["#153462", "#4FA095", "#BAD1C2", "#F6F6C9"],
      ["#FDF0E0", "#E6CBA8", "#2146C7", "#0008C1"],
      ["#5837D0", "#5DA7DB", "#81C6E8", "#7DE5ED"],
      ["#98A8F8", "#BCCEF8", "#CDFCF6", "#FAF7F0"],
      ["#F5EFE6", "#B4CDE6", "#628E90", "#3C2317"],
      ["#FFE7CC", "#E1CEB5", "#083AA9", "#002E94"],
      ["#DFF6FF", "#47B5FF", "#256D85", "#06283D"],
      ["#000000", "#5C2E7E", "#4C6793", "#8BBCCC"],
      ["#967E76", "#D7C0AE", "#EEE3CB", "#B7C4CF"],
      ["#FEF5AC", "#97D2EC", "#5F6F94", "#25316D"],
      ["#D2D79F", "#90B77D", "#42855B", "#483838"],
      ["#8FE3CF", "#256D85", "#2B4865", "#002B5B"],
      ["#B1E1FF", "#AFB4FF", "#9C9EFE", "#A66CFF"],
      ["#F9F5EB", "#EAE3D2", "#607EAA", "#1C3879"],
      ["#FFEA11", "#81CACF", "#5A8F7B", "#355764"],
      ["#FFE5B4", "#E8AA42", "#1F4690", "#231955"],
      ["#635666", "#839AA8", "#AEDBCE", "#D3EBCD"],
      ["#DFF6FF", "#47B5FF", "#1363DF", "#06283D"],
      ["#DBDFFD", "#9BA3EB", "#646FD4", "#242F9B"],
      ["#B3E8E5", "#82DBD8", "#3BACB6", "#2F8F9D"],
      ["#EEE4AB", "#E5CB9F", "#99C4C8", "#68A7AD"],
      ["#EFEFEF", "#F66B0E", "#205375", "#112B3C"],
      ["#FEE2C5", "#001D6E", "#7FB5FF", "#C4DDFF"],
      ["#363062", "#4D4C7D", "#827397", "#E9D5DA"],
      ["#FAFFAF", "#95D1CC", "#5584AC", "#22577E"],
      ["#534340", "#BB9981", "#C5D8A4", "#F4FCD9"],
    ],
  },
];
