import { req } from "./utils/ServerUtils";

export const useServer = ({ cache, setCache }) => {
  const fetchImages = (force) => {
    if (!force && cache["/images"]) {
      return Promise.resolve(cache["/images"]);
    }
    return req("/images").then((res) => {
      cache["/images"] = res;
      return res;
    });
  };

  return {
    fetchImages,
  };
};
