// @ts-nocheck

import { rand } from "./utils/utils";

export class Group {
  type = "group";
  children = [];

  constructor(id) {
    this.id = "" + id || "group-" + rand();
  }
}
