// @ts-nocheck

import { useSVG } from "../SVGContext";
import { getTransformStr } from "../utils/transformUtils";
import { allGoogleFonts } from "../values/all-google-fonts";
import { SVG_ATTRIBUTES } from "../values/SvgAttributes";
import SVGItemView from "./SVGItemView";

export function Children({ children, overlay }) {
  const { items, groups } = useSVG();

  return children.map((id, index) => {
    if (groups[id]) {
      return <SVGGroupView key={index} group={groups[id]} overlay={overlay} />;
    } else {
      return <SVGItemView key={index} item={items[id]} overlay={overlay} />;
    }
  });
}

export function SVGGroupView({ group, overlay }) {
  const { svgRef } = useSVG();
  if (!group) return <g></g>;
  let groupProps = { ...group };
  for (let key of ["children", "group"]) {
    delete groupProps[key];
  }
  if (groupProps.mpTransforms) {
    groupProps["transform"] = getTransformStr(svgRef, groupProps);
    delete groupProps.mpTransforms;
  }
  groupProps = renameKeysToCamelcase(groupProps);
  const children = Children({ children: group.children, overlay });
  return <g {...groupProps}>{children}</g>;
}

export function renameKeysToCamelcase(obj) {
  const valid = SVG_ATTRIBUTES;
  for (let key in obj) {
    if (!valid[key]) continue; // don't rename invalid keys

    const camelCasedKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

    if (key == "style") {
      obj[key] = stringStyleToJsonStyle(obj[key]);
    } else if (camelCasedKey !== key) {
      obj[camelCasedKey] = obj[key];
      delete obj[key];
    }
  }
  // rename class to className
  if (obj.class) {
    obj.className = obj.class;
    delete obj.class;
  }
  return obj;
}
