import { useSVG } from "./SVGContext";
import { getTemplatePreviewUrl } from "./utils/utils";

export function Pages({}) {
  const { pages } = useSVG();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 15,
        justifyContent: "start",
        alignItems: "center",
        padding: 15,

        marginBottom: 50, // unfortunate hack to support bottombar, should be removed in future
        overflowY: "scroll",
      }}
    >
      {pages.map((page, index) => (
        <Page pageIdx={index} key={index} page={page} />
      ))}
    </div>
  );
}
function Page({ page, pageIdx }) {
  const { setSelectedPage, width, height, viewBox } = useSVG();

  const aspectRatio = width / height;
  const w = 200;
  const h = w / aspectRatio;

  return (
    <div
      style={{
        border: "solid 1px #ccc",
        borderRadius: 5,
        padding: 5,
      }}
      onClick={() => setSelectedPage(pageIdx)}
    >
      <div
        style={{
          width: w + "px",
          height: h + "px",
        }}
        className="pages-svg-container"
      >
        <img src={page.preview} style={{ width: "100%", height: "100%" }} />
      </div>
    </div>
  );
}
