// @ts-nocheck

import { Group } from "./Group";
import { useSVG } from "./SVGContext";
import { ROOT_GROUP_ID } from "./values/constants";

export function FileUploader({ imageRef, fileRef }) {
  const { loadRawSVG, update, setTool, tools, mainGroup, items, groups, defs, selectedIds, width, changeDimensions, idGenerator } = useSVG();

  const onFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      loadRawSVG(e.target.result);
    };
    reader.readAsText(file);
  };

  const onImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result as string;
        const image = new Image();
        image.src = imageData;

        image.onload = () => {
          const originalWidth = image.width;
          const originalHeight = image.height;

          let item = {
            type: "image",
            id: idGenerator.id(),
            href: imageData,
            x: 0,
            y: 0,
            width: width,
            height: width * (originalHeight / originalWidth),
            originalWidth: originalWidth,
            originalHeight: originalHeight,
            center: {
              x: width / 2,
              y: (width * (originalHeight / originalWidth)) / 2,
            },

            clone: (o) => {
              return { ...this };
            },
            label: "Image",
          };
          item.group = mainGroup.id;
          groups[mainGroup.id].children.push(item.id);
          items[item.id] = item;

          update({ items, groups, defs, selectedIds: [item.id] });
        };
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <input style={{ display: "none" }} ref={fileRef} type="file" accept="image/*" onChange={(e) => onFileUpload(e)} />
      <input style={{ display: "none" }} ref={imageRef} type="file" accept="image/*" onChange={(e) => onImageUpload(e)} />
    </>
  );
}
