import { IconButton, Menu, MenuItem, Slider, TextField, Tooltip } from "@mui/material";
import { ArrangeBtn } from "./ArrangeBtn";
import { RotateBtn } from "./RotateBtn";
import { useSVG } from "../SVGContext";
import { useState } from "react";
import OpacityIcon from "@mui/icons-material/Opacity";

export function TopbarCommons({ item }) {
  return (
    <>
      <RotateBtn item={item} />
      <ArrangeBtn />
      <TopBarNumber name="Opacity" icon={<OpacityIcon />} valueName="opacity" min={0} max={1} step={0.1} defaultValue={1} />
    </>
  );
}

export function TopBarNumber({ name, icon, valueName, min, max, step, defaultValue, onChange }) {
  const { items, groups, defs, selectedIds, update } = useSVG();
  const [anchorEl, setAnchor] = useState(null);

  const item = items[selectedIds[0]];
  if (!item) return null;

  function standardOnChange(e) {
    if (onChange) onChange(e);
    else {
      item[valueName] = e.target.value;
      items[item.id] = item;
      update({ items, groups, defs });
    }
  }

  return (
    <>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
        <Tooltip title={name}>{icon}</Tooltip>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px",
              gap: "10px",
            }}
          >
            <Slider
              style={{ width: "100px" }}
              value={item[valueName] || defaultValue}
              min={min}
              max={max}
              step={step}
              onChange={(e) => {
                standardOnChange(e);
              }}
            />
            <TextField
              type="number"
              value={item[valueName] || defaultValue}
              size="small"
              style={{ width: "70px" }}
              onChange={(e) => {
                standardOnChange;
              }}
            />
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}
