// https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute

// li = document.querySelectorAll("article.main-page-content li");
// lis = Array.from(li).map((x) => x.textContent);
// map={}; for (let li of lis) map[li] = 1
// JSON.stringify(map)

export const SVG_ATTRIBUTES = {
  "accent-height": 1,
  accumulate: 1,
  additive: 1,
  "alignment-baseline": 1,
  alphabetic: 1,
  amplitude: 1,
  "arabic-form": 1,
  ascent: 1,
  attributeName: 1,
  attributeType: 1,
  azimuth: 1,
  baseFrequency: 1,
  "baseline-shift": 1,
  baseProfile: 1,
  bbox: 1,
  begin: 1,
  bias: 1,
  by: 1,
  calcMode: 1,
  "cap-height": 1,
  class: 1,
  clip: 1,
  clipPathUnits: 1,
  "clip-path": 1,
  "clip-rule": 1,
  color: 1,
  "color-interpolation": 1,
  "color-interpolation-filters": 1,
  "color-rendering": 1,
  crossorigin: 1,
  cursor: 1,
  cx: 1,
  cy: 1,
  d: 1,
  decelerate: 1,
  descent: 1,
  diffuseConstant: 1,
  direction: 1,
  display: 1,
  divisor: 1,
  "dominant-baseline": 1,
  dur: 1,
  dx: 1,
  dy: 1,
  edgeMode: 1,
  elevation: 1,
  end: 1,
  exponent: 1,
  fill: 1,
  "fill-opacity": 1,
  "fill-rule": 1,
  filter: 1,
  filterUnits: 1,
  "flood-color": 1,
  "flood-opacity": 1,
  "font-family": 1,
  "font-size": 1,
  "font-size-adjust": 1,
  "font-stretch": 1,
  "font-style": 1,
  "font-variant": 1,
  "font-weight": 1,
  format: 1,
  from: 1,
  fr: 1,
  fx: 1,
  fy: 1,
  g1: 1,
  g2: 1,
  "glyph-name": 1,
  "glyph-orientation-horizontal": 1,
  "glyph-orientation-vertical": 1,
  glyphRef: 1,
  gradientTransform: 1,
  gradientUnits: 1,
  hanging: 1,
  height: 1,
  href: 1,
  hreflang: 1,
  "horiz-adv-x": 1,
  "horiz-origin-x": 1,
  id: 1,
  ideographic: 1,
  "image-rendering": 1,
  in: 1,
  in2: 1,
  intercept: 1,
  k: 1,
  k1: 1,
  k2: 1,
  k3: 1,
  k4: 1,
  kernelMatrix: 1,
  kernelUnitLength: 1,
  keyPoints: 1,
  keySplines: 1,
  keyTimes: 1,
  lang: 1,
  lengthAdjust: 1,
  "letter-spacing": 1,
  "lighting-color": 1,
  limitingConeAngle: 1,
  local: 1,
  "marker-end": 1,
  "marker-mid": 1,
  "marker-start": 1,
  markerHeight: 1,
  markerUnits: 1,
  markerWidth: 1,
  mask: 1,
  maskContentUnits: 1,
  maskUnits: 1,
  mathematical: 1,
  max: 1,
  media: 1,
  method: 1,
  min: 1,
  mode: 1,
  name: 1,
  numOctaves: 1,
  offset: 1,
  opacity: 1,
  operator: 1,
  order: 1,
  orient: 1,
  orientation: 1,
  origin: 1,
  overflow: 1,
  "overline-position": 1,
  "overline-thickness": 1,
  "panose-1": 1,
  "paint-order": 1,
  path: 1,
  pathLength: 1,
  patternContentUnits: 1,
  patternTransform: 1,
  patternUnits: 1,
  ping: 1,
  "pointer-events": 1,
  points: 1,
  pointsAtX: 1,
  pointsAtY: 1,
  pointsAtZ: 1,
  preserveAlpha: 1,
  preserveAspectRatio: 1,
  primitiveUnits: 1,
  r: 1,
  radius: 1,
  referrerPolicy: 1,
  refX: 1,
  refY: 1,
  rel: 1,
  "rendering-intent": 1,
  repeatCount: 1,
  repeatDur: 1,
  requiredExtensions: 1,
  requiredFeatures: 1,
  restart: 1,
  result: 1,
  rotate: 1,
  rx: 1,
  ry: 1,
  scale: 1,
  seed: 1,
  "shape-rendering": 1,
  slope: 1,
  spacing: 1,
  specularConstant: 1,
  specularExponent: 1,
  speed: 1,
  spreadMethod: 1,
  startOffset: 1,
  stdDeviation: 1,
  stemh: 1,
  stemv: 1,
  stitchTiles: 1,
  "stop-color": 1,
  "stop-opacity": 1,
  "strikethrough-position": 1,
  "strikethrough-thickness": 1,
  string: 1,
  stroke: 1,
  "stroke-dasharray": 1,
  "stroke-dashoffset": 1,
  "stroke-linecap": 1,
  "stroke-linejoin": 1,
  "stroke-miterlimit": 1,
  "stroke-opacity": 1,
  "stroke-width": 1,
  style: 1,
  surfaceScale: 1,
  systemLanguage: 1,
  tabindex: 1,
  tableValues: 1,
  target: 1,
  targetX: 1,
  targetY: 1,
  "text-anchor": 1,
  "text-decoration": 1,
  "text-rendering": 1,
  textLength: 1,
  to: 1,
  transform: 1,
  "transform-origin": 1,
  type: 1,
  u1: 1,
  u2: 1,
  "underline-position": 1,
  "underline-thickness": 1,
  unicode: 1,
  "unicode-bidi": 1,
  "unicode-range": 1,
  "units-per-em": 1,
  "v-alphabetic": 1,
  "v-hanging": 1,
  "v-ideographic": 1,
  "v-mathematical": 1,
  values: 1,
  "vector-effect": 1,
  version: 1,
  "vert-adv-y": 1,
  "vert-origin-x": 1,
  "vert-origin-y": 1,
  viewBox: 1,
  visibility: 1,
  width: 1,
  widths: 1,
  "word-spacing": 1,
  "writing-mode": 1,
  x: 1,
  "x-height": 1,
  x1: 1,
  x2: 1,
  xChannelSelector: 1,
  "xlink:actuate": 1,
  "xlink:arcrole": 1,
  "xlink:href\\n  Deprecated\\n": 1,
  "xlink:role": 1,
  "xlink:show": 1,
  "xlink:title": 1,
  "xlink:type": 1,
  "xml:lang": 1,
  "xml:space": 1,
  y: 1,
  y1: 1,
  y2: 1,
  yChannelSelector: 1,
  z: 1,
};
