import React, { memo, useEffect, useState } from "react";
import { TextField, IconButton, Autocomplete, Menu, ListItem, List, Button, Select, InputAdornment, ImageList, MenuItem, CircularProgress, Dialog, Divider, DialogContent, DialogTitle } from "@mui/material";
import { cap, getTemplatePreviewUrl } from "./utils/utils";
import { req, searchTemplates } from "./utils/ServerUtils";
import { LazyImageWithAddBtn } from "./library/LazyImageWithAddBtn";
import { useSVG } from "./SVGContext";
import Masonry from "@mui/lab/Masonry";
import Gallery from "./Gallery";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AiSubjectChooser from "./AiSubjectChooser";
import { OrDivider } from "./OrDivider";
import { SELECTED_TEMPLATES } from "./defaultTemplates";
import { products } from "./Credits";

const SelectedTemplatesList = memo(() => {
  const [results, setResults] = useState(SELECTED_TEMPLATES);
  let [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const { isMobile } = useSVG();
  const [step, setStep] = useState("step1");

  useEffect(() => {
    if (results.length == 0) {
      req("/featured_templates").then((res) => {
        setResults(res);
      });
    }
  }, []);

  return (
    <div
      style={{
        padding: 15,
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: "stretch",

        height: "100%",
        overflow: isMobile ? "hidden" : "unset",
      }}
    >
      {step == "step2" && (
        <>
          <h2
            style={{
              textAlign: "center",
            }}
          >
            Step 2: Click on a Template
          </h2>
        </>
      )}
      <Pages currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
      <Results results={results} isSearching={isSearching} step={step} setStep={setStep} />
    </div>
  );
});
export default SelectedTemplatesList;

function Pages({ currentPage, setCurrentPage, totalPages }) {
  const pageStart = Math.floor((currentPage - 1) / 5) * 5 + 1;
  const pageList = Array.from(
    {
      length: Math.min(5, totalPages - pageStart + 1),
    },
    (_, i) => pageStart + i,
  );

  if (pageList.length <= 1) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <IconButton
        onClick={() => {
          setCurrentPage(currentPage - 5 >= 1 ? currentPage - 5 : 1);
        }}
        disabled={pageList[0] <= 1}
      >
        {"<"}
      </IconButton>
      {totalPages > 0 &&
        pageList.map((p, i) => (
          <Button
            size="small"
            key={i}
            onClick={() => {
              setCurrentPage(p);
            }}
            color="primary"
            style={{
              color: p === currentPage ? "red" : "black",
              minWidth: 50,
            }}
          >
            {p}
          </Button>
        ))}

      <IconButton
        onClick={() => {
          setCurrentPage(currentPage + 1);
        }}
        disabled={pageList[pageList.length - 1] >= totalPages - 1}
      >
        {">"}
      </IconButton>
    </div>
  );
}

function Searchbox({ query, setQuery, search }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        fullWidth
        value={query}
        variant="outlined"
        onChange={(e) => {
          query = e.target.value;
          setQuery(query);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            search();
          }
        }}
        placeholder="Search thousands of templates"
      />
    </div>
  );
}

function Results({ results, isSearching, step, setStep }) {
  const { isMobile, setFullscreenLoading } = useSVG();
  const [url, setUrl] = useState("");
  const [error, setError] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [isStep2BtnDisabled, setIsStep2BtnDisabled] = useState(true);

  useEffect(() => {
    if (url || selectedSubject) {
      setIsStep2BtnDisabled(false);
    } else {
      setIsStep2BtnDisabled(true);
    }
  }, [selectedSubject, url]);

  function onTemplateSelect(templateId) {
    setStep("loading");
    req(
      "/generatev2",
      "POST",
      JSON.stringify({
        url,
        subject: selectedSubject,
        template_id: templateId,
        s3: true,
      }),
    )
      .then((res) => {
        if (res.error) {
          if (res.error.includes("quota") && res.error.includes("exhausted")) {
            setError("quota_exhausted");
            setStep("error");
            return;
          }
          setError(res.error);
          setStep("error");
          return;
        }
        setStep("done");
        window.location.href = "/design/" + res.design_id;
      })
      .catch((err) => {
        setStep("error");
        setError(err?.error);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {step == "step1" && (
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: 10,
            width: "fit-content",
            padding: "20px 0px 20px 0px",
          }}
        >
          <div
            style={{
              marginBottom: 10,
              fontSize: "1.5em",
              textAlign: "center",
              borderBottom: "1px solid #ccc",
              padding: "0px 20px 10px 20px",
            }}
          >
            Step 1: Enter URL or Topic
          </div>
          <div
            style={{
              width: 500,
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 10,
              margin: 20,
            }}
          >
            <AiSubjectChooser url={url} setUrl={setUrl} selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Button
                onClick={() => {
                  setStep("step2");
                }}
                style={{
                  textTransform: "none",
                  flexGrow: 1,
                }}
                variant="contained"
                disabled={isStep2BtnDisabled}
              >
                Step 2: Select Template
              </Button>
            </div>
          </div>
        </div>
      )}
      {step == "done" && <div>Your design has been generated! Redirecting...</div>}
      {step == "error" && (
        <div>
          {error == "quota_exhausted" && (
            <div>
              Your credits are exhausted.{" "}
              <a href={products[0].url} target="_blank">
                Please top up by clicking here.
              </a>
            </div>
          )}
          {error !== "quota_exhausted" && "Sorry, there was an error generating design."}
        </div>
      )}

      {step == "loading" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            background: "rgba(255,255,255,0.7)",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {step == "step2" && (
        <Gallery
          style={{
            overflowY: isMobile ? "scroll" : "unset",
            width: "100%",
            maxWidth: "100%",
          }}
          data={results}
          render={(item, itemWidth) => <TemplateSearchResult item={item} itemWidth={itemWidth} isMobile={isMobile} onTemplateSelect={onTemplateSelect} />}
        />
      )}
    </div>
  );
}
function TemplateSearchResult({ item, itemWidth, isMobile, onTemplateSelect }) {
  const ar = item.width / item.height;
  const itemHeight = itemWidth / ar;
  return <LazyImageWithAddBtn key={item.id} src={getTemplatePreviewUrl(item.id)} alt={item.title} width={isMobile ? "100%" : itemWidth} height={isMobile ? "auto" : itemHeight} onClick={() => onTemplateSelect(item.id)} addString={"Create Design"} />;
}
