import { Button } from "@mui/material";

export function ResponsiveButton(props) {
  return (
    <Button
      startIcon={props.icon}
      sx={{
        "@media (max-width: 700px)": {
          ".MuiButton-startIcon + span": { display: "none" },
        },
      }}
      size="small"
      variant="outlined"
      style={{
        textTransform: "none",
      }}
      {...props}
    >
      <span>{props.label}</span>
    </Button>
  );
}
