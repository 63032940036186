import React, { memo, useEffect, useState } from "react";
import { TextField, IconButton, Autocomplete, Menu, ListItem, List, Button, Select, InputAdornment, ImageList, MenuItem, CircularProgress, Dialog } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { TEMPLATE_CATEGORIES } from "./values/TemplateCategories";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { SUBCATEGORIES } from "./values/Subcategories";
import { cap, getTemplatePreviewUrl } from "./utils/utils";
import { req, searchTemplates } from "./utils/ServerUtils";
import { ImageWithSkeleton } from "./library/ImageWithSkeleton";
import { LazyImageWithAddBtn } from "./library/LazyImageWithAddBtn";
import { useSVG } from "./SVGContext";
import Masonry from "@mui/lab/Masonry";
import Gallery from "./Gallery";

const TemplateSearchFullpage = memo(() => {
  const [isBookmarked, setIsBookmarked] = useState({});
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  const { cat } = useParams();

  let [category, setCategory] = useState(cat);
  let [query, setQuery] = useState(q);
  const [results, setResults] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [cache, setCache] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const { isMobile } = useSVG();

  useEffect(() => {
    req("/bookmarks").then((res) => {
      const b = {};
      for (let r of res) {
        b[r.id] = true;
      }
      setIsBookmarked(b);
      console.log("Bookmarks", b);
    });
  }, []);

  function bookmark(id) {
    setIsBookmarked({ ...isBookmarked, [id]: !isBookmarked[id] });
    const method = !isBookmarked[id] ? "POST" : "DELETE";
    req("/bookmarks/" + id, method, JSON.stringify({ id })).catch((err) => {
      console.error(err);
      setIsBookmarked({ ...isBookmarked, [id]: !isBookmarked[id] });
    });
  }

  function search() {
    setIsSearching(true);
    if (query == "" && cache[category]?.[currentPage]) {
      setResults(cache[category][currentPage]?.templates);
      setIsSearching(false);
      return;
    }
    searchTemplates(query, category, currentPage).then((res) => {
      if (query == "") {
        setCache({
          ...cache,
          [category]: {
            [currentPage]: res,
          },
        });
      }
      setResults(res?.templates);
      setTotalPages(res?.total_pages);
      setIsSearching(false);
    });
  }
  useEffect(() => {
    currentPage = 1;
    setCurrentPage(1);

    search();
  }, [category]);

  useEffect(() => {
    search();
  }, [currentPage]);

  useEffect(() => {
    if (category) {
      console.log(`Loading templates for category: ${category}`);
      // Fetch templates based on category
    } else if (q) {
      setCategory("all");
      query = q;
      setQuery(q);
      console.log(`Searching templates with query: ${q}`);
      // Fetch templates based on search query
    } else {
      console.log("Loading all templates");
      // Load all templates or handle no specific query/category
    }
    search();
  }, []);

  return (
    <div
      style={{
        padding: 15,
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: "stretch",

        height: "100%",
        overflow: isMobile ? "hidden" : "unset",
      }}
    >
      <Searchbox query={query} setQuery={setQuery} search={search} />
      <SubcatSelect category={category} setCategory={setCategory} />
      <Pages currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
      <Results results={results} isSearching={isSearching} isBookmarked={isBookmarked} bookmark={bookmark} />
    </div>
  );
});
export default TemplateSearchFullpage;

function Pages({ currentPage, setCurrentPage, totalPages }) {
  const pageStart = Math.floor((currentPage - 1) / 5) * 5 + 1;
  const pageList = Array.from(
    {
      length: Math.min(5, totalPages - pageStart + 1),
    },
    (_, i) => pageStart + i,
  );

  if (pageList.length <= 1) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <IconButton
        onClick={() => {
          setCurrentPage(currentPage - 5 >= 1 ? currentPage - 5 : 1);
        }}
        disabled={pageList[0] <= 1}
      >
        {"<"}
      </IconButton>
      {totalPages > 0 &&
        pageList.map((p, i) => (
          <Button
            size="small"
            key={i}
            onClick={() => {
              setCurrentPage(p);
            }}
            color="primary"
            style={{
              color: p === currentPage ? "red" : "black",
              minWidth: 50,
            }}
          >
            {p}
          </Button>
        ))}

      <IconButton
        onClick={() => {
          setCurrentPage(currentPage + 1);
        }}
        disabled={pageList[pageList.length - 1] >= totalPages - 1}
      >
        {">"}
      </IconButton>
    </div>
  );
}

function Searchbox({ query, setQuery, search }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        fullWidth
        value={query}
        variant="outlined"
        onChange={(e) => {
          query = e.target.value;
          setQuery(query);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            search();
          }
        }}
        placeholder="Search thousands of templates"
      />
    </div>
  );
}

function SubcatSelect({ category, setCategory }) {
  return (
    <Select
      value={category || "all"}
      onChange={(e) => {
        setCategory(e.target.value);
      }}
    >
      <MenuItem
        style={{
          padding: 10,
        }}
        value="all"
      >
        All
      </MenuItem>
      {Object.keys(SUBCATEGORIES).map((key) => (
        <MenuItem
          value={key}
          style={{
            padding: 10,
          }}
        >
          {cap(key)}
        </MenuItem>
      ))}
    </Select>
  );
}

function Results({ results, isSearching, isBookmarked, bookmark }) {
  const { isMobile } = useSVG();
  const [urlDialog, setUrlDialog] = useState(false);
  const [url, setUrl] = useState("");
  const [templateId, setTemplateId] = useState("");

  const onTemplateSelect = (id) => {
    setTemplateId(id);
    setUrlDialog(true);
  };
  const open = () => {
    req(
      "/generatev2",
      "POST",
      JSON.stringify({
        url,
        template_id: templateId,
      }),
    ).then((res) => {
      window.open("/design/" + res.design_id, "_blank");
    });
  };

  const pageWidth = window.innerWidth - 50;
  const width = isMobile ? pageWidth : pageWidth / 3;
  return (
    <>
      <Dialog open={urlDialog} fullScreen>
        <TextField fullWidth value={url} onChange={(e) => setUrl(e.target.value)} />
        <Button onClick={open}>Use this</Button>
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          background: "rgba(255,255,255,0.7)",

          visibility: isSearching ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </div>
      <Gallery
        style={{
          overflowY: isMobile ? "scroll" : "unset",
          width: "100%",
          maxWidth: "100%",
        }}
        data={results}
        render={(item, itemWidth) => <TemplateSearchResult item={item} itemWidth={itemWidth} isMobile={isMobile} onTemplateSelect={onTemplateSelect} isBookmarked={isBookmarked} bookmark={bookmark} />}
      />
    </>
  );
}
function TemplateSearchResult({ item, itemWidth, isMobile, onTemplateSelect, isBookmarked, bookmark }) {
  const ar = item.width / item.height;
  const itemHeight = itemWidth / ar;
  return <LazyImageWithAddBtn key={item.id} src={getTemplatePreviewUrl(item.id)} alt={item.title} width={isMobile ? "100%" : itemWidth} height={isMobile ? "auto" : itemHeight} onClick={() => onTemplateSelect(item.id)} addString={"Create Design"} isBookmarked={isBookmarked[item.id]} bookmark={() => bookmark(item.id)} />;
}
