import React, { useEffect, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import { IconButton } from "@mui/material";
import Share from "@mui/icons-material/Share";
import { useSVG } from "./SVGContext";

const ShareBtn = ({}) => {
  const { fileManager, svgRef, width, height, defs, designTitle, designId, setFullscreenLoading } = useSVG();
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    fileManager
      .getPreviewImage({
        svgRef,
        width,
        height,
        defs,
        scale: 0.05,
      })
      .then((canvas) => {
        canvas.toBlob(async (blob) => {
          const file = new File([blob], "image.png", { type: blob.type });
          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            setIsEnabled(true);
          }
        });
      })
      .catch((e) => {
        setIsEnabled(false);
      });
  }, [designId]);

  const handleShare = async () => {
    setFullscreenLoading(true);
    fileManager
      .getPreviewImage({
        svgRef,
        width,
        height,
        defs,
        scale: 1,
      })
      .then((canvas) => {
        canvas.toBlob(async (blob) => {
          const file = new File([blob], "image.png", { type: blob.type });
          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            try {
              await navigator.share({
                files: [file],
                title: designTitle,
                text: "Here is something cool.",
              });
            } catch (err) {
              console.error("Share failed:", err.message);
            }
          }
          setFullscreenLoading(false);
        }, "image/png");
      });
  };

  if (!isEnabled) return null;

  return (
    <div>
      <IconButton
        style={{
          color: "#ccc",
        }}
        onClick={handleShare}
        className="btn btn-primary"
      >
        <Share />
      </IconButton>
    </div>
  );
};

export default ShareBtn;
