export function Shape({ item, overlay }) {
  if (overlay) return;

  const id = `clip-${item.id}`;
  const props = { ...item };
  props.box = JSON.stringify(item.box);
  return (
    <>
      <rect
        style={{
          clipPath: `url(#${id})`,
        }}
        {...props}
      ></rect>
      <defs>
        <clipPath id={id}>
          <path d={item.d} />
        </clipPath>
      </defs>
    </>
  );
}
