// @ts-nocheck

import React, { useEffect, useRef } from "react";
import { useSVG } from "./SVGContext";
import { getCoordsInSvg } from "./utils/utils";

const DivWithEvents = ({ children, style, className, onMouseDown, onMouseMove, onMouseUp, onMouseOut, onTouchStart, onTouchMove, onTouchEnd, onTouchCancel, onKeyDown, onKeyUp, onContextMenu }) => {
  const { svgRef, zoomIn, zoomOut, pan } = useSVG();
  const svgParentRef = useRef(null);
  let [lastTouch, setLastTouch] = React.useState(null);

  const handleWheel = (event) => {
    const xy = getCoordsInSvg(svgRef, {
      x: event.clientX,
      y: event.clientY,
    });

    if (event.ctrlKey || event.metaKey) {
      if (event.deltaY < 0) {
        zoomIn(xy);
      } else {
        zoomOut(xy);
      }
      event.preventDefault(); // Prevent default scrolling behavior
    } else {
      // if not zooming, then pan
      pan(event.deltaX, event.deltaY);
    }
  };
  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      onTwoFingerTouchStart(e);
      return;
    }
    e.currentTarget.style.overflowY = "hidden";
    onTouchStart(e);
    lastTouch = e.touches[0];
    setLastTouch(lastTouch);
  };

  const handleTouchMove = (e) => {
    // console.log("ontouchmove", e.touches);
    if (e.touches.length === 2) {
      onTwoFingerTouchMove(e);
      return;
    }
    // see GOTCHA #1
    lastTouch = e.touches[0];
    setLastTouch(lastTouch);
    onTouchMove(e);
  };

  const handleTouchEnd = (e) => {
    // if (e.touches.length === 2) {
    onTwoFingerTouchEnd(e);
    //   return;
    // }

    // GOTCHA #1: touchend does not propogate the location, see https://stackoverflow.com/a/46263868/968903
    e.currentTarget.style.overflowY = "hidden";

    // e.touches = [lastTouch];
    onTouchEnd({
      ...e,
      touches: [lastTouch],
    });

    lastTouch = null;
    setLastTouch(lastTouch);
  };

  // two fingers

  const lastDistanceRef = useRef(null);

  const getDistance = (touches) => {
    const dx = touches[0].clientX - touches[1].clientX;
    const dy = touches[0].clientY - touches[1].clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };
  const onTwoFingerTouchStart = (e) => {
    lastDistanceRef.current = getDistance(e.touches);
  };
  const onTwoFingerTouchMove = (e) => {
    const currentDistance = getDistance(e.touches);
    if (lastDistanceRef.current !== null) {
      const zoomFactor = currentDistance / lastDistanceRef.current;
      const xy = getCoordsInSvg(svgRef, {
        x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
        y: (e.touches[0].clientY + e.touches[1].clientY) / 2,
      });

      if (zoomFactor > 1) {
        zoomIn(xy);
      } else {
        zoomOut(xy);
      }
    }
    lastDistanceRef.current = currentDistance;
  };
  const onTwoFingerTouchEnd = (e) => {
    lastDistanceRef.current = null;
  };

  /*
  Regarding pinch to zoom:
  1) First, if I add the events directly upon on the div here, you cannot call e.preventDefault() because React makes touch events as passive listeners. I need e.preventDefault() to prevent the default pinch to zoom behavior of the window.
  2) To solve this, you can add those events manually in useEffect, like we do for wheel.
  3) But if I do that, then the touch events are disconnected from context provider's state (tool, specifically). So it doesn't work either.
  4) Therefore, I've added touchAction: none to the parent of this div.
  */

  useEffect(() => {
    // doing this manually and not like <svg onWheel={...}> because react makes wheel & touch events as passive listeners
    // where you cannot call e.preventDefault() https://stackoverflow.com/a/67258046
    svgParentRef.current.addEventListener("wheel", handleWheel);
    return () => {
      svgParentRef.current && svgParentRef.current.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div
      className={className}
      style={style}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseUp}
      onMouseUp={onMouseUp}
      onMouseOut={onMouseOut}
      onTouchCancel={onTouchCancel}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      // onContextMenu={onContextMenu}
      ref={svgParentRef}
    >
      {children}
    </div>
  );
};

export default DivWithEvents;
