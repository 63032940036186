export const ShapeClipPaths = [
  { id: "__shape_361", d: "M0,0L256,0L256,256L0,256Z", width: 256, height: 256, transform: "scale(0.414315, 0.414315)" },
  { id: "__shape_363", d: "M89.30412124,0L166.69587876,0C216.01718304,0 256,39.98281696 256,89.30412124L256,166.69587876C256,216.01718304 216.01718304,256 166.69587876,256L89.30412124,256C39.98281696,256 0,216.01718304 0,166.69587876L0,89.30412124C0,39.98281696 39.98281696,0 89.30412124,0Z", width: 256, height: 256, transform: "scale(0.414315, 0.414315)" },
  { id: "__shape_364", d: "M128,0C57.30755202165845,0 0,57.30755202165844 0,128C0,198.69244797834153 57.307552021658424,256 128,256C198.69244797834153,256 256,198.6924479783416 256,128C256,57.30755202165848 198.6924479783416,0 128,0Z", width: 256, height: 256, transform: "scale(0.414315, 0.414315)" },
  { id: "__shape_365", d: "M146.28571428571428,0L292.57142857142856,256L0,256L146.28571428571428,0Z", width: 292.571, height: 256, transform: "scale(0.362525, 0.362525)" },
  { id: "__shape_366", d: "M146.28571428571428,256L292.57142857142856,0L0,0L146.28571428571428,256Z", width: 292.571, height: 256, transform: "scale(0.362525, 0.362525)" },
  { id: "__shape_367", d: "M128,0L256,128L128,256L0,128L128,0Z", width: 256, height: 256, transform: "scale(0.414315, 0.414315)" },
  { id: "__shape_368", d: "M128,0L256,74.47272727272727L256,223.4181818181818L128,297.8909090909091L0,223.4181818181818L0,74.47272727272727L128,0Z", width: 256, height: 297.891, transform: "scale(0.356052, 0.356052)" },
  { id: "__shape_369", d: "M128,0L256,74.47272727272727L256,223.4181818181818L128,297.8909090909091L0,223.4181818181818L0,74.47272727272727L128,0Z", width: 256, height: 297.891, transform: "scale(0.356052, 0.356052)" },
  { id: "__shape_370", d: "M297.8909090909091,128L223.4181818181818,256L74.47272727272727,256L0,128L74.47272727272727,0L223.4181818181818,0L297.8909090909091,128Z", width: 297.891, height: 256, transform: "scale(0.356052, 0.356052)" },
  { id: "__shape_398", d: "M128,0L168.7292,87.2708L256,128L168.7292,168.7292L128,256L87.2708,168.7292L0,128L87.2708,87.2708L128,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  { id: "__shape_399", d: "M134.29508196721312,0L165.99795409836068,97.78318688524591L268.59016393442624,97.78318688524591L185.59118688524592,158.2168131147541L217.29405901639345,256L134.29508196721312,195.56679344262295L51.29610491803279,256L82.99897704918034,158.2168131147541L0,97.78318688524591L102.59220983606558,97.78318688524591L134.29508196721312,0Z", width: 268.59, height: 256, transform: "scale(0.886519, 0.886519)" },
  { id: "__shape_400", d: "M128,0L179.87839999999997,57.35131428571428L256,73.14285714285714L231.7568,146.28571428571428L256,219.42857142857142L179.87839999999997,235.22011428571426L128,292.57142857142856L76.12159999999999,235.22011428571426L0,219.42857142857142L24.2432,146.28571428571428L0,73.14285714285714L76.12159999999999,57.35131428571428L128,0Z", width: 256, height: 292.571, transform: "scale(0.813853, 0.813853)" },
  { id: "__shape_401", d: "M128,0L164.7376,39.30756L218.5096,37.49032L216.6924,91.2624L256,128L216.6924,164.7376L218.5096,218.5096L164.7376,216.6924L128,256L91.2624,216.6924L37.49032,218.5096L39.30756,164.7376L0,128L39.30756,91.2624L37.49032,37.49032L91.2624,39.30756L128,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  { id: "__shape_402", d: "M128,0L152.8468,35.27112L192,17.14876L195.8824,60.1176L238.8512,64L220.7288,103.1532L256,128L220.7288,152.8468L238.8512,192L195.8824,195.8824L192,238.8512L152.8468,220.7288L128,256L103.1532,220.7288L64,238.8512L60.1176,195.8824L17.14876,192L35.27112,152.8468L0,128L35.27112,103.1532L17.14876,64L60.1176,60.1176L64,17.14876L103.1532,35.27112L128,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  {
    id: "__shape_403",
    d: "M128,0L146.8964,27.57832L174.4372,8.71776L182.1376,41.2892L214.6024,33.69372L210.0672,66.8596L243.0716,71.5548L226.9128,100.8352L256,117.1872L230.4,138.6284L251.6412,164.4288L220.0572,175.1348L230.584,206.8988L197.282,205.4232L195.672,238.8612L165.1496,225.404L151.6208,256L128,232.3784L104.3792,256L90.8504,225.404L60.328,238.8612L58.718,205.4232L25.4162,206.8988L35.94256,175.1348L4.35888,164.4288L25.6,138.6284L0,117.1872L29.08712,100.8352L12.9282,71.5548L45.9328,66.8596L41.3976,33.69372L73.8624,41.2892L81.5628,8.71776L109.1036,27.57832L128,0Z",
    width: 256,
    height: 256,
    transform: "scale(0.930118, 0.930118)",
  },
  { id: "__shape_404", d: "M128,0L170.2728,25.94456L218.5096,37.49032L230.0556,85.7272L256,128L230.0556,170.2728L218.5096,218.5096L170.2728,230.0556L128,256L85.7272,230.0556L37.49032,218.5096L25.94456,170.2728L0,128L25.94456,85.7272L37.49032,37.49032L85.7272,25.94456L128,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  {
    id: "__shape_405",
    d: "M128,0L146.0212,14.21832L167.554,6.26476L180.2996,25.35604L203.2364,24.44584L209.4588,46.5412L231.554,52.7636L230.644,75.7004L249.7352,88.446L241.7816,109.9788L256,128L241.7816,146.0212L249.7352,167.554L230.644,180.2996L231.554,203.2364L209.4588,209.4588L203.2364,231.554L180.2996,230.644L167.554,249.7352L146.0212,241.7816L128,256L109.9788,241.7816L88.446,249.7352L75.7004,230.644L52.7636,231.554L46.5412,209.4588L24.44584,203.2364L25.35604,180.2996L6.26476,167.554L14.21832,146.0212L0,128L14.21832,109.9788L6.26476,88.446L25.35604,75.7004L24.44584,52.7636L46.5412,46.5412L52.7636,24.44584L75.7004,25.35604L88.446,6.26476L109.9788,14.21832L128,0Z",
    width: 256,
    height: 256,
    transform: "scale(0.930118, 0.930118)",
  },
  {
    id: "__shape_406",
    d: "M128,0L140.4176,10.5032L154.7592,2.797104L164.7096,15.6384L180.3492,11.0662L187.3972,25.68428L203.6508,24.44584L207.4892,40.202L223.6464,42.3512L224.1068,58.5568L239.462,64L236.5244,79.9464L250.4056,88.446L244.1988,103.4364L256,114.6204L246.7948,128L256,141.3796L244.1988,152.5636L250.4056,167.554L236.5244,176.0536L239.462,192L224.1068,197.4432L223.6464,213.6488L207.4892,215.798L203.6508,231.554L187.3972,230.3156L180.3492,244.934L164.7096,240.3616L154.7592,253.2028L140.4176,245.4968L128,256L115.5824,245.4968L101.2408,253.2028L91.2904,240.3616L75.6508,244.934L68.6028,230.3156L52.3492,231.554L48.5108,215.798L32.35352,213.6488L31.89304,197.4432L16.53816,192L19.4756,176.0536L5.5942,167.554L11.8012,152.5636L0,141.3796L9.20524,128L0,114.6204L11.8012,103.4364L5.5942,88.446L19.4756,79.9464L16.53816,64L31.89304,58.5568L32.35352,42.3512L48.5108,40.202L52.3492,24.44584L68.6028,25.68428L75.6508,11.0662L91.2904,15.6384L101.2408,2.797104L115.5824,10.5032L128,0Z",
    width: 256,
    height: 256,
    transform: "scale(0.930118, 0.930118)",
  },
  { id: "__shape_407", d: "M256,128L128,0L128,64L0,64L0,192L128,192L128,256L256,128Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  { id: "__shape_408", d: "M0,128L128,0L128,64L256,64L256,192L128,192L128,256L0,128Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  { id: "__shape_409", d: "M128,0L0,128L64,128L64,256L192,256L192,128L256,128L128,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  { id: "__shape_410", d: "M128,256L0,128L64,128L64,0L192,0L192,128L256,128L128,256Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  { id: "__shape_411", d: "M131.04761904761904,0L0,128L131.04761904761904,256L131.04761904761904,192L259.04761904761904,192L259.04761904761904,256L390.0952380952381,128L259.04761904761904,0L259.04761904761904,64L131.04761904761904,64L131.04761904761904,0Z", width: 390.095, height: 256, transform: "scale(0.61039, 0.61039)" },
  { id: "__shape_412", d: "M384,0L0,0L0,256L384,256L512,128L384,0Z", width: 512, height: 256, transform: "scale(0.465059, 0.465059)" },
  { id: "__shape_413", d: "M0,0L384,0L512,128L384,256L0,256L128,128L0,0Z", width: 512, height: 256, transform: "scale(0.465059, 0.465059)" },
  { id: "__shape_414", d: "M512,0L0,0L64,128L0,256L512,256L448,128L512,0Z", width: 512, height: 256, transform: "scale(0.465059, 0.465059)" },
  { id: "__shape_415", d: "M384,0L128,0L0,128L128,256L384,256L512,128L384,0Z", width: 512, height: 256, transform: "scale(0.465059, 0.465059)" },
  { id: "__shape_416", d: "M384,0C454.69244797834153,0 512,57.30755202165845 512,128C512,198.69244797834153 454.69244797834153,256 384,256L128,256C57.30755202165845,256 0,198.6924479783416 0,128C0,57.30755202165848 57.307552021658424,0 128,0Z", width: 512, height: 256, transform: "scale(0.465059, 0.465059)" },
  { id: "__shape_417", d: "M256,0L0,0L0,196.8L49.6,196.8L49.6,256L146,196.8L256,196.8L256,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  {
    id: "__shape_418",
    d: "M190.90971428571427,0L101.65394285714285,0C45.50774857142857,0 0,44.45874285714286 0,99.30102857142857C0,139.00342857142857 23.857417142857145,173.24982857142857 58.325942857142856,189.13874285714283L58.325942857142856,256L127.36777142857142,198.59885714285713L190.90971428571427,198.59885714285713C247.05965714285713,198.59885714285713 292.57142857142856,154.1398857142857 292.57142857142856,99.29737142857142C292.57554285714286,44.45874285714286 247.05965714285713,0 190.90971428571427,0Z",
    width: 292.571,
    height: 256,
    transform: "scale(0.813853, 0.813853)",
  },
  {
    id: "__shape_419",
    d: "M11.598857142857142,40.875611428571425C-12.123199999999999,79.95702857142857 4.58912,117.99634285714285 25.59145142857143,138.1856L148.3904,256L268.5974857142857,138.60662857142856C288.1312,116.88822857142856 295.6187428571429,93.85097142857143 291.46011428571427,68.19885714285714C285.71611428571424,32.7136 256.47405714285713,5.18272 220.35062857142856,1.2513188571428573C198.19565714285713,-1.1341165714285715 176.7936,5.135634285714286 160.09005714285715,19.016045714285713C155.59405714285714,22.75072 151.57577142857144,26.925942857142854 148.0736,31.464091428571425C143.9181714285714,26.29705142857143 139.04594285714285,21.57046857142857 133.5350857142857,17.381439999999998C114.32685714285714,2.7834285714285714 89.87611428571428,-3.0125439999999997 66.38994285714286,1.495126857142857C44.145782857142855,5.792228571428571 24.17938285714286,20.14089142857143 11.598857142857142,40.875611428571425Z",
    width: 292.571,
    height: 256,
    transform: "scale(0.813853, 0.813853)",
  },
  { id: "__shape_420", d: "M176,0L80,0L80,80L0,80L0,176L80,176L80,256L176,256L176,176L256,176L256,80L176,80L176,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  {
    id: "__shape_421",
    d: "M221.48754285714284,0C225.76639999999998,0 230.07085714285714,0 234.3411809523809,0C268.38735238095234,4.275736380952381 289.66460952380953,18.50855619047619 299.3560380952381,41.79785142857143C356.1167238095238,38.69202285714285 397.3851428571428,82.72883809523809 372.2349714285714,125.94895238095236C380.5982476190476,135.03085714285714 387.5754666666666,145.18979047619047 390.0952380952381,158.8248380952381C390.0952380952381,162.73066666666665 390.0952380952381,166.62735238095237 390.0952380952381,170.53257142857143C382.58590476190477,205.2352 350.273219047619,228.6848 297.2245333333333,222.3719619047619C283.5754666666666,240.1901714285714 259.2883809523809,258.03398095238094 221.49546666666666,255.81165714285711C201.96083809523807,254.64198095238095 188.37089523809522,247.86651428571426 176.47238095238095,239.63489523809523C163.9399619047619,246.4774095238095 150.3670857142857,251.84731428571428 130.75626666666668,251.9064380952381C85.3863619047619,252.00761904761902 55.03567238095238,225.6463238095238 54.29991619047619,189.48754285714284C25.133043809523805,181.0285714285714 5.378407619047619,165.23885714285714 0,138.22049523809522C0,134.3152761904762 0,130.40152380952378 0,126.5127619047619C5.936542476190477,99.73942857142856 24.617203809523808,82.92205714285714 55.72065523809523,75.79306666666666C53.851733333333335,30.628754285714283 116.06735238095239,2.4072045714285712 167.17897142857143,22.30454857142857C179.34811428571427,12.465310476190476 196.56533333333334,1.7338636190476189 221.48754285714284,0Z",
    width: 390.095,
    height: 256,
    transform: "scale(0.61039, 0.61039)",
  },
  { id: "__shape_422", d: "M256,0L256,281.6L128,327.68L0,281.6L0,0L256,0Z", width: 256, height: 327.68, transform: "scale(0.726655, 0.726655)" },
  { id: "__shape_423", d: "M256,0L256,282.48275862068965L128,238.3448275862069L0,282.48275862068965L0,0L256,0Z", width: 256, height: 282.483, transform: "scale(0.84292, 0.84292)" },
  { id: "__shape_424", d: "M230.9864,0L25.01356,0C25.01356,13.76344 13.88312,25.01356 0,25.01356L0,230.9864C13.76344,230.9864 25.01356,242.1168 25.01356,256L230.9864,256C230.9864,242.2364 242.1168,230.9864 256,230.9864L256,25.01356C242.2364,25.01356 230.9864,13.88312 230.9864,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  { id: "__shape_425", d: "M205.6,0L50.4,0L0,50.4L0,205.6L50.4,256L205.6,256L256,205.6L256,50.4L205.6,0Z", width: 256, height: 256, transform: "scale(0.930118, 0.930118)" },
  { id: "__shape_426", d: "M0,18.285714285714285L146.28571428571428,0L292.57142857142856,18.285714285714285L292.57142857142856,237.7142857142857L146.28571428571428,256L0,237.7142857142857L0,18.285714285714285Z", width: 292.571, height: 256, transform: "scale(0.813853, 0.813853)" },
  { id: "__shape_427", d: "M85.33333333333333,0L341.3333333333333,0L256,256L0,256L85.33333333333333,0Z", width: 341.333, height: 256, transform: "scale(0.697589, 0.697589)" },
  { id: "__shape_428", d: "M256,0L0,0L85.33333333333333,256L341.3333333333333,256L256,0Z", width: 341.333, height: 256, transform: "scale(0.697589, 0.697589)" },
  { id: "__shape_429", d: "M85.33333333333333,0L256,0L341.3333333333333,256L0,256L85.33333333333333,0Z", width: 341.333, height: 256, transform: "scale(0.697589, 0.697589)" },
  { id: "__shape_430", d: "M85.33333333333333,256L256,256L341.3333333333333,0L0,0L85.33333333333333,256Z", width: 341.333, height: 256, transform: "scale(0.697589, 0.697589)" },
  {
    id: "__shape_431",
    d: "M85.37944615384616,307.6849230769231C98.50387692307693,312.1481846153846 113.42473846153847,315.0769230769231 128.06892307692308,315.0769230769231C142.7136,315.0769230769231 156.8054153846154,312.5661538461539 169.7915076923077,308.1028923076923C170.0681846153846,307.9635692307693 170.34436923076925,307.9635692307693 170.62055384615385,307.82424615384616C219.38904615384618,289.9712 255.30929230769232,242.8283076923077 256,187.73513846153847L256,0L0,0L0,187.5958153846154C0.6907716923076923,243.10695384615389 36.05828923076923,290.2503384615385 85.37944615384616,307.6849230769231Z",
    width: 256,
    height: 315.077,
    transform: "scale(0.755721, 0.755721)",
  },
  {
    id: "__shape_432",
    d: "M85.37944615384616,7.3921969230769236C98.46153846153847,2.9289747692307695 113.42473846153847,0 128.06892307692308,0C142.7136,0 156.8054153846154,2.5105526153846154 169.7915076923077,6.9737846153846155C170.0681846153846,7.113304615384616 170.34436923076925,7.113304615384616 170.62055384615385,7.252726153846154C219.38904615384618,25.105723076923077 255.30929230769232,72.24861538461539 256,127.34178461538461L256,315.0769230769231L0,315.0769230769231L0,127.4811076923077C0.6907716923076923,71.96996923076924 36.05828923076923,24.82673230769231 85.37944615384616,7.3921969230769236Z",
    width: 256,
    height: 315.077,
    transform: "scale(0.755721, 0.755721)",
  },
];
