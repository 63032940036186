// from https://gist.github.com/acodesmith/b4a17519eee1be5a9b17cddbe06d72fe
export const WebSafeFonts = ["Arial", "Calibri", "Century Gothic", "Gill Sans", "Tahoma", "Trebuchet MS", "Verdana", "Book Antiqua", "Cambria", "Garamond", "Georgia", "Goudy Old Style", "Lucida Bright", "Palatino", "Baskerville", "Times New Roman", "Courier New", "Lucida Sans Typewriter", "Copperplate", "Brush Script MT"];

// from https://www.dreamhost.com/blog/best-google-fonts/
export const GoogleFontFamilies = [
  "Abril Fatface",
  "Alfa Slab One",
  "Anonymous Pro",
  "Antic Didone",
  "Azeret Mono",
  "Bebas Neue",
  "Berkshire Swash",
  "Caveat",
  "Cedarville Cursive",
  "Cinzel",
  "Comfortaa",
  "Cormorant",
  "Courier Prime",
  "Dancing Script",
  "Fira Sans",
  "Homemade Apple",
  "IBM Plex Mono",
  "Inconsolata",
  "Indie Flower",
  "Italiana",
  "JetBrains Mono",
  "La Belle Aurore",
  "Lato",
  "League Script",
  "Libre Baskerville",
  "Lobster",
  "Lora",
  "Merriweather",
  "Montserrat",
  "Noto Sans Mono",
  "Noto Serif",
  "Old Standard TT",
  "Open Sans",
  "Oswald",
  "PT Serif",
  "Pacifico",
  "Playfair Display",
  "Poppins",
  "Proza Libre",
  "Raleway",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Shadows Into Light",
  "Slabo",
  "Source Code Pro",
  "Space Mono",
  "Vollkorn",
  "Work Sans",
];
