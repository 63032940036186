import { useState } from "react";
import { TextField, Button } from "@mui/material";
import { req } from "./utils/ServerUtils";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const resetPassword = async (e) => {
    req("/forgot_password", "POST", JSON.stringify({ email })).then((res) => {
      if (res.error) {
        setMsg(res.error);
      } else if (res.email_sent) {
        setMsg("An email has been sent to you with a link to reset your password. Please click on it.");
      }
    });
  };

  if (msg) {
    return (
      <div
        style={{
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
          padding: 40,
          margin: "auto",
          maxWidth: 400,
        }}
      >
        {msg}
      </div>
    );
  }

  return (
    <div
      style={{
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        padding: 40,
        margin: "auto",
        maxWidth: 400,

        display: "flex",
        flexDirection: "column",
        gap: 20,
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: "1.5rem",
          fontWeight: 600,
        }}
      >
        Reset Password
      </div>
      <div>Lost your password? Please enter your email address. You will receive a link to create a new password via email.</div>
      <TextField sx={{ width: "100%" }} type="email" name="email" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)} />

      <Button
        fullWidth
        type="submit"
        variant="contained"
        onClick={() => {
          resetPassword();
        }}
      >
        Reset Password
      </Button>
    </div>
  );
};

export default ForgotPassword;
