// Update Color
export function updateColor(proseMirrorData, newColor) {
  function updateNodeColor(node) {
    if (node.marks) {
      node.marks = node.marks.map((mark) => {
        if (mark.type === "textStyle") {
          return {
            ...mark,
            attrs: {
              ...mark.attrs,
              color: newColor,
            },
          };
        }
        return mark;
      });
    }

    if (node.content) {
      node.content = node.content.map(updateNodeColor);
    }

    return node;
  }

  return {
    ...proseMirrorData,
    content: proseMirrorData.content.map(updateNodeColor),
  };
}

// Update Text Size
export function updateTextSize(proseMirrorData, newSize) {
  function updateNodeTextSize(node) {
    if (node.marks) {
      node.marks = node.marks.map((mark) => {
        if (mark.type === "textStyle") {
          return {
            ...mark,
            attrs: {
              ...mark.attrs,
              fontSize: newSize,
            },
          };
        }
        return mark;
      });
    }

    if (node.content) {
      node.content = node.content.map(updateNodeTextSize);
    }

    return node;
  }

  return {
    ...proseMirrorData,
    content: proseMirrorData.content.map(updateNodeTextSize),
  };
}

// Update Line Height
export function updateLineHeight(proseMirrorData, newLineHeight) {
  function updateNodeLineHeight(node) {
    if (node.marks) {
      node.marks = node.marks.map((mark) => {
        if (mark.type === "textStyle") {
          return {
            ...mark,
            attrs: {
              ...mark.attrs,
              lineHeight: newLineHeight,
            },
          };
        }
        return mark;
      });
    }

    if (node.content) {
      node.content = node.content.map(updateNodeLineHeight);
    }

    return node;
  }

  return {
    ...proseMirrorData,
    content: proseMirrorData.content.map(updateNodeLineHeight),
  };
}

// Update Font Face
export function updateFontFace(proseMirrorData, newFontFace) {
  function updateNodeFontFace(node) {
    if (node.marks) {
      node.marks = node.marks.map((mark) => {
        if (mark.type === "textStyle") {
          return {
            ...mark,
            attrs: {
              ...mark.attrs,
              fontFamily: newFontFace,
            },
          };
        }
        return mark;
      });
    }

    if (node.content) {
      node.content = node.content.map(updateNodeFontFace);
    }

    return node;
  }

  return {
    ...proseMirrorData,
    content: proseMirrorData.content.map(updateNodeFontFace),
  };
}

// Update Text Alignment
export function updateTextAlign(proseMirrorData, newAlign) {
  function updateNodeTextAlign(node) {
    if (node.marks) {
      node.marks = node.marks.map((mark) => {
        if (mark.type === "align") {
          return {
            ...mark,
            attrs: {
              ...mark.attrs,
              align: newAlign,
            },
          };
        }
        return mark;
      });
    }

    if (node.content) {
      node.content = node.content.map(updateNodeTextAlign);
    }

    return node;
  }

  return {
    ...proseMirrorData,
    content: proseMirrorData.content.map(updateNodeTextAlign),
  };
}

// Update Bold, Italic, and Underline
export function updateBoldItalicUnderline(proseMirrorData, { bold = false, italic = false, underline = false }) {
  function updateNodeTextStyles(node) {
    if (node.marks) {
      let updatedMarks = [...node.marks];

      if (bold != undefined) {
        if (bold) {
          updatedMarks = addOrUpdateMark(updatedMarks, "bold");
        } else {
          updatedMarks = removeMark(updatedMarks, "bold");
        }
      }

      if (italic != undefined) {
        if (italic) {
          updatedMarks = addOrUpdateMark(updatedMarks, "italic");
        } else {
          updatedMarks = removeMark(updatedMarks, "italic");
        }
      }
      if (underline != undefined) {
        if (underline) {
          updatedMarks = addOrUpdateMark(updatedMarks, "underline");
        } else {
          updatedMarks = removeMark(updatedMarks, "underline");
        }
      }

      node.marks = updatedMarks;
    }

    if (node.content) {
      node.content = node.content.map(updateNodeTextStyles);
    }

    return node;
  }

  function addOrUpdateMark(marks, type) {
    const existingMarkIndex = marks.findIndex((mark) => mark.type === type);
    if (existingMarkIndex === -1) {
      marks.push({ type });
    }
    return marks;
  }

  function removeMark(marks, type) {
    return marks.filter((mark) => mark.type !== type);
  }

  return {
    ...proseMirrorData,
    content: proseMirrorData.content.map(updateNodeTextStyles),
  };
}
