import { useEffect, useState } from "react";
import { deleteImage, fetchImages, searchIcons, searchPexelsImages, uploadImage } from "../utils/ServerUtils";
import { DARKTHEME, THEME_BG, useSVG } from "../SVGContext";
import "./ImagesPanel.css";
import { Box, CircularProgress, IconButton, ImageList, ImageListItem, Tab, Tabs, TextField, ThemeProvider } from "@mui/material";

import { ImageUploads } from "./ImageUploads";
import { ScrollableImageList } from "./ScrollableImageList";
import { ImageWithSkeleton } from "./ImageWithSkeleton";

const unselectedTabBackground = THEME_BG;
const unselectedTabText = "#aaa";

const selectedTabBackground = "black";
const selectedTabText = "white";

function CustomTabPanel(props) {
  const { children, value, index } = props;
  if (value === index) return <>{children}</>;
}

export function ImagesPanel() {
  const [images, setImages] = useState([]);
  const [tabValue, setTabValue] = useState(1);
  const { server } = useSVG();

  useEffect(() => {
    server.fetchImages().then(setImages);
  }, []);

  function reload() {
    server.fetchImages(true).then(setImages);
  }
  return (
    <>
      <div>
        <Tabs
          value={tabValue}
          onChange={(e, v) => setTabValue(v)}
          TabIndicatorProps={{
            sx: { display: "none" },
          }}
        >
          <Tab
            label="Library"
            value={1}
            style={{
              background: tabValue === 1 ? selectedTabBackground : unselectedTabBackground,
              color: tabValue === 1 ? selectedTabText : unselectedTabText,
              textTransform: "none",
            }}
          />
          <Tab
            label="Uploads"
            value={0}
            style={{
              background: tabValue === 0 ? selectedTabBackground : unselectedTabBackground,
              color: tabValue === 0 ? selectedTabText : unselectedTabText,
              textTransform: "none",
            }}
          />
        </Tabs>
      </div>
      <CustomTabPanel role="tabpanel" value={tabValue} index={1}>
        <Library />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={0}>
        <ImageUploads images={images} reload={reload} />
      </CustomTabPanel>
    </>
  );
}

export function iconIdToIconSrc(id) {
  const prefix = id.split("/")[0];
  const idWithoutPrefix = id.split("/")[1];
  let suffix;
  if (prefix == "1") {
    suffix = `${id}/${idWithoutPrefix}.svg`;
  } else {
    suffix = `${id}.svg`;
  }
  return `https://movingvectors.s3.amazonaws.com/${suffix}`;
}
function Library({}) {
  const [searchTabValue, setSearchTabValue] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState("");

  let [page, setPage] = useState({ pexels: 1, icons: 1 });
  const [isFinished, setIsFinished] = useState({ pexels: false, icons: false });

  const { addImage, addVector } = useSVG();

  function idsToSrcs(ids) {
    return ids.map((id) => {
      return {
        src: iconIdToIconSrc(id),
      };
    });
  }

  function search() {
    if (searchTabValue == 0) {
      searchPexelsImages(query, page.pexels).then((res) => {
        setSearchResults((prev) => [...prev, ...res.images]);
        page = { ...page, pexels: +res.page + 1 };
        setPage((prev) => ({ ...prev, pexels: +res.page + 1 }));
        if (res.images.length == 0) {
          setIsFinished((prev) => ({ ...prev, pexels: true }));
        }
      });
    } else {
      searchIcons(query, page.icons).then((res) => {
        setSearchResults((prev) => [...prev, ...idsToSrcs(res.svgs)]);
        page = { ...page, icons: +res.page + 1 };
        setPage((prev) => ({ ...prev, icons: +res.page + 1 }));
        if (res.images == 0) {
          setIsFinished((prev) => ({ ...prev, icons: true }));
        }
      });
    }
  }

  function reset() {
    setSearchResults([]);

    setPage({ pexels: 1, icons: 1 });
    setIsFinished({ pexels: false, icons: false });
  }

  useEffect(() => {
    reset();
    query && search(query);
  }, [searchTabValue]);

  useEffect(() => {
    reset();
  }, [query]);

  return (
    <>
      <ThemeProvider theme={DARKTHEME}>
        <div
          style={{
            background: selectedTabBackground,
            padding: 10,
            color: selectedTabText,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search Images"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                search(query);
              }
            }}
          />
        </div>

        {(query.length > 0 || searchResults.length > 0) && (
          <div
            style={{
              background: selectedTabBackground,
              color: selectedTabText,
            }}
          >
            <Tabs value={searchTabValue} onChange={(e, v) => setSearchTabValue(v)}>
              <Tab
                label="Icons"
                value={1}
                style={{
                  textTransform: "none",
                }}
              />
              <Tab
                label="Images"
                value={0}
                style={{
                  textTransform: "none",
                }}
              />
            </Tabs>
          </div>
        )}

        <CustomTabPanel value={searchTabValue} index={1}>
          {searchResults.length > 0 && <ScrollableImageList page={page.icons} onClick={(o) => addVector(o)} images={searchResults} fetchMore={() => search(query)} renderImage={(image) => <SearchResultImageSvg image={image} />} finished={isFinished["icons"]} cols={4} />}
        </CustomTabPanel>
        <CustomTabPanel value={searchTabValue} index={0}>
          {searchResults.length > 0 && <ScrollableImageList page={page.pexels} onClick={addImage} images={searchResults} fetchMore={() => search(query)} renderImage={(image) => <SearchResultImagePexels image={image} />} finished={isFinished["pexels"]} cols={2} />}
        </CustomTabPanel>
      </ThemeProvider>
    </>
  );
}

function SearchResultImagePexels({ image }) {
  const aspectRatio = image.width / image.height;
  const w = 300;
  const h = w / aspectRatio;
  const tinySrc = image.src + `?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=${h}&w=${w}`;
  return <ImageWithSkeleton src={tinySrc} alt={image.alt} height={h} width={"100%"} objectFit="contain" />;
}

function SearchResultImageSvg({ image }) {
  return (
    <div
      style={{
        background: "white",
        width: 40,
      }}
    >
      <ImageWithSkeleton src={image.src} height={"auto"} width={"40px"} objectFit="contain" />
    </div>
  );
}
