import React, { useState } from "react";
import { TextField } from "@mui/material";
import { OrDivider } from "./OrDivider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AiSubjectAutocomplete from "./AiSubjectAutocomplete";

export default function AiSubjectChooser({ url, setUrl, selectedSubject, setSelectedSubject }) {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleTabChange}
        style={{
          paddingBottom: 10,
          borderBottom: "1px solid #ccc",
        }}
      >
        <Tab label="Blog URL" />
        <Tab label="Select Topic" />
      </Tabs>
      <div
        style={{
          padding: 10,
        }}
      >
        {value === 0 && (
          <div>
            <TextField label="Paste a Blog Link" fullWidth value={url} onChange={(e) => setUrl(e.target.value)} />
          </div>
        )}

        {value === 1 && (
          <div>
            <AiSubjectAutocomplete setSelectedSubject={setSelectedSubject} />
          </div>
        )}
      </div>
    </div>
  );
}
