import { useSVG } from "../SVGContext";
import { rand } from "../utils";
import { ROOT_GROUP_ID } from "../values/constants";

const texts = [
  {
    name: "Heading 1",
    proseMirrorData: {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: {
            level: 1,
          },
          content: [
            {
              type: "text",
              text: "Heading 1",
              marks: [
                {
                  type: "textStyle",
                  attrs: {
                    fontSize: "36px",
                    fontFamily: "Roboto Condensed",
                    lineHeight: "1.2",
                  },
                },
                {
                  type: "bold",
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      fontSize: "36px",
      fontWeight: "bold",
      lineHeight: "1.2",
      fontFamily: "Roboto Condensed",
    },
  },
  {
    name: "Heading 2",
    proseMirrorData: {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: {
            level: 2,
          },
          content: [
            {
              type: "text",
              text: "Heading 2",
              marks: [
                {
                  type: "textStyle",
                  attrs: {
                    fontSize: "30px",
                    fontFamily: "Roboto Condensed",
                    lineHeight: "1.2",
                  },
                },
                {
                  type: "bold",
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      fontSize: "30px",
      fontWeight: "bold",
      lineHeight: "1.2",
      fontFamily: "Roboto Condensed",
    },
  },
  {
    name: "Heading 3",
    proseMirrorData: {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: {
            level: 3,
          },
          content: [
            {
              type: "text",
              text: "Heading 3",
              marks: [
                {
                  type: "textStyle",
                  attrs: {
                    fontSize: "24px",
                    fontFamily: "Roboto Condensed",
                    lineHeight: "1.2",
                  },
                },
                {
                  type: "bold",
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "1.2",
      fontFamily: "Roboto Condensed",
    },
  },
  {
    name: "Heading 4",
    proseMirrorData: {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: {
            level: 4,
          },
          content: [
            {
              type: "text",
              text: "Heading 4",
              marks: [
                {
                  type: "textStyle",
                  attrs: {
                    fontSize: "20px",
                    fontFamily: "Roboto Condensed",
                    lineHeight: "1.2",
                    color: "#717178",
                  },
                },
                {
                  type: "bold",
                },
                {
                  type: "italic",
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: "1.2",
      fontFamily: "Roboto Condensed",
      color: "#717178",
      fontDecoration: "italic",
    },
  },
  {
    name: "Heading 5",
    proseMirrorData: {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: {
            level: 5,
          },
          content: [
            {
              type: "text",
              text: "Heading 5",
              marks: [
                {
                  type: "textStyle",
                  attrs: {
                    fontSize: "18px",
                    fontFamily: "Roboto Condensed",
                    lineHeight: "1.2",
                    color: "#717178",
                  },
                },
                {
                  type: "bold",
                },
                {
                  type: "italic",
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "1.2",
      fontFamily: "Roboto Condensed",
      color: "#717178",
      fontDecoration: "italic",
    },
  },
  {
    name: "Heading 6",
    proseMirrorData: {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: {
            level: 6,
          },
          content: [
            {
              type: "text",
              text: "Heading 6",
              marks: [
                {
                  type: "textStyle",
                  attrs: {
                    fontSize: "16px",
                    fontFamily: "Roboto Condensed",
                    lineHeight: "1.2",
                    color: "#717178",
                  },
                },
                {
                  type: "bold",
                },
                {
                  type: "italic",
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "1.2",
      fontFamily: "Roboto Condensed",
      color: "#717178",
      fontDecoration: "italic",
    },
  },
  {
    name: "Paragraph",
    proseMirrorData: {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "This is a paragraph.",
              marks: [
                {
                  type: "textStyle",
                  attrs: {
                    fontSize: "16px",
                    fontFamily: "Roboto Condensed",
                    lineHeight: "1.2",
                    color: "#2B2B35",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      fontSize: "16px",
      lineHeight: "1.2",
      color: "#2B2B35",
      fontFamily: "Roboto Condensed",
    },
  },
];

export function TextPanel({}) {
  const style = { padding: "15px", width: "100%", overflowY: "scroll", display: "flex", flexDirection: "column", gap: 15 };
  const { addText } = useSVG();

  return (
    <>
      <div style={style}>
        {texts.map((t, index) => {
          return (
            <div
              style={{
                ...t.style,
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                // fontFamily: t.style.fontFamily,
              }}
              key={index}
              onClick={() => addText(t.name, t.proseMirrorData)}
            >
              <Italic on={t.style.fontDecoration === "italic"}>
                <Bold on={t.style.fontWeight === "bold"}>
                  <p>{t.name}</p>
                </Bold>
              </Italic>
            </div>
          );
        })}
      </div>
    </>
  );
}

function Italic({ on, children }) {
  if (!on) return children;
  return <em>{children}</em>;
}
function Bold({ on, children }) {
  if (!on) return children;
  return <strong>{children}</strong>;
}
