import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import Color from "@tiptap/extension-color";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import FontFamily from "@tiptap/extension-font-family";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from "@tiptap/extension-table-cell";
import Placeholder from "@tiptap/extension-placeholder";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import FloatingMenu from "@tiptap/extension-floating-menu";
import CharacterCount from "@tiptap/extension-character-count";
import FontSize from "./extensions/FontSize"; // Custom FontSize extension
import LineHeight from "./extensions/LineHeight"; // Custom LineHeight extension
import { Extension } from "@tiptap/core";

const DisableDraggablePlugin = Extension.create({
  name: "disableDraggable",

  // Modify global attributes for all node types
  addGlobalAttributes() {
    return [
      {
        types: ["*"], // This should be a string '*' to target all nodes.
        attributes: {
          draggable: {
            default: false,
            parseHTML: () => false,
            renderHTML: () => ({ draggable: "false" }),
          },
        },
      },
    ];
  },
});

export const EditorExtensions = [
  StarterKit,
  TextStyle,
  TextAlign.configure({ types: ["heading", "paragraph"] }),
  Link,
  Underline,
  Highlight,
  Color,
  Subscript,
  Superscript,
  TaskList,
  TaskItem,
  FontFamily.configure({
    types: ["textStyle"], // Ensure it works with TextStyle
  }),
  FontSize, // Use custom FontSize extension
  LineHeight, // Use custom LineHeight extension
  Table, // Table extension
  TableRow, // Required for table rows
  TableHeader, // Required for table headers
  TableCell, // Required for table cells      Focus,
  Placeholder,
  BubbleMenu,
  FloatingMenu,
  CharacterCount,
  DisableDraggablePlugin, // Add this plugin to disable dragging for all nodes
];
