import { useSVG } from "./SVGContext";

const fill = "rgba(0, 0, 0, 0.8)";
const FadedRects = ({}) => {
  const { width, height } = useSVG();
  const extra = 1000000;
  return (
    <>
      {/* Top rectangle */}
      <rect type="boundary-rect" stroke="transparent" x={-extra} y={-height - extra} width={extra + width + extra} height={height + extra} fill={fill} />
      {/* Bottom rectangle */}
      <rect type="boundary-rect" stroke="transparent" x={-extra} y={height} width={extra + width + extra} height={extra} fill={fill} />
      {/* Left rectangle */}
      <rect type="boundary-rect" stroke="transparent" x={-width - extra} y={0} width={width + extra} height={height} fill={fill} />
      {/* Right rectangle */}
      <rect type="boundary-rect" stroke="transparent" x={width} y={0} width={width + extra} height={height} fill={fill} />
    </>
  );
};

export default FadedRects;
