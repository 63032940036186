import React from "react";
import { Autocomplete, TextField } from "@mui/material";
const chipOptions = {
  chips: [
    {
      label: "HR - Employee Engagement",
      dropdown: [
        "Effective time management techniques",
        "Improving email communication skills",
        "Mastering remote work productivity",
        "Strategies for successful virtual meetings",
        "Cybersecurity best practices",
        "Stress management in the workplace",
        "Developing emotional intelligence",
        "Enhancing teamwork and collaboration",
        "Personal branding for career growth",
        "Effective presentation skills",
        "Networking tips for professionals",
        "Mindfulness and meditation at work",
        "Ergonomics and workplace health",
        "Efficient project management techniques",
        "Conflict resolution strategies",
        "Developing leadership skills",
        "Work-life balance tips",
        "Effective goal setting and achievement",
        "Mastering Microsoft Office tools",
        "Data privacy and compliance guidelines",
        "Cross-cultural communication in global teams",
        "Creative problem-solving techniques",
        "Financial literacy for employees",
        "Effective delegation skills",
        "Building resilience in the workplace",
        "Continuous learning and skill development",
        "Giving and receiving constructive feedback",
        "Workplace diversity and inclusion practices",
        "Negotiation skills for professionals",
        "Managing up: Working effectively with your boss",
        "Improving customer service skills",
        "Effective decision-making techniques",
        "Personal productivity hacks",
      ],
    },
    {
      label: "Education",
      dropdown: ["Differentiated Instruction Strategies", "Incorporating Technology in the Classroom", "Fostering Social-Emotional Learning (SEL)", "Effective Classroom Management Techniques", "Formative Assessment Methods", "Promoting Critical Thinking Skills", "Inclusive Education Practices", "Project-Based Learning Implementation", "Strategies for Student Engagement", "Building Positive Parent-Teacher Relationships"],
    },

    {
      label: "Technology",
      dropdown: ["The Evolution of Mobile Phones", "How Artificial Intelligence is Transforming Industries", "Blockchain Explained for Beginners", "The Impact of 5G on Global Connectivity", "Smart Homes: Pros and Cons", "Future Trends in Virtual and Augmented Reality", "Understanding the Internet of Things (IoT)"],
    },
    {
      label: "Health & Wellness",
      dropdown: ["Top Benefits of Regular Exercise", "The Importance of Mental Health Awareness", "How Sleep Impacts Physical Health", "A Guide to Healthy Eating", "Mindfulness and Meditation Basics", "Understanding Your Immune System", "Popular Diet Plans: A Comparison"],
    },
    {
      label: "Environment & Sustainability",
      dropdown: ["Understanding Your Carbon Footprint", "10 Ways to Reduce Plastic Waste", "Renewable Energy Sources Explained", "The Water Crisis: Causes and Solutions", "Effects of Deforestation on Climate", "How Recycling Impacts the Planet"],
    },
    {
      label: "Education",
      dropdown: ["Differentiated Instruction Strategies", "Incorporating Technology in the Classroom", "Benefits of Bilingual Education", "The Impact of Online Learning", "Understanding the Montessori Method", "A Guide to Early Childhood Education"],
    },
    {
      label: "Business & Finance",
      dropdown: ["Investment Basics for Beginners", "How to Create a Business Plan", "Understanding Financial Statements", "Budgeting for Small Businesses", "Marketing Strategies for Startups", "The Importance of Financial Literacy"],
    },
    {
      label: "Food & Nutrition",
      dropdown: ["The Basics of a Balanced Diet", "Benefits of Organic Foods", "Understanding Nutritional Labels", "Top Superfoods for Health", "Pros and Cons of Veganism", "Meal Prep Tips for Busy Schedules"],
    },
    {
      label: "Travel & Tourism",
      dropdown: ["Top Eco-Friendly Travel Destinations", "Budgeting Tips for Travelers", "How to Travel Sustainably", "A Guide to Backpacking Europe", "Travel Safety Tips for Tourists", "Popular Tourist Spots Around the World"],
    },
    {
      label: "Science & Research",
      dropdown: ["A Guide to the Scientific Method", "Top Discoveries in Astronomy", "Understanding Climate Science", "Genetics 101: What We Inherit", "Breakthroughs in Renewable Energy", "The Basics of Quantum Mechanics"],
    },
    {
      label: "Art & Design",
      dropdown: ["Understanding Color Theory", "History of Modern Art Movements", "Typography Essentials for Designers", "Basics of Graphic Design", "Famous Sculptures Around the World", "How to Create a Balanced Composition"],
    },
    {
      label: "Sports & Recreation",
      dropdown: ["A Guide to Yoga for Beginners", "Top Health Benefits of Cycling", "Understanding Different Types of Sports", "The Rules of Popular Team Sports", "Most Popular Outdoor Activities", "How to Get Started with Rock Climbing"],
    },
  ],
};
const options = [];

for (let chip of chipOptions.chips) {
  for (let option of chip.dropdown) {
    options.push({ title: option, category: chip.label });
  }
}

export default function AiSubjectAutocomplete({ setSelectedSubject }) {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.title}
      filterOptions={(options, state) => {
        const query = state.inputValue.toLowerCase();
        return options.filter((option) => option.title.toLowerCase().includes(query) || option.category.toLowerCase().includes(query));
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <div>
            <div>{option.title}</div>
            <div
              style={{
                fontSize: "0.7rem",
                color: "#555",
              }}
            >
              {option.category}
            </div>
          </div>
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Enter a Topic" />}
      onChange={(e, value) => {
        setSelectedSubject(value.title);
      }}
    />
  );
}
