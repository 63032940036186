import React, { useState, useRef, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Avatar, IconButton, CircularProgress, Slider } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import YesIcon from "@mui/icons-material/Check";
import NoIcon from "@mui/icons-material/Close";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { req } from "./utils/ServerUtils";
import { useSVG } from "./SVGContext";
import CopyTextField from "./CopyTextField";
import VideoPlayer from "./VideoPlayer";
import { Credits } from "./Credits";

const EditProfileDialog = ({ onClose, dialog = "profile" }) => {
  const { userInfo, setUserInfo } = useSVG();

  const [name, setName] = useState(userInfo?.name);
  const [email, setEmail] = useState(userInfo?.email);
  const [token, setToken] = useState(userInfo?.tokens.length > 0 ? userInfo?.tokens[0] : "");
  const [fixedImage, setFixedImage] = useState(userInfo?.profile_pic_cropped || "/images/profilepic.jpg");

  const [image, setImage] = useState(null);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(1.2); // Initial zoom level

  const fileRef = useRef(null);

  const editorRef = useRef(null);
  const [tokenCopied, setTokenCopied] = useState(false);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleZoomChange = (event, newValue) => {
    setScale(newValue);
  };

  const handleSave = () => {
    // TODO: update name
    onClose();
  };

  useEffect(() => {
    setName(userInfo?.name);
    setEmail(userInfo?.email);
    setFixedImage(userInfo?.profile_pic_cropped + "?t=" + new Date().getTime() || "/images/profilepic.jpg");
    setToken(userInfo?.tokens.length > 0 ? userInfo?.tokens[0] : "");
  }, [userInfo]);

  function onCropComplete(blob) {
    setImage(null);

    const formData = new FormData();
    formData.append("profile_pic_cropped", blob, "cropped-image.png");
    setLoading(true);
    req("/upload_profile_pic", "POST", formData, false)
      .then((res) => {
        setLoading(false);
        userInfo.profile_pic_cropped = res.profile_pic_url;
        setUserInfo({ ...userInfo });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function genToken() {
    req("/generate_token", "POST").then((res) => {
      userInfo.tokens = [res.token];
      setUserInfo({ ...userInfo });
      setToken(res.token);
    });
  }

  function copyToken() {
    navigator.clipboard.writeText(token);
    setTokenCopied(true);
    setTimeout(() => {
      setTokenCopied(false);
    }, 5000);
  }

  return (
    <Dialog open={Boolean(dialog)} onClose={onClose}>
      <DialogTitle>{dialog == "profile" ? "Your Profile" : "Your Token"}</DialogTitle>
      <DialogContent>
        {dialog == "profile" && (
          <>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              {!image && (
                <div style={{ position: "relative" }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                  <Avatar src={fixedImage} sx={{ width: 100, height: 100 }} />
                  {hover && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    >
                      <IconButton
                        component="label"
                        onClick={() => {
                          fileRef.current.click();
                        }}
                      >
                        <AddAPhotoIcon style={{ color: "#fff" }} />
                      </IconButton>
                    </div>
                  )}
                  <input ref={fileRef} type="file" accept="image/*" onChange={handleImageChange} hidden />
                </div>
              )}

              {image && (
                <>
                  <AvatarEditor ref={editorRef} image={image} width={150} height={150} border={50} borderRadius={75} scale={scale} crossOrigin="anonymous" />

                  <Slider value={scale} min={1} max={3} step={0.1} onChange={handleZoomChange} aria-labelledby="zoom-slider" style={{ width: "80%", marginTop: 20 }} />

                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "center",
                      marginTop: 10,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {!loading && (
                      <>
                        <IconButton
                          size="small"
                          color="success"
                          onClick={() => {
                            if (editorRef.current) {
                              editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
                                onCropComplete(blob);
                              }, "image/png");
                            }
                          }}
                        >
                          <YesIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => {
                            setImage(null);
                          }}
                        >
                          <NoIcon />
                        </IconButton>
                      </>
                    )}
                    {loading && <CircularProgress size={20} />}
                  </div>
                </>
              )}
            </div>

            <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth margin="dense" />
            <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="dense" disabled={true} />
          </>
        )}
        {dialog == "tokens" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignItems: "stretch",
              justifyContent: "start",
              width: 500,
            }}
          >
            {tokenCopied && (
              <div
                style={{
                  background: "#137e13",
                  color: "white",
                  padding: 20,
                }}
              >
                Copied token!
              </div>
            )}
            {token.length > 0 && (
              <div
                style={{
                  overflowWrap: "anywhere",
                  overflow: "wrap",
                  border: "1px solid #ccc",
                  borderRadius: 5,
                  padding: 10,
                  cursor: "pointer",
                }}
                onClick={copyToken}
              >
                {token}
              </div>
            )}
            <a href="/videos/token.mp4" target="_blank">
              Integrating with wordpress?
            </a>
          </div>
        )}
      </DialogContent>

      <DialogActions
        style={{
          padding: "0 24px 24px 24px",
        }}
      >
        {dialog == "profile" && (
          <>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </>
        )}
        {dialog == "tokens" && (
          <>
            <Button
              variant="contained"
              onClick={genToken}
              style={{
                textTransform: "none",
                backgroundColor: "#ccc",
                color: "#333",
              }}
            >
              Regenerate Token
            </Button>
            <Button
              variant="contained"
              onClick={copyToken}
              style={{
                textTransform: "none",
              }}
            >
              Copy Token
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditProfileDialog;
