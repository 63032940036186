// @ts-nocheck

import { ROOT_GROUP_ID } from "../values/constants";

function toSelectedObjects(selectedIds, items, groups) {
  let selectedGroupOrItems = [];
  for (let i of selectedIds) {
    if (items[i].group == ROOT_GROUP_ID) {
      // this means this item belongs to mainGroup
      selectedGroupOrItems.push(items[i]);
    } else {
      // this means this item belongs to a group other than mainGroup
      selectedGroupOrItems.push(groups[items[i].group]);
    }
  }
  return Array.from(new Set(selectedGroupOrItems));
}

export const arrange = (z, type) => {
  const { selectedIds, items, groups, defs, update, width, height } = z;
  const selectedObject = toSelectedObjects(selectedIds, items, groups)[0]; // item or group
  if (!selectedObject) return;

  const group = groups[selectedObject.group];
  const idx = group.children.indexOf(selectedObject.id);
  group.children = group.children.filter((id) => id != selectedObject.id);

  if (type == "bringtofront") {
    // all the way to the front of this group
    group.children.push(selectedObject.id);
  }
  if (type == "bringforward") {
    // one step forward
    group.children.splice(idx + 1, 0, selectedObject.id);
  }
  if (type == "sendbackward") {
    // one step backward
    group.children.splice(idx - 1, 0, selectedObject.id);
  }
  if (type == "sendtoback") {
    // all the way to the back
    group.children.unshift(selectedObject.id);
  }

  // align to page
  if (type == "left") {
    for (let i of selectedIds) {
      items[i].x = 0;
    }
  }
  if (type == "center") {
    for (let i of selectedIds) {
      items[i].x = width / 2 - items[i].width / 2;
    }
  }

  if (type == "right") {
    for (let i of selectedIds) {
      items[i].x = width - items[i].width;
    }
  }
  if (type == "top") {
    for (let i of selectedIds) {
      items[i].y = 0;
    }
  }
  if (type == "middle") {
    for (let i of selectedIds) {
      items[i].y = height / 2 - items[i].height / 2;
    }
  }
  if (type == "bottom") {
    for (let i of selectedIds) {
      items[i].y = height - items[i].height;
    }
  }

  console.log(items[selectedIds[0]]);

  update({
    groups,
    items: { ...items },
    defs,
    selectedIds,
  });
};
