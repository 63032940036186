import { TextField } from "@mui/material";
import { useSVG } from "../SVGContext";
import { useEffect, useState } from "react";

export function RotateBtn({ item }) {
  const { items, groups, defs, selectedIds, update } = useSVG();
  let [rotateDeg, setRotateDeg] = useState(item.rotateDeg);

  useEffect(() => {
    setRotateDeg(item.rotateDeg);
  }, [item]);

  return (
    <div
      style={{
        width: 60,
      }}
    >
      <TextField
        label="Angle"
        size="small"
        type="number"
        min="0"
        max="360"
        style={{
          width: 60,
        }}
        value={rotateDeg}
        onChange={(e) => {
          let val = parseInt(e.target.value);
          if (val < 0) val += 360;
          rotateDeg = val;
          setRotateDeg(rotateDeg);

          items[item.id].rotateDeg = val;
          update({ items, groups, defs, selectedIds });
        }}
      />
    </div>
  );
}
