// @ts-nocheck

import { useEffect, useRef, useState } from "react";
import { useSVG } from "./SVGContext";
import { findAncestralTransform } from "./utils/PointerUtils";
import { fillToFillId } from "./utils/utils";
import { getFrameStyle } from "./views/MpImageView";
import SVGItemView from "./views/SVGItemView";
import { Slider } from "@mui/material";

const ImageZoomer = ({ item, imageRef }) => {
  const { width, height, croppingItemId } = useSVG();
  const [points, setPoints] = useState(getPoints(item));
  const { items, groups, defs, selectedIds, update } = useSVG();
  const [imageZoom, setImageZoom] = useState(100);

  const handleWheel = (event) => {
    // event.preventDefault(); // Prevent default scrolling behavior
    // event.stopPropagation(); // Prevent the event from bubbling

    if (event.ctrlKey || event.metaKey) {
      const sign = event.deltaY < 0 ? 1 : -1;
      zoom(sign * 10);
    }
  };

  function zoom(d) {
    const top = +item.crop.top - d;
    const left = +item.crop.left + d;
    const right = +item.crop.right + d;
    const bottom = +item.crop.bottom + d;
    let newCrop = {
      top,
      right,
      bottom,
      left,
    };
    item.crop = newCrop;
    item.imageRect.width = +item.imageRect.width + 2 * d;
    item.imageRect.height = +item.imageRect.height + 2 * d;

    items[item.id] = item;
    update({ items, groups, defs, selectedIds });
  }

  useEffect(() => {
    // doing this manually because react makes wheel & touch events as passive listeners
    // where you cannot call e.preventDefault() https://stackoverflow.com/a/67258046

    imageRef?.current?.addEventListener("wheel", handleWheel);
    return () => {
      imageRef?.current && imageRef?.current?.removeEventListener("wheel", handleWheel);
    };
  }, [imageRef]);

  useEffect(() => {
    setPoints(getPoints(item));
  }, [item]);

  return (
    <>
      <g transform={"translate(" + item.x + ", " + item.y + ")"}>
        <foreignObject width={item.width} height={30} y={item.height + 20}>
          
        </foreignObject>
      </g>
    </>
  );
};

export default ImageZoomer;

export const getPoints = (item) => {
  return [
    {
      x: 0,
      y: 0,
      cursor: "nw-resize",
    },
    {
      x: item.width,
      y: 0,
      cursor: "ne-resize",
    },
    {
      x: item.width,
      y: item.height,
      cursor: "se-resize",
    },
    {
      x: 0,
      y: item.height,
      cursor: "sw-resize",
    },
  ];
};
