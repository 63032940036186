// @ts-nocheck

import { useState } from "react";

export function useIdGenerator() {
  const [counters, setCounters] = useState({});
  function id(type: string) {
    if (counters[type] == null) {
      counters[type] = 0;
    }
    counters[type]++;
    setCounters({ ...counters });
    return `${type}-${counters[type]}`;
  }
  return { id };
}
