export const DEFAULT_BRAND_TEXTS = [
  {
    defaultText: "Title",
    font: "Roboto",
    size: 24,
  },
  {
    defaultText: "Subtitle",
    font: "Roboto",
    size: 18,
  },
  {
    defaultText: "Body",
    font: "Open Sans",
    size: 14,
  },
  {
    defaultText: "Caption",
    font: "Roboto",
    size: 12,
  },
  {
    defaultText: "Quote",
    font: "Lato",
    size: 16,
  },
];
