import React, { useState } from "react";
import { TextField, Button, Typography, Snackbar } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { req } from "./utils/ServerUtils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [msg, setMsg] = useState("");

  const resetPassword = async (e) => {
    if (!validate()) return;
    const url = "/reset_password" + window.location.href.split("/reset_password")[1];
    req(url, "POST", JSON.stringify({ new_password: password1 })).then((res) => {
      if (res.error) {
        setMsg(res.error);
      } else if (res.password_reset) {
        window.location.href = "/login?password_reset=complete";
      }
    });
  };

  const validate = () => {
    setMsg("");
    if (password1.length < 8) {
      setMsg("Password must be at least 8 characters long");
      return false;
    }
    if (password1 !== password2) {
      setMsg("Passwords do not match");
      return false;
    }

    return true;
  };

  return (
    <div
      style={{
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        padding: 40,
        margin: "auto",
        maxWidth: 400,

        display: "flex",
        flexDirection: "column",
        gap: 20,
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      {msg && (
        <div
          style={{
            color: "red",
            textAlign: "center",
            padding: 20,
          }}
        >
          {msg}
        </div>
      )}
      <div
        style={{
          textAlign: "center",
          fontSize: "1.5rem",
          fontWeight: 600,
        }}
      >
        Please enter your new password
      </div>
      <TextField label="New Password" sx={{ width: "100%" }} type="password" name="password1" required value={password1} onChange={(e) => setPassword1(e.target.value)} />
      <TextField label="Re-enter Password" sx={{ width: "100%" }} type="password" name="password2" required value={password2} onChange={(e) => setPassword2(e.target.value)} />

      <Button
        fullWidth
        type="submit"
        variant="contained"
        onClick={() => {
          resetPassword();
        }}
      >
        Reset Password
      </Button>
    </div>
  );
};

export default ResetPassword;
