import { Button, Dialog, Divider, IconButton, Link, Menu, MenuItem } from "@mui/material";
import { LOGIN_URL, useSVG } from "./SVGContext";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SUBCATEGORIES } from "./values/Subcategories";

const style = {
  display: "flex",
  flexDirection: "row",
  gap: 5,
  justifyContent: "space-between",
  alignItems: "center",
};

const subStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 5,
  justifyContent: "start",
  alignItems: "center",
};

export function MainNavBar() {
  const { userInfo, designId } = useSVG();

  return (
    <>
      <div style={style}>
        <div style={subStyle}>
          <Button
            underline="none"
            href="/designs"
            sx={{
              textTransform: "none",
              marginRight: 2,
            }}
          >
            DesignKart
          </Button>
          <Button
            underline="none"
            href="/designs"
            sx={{
              textTransform: "none",
              transition: "none",

              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#f0f0f0",
                transition: "none",
              },
            }}
          >
            Home
          </Button>
          <Divider orientation="vertical" flexItem /> {/* Vertical separator */}
          <BrowseTemplates />
        </div>
        <div style={subStyle}>
          {!userInfo && (
            <Link underline="none" variant="button" href={LOGIN_URL}>
              Login
            </Link>
          )}
          {userInfo && <ProfileButton userInfo={userInfo} />}
        </div>
      </div>
    </>
  );
}

function ProfileButton({ userInfo }) {
  const [anchorEl, setAnchor] = useState(null);
  const [initials, setInitials] = useState("");

  useEffect(() => {
    if (userInfo) {
      setInitials(userInfo.name?.charAt(0) || userInfo.email?.charAt(0));
    }
  }, [userInfo]);

  const logout = () => {
    window.location.href = "https://api.designkart.in/logout";
  };

  return (
    <>
      <IconButton
        style={{ marginLeft: 10 }}
        variant="contained"
        onClick={(e) => {
          setAnchor(anchorEl ? null : e.currentTarget);
        }}
      >
        {initials}
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        {userInfo.email && <MenuItem>{userInfo.email}</MenuItem>}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

function BrowseTemplates() {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleMouseLeave = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        style={{
          display: "inline-block",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            flexdirection: "row",
            gap: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            underline="none"
            sx={{
              textTransform: "none",
              transition: "none",
              backgroundColor: anchorEl ? "#f0f0f0" : "transparent",
              "&:hover": {
                transition: "none",
              },
            }}
          >
            Browse Templates
          </Button>
          <KeyboardArrowDownIcon color="primary" />
        </div>

        <Menu
          style={{
            padding: 100,
            marginTop: 8,
          }}
          onMouseEnter={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              maxWidth: "500px",
              justifyContent: "start",
              alignItems: "center",
              gap: 5,
              padding: 20,
            }}
            onMouseLeave={handleMouseLeave}
          >
            {Object.keys(SUBCATEGORIES).map((cat) => (
              <Button
                underline="none"
                onClick={() => (window.location.href = "/templates/" + cat)}
                sx={{
                  textTransform: "none",
                  transition: "none",

                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "#f0f0f0",
                    transition: "none",
                  },
                }}
              >
                {cat[0].toUpperCase() + cat.slice(1)}
              </Button>
            ))}
          </div>
        </Menu>
      </div>
    </>
  );
}
