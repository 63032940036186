import { IconButton } from "@mui/material";
import HambergerMenu from "@mui/icons-material/Menu";
import { useSVG } from "./SVGContext";
import { ReactComponent as Logo } from "./logo.svg";

export function HamburgerLogo() {
  const { setDrawerOpen } = useSVG();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        justifyContent: "space-between",
        alignItems: "center",

        background: "transparent",
      }}
    >
      <IconButton
        style={{
          color: "#ccc",
        }}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <HambergerMenu />
      </IconButton>

      <Logo
        className="max-width-1000"
        style={{
          cursor: "pointer",
          height: 30,
        }}
        onClick={() => {
          window.location.href = "/";
        }}
      />

      <div
        className="min-width-1000"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Make My Brand
      </div>
    </div>
  );
}
