export const allGoogleFonts = {
  ABeeZee: 1,
  "ADLaM Display": 1,
  "AR One Sans": 1,
  Abel: 1,
  "Abhaya Libre": 1,
  Aboreto: 1,
  "Abril Fatface": 1,
  "Abyssinica SIL": 1,
  Aclonica: 1,
  Acme: 1,
  Actor: 1,
  Adamina: 1,
  "Advent Pro": 1,
  Afacad: 1,
  Agbalumo: 1,
  Agdasima: 1,
  "Aguafina Script": 1,
  Akatab: 1,
  "Akaya Kanadaka": 1,
  "Akaya Telivigala": 1,
  Akronim: 1,
  Akshar: 1,
  Aladin: 1,
  Alata: 1,
  Alatsi: 1,
  "Albert Sans": 1,
  Aldrich: 1,
  Alef: 1,
  Alegreya: 1,
  "Alegreya SC": 1,
  "Alegreya Sans": 1,
  "Alegreya Sans SC": 1,
  Aleo: 1,
  "Alex Brush": 1,
  Alexandria: 1,
  "Alfa Slab One": 1,
  Alice: 1,
  Alike: 1,
  "Alike Angular": 1,
  Alkalami: 1,
  Alkatra: 1,
  Allan: 1,
  Allerta: 1,
  "Allerta Stencil": 1,
  Allison: 1,
  Allura: 1,
  Almarai: 1,
  Almendra: 1,
  "Almendra Display": 1,
  "Almendra SC": 1,
  "Alumni Sans": 1,
  "Alumni Sans Collegiate One": 1,
  "Alumni Sans Inline One": 1,
  "Alumni Sans Pinstripe": 1,
  Amarante: 1,
  Amaranth: 1,
  "Amatic SC": 1,
  Amethysta: 1,
  Amiko: 1,
  Amiri: 1,
  "Amiri Quran": 1,
  Amita: 1,
  Anaheim: 1,
  "Andada Pro": 1,
  Andika: 1,
  "Anek Bangla": 1,
  "Anek Devanagari": 1,
  "Anek Gujarati": 1,
  "Anek Gurmukhi": 1,
  "Anek Kannada": 1,
  "Anek Latin": 1,
  "Anek Malayalam": 1,
  "Anek Odia": 1,
  "Anek Tamil": 1,
  "Anek Telugu": 1,
  Angkor: 1,
  "Annapurna SIL": 1,
  "Annie Use Your Telescope": 1,
  "Anonymous Pro": 1,
  Anta: 1,
  Antic: 1,
  "Antic Didone": 1,
  "Antic Slab": 1,
  Anton: 1,
  Antonio: 1,
  Anuphan: 1,
  Anybody: 1,
  "Aoboshi One": 1,
  Arapey: 1,
  Arbutus: 1,
  "Arbutus Slab": 1,
  "Architects Daughter": 1,
  Archivo: 1,
  "Archivo Black": 1,
  "Archivo Narrow": 1,
  "Are You Serious": 1,
  "Aref Ruqaa": 1,
  "Aref Ruqaa Ink": 1,
  Arima: 1,
  Arimo: 1,
  Arizonia: 1,
  Armata: 1,
  Arsenal: 1,
  Artifika: 1,
  Arvo: 1,
  Arya: 1,
  Asap: 1,
  "Asap Condensed": 1,
  Asar: 1,
  Asset: 1,
  Assistant: 1,
  Astloch: 1,
  Asul: 1,
  Athiti: 1,
  "Atkinson Hyperlegible": 1,
  Atma: 1,
  "Atomic Age": 1,
  Aubrey: 1,
  Audiowide: 1,
  "Autour One": 1,
  Average: 1,
  "Average Sans": 1,
  "Averia Gruesa Libre": 1,
  "Averia Libre": 1,
  "Averia Sans Libre": 1,
  "Averia Serif Libre": 1,
  "Azeret Mono": 1,
  B612: 1,
  "B612 Mono": 1,
  "BIZ UDGothic": 1,
  "BIZ UDMincho": 1,
  "BIZ UDPGothic": 1,
  "BIZ UDPMincho": 1,
  Babylonica: 1,
  "Bacasime Antique": 1,
  "Bad Script": 1,
  "Bagel Fat One": 1,
  Bahiana: 1,
  Bahianita: 1,
  "Bai Jamjuree": 1,
  "Bakbak One": 1,
  Ballet: 1,
  "Baloo 2": 1,
  "Baloo Bhai 2": 1,
  "Baloo Bhaijaan 2": 1,
  "Baloo Bhaina 2": 1,
  "Baloo Chettan 2": 1,
  "Baloo Da 2": 1,
  "Baloo Paaji 2": 1,
  "Baloo Tamma 2": 1,
  "Baloo Tammudu 2": 1,
  "Baloo Thambi 2": 1,
  "Balsamiq Sans": 1,
  Balthazar: 1,
  Bangers: 1,
  Barlow: 1,
  "Barlow Condensed": 1,
  "Barlow Semi Condensed": 1,
  Barriecito: 1,
  Barrio: 1,
  Basic: 1,
  Baskervville: 1,
  Battambang: 1,
  Baumans: 1,
  Bayon: 1,
  "Be Vietnam Pro": 1,
  "Beau Rivage": 1,
  "Bebas Neue": 1,
  Belanosima: 1,
  Belgrano: 1,
  Bellefair: 1,
  Belleza: 1,
  Bellota: 1,
  "Bellota Text": 1,
  BenchNine: 1,
  Benne: 1,
  Bentham: 1,
  "Berkshire Swash": 1,
  Besley: 1,
  "Beth Ellen": 1,
  Bevan: 1,
  "BhuTuka Expanded One": 1,
  "Big Shoulders Display": 1,
  "Big Shoulders Inline Display": 1,
  "Big Shoulders Inline Text": 1,
  "Big Shoulders Stencil Display": 1,
  "Big Shoulders Stencil Text": 1,
  "Big Shoulders Text": 1,
  "Bigelow Rules": 1,
  "Bigshot One": 1,
  Bilbo: 1,
  "Bilbo Swash Caps": 1,
  BioRhyme: 1,
  "BioRhyme Expanded": 1,
  Birthstone: 1,
  "Birthstone Bounce": 1,
  Biryani: 1,
  Bitter: 1,
  "Black And White Picture": 1,
  "Black Han Sans": 1,
  "Black Ops One": 1,
  Blaka: 1,
  "Blaka Hollow": 1,
  "Blaka Ink": 1,
  Blinker: 1,
  "Bodoni Moda": 1,
  Bokor: 1,
  "Bona Nova": 1,
  Bonbon: 1,
  "Bonheur Royale": 1,
  Boogaloo: 1,
  Borel: 1,
  "Bowlby One": 1,
  "Bowlby One SC": 1,
  "Braah One": 1,
  Brawler: 1,
  "Bree Serif": 1,
  "Bricolage Grotesque": 1,
  "Bruno Ace": 1,
  "Bruno Ace SC": 1,
  "Brygada 1918": 1,
  "Bubblegum Sans": 1,
  "Bubbler One": 1,
  Buda: 1,
  Buenard: 1,
  Bungee: 1,
  "Bungee Hairline": 1,
  "Bungee Inline": 1,
  "Bungee Outline": 1,
  "Bungee Shade": 1,
  "Bungee Spice": 1,
  Butcherman: 1,
  "Butterfly Kids": 1,
  Cabin: 1,
  "Cabin Condensed": 1,
  "Cabin Sketch": 1,
  "Cactus Classical Serif": 1,
  "Caesar Dressing": 1,
  Cagliostro: 1,
  Cairo: 1,
  "Cairo Play": 1,
  Caladea: 1,
  Calistoga: 1,
  Calligraffitti: 1,
  Cambay: 1,
  Cambo: 1,
  Candal: 1,
  Cantarell: 1,
  "Cantata One": 1,
  "Cantora One": 1,
  Caprasimo: 1,
  Capriola: 1,
  Caramel: 1,
  Carattere: 1,
  Cardo: 1,
  Carlito: 1,
  Carme: 1,
  "Carrois Gothic": 1,
  "Carrois Gothic SC": 1,
  "Carter One": 1,
  Castoro: 1,
  "Castoro Titling": 1,
  Catamaran: 1,
  Caudex: 1,
  Caveat: 1,
  "Caveat Brush": 1,
  "Cedarville Cursive": 1,
  "Ceviche One": 1,
  "Chakra Petch": 1,
  Changa: 1,
  "Changa One": 1,
  Chango: 1,
  "Charis SIL": 1,
  Charm: 1,
  Charmonman: 1,
  Chathura: 1,
  "Chau Philomene One": 1,
  "Chela One": 1,
  "Chelsea Market": 1,
  Chenla: 1,
  Cherish: 1,
  "Cherry Bomb One": 1,
  "Cherry Cream Soda": 1,
  "Cherry Swash": 1,
  Chewy: 1,
  Chicle: 1,
  Chilanka: 1,
  Chivo: 1,
  "Chivo Mono": 1,
  "Chocolate Classical Sans": 1,
  Chokokutai: 1,
  Chonburi: 1,
  Cinzel: 1,
  "Cinzel Decorative": 1,
  "Clicker Script": 1,
  "Climate Crisis": 1,
  Coda: 1,
  Codystar: 1,
  Coiny: 1,
  Combo: 1,
  Comfortaa: 1,
  Comforter: 1,
  "Comforter Brush": 1,
  "Comic Neue": 1,
  "Coming Soon": 1,
  Comme: 1,
  Commissioner: 1,
  "Concert One": 1,
  Condiment: 1,
  Content: 1,
  "Contrail One": 1,
  Convergence: 1,
  Cookie: 1,
  Copse: 1,
  Corben: 1,
  Corinthia: 1,
  Cormorant: 1,
  "Cormorant Garamond": 1,
  "Cormorant Infant": 1,
  "Cormorant SC": 1,
  "Cormorant Unicase": 1,
  "Cormorant Upright": 1,
  Courgette: 1,
  "Courier Prime": 1,
  Cousine: 1,
  Coustard: 1,
  "Covered By Your Grace": 1,
  "Crafty Girls": 1,
  Creepster: 1,
  "Crete Round": 1,
  "Crimson Pro": 1,
  "Crimson Text": 1,
  "Croissant One": 1,
  Crushed: 1,
  Cuprum: 1,
  "Cute Font": 1,
  Cutive: 1,
  "Cutive Mono": 1,
  "DM Mono": 1,
  "DM Sans": 1,
  "DM Serif Display": 1,
  "DM Serif Text": 1,
  "Dai Banna SIL": 1,
  Damion: 1,
  "Dancing Script": 1,
  Danfo: 1,
  Dangrek: 1,
  "Darker Grotesque": 1,
  "Darumadrop One": 1,
  "David Libre": 1,
  "Dawning of a New Day": 1,
  "Days One": 1,
  Dekko: 1,
  "Dela Gothic One": 1,
  "Delicious Handrawn": 1,
  Delius: 1,
  "Delius Swash Caps": 1,
  "Delius Unicase": 1,
  "Della Respira": 1,
  "Denk One": 1,
  Devonshire: 1,
  Dhurjati: 1,
  "Didact Gothic": 1,
  Diphylleia: 1,
  Diplomata: 1,
  "Diplomata SC": 1,
  "Do Hyeon": 1,
  Dokdo: 1,
  Domine: 1,
  "Donegal One": 1,
  Dongle: 1,
  "Doppio One": 1,
  Dorsa: 1,
  Dosis: 1,
  DotGothic16: 1,
  "Dr Sugiyama": 1,
  "Duru Sans": 1,
  DynaPuff: 1,
  Dynalight: 1,
  "EB Garamond": 1,
  "Eagle Lake": 1,
  "East Sea Dokdo": 1,
  Eater: 1,
  Economica: 1,
  Eczar: 1,
  "Edu NSW ACT Foundation": 1,
  "Edu QLD Beginner": 1,
  "Edu SA Beginner": 1,
  "Edu TAS Beginner": 1,
  "Edu VIC WA NT Beginner": 1,
  "El Messiri": 1,
  Electrolize: 1,
  Elsie: 1,
  "Elsie Swash Caps": 1,
  "Emblema One": 1,
  "Emilys Candy": 1,
  "Encode Sans": 1,
  "Encode Sans Condensed": 1,
  "Encode Sans Expanded": 1,
  "Encode Sans SC": 1,
  "Encode Sans Semi Condensed": 1,
  "Encode Sans Semi Expanded": 1,
  Engagement: 1,
  Englebert: 1,
  Enriqueta: 1,
  Ephesis: 1,
  Epilogue: 1,
  "Erica One": 1,
  Esteban: 1,
  Estonia: 1,
  "Euphoria Script": 1,
  Ewert: 1,
  Exo: 1,
  "Exo 2": 1,
  "Expletus Sans": 1,
  Explora: 1,
  Fahkwang: 1,
  "Familjen Grotesk": 1,
  "Fanwood Text": 1,
  Farro: 1,
  Farsan: 1,
  Fascinate: 1,
  "Fascinate Inline": 1,
  "Faster One": 1,
  Fasthand: 1,
  "Fauna One": 1,
  Faustina: 1,
  Federant: 1,
  Federo: 1,
  Felipa: 1,
  Fenix: 1,
  Festive: 1,
  Figtree: 1,
  "Finger Paint": 1,
  Finlandica: 1,
  "Fira Code": 1,
  "Fira Mono": 1,
  "Fira Sans": 1,
  "Fira Sans Condensed": 1,
  "Fira Sans Extra Condensed": 1,
  "Fjalla One": 1,
  "Fjord One": 1,
  Flamenco: 1,
  Flavors: 1,
  "Fleur De Leah": 1,
  "Flow Block": 1,
  "Flow Circular": 1,
  "Flow Rounded": 1,
  Foldit: 1,
  Fondamento: 1,
  "Fontdiner Swanky": 1,
  Forum: 1,
  "Fragment Mono": 1,
  "Francois One": 1,
  "Frank Ruhl Libre": 1,
  Fraunces: 1,
  "Freckle Face": 1,
  "Fredericka the Great": 1,
  Fredoka: 1,
  Freehand: 1,
  Freeman: 1,
  Fresca: 1,
  Frijole: 1,
  Fruktur: 1,
  "Fugaz One": 1,
  Fuggles: 1,
  "Fuzzy Bubbles": 1,
  "GFS Didot": 1,
  "GFS Neohellenic": 1,
  Gabarito: 1,
  Gabriela: 1,
  Gaegu: 1,
  Gafata: 1,
  "Gajraj One": 1,
  Galada: 1,
  Galdeano: 1,
  Galindo: 1,
  "Gamja Flower": 1,
  Gantari: 1,
  "Gasoek One": 1,
  Gayathri: 1,
  Gelasio: 1,
  "Gemunu Libre": 1,
  Genos: 1,
  "Gentium Book Plus": 1,
  "Gentium Plus": 1,
  Geo: 1,
  Geologica: 1,
  Georama: 1,
  Geostar: 1,
  "Geostar Fill": 1,
  "Germania One": 1,
  "Gideon Roman": 1,
  Gidugu: 1,
  "Gilda Display": 1,
  Girassol: 1,
  "Give You Glory": 1,
  "Glass Antiqua": 1,
  Glegoo: 1,
  Gloock: 1,
  "Gloria Hallelujah": 1,
  Glory: 1,
  Gluten: 1,
  "Goblin One": 1,
  "Gochi Hand": 1,
  Goldman: 1,
  "Golos Text": 1,
  Gorditas: 1,
  "Gothic A1": 1,
  Gotu: 1,
  "Goudy Bookletter 1911": 1,
  "Gowun Batang": 1,
  "Gowun Dodum": 1,
  Graduate: 1,
  "Grand Hotel": 1,
  "Grandiflora One": 1,
  Grandstander: 1,
  "Grape Nuts": 1,
  "Gravitas One": 1,
  "Great Vibes": 1,
  "Grechen Fuemen": 1,
  Grenze: 1,
  "Grenze Gotisch": 1,
  "Grey Qo": 1,
  Griffy: 1,
  Gruppo: 1,
  Gudea: 1,
  Gugi: 1,
  Gulzar: 1,
  Gupter: 1,
  Gurajada: 1,
  Gwendolyn: 1,
  Habibi: 1,
  "Hachi Maru Pop": 1,
  Hahmlet: 1,
  Halant: 1,
  "Hammersmith One": 1,
  Hanalei: 1,
  "Hanalei Fill": 1,
  Handjet: 1,
  Handlee: 1,
  "Hanken Grotesk": 1,
  Hanuman: 1,
  "Happy Monkey": 1,
  Harmattan: 1,
  "Headland One": 1,
  "Hedvig Letters Sans": 1,
  "Hedvig Letters Serif": 1,
  Heebo: 1,
  "Henny Penny": 1,
  "Hepta Slab": 1,
  "Herr Von Muellerhoff": 1,
  "Hi Melody": 1,
  "Hina Mincho": 1,
  Hind: 1,
  "Hind Guntur": 1,
  "Hind Madurai": 1,
  "Hind Siliguri": 1,
  "Hind Vadodara": 1,
  "Holtwood One SC": 1,
  "Homemade Apple": 1,
  Homenaje: 1,
  Honk: 1,
  Hubballi: 1,
  Hurricane: 1,
  "IBM Plex Mono": 1,
  "IBM Plex Sans": 1,
  "IBM Plex Sans Arabic": 1,
  "IBM Plex Sans Condensed": 1,
  "IBM Plex Sans Devanagari": 1,
  "IBM Plex Sans Hebrew": 1,
  "IBM Plex Sans JP": 1,
  "IBM Plex Sans KR": 1,
  "IBM Plex Sans Thai": 1,
  "IBM Plex Sans Thai Looped": 1,
  "IBM Plex Serif": 1,
  "IM Fell DW Pica": 1,
  "IM Fell DW Pica SC": 1,
  "IM Fell Double Pica": 1,
  "IM Fell Double Pica SC": 1,
  "IM Fell English": 1,
  "IM Fell English SC": 1,
  "IM Fell French Canon": 1,
  "IM Fell French Canon SC": 1,
  "IM Fell Great Primer": 1,
  "IM Fell Great Primer SC": 1,
  "Ibarra Real Nova": 1,
  Iceberg: 1,
  Iceland: 1,
  Imbue: 1,
  "Imperial Script": 1,
  Imprima: 1,
  "Inclusive Sans": 1,
  Inconsolata: 1,
  Inder: 1,
  "Indie Flower": 1,
  "Ingrid Darling": 1,
  Inika: 1,
  "Inknut Antiqua": 1,
  "Inria Sans": 1,
  "Inria Serif": 1,
  Inspiration: 1,
  "Instrument Sans": 1,
  "Instrument Serif": 1,
  Inter: 1,
  "Inter Tight": 1,
  "Irish Grover": 1,
  "Island Moments": 1,
  "Istok Web": 1,
  Italiana: 1,
  Italianno: 1,
  Itim: 1,
  "Jacquard 12": 1,
  "Jacquard 12 Charted": 1,
  "Jacquard 24": 1,
  "Jacquard 24 Charted": 1,
  "Jacquarda Bastarda 9": 1,
  "Jacquarda Bastarda 9 Charted": 1,
  "Jacques Francois": 1,
  "Jacques Francois Shadow": 1,
  Jaini: 1,
  "Jaini Purva": 1,
  Jaldi: 1,
  Jaro: 1,
  "Jersey 10": 1,
  "Jersey 10 Charted": 1,
  "Jersey 15": 1,
  "Jersey 15 Charted": 1,
  "Jersey 20": 1,
  "Jersey 20 Charted": 1,
  "Jersey 25": 1,
  "Jersey 25 Charted": 1,
  "JetBrains Mono": 1,
  "Jim Nightshade": 1,
  Joan: 1,
  "Jockey One": 1,
  "Jolly Lodger": 1,
  Jomhuria: 1,
  Jomolhari: 1,
  "Josefin Sans": 1,
  "Josefin Slab": 1,
  Jost: 1,
  "Joti One": 1,
  Jua: 1,
  Judson: 1,
  Julee: 1,
  "Julius Sans One": 1,
  Junge: 1,
  Jura: 1,
  "Just Another Hand": 1,
  "Just Me Again Down Here": 1,
  K2D: 1,
  Kablammo: 1,
  Kadwa: 1,
  "Kaisei Decol": 1,
  "Kaisei HarunoUmi": 1,
  "Kaisei Opti": 1,
  "Kaisei Tokumin": 1,
  Kalam: 1,
  Kalnia: 1,
  Kameron: 1,
  Kanit: 1,
  "Kantumruy Pro": 1,
  Karantina: 1,
  Karla: 1,
  Karma: 1,
  Katibeh: 1,
  "Kaushan Script": 1,
  Kavivanar: 1,
  Kavoon: 1,
  "Kay Pho Du": 1,
  "Kdam Thmor Pro": 1,
  "Keania One": 1,
  "Kelly Slab": 1,
  Kenia: 1,
  Khand: 1,
  Khmer: 1,
  Khula: 1,
  Kings: 1,
  "Kirang Haerang": 1,
  "Kite One": 1,
  "Kiwi Maru": 1,
  "Klee One": 1,
  Knewave: 1,
  KoHo: 1,
  Kodchasan: 1,
  "Kode Mono": 1,
  "Koh Santepheap": 1,
  "Kolker Brush": 1,
  "Konkhmer Sleokchher": 1,
  Kosugi: 1,
  "Kosugi Maru": 1,
  "Kotta One": 1,
  Koulen: 1,
  Kranky: 1,
  Kreon: 1,
  Kristi: 1,
  "Krona One": 1,
  Krub: 1,
  Kufam: 1,
  "Kulim Park": 1,
  "Kumar One": 1,
  "Kumar One Outline": 1,
  "Kumbh Sans": 1,
  Kurale: 1,
  "LXGW WenKai Mono TC": 1,
  "LXGW WenKai TC": 1,
  "La Belle Aurore": 1,
  Labrada: 1,
  Lacquer: 1,
  Laila: 1,
  "Lakki Reddy": 1,
  Lalezar: 1,
  Lancelot: 1,
  Langar: 1,
  Lateef: 1,
  Lato: 1,
  "Lavishly Yours": 1,
  "League Gothic": 1,
  "League Script": 1,
  "League Spartan": 1,
  "Leckerli One": 1,
  Ledger: 1,
  Lekton: 1,
  Lemon: 1,
  Lemonada: 1,
  Lexend: 1,
  "Lexend Deca": 1,
  "Lexend Exa": 1,
  "Lexend Giga": 1,
  "Lexend Mega": 1,
  "Lexend Peta": 1,
  "Lexend Tera": 1,
  "Lexend Zetta": 1,
  "Libre Barcode 128": 1,
  "Libre Barcode 128 Text": 1,
  "Libre Barcode 39": 1,
  "Libre Barcode 39 Extended": 1,
  "Libre Barcode 39 Extended Text": 1,
  "Libre Barcode 39 Text": 1,
  "Libre Barcode EAN13 Text": 1,
  "Libre Baskerville": 1,
  "Libre Bodoni": 1,
  "Libre Caslon Display": 1,
  "Libre Caslon Text": 1,
  "Libre Franklin": 1,
  Licorice: 1,
  "Life Savers": 1,
  "Lilita One": 1,
  "Lily Script One": 1,
  Limelight: 1,
  "Linden Hill": 1,
  Linefont: 1,
  "Lisu Bosa": 1,
  Literata: 1,
  "Liu Jian Mao Cao": 1,
  Livvic: 1,
  Lobster: 1,
  "Lobster Two": 1,
  "Londrina Outline": 1,
  "Londrina Shadow": 1,
  "Londrina Sketch": 1,
  "Londrina Solid": 1,
  "Long Cang": 1,
  Lora: 1,
  "Love Light": 1,
  "Love Ya Like A Sister": 1,
  "Loved by the King": 1,
  "Lovers Quarrel": 1,
  "Luckiest Guy": 1,
  Lugrasimo: 1,
  Lumanosimo: 1,
  Lunasima: 1,
  Lusitana: 1,
  Lustria: 1,
  "Luxurious Roman": 1,
  "Luxurious Script": 1,
  "M PLUS 1": 1,
  "M PLUS 1 Code": 1,
  "M PLUS 1p": 1,
  "M PLUS 2": 1,
  "M PLUS Code Latin": 1,
  "M PLUS Rounded 1c": 1,
  "Ma Shan Zheng": 1,
  Macondo: 1,
  "Macondo Swash Caps": 1,
  Mada: 1,
  "Madimi One": 1,
  Magra: 1,
  "Maiden Orange": 1,
  Maitree: 1,
  "Major Mono Display": 1,
  Mako: 1,
  Mali: 1,
  Mallanna: 1,
  Mandali: 1,
  Manjari: 1,
  Manrope: 1,
  Mansalva: 1,
  Manuale: 1,
  Marcellus: 1,
  "Marcellus SC": 1,
  "Marck Script": 1,
  Margarine: 1,
  Marhey: 1,
  "Markazi Text": 1,
  "Marko One": 1,
  Marmelad: 1,
  Martel: 1,
  "Martel Sans": 1,
  "Martian Mono": 1,
  Marvel: 1,
  Mate: 1,
  "Mate SC": 1,
  "Maven Pro": 1,
  McLaren: 1,
  "Mea Culpa": 1,
  Meddon: 1,
  MedievalSharp: 1,
  "Medula One": 1,
  "Meera Inimai": 1,
  Megrim: 1,
  "Meie Script": 1,
  "Meow Script": 1,
  Merienda: 1,
  Merriweather: 1,
  "Merriweather Sans": 1,
  Metal: 1,
  "Metal Mania": 1,
  Metamorphous: 1,
  Metrophobic: 1,
  Michroma: 1,
  "Micro 5": 1,
  "Micro 5 Charted": 1,
  Milonga: 1,
  Miltonian: 1,
  "Miltonian Tattoo": 1,
  Mina: 1,
  Mingzat: 1,
  Miniver: 1,
  "Miriam Libre": 1,
  Mirza: 1,
  "Miss Fajardose": 1,
  Mitr: 1,
  "Mochiy Pop One": 1,
  "Mochiy Pop P One": 1,
  Modak: 1,
  "Modern Antiqua": 1,
  Mogra: 1,
  Mohave: 1,
  "Moirai One": 1,
  Molengo: 1,
  Molle: 1,
  Monda: 1,
  Monofett: 1,
  "Monomaniac One": 1,
  Monoton: 1,
  "Monsieur La Doulaise": 1,
  Montaga: 1,
  "Montagu Slab": 1,
  MonteCarlo: 1,
  Montez: 1,
  Montserrat: 1,
  "Montserrat Alternates": 1,
  "Montserrat Subrayada": 1,
  "Moo Lah Lah": 1,
  Mooli: 1,
  "Moon Dance": 1,
  Moul: 1,
  Moulpali: 1,
  "Mountains of Christmas": 1,
  "Mouse Memoirs": 1,
  "Mr Bedfort": 1,
  "Mr Dafoe": 1,
  "Mr De Haviland": 1,
  "Mrs Saint Delafield": 1,
  "Mrs Sheppards": 1,
  "Ms Madi": 1,
  Mukta: 1,
  "Mukta Mahee": 1,
  "Mukta Malar": 1,
  "Mukta Vaani": 1,
  Mulish: 1,
  Murecho: 1,
  MuseoModerno: 1,
  "My Soul": 1,
  Mynerve: 1,
  "Mystery Quest": 1,
  NTR: 1,
  Nabla: 1,
  Namdhinggo: 1,
  "Nanum Brush Script": 1,
  "Nanum Gothic": 1,
  "Nanum Gothic Coding": 1,
  "Nanum Myeongjo": 1,
  "Nanum Pen Script": 1,
  Narnoor: 1,
  Neonderthaw: 1,
  "Nerko One": 1,
  Neucha: 1,
  Neuton: 1,
  "New Rocker": 1,
  "New Tegomin": 1,
  "News Cycle": 1,
  Newsreader: 1,
  Niconne: 1,
  Niramit: 1,
  "Nixie One": 1,
  Nobile: 1,
  Nokora: 1,
  Norican: 1,
  Nosifer: 1,
  Notable: 1,
  "Nothing You Could Do": 1,
  "Noticia Text": 1,
  "Noto Color Emoji": 1,
  "Noto Emoji": 1,
  "Noto Kufi Arabic": 1,
  "Noto Music": 1,
  "Noto Naskh Arabic": 1,
  "Noto Nastaliq Urdu": 1,
  "Noto Rashi Hebrew": 1,
  "Noto Sans": 1,
  "Noto Sans Adlam": 1,
  "Noto Sans Adlam Unjoined": 1,
  "Noto Sans Anatolian Hieroglyphs": 1,
  "Noto Sans Arabic": 1,
  "Noto Sans Armenian": 1,
  "Noto Sans Avestan": 1,
  "Noto Sans Balinese": 1,
  "Noto Sans Bamum": 1,
  "Noto Sans Bassa Vah": 1,
  "Noto Sans Batak": 1,
  "Noto Sans Bengali": 1,
  "Noto Sans Bhaiksuki": 1,
  "Noto Sans Brahmi": 1,
  "Noto Sans Buginese": 1,
  "Noto Sans Buhid": 1,
  "Noto Sans Canadian Aboriginal": 1,
  "Noto Sans Carian": 1,
  "Noto Sans Caucasian Albanian": 1,
  "Noto Sans Chakma": 1,
  "Noto Sans Cham": 1,
  "Noto Sans Cherokee": 1,
  "Noto Sans Chorasmian": 1,
  "Noto Sans Coptic": 1,
  "Noto Sans Cuneiform": 1,
  "Noto Sans Cypriot": 1,
  "Noto Sans Cypro Minoan": 1,
  "Noto Sans Deseret": 1,
  "Noto Sans Devanagari": 1,
  "Noto Sans Display": 1,
  "Noto Sans Duployan": 1,
  "Noto Sans Egyptian Hieroglyphs": 1,
  "Noto Sans Elbasan": 1,
  "Noto Sans Elymaic": 1,
  "Noto Sans Ethiopic": 1,
  "Noto Sans Georgian": 1,
  "Noto Sans Glagolitic": 1,
  "Noto Sans Gothic": 1,
  "Noto Sans Grantha": 1,
  "Noto Sans Gujarati": 1,
  "Noto Sans Gunjala Gondi": 1,
  "Noto Sans Gurmukhi": 1,
  "Noto Sans HK": 1,
  "Noto Sans Hanifi Rohingya": 1,
  "Noto Sans Hanunoo": 1,
  "Noto Sans Hatran": 1,
  "Noto Sans Hebrew": 1,
  "Noto Sans Imperial Aramaic": 1,
  "Noto Sans Indic Siyaq Numbers": 1,
  "Noto Sans Inscriptional Pahlavi": 1,
  "Noto Sans Inscriptional Parthian": 1,
  "Noto Sans JP": 1,
  "Noto Sans Javanese": 1,
  "Noto Sans KR": 1,
  "Noto Sans Kaithi": 1,
  "Noto Sans Kannada": 1,
  "Noto Sans Kawi": 1,
  "Noto Sans Kayah Li": 1,
  "Noto Sans Kharoshthi": 1,
  "Noto Sans Khmer": 1,
  "Noto Sans Khojki": 1,
  "Noto Sans Khudawadi": 1,
  "Noto Sans Lao": 1,
  "Noto Sans Lao Looped": 1,
  "Noto Sans Lepcha": 1,
  "Noto Sans Limbu": 1,
  "Noto Sans Linear A": 1,
  "Noto Sans Linear B": 1,
  "Noto Sans Lisu": 1,
  "Noto Sans Lycian": 1,
  "Noto Sans Lydian": 1,
  "Noto Sans Mahajani": 1,
  "Noto Sans Malayalam": 1,
  "Noto Sans Mandaic": 1,
  "Noto Sans Manichaean": 1,
  "Noto Sans Marchen": 1,
  "Noto Sans Masaram Gondi": 1,
  "Noto Sans Math": 1,
  "Noto Sans Mayan Numerals": 1,
  "Noto Sans Medefaidrin": 1,
  "Noto Sans Meetei Mayek": 1,
  "Noto Sans Mende Kikakui": 1,
  "Noto Sans Meroitic": 1,
  "Noto Sans Miao": 1,
  "Noto Sans Modi": 1,
  "Noto Sans Mongolian": 1,
  "Noto Sans Mono": 1,
  "Noto Sans Mro": 1,
  "Noto Sans Multani": 1,
  "Noto Sans Myanmar": 1,
  "Noto Sans NKo": 1,
  "Noto Sans NKo Unjoined": 1,
  "Noto Sans Nabataean": 1,
  "Noto Sans Nag Mundari": 1,
  "Noto Sans Nandinagari": 1,
  "Noto Sans New Tai Lue": 1,
  "Noto Sans Newa": 1,
  "Noto Sans Nushu": 1,
  "Noto Sans Ogham": 1,
  "Noto Sans Ol Chiki": 1,
  "Noto Sans Old Hungarian": 1,
  "Noto Sans Old Italic": 1,
  "Noto Sans Old North Arabian": 1,
  "Noto Sans Old Permic": 1,
  "Noto Sans Old Persian": 1,
  "Noto Sans Old Sogdian": 1,
  "Noto Sans Old South Arabian": 1,
  "Noto Sans Old Turkic": 1,
  "Noto Sans Oriya": 1,
  "Noto Sans Osage": 1,
  "Noto Sans Osmanya": 1,
  "Noto Sans Pahawh Hmong": 1,
  "Noto Sans Palmyrene": 1,
  "Noto Sans Pau Cin Hau": 1,
  "Noto Sans Phags Pa": 1,
  "Noto Sans Phoenician": 1,
  "Noto Sans Psalter Pahlavi": 1,
  "Noto Sans Rejang": 1,
  "Noto Sans Runic": 1,
  "Noto Sans SC": 1,
  "Noto Sans Samaritan": 1,
  "Noto Sans Saurashtra": 1,
  "Noto Sans Sharada": 1,
  "Noto Sans Shavian": 1,
  "Noto Sans Siddham": 1,
  "Noto Sans SignWriting": 1,
  "Noto Sans Sinhala": 1,
  "Noto Sans Sogdian": 1,
  "Noto Sans Sora Sompeng": 1,
  "Noto Sans Soyombo": 1,
  "Noto Sans Sundanese": 1,
  "Noto Sans Syloti Nagri": 1,
  "Noto Sans Symbols": 1,
  "Noto Sans Symbols 2": 1,
  "Noto Sans Syriac": 1,
  "Noto Sans Syriac Eastern": 1,
  "Noto Sans TC": 1,
  "Noto Sans Tagalog": 1,
  "Noto Sans Tagbanwa": 1,
  "Noto Sans Tai Le": 1,
  "Noto Sans Tai Tham": 1,
  "Noto Sans Tai Viet": 1,
  "Noto Sans Takri": 1,
  "Noto Sans Tamil": 1,
  "Noto Sans Tamil Supplement": 1,
  "Noto Sans Tangsa": 1,
  "Noto Sans Telugu": 1,
  "Noto Sans Thaana": 1,
  "Noto Sans Thai": 1,
  "Noto Sans Thai Looped": 1,
  "Noto Sans Tifinagh": 1,
  "Noto Sans Tirhuta": 1,
  "Noto Sans Ugaritic": 1,
  "Noto Sans Vai": 1,
  "Noto Sans Vithkuqi": 1,
  "Noto Sans Wancho": 1,
  "Noto Sans Warang Citi": 1,
  "Noto Sans Yi": 1,
  "Noto Sans Zanabazar Square": 1,
  "Noto Serif": 1,
  "Noto Serif Ahom": 1,
  "Noto Serif Armenian": 1,
  "Noto Serif Balinese": 1,
  "Noto Serif Bengali": 1,
  "Noto Serif Devanagari": 1,
  "Noto Serif Display": 1,
  "Noto Serif Dogra": 1,
  "Noto Serif Ethiopic": 1,
  "Noto Serif Georgian": 1,
  "Noto Serif Grantha": 1,
  "Noto Serif Gujarati": 1,
  "Noto Serif Gurmukhi": 1,
  "Noto Serif HK": 1,
  "Noto Serif Hebrew": 1,
  "Noto Serif JP": 1,
  "Noto Serif KR": 1,
  "Noto Serif Kannada": 1,
  "Noto Serif Khitan Small Script": 1,
  "Noto Serif Khmer": 1,
  "Noto Serif Khojki": 1,
  "Noto Serif Lao": 1,
  "Noto Serif Makasar": 1,
  "Noto Serif Malayalam": 1,
  "Noto Serif Myanmar": 1,
  "Noto Serif NP Hmong": 1,
  "Noto Serif Old Uyghur": 1,
  "Noto Serif Oriya": 1,
  "Noto Serif Ottoman Siyaq": 1,
  "Noto Serif SC": 1,
  "Noto Serif Sinhala": 1,
  "Noto Serif TC": 1,
  "Noto Serif Tamil": 1,
  "Noto Serif Tangut": 1,
  "Noto Serif Telugu": 1,
  "Noto Serif Thai": 1,
  "Noto Serif Tibetan": 1,
  "Noto Serif Toto": 1,
  "Noto Serif Vithkuqi": 1,
  "Noto Serif Yezidi": 1,
  "Noto Traditional Nushu": 1,
  "Noto Znamenny Musical Notation": 1,
  "Nova Cut": 1,
  "Nova Flat": 1,
  "Nova Mono": 1,
  "Nova Oval": 1,
  "Nova Round": 1,
  "Nova Script": 1,
  "Nova Slim": 1,
  "Nova Square": 1,
  Numans: 1,
  Nunito: 1,
  "Nunito Sans": 1,
  "Nuosu SIL": 1,
  "Odibee Sans": 1,
  "Odor Mean Chey": 1,
  Offside: 1,
  Oi: 1,
  Ojuju: 1,
  "Old Standard TT": 1,
  Oldenburg: 1,
  Ole: 1,
  "Oleo Script": 1,
  "Oleo Script Swash Caps": 1,
  Onest: 1,
  "Oooh Baby": 1,
  "Open Sans": 1,
  Oranienbaum: 1,
  Orbit: 1,
  Orbitron: 1,
  Oregano: 1,
  "Orelega One": 1,
  Orienta: 1,
  "Original Surfer": 1,
  Oswald: 1,
  Outfit: 1,
  "Over the Rainbow": 1,
  Overlock: 1,
  "Overlock SC": 1,
  Overpass: 1,
  "Overpass Mono": 1,
  Ovo: 1,
  Oxanium: 1,
  Oxygen: 1,
  "Oxygen Mono": 1,
  "PT Mono": 1,
  "PT Sans": 1,
  "PT Sans Caption": 1,
  "PT Sans Narrow": 1,
  "PT Serif": 1,
  "PT Serif Caption": 1,
  Pacifico: 1,
  Padauk: 1,
  "Padyakke Expanded One": 1,
  Palanquin: 1,
  "Palanquin Dark": 1,
  "Palette Mosaic": 1,
  Pangolin: 1,
  Paprika: 1,
  Parisienne: 1,
  "Passero One": 1,
  "Passion One": 1,
  "Passions Conflict": 1,
  "Pathway Extreme": 1,
  "Pathway Gothic One": 1,
  "Patrick Hand": 1,
  "Patrick Hand SC": 1,
  Pattaya: 1,
  "Patua One": 1,
  Pavanam: 1,
  "Paytone One": 1,
  Peddana: 1,
  Peralta: 1,
  "Permanent Marker": 1,
  Petemoss: 1,
  "Petit Formal Script": 1,
  Petrona: 1,
  Philosopher: 1,
  Phudu: 1,
  Piazzolla: 1,
  Piedra: 1,
  "Pinyon Script": 1,
  "Pirata One": 1,
  "Pixelify Sans": 1,
  Plaster: 1,
  Platypi: 1,
  Play: 1,
  Playball: 1,
  Playfair: 1,
  "Playfair Display": 1,
  "Playfair Display SC": 1,
  "Playpen Sans": 1,
  "Plus Jakarta Sans": 1,
  Podkova: 1,
  "Poetsen One": 1,
  "Poiret One": 1,
  "Poller One": 1,
  "Poltawski Nowy": 1,
  Poly: 1,
  Pompiere: 1,
  "Pontano Sans": 1,
  "Poor Story": 1,
  Poppins: 1,
  "Port Lligat Sans": 1,
  "Port Lligat Slab": 1,
  "Potta One": 1,
  "Pragati Narrow": 1,
  Praise: 1,
  Prata: 1,
  Preahvihear: 1,
  "Press Start 2P": 1,
  Pridi: 1,
  "Princess Sofia": 1,
  Prociono: 1,
  Prompt: 1,
  "Prosto One": 1,
  "Protest Guerrilla": 1,
  "Protest Revolution": 1,
  "Protest Riot": 1,
  "Protest Strike": 1,
  "Proza Libre": 1,
  "Public Sans": 1,
  "Puppies Play": 1,
  Puritan: 1,
  "Purple Purse": 1,
  Qahiri: 1,
  Quando: 1,
  Quantico: 1,
  Quattrocento: 1,
  "Quattrocento Sans": 1,
  Questrial: 1,
  Quicksand: 1,
  Quintessential: 1,
  Qwigley: 1,
  "Qwitcher Grypen": 1,
  REM: 1,
  "Racing Sans One": 1,
  "Radio Canada": 1,
  "Radio Canada Big": 1,
  Radley: 1,
  Rajdhani: 1,
  Rakkas: 1,
  Raleway: 1,
  "Raleway Dots": 1,
  Ramabhadra: 1,
  Ramaraja: 1,
  Rambla: 1,
  "Rammetto One": 1,
  "Rampart One": 1,
  Ranchers: 1,
  Rancho: 1,
  Ranga: 1,
  Rasa: 1,
  Rationale: 1,
  "Ravi Prakash": 1,
  "Readex Pro": 1,
  Recursive: 1,
  "Red Hat Display": 1,
  "Red Hat Mono": 1,
  "Red Hat Text": 1,
  "Red Rose": 1,
  Redacted: 1,
  "Redacted Script": 1,
  "Reddit Mono": 1,
  "Reddit Sans": 1,
  "Reddit Sans Condensed": 1,
  Redressed: 1,
  "Reem Kufi": 1,
  "Reem Kufi Fun": 1,
  "Reem Kufi Ink": 1,
  "Reenie Beanie": 1,
  "Reggae One": 1,
  "Rethink Sans": 1,
  Revalia: 1,
  "Rhodium Libre": 1,
  Ribeye: 1,
  "Ribeye Marrow": 1,
  Righteous: 1,
  Risque: 1,
  "Road Rage": 1,
  Roboto: 1,
  "Roboto Condensed": 1,
  "Roboto Flex": 1,
  "Roboto Mono": 1,
  "Roboto Serif": 1,
  "Roboto Slab": 1,
  Rochester: 1,
  "Rock 3D": 1,
  "Rock Salt": 1,
  "RocknRoll One": 1,
  Rokkitt: 1,
  Romanesco: 1,
  "Ropa Sans": 1,
  Rosario: 1,
  Rosarivo: 1,
  "Rouge Script": 1,
  Rowdies: 1,
  "Rozha One": 1,
  Rubik: 1,
  "Rubik 80s Fade": 1,
  "Rubik Beastly": 1,
  "Rubik Broken Fax": 1,
  "Rubik Bubbles": 1,
  "Rubik Burned": 1,
  "Rubik Dirt": 1,
  "Rubik Distressed": 1,
  "Rubik Doodle Shadow": 1,
  "Rubik Doodle Triangles": 1,
  "Rubik Gemstones": 1,
  "Rubik Glitch": 1,
  "Rubik Glitch Pop": 1,
  "Rubik Iso": 1,
  "Rubik Lines": 1,
  "Rubik Maps": 1,
  "Rubik Marker Hatch": 1,
  "Rubik Maze": 1,
  "Rubik Microbe": 1,
  "Rubik Mono One": 1,
  "Rubik Moonrocks": 1,
  "Rubik Pixels": 1,
  "Rubik Puddles": 1,
  "Rubik Scribble": 1,
  "Rubik Spray Paint": 1,
  "Rubik Storm": 1,
  "Rubik Vinyl": 1,
  "Rubik Wet Paint": 1,
  Ruda: 1,
  Rufina: 1,
  "Ruge Boogie": 1,
  Ruluko: 1,
  "Rum Raisin": 1,
  "Ruslan Display": 1,
  "Russo One": 1,
  Ruthie: 1,
  Ruwudu: 1,
  Rye: 1,
  "STIX Two Text": 1,
  Sacramento: 1,
  Sahitya: 1,
  Sail: 1,
  Saira: 1,
  "Saira Condensed": 1,
  "Saira Extra Condensed": 1,
  "Saira Semi Condensed": 1,
  "Saira Stencil One": 1,
  Salsa: 1,
  Sanchez: 1,
  Sancreek: 1,
  Sansita: 1,
  "Sansita Swashed": 1,
  Sarabun: 1,
  Sarala: 1,
  Sarina: 1,
  Sarpanch: 1,
  "Sassy Frass": 1,
  Satisfy: 1,
  "Sawarabi Gothic": 1,
  "Sawarabi Mincho": 1,
  Scada: 1,
  "Scheherazade New": 1,
  "Schibsted Grotesk": 1,
  Schoolbell: 1,
  "Scope One": 1,
  "Seaweed Script": 1,
  "Secular One": 1,
  Sedan: 1,
  "Sedan SC": 1,
  "Sedgwick Ave": 1,
  "Sedgwick Ave Display": 1,
  Sen: 1,
  "Send Flowers": 1,
  Sevillana: 1,
  "Seymour One": 1,
  "Shadows Into Light": 1,
  "Shadows Into Light Two": 1,
  Shalimar: 1,
  "Shantell Sans": 1,
  Shanti: 1,
  Share: 1,
  "Share Tech": 1,
  "Share Tech Mono": 1,
  "Shippori Antique": 1,
  "Shippori Antique B1": 1,
  "Shippori Mincho": 1,
  "Shippori Mincho B1": 1,
  Shizuru: 1,
  Shojumaru: 1,
  "Short Stack": 1,
  Shrikhand: 1,
  Siemreap: 1,
  Sigmar: 1,
  "Sigmar One": 1,
  Signika: 1,
  "Signika Negative": 1,
  Silkscreen: 1,
  Simonetta: 1,
  "Single Day": 1,
  Sintony: 1,
  "Sirin Stencil": 1,
  "Six Caps": 1,
  Sixtyfour: 1,
  Skranji: 1,
  "Slabo 13px": 1,
  "Slabo 27px": 1,
  Slackey: 1,
  "Slackside One": 1,
  Smokum: 1,
  Smooch: 1,
  "Smooch Sans": 1,
  Smythe: 1,
  Sniglet: 1,
  Snippet: 1,
  "Snowburst One": 1,
  "Sofadi One": 1,
  Sofia: 1,
  "Sofia Sans": 1,
  "Sofia Sans Condensed": 1,
  "Sofia Sans Extra Condensed": 1,
  "Sofia Sans Semi Condensed": 1,
  Solitreo: 1,
  Solway: 1,
  "Sometype Mono": 1,
  "Song Myung": 1,
  Sono: 1,
  "Sonsie One": 1,
  Sora: 1,
  "Sorts Mill Goudy": 1,
  "Source Code Pro": 1,
  "Source Sans 3": 1,
  "Source Serif 4": 1,
  "Space Grotesk": 1,
  "Space Mono": 1,
  "Special Elite": 1,
  Spectral: 1,
  "Spectral SC": 1,
  "Spicy Rice": 1,
  Spinnaker: 1,
  Spirax: 1,
  Splash: 1,
  "Spline Sans": 1,
  "Spline Sans Mono": 1,
  "Squada One": 1,
  "Square Peg": 1,
  "Sree Krushnadevaraya": 1,
  Sriracha: 1,
  Srisakdi: 1,
  Staatliches: 1,
  Stalemate: 1,
  "Stalinist One": 1,
  "Stardos Stencil": 1,
  Stick: 1,
  "Stick No Bills": 1,
  "Stint Ultra Condensed": 1,
  "Stint Ultra Expanded": 1,
  Stoke: 1,
  Strait: 1,
  "Style Script": 1,
  Stylish: 1,
  "Sue Ellen Francisco": 1,
  "Suez One": 1,
  "Sulphur Point": 1,
  Sumana: 1,
  Sunflower: 1,
  Sunshiney: 1,
  "Supermercado One": 1,
  Sura: 1,
  Suranna: 1,
  Suravaram: 1,
  Suwannaphum: 1,
  "Swanky and Moo Moo": 1,
  Syncopate: 1,
  Syne: 1,
  "Syne Mono": 1,
  "Syne Tactile": 1,
  "Tac One": 1,
  "Tai Heritage Pro": 1,
  Tajawal: 1,
  Tangerine: 1,
  Tapestry: 1,
  Taprom: 1,
  Tauri: 1,
  Taviraj: 1,
  Teachers: 1,
  Teko: 1,
  Tektur: 1,
  Telex: 1,
  "Tenali Ramakrishna": 1,
  "Tenor Sans": 1,
  "Text Me One": 1,
  Texturina: 1,
  Thasadith: 1,
  "The Girl Next Door": 1,
  "The Nautigal": 1,
  Tienne: 1,
  Tillana: 1,
  "Tilt Neon": 1,
  "Tilt Prism": 1,
  "Tilt Warp": 1,
  Timmana: 1,
  Tinos: 1,
  Tiny5: 1,
  "Tiro Bangla": 1,
  "Tiro Devanagari Hindi": 1,
  "Tiro Devanagari Marathi": 1,
  "Tiro Devanagari Sanskrit": 1,
  "Tiro Gurmukhi": 1,
  "Tiro Kannada": 1,
  "Tiro Tamil": 1,
  "Tiro Telugu": 1,
  "Titan One": 1,
  "Titillium Web": 1,
  Tomorrow: 1,
  Tourney: 1,
  "Trade Winds": 1,
  "Train One": 1,
  Trirong: 1,
  Trispace: 1,
  Trocchi: 1,
  Trochut: 1,
  Truculenta: 1,
  Trykker: 1,
  "Tsukimi Rounded": 1,
  "Tulpen One": 1,
  "Turret Road": 1,
  "Twinkle Star": 1,
  Ubuntu: 1,
  "Ubuntu Condensed": 1,
  "Ubuntu Mono": 1,
  "Ubuntu Sans": 1,
  "Ubuntu Sans Mono": 1,
  Uchen: 1,
  Ultra: 1,
  Unbounded: 1,
  "Uncial Antiqua": 1,
  Underdog: 1,
  "Unica One": 1,
  UnifrakturCook: 1,
  UnifrakturMaguntia: 1,
  Unkempt: 1,
  Unlock: 1,
  Unna: 1,
  Updock: 1,
  Urbanist: 1,
  VT323: 1,
  "Vampiro One": 1,
  Varela: 1,
  "Varela Round": 1,
  Varta: 1,
  "Vast Shadow": 1,
  Vazirmatn: 1,
  "Vesper Libre": 1,
  "Viaoda Libre": 1,
  Vibes: 1,
  Vibur: 1,
  "Victor Mono": 1,
  Vidaloka: 1,
  Viga: 1,
  "Vina Sans": 1,
  Voces: 1,
  Volkhov: 1,
  Vollkorn: 1,
  "Vollkorn SC": 1,
  Voltaire: 1,
  "Vujahday Script": 1,
  "Waiting for the Sunrise": 1,
  Wallpoet: 1,
  "Walter Turncoat": 1,
  Warnes: 1,
  "Water Brush": 1,
  Waterfall: 1,
  Wavefont: 1,
  Wellfleet: 1,
  "Wendy One": 1,
  Whisper: 1,
  WindSong: 1,
  "Wire One": 1,
  "Wix Madefor Display": 1,
  "Wix Madefor Text": 1,
  "Work Sans": 1,
  Workbench: 1,
  "Xanh Mono": 1,
  Yaldevi: 1,
  "Yanone Kaffeesatz": 1,
  Yantramanav: 1,
  "Yarndings 12": 1,
  "Yarndings 12 Charted": 1,
  "Yarndings 20": 1,
  "Yarndings 20 Charted": 1,
  "Yatra One": 1,
  Yellowtail: 1,
  "Yeon Sung": 1,
  "Yeseva One": 1,
  Yesteryear: 1,
  Yomogi: 1,
  "Young Serif": 1,
  Yrsa: 1,
  Ysabeau: 1,
  "Ysabeau Infant": 1,
  "Ysabeau Office": 1,
  "Ysabeau SC": 1,
  "Yuji Boku": 1,
  "Yuji Hentaigana Akari": 1,
  "Yuji Hentaigana Akebono": 1,
  "Yuji Mai": 1,
  "Yuji Syuku": 1,
  "Yusei Magic": 1,
  "ZCOOL KuaiLe": 1,
  "ZCOOL QingKe HuangYou": 1,
  "ZCOOL XiaoWei": 1,
  "Zen Antique": 1,
  "Zen Antique Soft": 1,
  "Zen Dots": 1,
  "Zen Kaku Gothic Antique": 1,
  "Zen Kaku Gothic New": 1,
  "Zen Kurenaido": 1,
  "Zen Loop": 1,
  "Zen Maru Gothic": 1,
  "Zen Old Mincho": 1,
  "Zen Tokyo Zoo": 1,
  Zeyada: 1,
  "Zhi Mang Xing": 1,
  "Zilla Slab": 1,
  "Zilla Slab Highlight": 1,
};
