import React, { useState, useEffect, useRef, useCallback } from "react";
import { CircularProgress, ImageList, ImageListItem } from "@mui/material";

export function ScrollableImageList({ images, renderImage, fetchMore, onClick, finished, cols = 1 }) {
  const [loading, setLoading] = useState(false);
  const observerRef = useRef();
  const containerRef = useRef();
  const scrollRef = useRef();

  const loadMore = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    await fetchMore();
    setLoading(false);
  }, []);

  useEffect(() => {
    const options = {
      rootMargin: "20px",
      threshold: 0.5,
    };

    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        console.log("calling load more");
        loadMore();
      }
    }, options);

    if (containerRef.current) {
      observerRef.current.observe(containerRef.current);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [loadMore]);

  return (
    <div ref={scrollRef} style={{ overflowY: "auto", height: "100%" }}>
      <ImageList variant="masonry" cols={cols}>
        {images &&
          images.map((image, j) => (
            <ImageListItem key={j} onClick={() => onClick(image)}>
              {renderImage(image)}
            </ImageListItem>
          ))}
      </ImageList>
      {!finished && (
        <div
          ref={containerRef}
          style={{
            textAlign: "center",
            padding: 10,
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
