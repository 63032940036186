import { ListSubheader, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import WebFont from "webfontloader";
import { useSVG } from "./SVGContext";
import { GoogleFontFamilies, WebSafeFonts } from "./values/GoogleFontFamilies";
import { updateFontFace } from "./utils/proseMirrorUtils";
import { updateAllSelectedTexts } from "./topbar/TopbarText";

/* This component works directly with SVGContext's editor and selectedFont state.
 * It loads Google Fonts and displays them in a Select component.
 * It reads the selectedFont state and shows that font in <Select>.
 * When a font is selected, it updates the editor's font family, and manually triggers editor's onUpdate.
 */
export function FontsList({}) {
  const { editorState, setEditorState, editor, fonts, items, selectedIds, update } = useSVG();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (editorState) {
      if (fonts[index] != editorState.font) {
        const idx = fonts.findIndex((f) => f === editorState.font);
        setIndex(idx === -1 ? 0 : idx);
      }
    }
  }, [editorState]);

  function onFontSelect(font) {
    if (selectedIds.length > 1) {
      updateAllSelectedTexts(items, selectedIds, updateFontFace, font, update);
      return;
    }

    editor.chain().focus().setFontFamily(font).run();
    // Manually dispatch a transaction to trigger onUpdate
    editor.view.dispatch(editor.state.tr);

    setEditorState({
      ...editorState,
      font: font,
    });
  }

  useEffect(() => {
    // maybe once these are loaded, you can keep loading a few more?
    WebFont.load({
      google: {
        families: fonts,
      },
    });
  }, [fonts]);

  return (
    <Select
      size="small"
      id="select"
      value={index}
      onChange={(e, v) => {
        onFontSelect(fonts[e.target.value]);
      }}
      sx={{ padding: "2px 4px" }}
    >
      {fonts.map((font, idx) => {
        if (font.type === "divider") {
          return <ListSubheader sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.1rem" }}>{font}</ListSubheader>;
        }
        return (
          <MenuItem key={font} value={idx}>
            <div style={{ justifyContent: "space-between", width: "100%", fontFamily: font }}>{font}</div>
          </MenuItem>
        );
      })}
    </Select>
  );
}
