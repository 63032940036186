import TollIcon from "@mui/icons-material/Toll";
import { useSVG } from "./SVGContext";
import { Button, Dialog, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export function Credits({}) {
  const { userInfo } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!userInfo) return;

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{}}
        style={{
          textTransform: "none",
          borderRadius: 20,
          background: "white",
          cursor: "pointer",
          color: "green",

          display: "flex",
          flexDirection: "row",
          gap: 5,
          justifyContent: "start",
          alignItems: "center",
        }}
        onClick={(e) => {
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
      >
        <TollIcon />
        <div>{userInfo?.purchased_designs_blog}</div>
        <div className="min-width-1000">Credits</div>
      </Button>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} transformOrigin={{ vertical: "top", horizontal: "center" }} getContentAnchorEl={null} style={{ marginTop: 10 }}>
        {products.map((product, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setAnchorEl(null);
              window.open(product.url + `?checkout[email]=${userInfo.email}`, "_blank");
            }}
          >
            {product.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export const products = [
  {
    label: "Buy 10 Credits for $5",
    url: "https://store.makemybrand.ai/buy/eb1d4705-d610-42c3-b0fa-7ec0d14d394b",
  },
  {
    label: " Buy 30 Credits for $13",
    url: "https://store.makemybrand.ai/buy/a1ef03f8-8e10-4806-ab69-3c009868c0ca",
  },
];
