export function onLogin() {
  var callback = function () {};
  gtag("event", "conversion", {
    send_to: "AW-16719932764/qgbKCIeVhOcZENza16Q-",
    value: 1.0,
    currency: "INR",
    transaction_id: "",
    event_callback: callback,
  });
  return false;
}
export function onPurchase() {
  var callback = function () {};
  gtag("event", "conversion", {
    send_to: "AW-16719932764/iutOCI2VhOcZENza16Q-",
    value: 1.0,
    currency: "INR",
    transaction_id: "",
    event_callback: callback,
  });
  return false;
}

export function onFirstDesign(url) {
  var callback = function () {};
  gtag("event", "conversion", {
    send_to: "AW-16719932764/C8MACIqVhOcZENza16Q-",
    value: 1.0,
    currency: "INR",
    transaction_id: "",
    event_callback: callback,
  });
  return false;
}
