// @ts-nocheck
import { rand } from "./utils";

export const writeToClipboard = (json) => {
  navigator.clipboard.writeText(json).catch((error) => {
    console.error("Failed to write to clipboard:", error);
  });
};

export const readFromClipboard = () => {
  return navigator.clipboard.readText().catch((error) => {
    console.error("Failed to read from clipboard:", error);
  });
};

// cut
export function cut({ items, addItems, selectedIds, deleteItems }) {
  writeToClipboard(JSON.stringify(selectedIds.map((i) => items[i])));

  deleteItems(selectedIds);
}

// copy
export async function copy({ items, svgRef, width, height, selectedIds, fileManager }) {
  writeToClipboard(JSON.stringify(selectedIds.map((i) => items[i])));
}

// paste
export function paste({ items, groups, update }) {
  readFromClipboard()
    .then((json) => {
      const its = JSON.parse(json);
      for (let it of its) {
        try {
          it.id = "c-" + rand();
          it.x += 20;
          it.y += 20;
          items[it.id] = it;

          groups[it.group].children.push(it.id);
        } catch (e) {
          console.error("Could not paste", e);
        }
      }
      update({ items: { ...items }, groups: { ...groups } });
    })
    .catch((e) => {
      console.error("Could not paste", e);
    });
}
