export function Terms() {
  return (
    <div class="container mt-5">
      <h1>Terms & Conditions</h1>
      <strong>Effective Date:</strong> September 14, 2024
      <h4>1. Introduction</h4>
      <p>Welcome to Designkart. By accessing and using our platform, you agree to comply with these Terms & Conditions. If you do not agree with any part of these terms, please do not use our services.</p>
      <h4>2. Services Provided</h4>
      <p>Designkart offers an online design platform where users can create and download various designs, including posters, invitations, and infographics. Our services are provided on a pay-per-use basis.</p>
      <h4>3. User Responsibilities</h4>
      <ul>
        <li>You must be at least 18 years old to use our platform.</li>
        <li>You agree to use the platform for lawful purposes only.</li>
        <li>You are responsible for maintaining the confidentiality of your account and password.</li>
      </ul>
      <h4>4. Payment and Pricing</h4>
      <ul>
        <li>All designs are available on a pay-per-use basis. Prices are clearly indicated on the platform.</li>
        <li>Payments are processed securely through our third-party payment provider.</li>
        <li>We reserve the right to change our pricing at any time.</li>
      </ul>
      <h4>5. Intellectual Property</h4>
      <p>All designs created using our platform remain the intellectual property of Designkart until payment is completed. Upon payment, you are granted a non-exclusive, non-transferable license to use the design for personal or commercial purposes.</p>
      <h4>6. Refund Policy</h4>
      <p>Due to the digital nature of our products, all sales are final. Refunds will only be issued in case of technical issues preventing you from downloading your design.</p>
      <h4>7. Limitation of Liability</h4>
      <p>Designkart is not liable for any damages resulting from the use or inability to use our platform. We do not guarantee that our platform will be available at all times or that it will be error-free.</p>
      <h4>8. Shipping Policy</h4>
      <p>Designkart provides only digital goods. No physical goods are available or shipped through our platform.</p>
      <h4>9. Changes to Terms</h4>
      <p>We reserve the right to update these Terms & Conditions at any time. Changes will be effective immediately upon posting.</p>
      <h4>10. Contact Information</h4>
      <p>
        If you have any questions or concerns regarding these Terms & Conditions, please contact us at <a href="mailto:support@designkart.in">support@designkart.in</a>.
      </p>
    </div>
  );
}
