import { Button } from "@mui/material";
import { useSVG } from "./SVGContext";
import { req } from "./utils/ServerUtils";

export function AddTemplateBtn({ addString, isBookmarked, bookmark, ...others }) {
  const {userInfo} = useSVG();

  return (
    <>
      <div
        {...others}
        className="add-btn"
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          display: "none",

          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            backgroundColor: "rgb(20 25 122 )",
            color: "white",
            borderRadius: "5px",
            padding: "10px 15px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          {addString}
        </Button>
      </div>
      <div
        className="bookmark-icon"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          bookmark();
        }}
      >
        <span
          style={{
            fontSize: "24px",
            color: isBookmarked ? "gold" : "#ccc",
          }}
        >
          ★
        </span>
      </div>
    </>
  );
}
