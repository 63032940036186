// @ts-nocheck

import { memo, useEffect, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import ShapesMap from "../values/ShapesMap";
import { rand } from "../utils";
import { ROOT_GROUP_ID } from "../values/constants";
import { ShapeClipPaths } from "../ShapeClipPaths";

// NOTE: d values are parsed each time shapes are loaded
export const ShapesPanel = memo(({ close, addShape }) => {
  const svgStyle = { margin: "10px", width: "60px" };
  const [fill, setFill] = useState("white");
  const [stroke, setStroke] = useState("black");

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ColorSquare
          color={fill}
          onChange={(color) => {
            setFill(color.hex);
          }}
        />
        <ColorSquare
          color={stroke}
          onChange={(color) => {
            setStroke(color.hex);
          }}
        />
      </div>
      <span style={{ height: 1, width: 1 }}>
        <svg>
          <defs>
            {ShapeClipPaths.map((s, index) => (
              <clipPath id={s.id} key={index}>
                <path d={s.d} />
              </clipPath>
            ))}
          </defs>
        </svg>
      </span>
      <div
        style={{
          padding: "10px",
          width: "100%",
          overflowY: "scroll",

          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        {ShapeClipPaths.map((s, index) => {
          const [scaleX, scaleY] = s.transform.match(/scale\(([^,]+),([^)]+)\)/).slice(1);
          const width = s.width * scaleX;
          const height = s.height * scaleY;

          return (
            <div
              style={{
                width,
                height,
              }}
              onClick={() => {
                addShape({
                  fill,
                  stroke,
                  width: s.width,
                  height: s.height,
                  scale: {
                    x: scaleX,
                    y: scaleY,
                  },
                  d: s.d,
                });
              }}
            >
              <div
                style={{
                  clipPath: `url(#${s.id})`,
                  width: s.width,
                  height: s.height,
                  backgroundColor: fill,
                  border: `1px solid ${stroke}`,
                  transform: s.transform,
                  transformOrigin: "0px 0px",
                }}
              ></div>
            </div>
          );
        })}
      </div>
    </>
  );
});

function RenderedShape({ shape, fill, stroke }) {
  const [d, setD] = useState("");
  useEffect(() => {
    if (shape.length) {
      setD(shape.join(" "));
    }
  }, [shape]);

  if (d) {
    return <path d={d} fill={fill} stroke={stroke} strokeWidth="2" />;
  } else {
    return <svg id={shape.id} viewBox={shape.viewbox} x={shape.x} y={shape.y} width={shape.width} height={shape.height} fill={shape.fill} stroke={shape.stroke} strokeWidth={shape.strokeWidth} dangerouslySetInnerHTML={{ __html: shape.svg }} overlay={false} />;
  }
}
