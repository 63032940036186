// @ts-nocheck

import { Cursor } from "../values/enums";
import { getSVGBoundingBox, isNumber } from "./utils";

export function setX(item, x) {
  if (isNumber(item.x)) {
    item.x = x;
  } else {
    item.mpTransforms = item.mpTransforms || {};
    item.mpTransforms.translate = item.mpTransforms.translate || { x: 0, y: 0 };
    item.mpTransforms.translate.x = x;
    // item.mpTransformStr = getTransformStr(item);
  }
  return item;
}
export function setY(item, y) {
  if (isNumber(item.y)) {
    item.y = y;
  } else {
    item.mpTransforms = item.mpTransforms || {};
    item.mpTransforms.translate = item.mpTransforms.translate || { x: 0, y: 0 };
    item.mpTransforms.translate.y = y;
    // item.mpTransformStr = getTransformStr(item);
  }
  return item;
}

export function transform(svgRef, item, transformation) {
  item.mp = item.mp || {};
  item.mpTransforms = item.mpTransforms || {
    translate: { x: 0, y: 0 },
    scale: { x: 1, y: 1 },
    rotate: { angle: 0 },
  };
  const t = transformation,
    mp = item.mpTransforms;

  mp.translate = mp.translate || { x: 0, y: 0 };
  mp.scale = mp.scale || { x: 1, y: 1 };
  mp.rotate = mp.rotate || { angle: 0 };

  if (t.type == "translate") {
    if (isNumber(item.x) && isNumber(item.y)) {
      item.x = +item.x + +t.x;
      item.y = +item.y + +t.y;
    } else {
      mp.translate.x = +mp.translate.x + +t.x;
      mp.translate.y = +mp.translate.y + +t.y;
    }
  }
  item.mpTransforms = mp;
  // item.mpTransformStr = getTransformStr(svgRef, item);

  return item;
}

export function getTransformStr(svgRef, item) {
  const t = item.mpTransforms;
  const translate = t.translate ? `translate(${t.translate.x}, ${t.translate.y})` : "";
  const scale = t.scale ? `scale(${t.scale.x}, ${t.scale.y})` : "";
  let rotate = "",
    b;

  if (t.rotate) {
    const elem = document.getElementById(item.id);
    b = getSVGBoundingBox(svgRef, elem);
    if (b) {
      const center = {
        x: b.x + b.width / 2,
        y: b.y + b.height / 2,
      };
      rotate = `rotate(${t.rotate.angle}, ${center.x}, ${center.y})`;
    }
  }
  return `${translate} ${scale} ${rotate}`;
}
