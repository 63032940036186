import { useEffect, useState } from "react";
import { useSVG } from "./SVGContext";
import { SCALE_PREVIEW } from "./values/constants";

export function PreviewPanel() {
  const { fileManager, svgRef, width, height, items } = useSVG();
  const [url, setUrl] = useState("");

  useEffect(() => {
    fileManager
      .getPreviewImage({
        svgRef,
        width,
        height,
        items,
        scale: 1,
      })
      .then((canvas) => {
        const base64 = canvas.toDataURL("image/png");
        setUrl(base64);
      });
  }, []);

  return (
    <>
      <img
        style={{
          width: "100%",
        }}
        src={url}
        alt="preview"
      />
    </>
  );
}
