import React, { useState, useEffect, useRef } from "react";
import { PALETTES } from "../values/Palettes";
import { CircularProgress } from "@mui/material";

const LazyPalettes = ({ onPaletteSelect }) => {
  const [allPalettes, setAllPalettes] = useState([]); // Holds all palettes
  const [displayedPalettes, setDisplayedPalettes] = useState([]); // Holds currently displayed palettes
  const [loading, setLoading] = useState(false);

  const containerRef = useRef(null);
  const rowsToShow = 15; // Limit to 5 rows of palettes at a time

  // Flatten the palettes array and set it as the "allPalettes" state
  useEffect(() => {
    let res = [];
    for (let k of PALETTES) {
      res = [...res, ...k.palettes];
    }
    setAllPalettes(res);
  }, []);

  // Initially show the first 5 rows of palettes (5 rows, each with multiple colors)
  useEffect(() => {
    setDisplayedPalettes(allPalettes.slice(0, rowsToShow));
  }, [allPalettes]);

  // Load more palettes when the user scrolls to the bottom
  const loadMorePalettes = () => {
    if (loading) return;
    setLoading(true);

    setTimeout(() => {
      const currentCount = displayedPalettes.length;
      // Get the next 5 rows
      const nextPalettes = allPalettes.slice(currentCount, currentCount + rowsToShow);
      setDisplayedPalettes((prev) => [...prev, ...nextPalettes]);
      setLoading(false);
    }, 1000); // Simulate loading delay
  };

  useEffect(() => {
    const handleScroll = () => {
      const bottom = containerRef.current.scrollHeight === containerRef.current.scrollTop + containerRef.current.clientHeight;
      if (bottom) {
        loadMorePalettes();
      }
    };

    const refCurrent = containerRef.current;
    refCurrent.addEventListener("scroll", handleScroll);

    return () => {
      refCurrent.removeEventListener("scroll", handleScroll);
    };
  }, [displayedPalettes, loading]);

  return (
    <div
      ref={containerRef}
      style={{
        width: 300,
        height: 400,
        overflowY: "scroll",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {displayedPalettes.map((palette, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              margin: 10,
              padding: 10,
              cursor: "pointer",
            }}
            className="palette-holder"
            onClick={() => {
              onPaletteSelect(palette);
              setDisplayedPalettes(allPalettes.slice(0, rowsToShow));
            }}
          >
            {palette.map((color, colorIndex) => (
              <div
                key={colorIndex}
                style={{
                  backgroundColor: color,
                  width: 300 / palette.length,
                  height: 50,
                  borderTopLeftRadius: colorIndex == 0 ? 5 : 0,
                  borderBottomLeftRadius: colorIndex == 0 ? 5 : 0,
                  borderTopRightRadius: colorIndex == palette.length - 1 ? 5 : 0,
                  borderBottomRightRadius: colorIndex == palette.length - 1 ? 5 : 0,
                }}
              />
            ))}
          </div>
        ))}
      </div>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        ></div>
      )}
    </div>
  );
};

export default LazyPalettes;
