import { useEffect, useState } from "react";
import { useSVG } from "../SVGContext";
import { updateAllSelectedTexts } from "./TopbarText";
import { Button, ButtonGroup, TextField } from "@mui/material";
import { generateHTML } from "@tiptap/core";
import { EditorExtensions } from "../views/Tiptap";

export function TopbarFontSize() {
  const [showTextField, setShowTextField] = useState(false);
  return (
    <>
      <FontSizePlusMinus showTextField={showTextField} setShowTextField={setShowTextField} />
      <FontSizeAbsolute showTextField={showTextField} setShowTextField={setShowTextField} />
    </>
  );
}
function FontSizePlusMinus({ showTextField, setShowTextField }) {
  if (showTextField) return null;
  const [fontSizeNum, setFontSizeNum] = useState(12);
  const { items, update, selectedIds, editorState, editor } = useSVG();

  function setFontSize(size, sign) {
    if (!editor.isFocused) {
      for (let id in selectedIds) {
        const item = items[selectedIds[id]];
        if (item.proseMirrorData) {
          item.proseMirrorData = recursivelyUpdateFontSize(item.proseMirrorData, sign, "relative");
          item.html = generateHTML(item.proseMirrorData, EditorExtensions);
          items[selectedIds[id]] = item;
        }
      }
      update({ items });
      return;
    }
    editor
      .chain()
      .focus()
      .setFontSize(size + "px")
      .run();
    editor.view.dispatch(editor.state.tr);
  }

  useEffect(() => {
    setFontSizeNum(whichFontSizeToShow(editor, editorState, items, selectedIds));
  }, [selectedIds, editorState, items]);

  return (
    <ButtonGroup>
      <Button onClick={() => setFontSize(fontSizeNum - 1, -1)}>
        <span>-</span>
      </Button>
      <Button onClick={() => setShowTextField(true)}>{fontSizeNum}</Button>
      <Button onClick={() => setFontSize(fontSizeNum + 1, 1)}>
        <span>+</span>
      </Button>
    </ButtonGroup>
  );
}

function FontSizeAbsolute({ showTextField, setShowTextField }) {
  const { update, items, selectedIds, editorState, editor } = useSVG();
  const [fontSize, setFontSize] = useState(12);

  useEffect(() => {
    setFontSize(whichFontSizeToShow(editor, editorState, items, selectedIds));
  }, [selectedIds, editorState, items]);

  return (
    <div
      style={{
        display: showTextField ? "flex" : "none",
        // width:0,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <TextField
        label="Size"
        type="number"
        size="small"
        value={fontSize}
        onChange={(e) => setFontSize(+e.target.value)}
        onBlur={(e) => {
          let value = +e.target.value;
          if (isNaN(value)) {
            console.log("Not a number");
            return;
          }

          for (let id in selectedIds) {
            const item = items[selectedIds[id]];
            if (item.proseMirrorData) {
              item.proseMirrorData = recursivelyUpdateFontSize(item.proseMirrorData, value, "absolute");
              item.html = generateHTML(item.proseMirrorData, EditorExtensions);
              items[selectedIds[id]] = item;
            }
          }
          update({ items });

          setShowTextField(false);
        }}
        style={{ display: showTextField ? "block" : "none", width: 60 }}
      />
    </div>
  );
}

function whichFontSizeToShow(editor, editorState, items, selectedIds) {
  if (editor.isFocused) {
    return editorState.fontSize.replace ? +editorState.fontSize.replace("px", "") : +editorState.fontSize;
  } else if (selectedIds.length > 1) {
    return " ";
  } else if (selectedIds.length === 1) {
    return items[selectedIds[0]].proseMirrorData?.content[0]?.attrs?.fontSize?.replace("px", "");
  }
}

function recursivelyUpdateFontSize(node, value, absoluteOrRelative) {
  if (node.attrs?.fontSize) {
    if (absoluteOrRelative == "relative") node.attrs.fontSize = addToFontSize(node.attrs.fontSize, value);
    else node.attrs.fontSize = value + "px";
  }
  if (node.marks) {
    for (let mark of node.marks) {
      if (mark.attrs?.fontSize) {
        if (absoluteOrRelative == "relative") mark.attrs.fontSize = addToFontSize(mark.attrs.fontSize, value);
        else mark.attrs.fontSize = value + "px";
      }
    }
  }

  node.content?.forEach((child) => {
    recursivelyUpdateFontSize(child, value, absoluteOrRelative);
  });

  return node;
}

function addToFontSize(fontSizeStr, num) {
  let px = "";
  if (fontSizeStr.includes("px")) {
    px = "px";
  }
  if (fontSizeStr.includes("pt")) {
    px = "pt";
  }
  return +fontSizeStr.replace(px, "") + num + px;
}
